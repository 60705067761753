.o-icon {
  $root : ".o-icon";

  display: inline-block;
  position: relative;
  vertical-align: middle;

  // 우측화살표
  &--right-arrow {
    width: 5px;
    height: 5px;
    margin-left: 7px;

    &::before,
    &::after {
      position: absolute;
      left: 0;
      width: 5px;
      height: 2px;
      background-color: #666;
      content:"";
    }

    &::before {
      top: 0;
      transform: rotate(45deg);
    }

    &::after {
      bottom: 0;
      transform: rotate(-45deg);
    }

    .blue & {
      &::before,
      &::after {
        background-color: #0076c0;
      }
    }
  }

  // 에러 빨강
  &--error-red {
    width: 14px;
    height: 14px;
    margin-right: 10px;
    background: url("../img/com/ico_error_red.png") no-repeat 50% 50%;
  }

  // view
  &--view {
    width: 16px;
    height: 12px;
    margin-left: -4px;
    margin-right: -4px;
    background: url("../img/com/ico_view.png") no-repeat 50% 50%;
  }

  // trash
  &--trash {
    width: 14px;
    height: 14px;
    margin-left: -3px;
    margin-right: -3px;
    background: url("../img/com/ico_trash.png") no-repeat 50% 50%;
  }

  // download
  &--download {
    width: 12px;
    height: 15px;
    margin-left: -2px;
    margin-right: -2px;
    background: url("../img/com/ico_download.png") no-repeat 50% 50%;

    &:only-child {
      margin-left: 4px;
    }
  }

  // pdf
  &--pdf {
    width: 15px;
    height: 16px;
    margin-left: 4px;
    background: url("../img/com/ico_pdf.png") no-repeat 50% 50%;

    &#{$root}--large {
      width: 23px;
      height: 26px;
      background-image: url('../img/com/ico_pdf_large.png');
    }
  }

  // word
  &--word {
    width: 15px;
    height: 16px;
    margin-left: 4px;
    background: url("../img/com/ico_word.png") no-repeat 50% 50%;

    &#{$root}--large {
      width: 23px;
      height: 26px;
      background-image: url('../img/com/ico_word_large.png');
    }
  }

  // excel
  &--excel {
    width: 15px;
    height: 16px;
    margin-left: 4px;
    background: url("../img/com/ico_excel.png") no-repeat 50% 50%;

    &#{$root}--large {
      width: 23px;
      height: 26px;
      background-image: url('../img/com/ico_excel_large.png');
    }
  }

  // image
  &--image {
    &#{$root}--large {
      width: 23px;
      height: 26px;
      background-image: url('../img/com/ico_image_large.png');
    }
  }

  // text
  &--text {
    &#{$root}--large {
     width: 23px;
     height: 26px;
     background-image: url('../img/com/ico_text_large.png');
    }
  }

  // sms
  &--sms {
    width: 16px;
    height: 12px;
    margin-left: 2px;
    background: url("../img/com/ico_sns.png") no-repeat 50% 50%;
  }

  // facebook
  &--facebook {
    width: 16px;
    height: 12px;
    margin-left: 2px;
    background: url("../img/com/ico_facebook.png") no-repeat 50% 50%;
  }

  //add
  &--add {
    position: relative;
    width: 25px;
    height: 25px;

    &::before {
      display: block;
      margin: 0 auto;
      width: 5px;
      height: 25px;
      background-color: #0070ab;
      content: '';
    }

    &::after {
      display: block;
      position: absolute;
      top: 10px;
      left: 0;
      width: 25px;
      height: 5px;
      background-color: #00a19c;
      content: '';
    }
  }

  // plus
  &--plus {
    width: 34px;
    height: 34px;
    background: url("../img/com/ico_add.png") no-repeat 50% 50%;
  }

  // equals
  &--equals {
    width: 34px;
    height: 36px;
    background: url("../img/com/ico_equals.png") no-repeat 50% 50%;
  }

  // next
  &--next {
    width: 34px;
    height: 36px;
    background: url("../img/com/ico_next.png") no-repeat 50% 50%;
  }

  //mail
  &--mail {
    width: 52px;
    height: 40px;
    background: url("../img/com/ico_mail.png") no-repeat 50% 50%;
  }

  //more
  &--more {
    width: 10px;
    height: 10px;
    margin-left: 8px;
    background: url("../img/com/ico_plus.png") no-repeat 50% 50%;
  }

  //minus
  &--minus {
    width: 10px;
    height: 10px;
    margin-left: 8px;
    background: url("../img/com/ico_minus.png") no-repeat 50% 50%;
  }

  &--cross {
    position: relative;
    width: 18px;
    height: 18px;
    margin-right: 10px;
    padding-top: 6px;

    &:before,
    &:after {
      content: '';
      position: absolute;
      display: block;
      width: 100%;
      height: 4px;
      background-color: $blue;
    }

    &:after {
      transform: rotate(90deg);
      background-color: #00a19c;
    }
  }

  .o-button & {
    margin-top: -2px;
  }

  .c-text-list__item .o-button & {
    margin-top: -4px;
  }
}
