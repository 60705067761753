.c-tab {
  $root : ".c-tab";

  &__panel {
    display: none;

    &.is-active {
      display: block;
    }
  }

  &__button {
    display: flex;
    flex: 1;
    justify-content: center;
    align-items: center;
  }

  &__text {
    position: relative;
  }

  &--type1 {
    & > #{$root}__list {
      display: flex;
      height: 50px;
      border: 1px solid #d8d8d8;
      border-radius: 6px;
      background-color: #fff;
    }

    & > #{$root}__list > #{$root}__button {
      padding: 0 20px;
      font-family: youandiR;
      font-size: 17px;

      &:hover,
      &:focus {
        color: $blue;
      }

      &:nth-child(n+2) {
        position: relative;

        &::before {
          position: absolute;
          top: 50%;
          left: 0;
          width: 1px;
          height: 16px;
          background-color: #d8d8d8;
          transform: translateY(-50%);
          content: "";
        }
      }

      &.is-active {
        height: 54px;
        margin-top: -3px;
        background-color: $blue;
        border: none;
        border-radius: 8px;
        font-family: youandiB;
        color: #fff;
        box-shadow: 4px 7px 20px rgba(0, 79, 121, .20);

        &::before {
          display: none;
        }

        & + #{$root}__button {
          &::before {
            display: none;
          }
        }
      }
    }

    & > #{$root}__panel {
      margin-top: 60px;
    }

    .page-head.bg-full + & {
      position: relative;
      margin-top: -73px;

      &:after {
        content: '';
        position: absolute;
        display: block;
        top: 0;
        left: 50%;
        transform: translateX(-50%);
        min-width: 1280px;
        width: 100vw;
        height: 110px;
        background-color: #f8f8f8;
        z-index: -1;
      }

      & > #{$root}__panel {
        padding-top: 60px;

        // page-head.bg-full 다음에 오는 탭의 탭패널 안에서 탭패널 제목 바로 다음에 오는 헤딩태그의 윗 여백을 제거 (pmp110701010000)
        .o-heading:nth-child(2) {
          margin-top: 0;
        }
      }

      .product-wrap.bg-full:nth-child(2) {
        margin-top: -60px;
      }
    }

    h2.sr-only + .o-heading {
      margin-top: 0;
    }

    #{$root}__panel .inner-pagehead-text.bg-full {
      background-color: #f8f8f8;
      text-align: center;
      margin-top: -66px;
      padding-bottom: 55px;
      font-size: 17px;

      &+ :not(.product-wrap) {
        margin-top: 60px;
      }
    }
  }

  &--type2 {
    margin: 30px 0 0;

    & > #{$root}__list {
      display: flex;
    }

    & > #{$root}__list > #{$root}__button {
      position: relative;
      height: 50px;
      padding: 0 20px;
      background-color: #f8f8f8;
      border-top: 1px solid #d8d8d8;
      border-bottom: 1px solid #d8d8d8;
      border-left: 1px solid transparent;
      border-right: 1px solid transparent;
      font-family: youandiR;
      font-size: 15px;

      &:first-child {
        border-left: 1px solid #d8d8d8;
      }

      &:last-child {
        border-right: 1px solid #d8d8d8;
      }

      &:nth-child(n+2) {
        position: relative;

        &::before {
          position: absolute;
          top: 50%;
          left: 0;
          width: 1px;
          height: 16px;
          background-color: #d8d8d8;
          transform: translateY(-50%);
          content: "";
        }
      }

      &.is-active {
        border-top: 2px solid $blue;
        border-bottom-color: transparent;
        border-left: 1px solid #d8d8d8;
        border-right: 1px solid #d8d8d8;
        background-color: #fff;
        color: $blue;

        &::before {
          display: none;
        }

        & + #{$root}__button {
          &::before {
            display: none;
          }
        }
      }
    }

    & > #{$root}__panel {
      margin-top: 60px;

      .sr-only:first-child + .o-heading--2 {
        margin-top: 66px;
  
        + .c-tab--type4 {
          margin-top: 60px;
        }
      }
    }
  }

  &--type3 {
    & > #{$root}__list {
      font-size: 0;
    }

    & > #{$root}__list > #{$root}__button {
      display: inline-block;
      min-width: 100px;
      height: 40px;
      padding: 0 30px;
      border: 1px solid #d8d8d8;
      border-radius: 8px;
      font-family: youandiR;
      font-size: 15px;

      &:hover,
      &:focus {
        border-color: $blue;
        color: $blue;
      }

      &:nth-child(n+2) {
        margin-left: 4px;
      }

      &.is-active {
        border-color: $blue;
        background-color: $blue;
        color: #fff;
      }
    }
  }

  &--type4 {
    & > #{$root}__list {
      font-size: 0;
      text-align: center;
    }

    & > #{$root}__list > #{$root}__button {
      position: relative;
      display: inline-block;
      padding: 0 30px;
      font-family: youandiR;
      font-size: 17px;

      &:nth-child(n+2) {
        &::before {
          content: "";
          position: absolute;
          top: 3px;
          left: 0;
          width: 1px;
          height: 16px;
          background-color: #d8d8d8;
          outline: none;
        }
      }

      &.is-active {
        color: $blue;

        #{$root}__text {
          display: inline-block;
          border-bottom: 1px solid $blue;
        }
      }
    }

    & > #{$root}__panel {
      margin-top: 60px;
    }
  }

  &--button-small {
    #{$root}__button {
      font-size: 14px !important;
      line-height: 18px !important;
    }
  }

  .page-head + & {
    margin-top: -10px;
  }
}

[role="tabpanel"] {
  &:not(.is-active) {
    display: none;
  }
}
