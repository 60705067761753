// Common
// Info in Tab
.c-tab .info {
  background-color: #f8f8f8;
  border-radius: 10px;
  padding: 40px 50px;

  &::before {
    display: none;
  }
}

// 로그인 안에 있는 o-text reset - 이용등록 COM040401000000
.login .c-accordion .o-text.o-text--star {
  margin-top: 10px;
  margin-bottom: 0;
  font-size: 13px;
  font-family: $font-family-base;
  text-align: left;
}

// 보험료계산하기 하단 SMS카드 스타일 - [공시실] CUSI150212010000
.product-card--type4 {
  $root: '.product-card';

  min-height: auto;
  margin-top: 56px;
  text-align: center;
  background: #0076c0;
  border-bottom-right-radius: 15px;

  #{$root}__title {
    color: #fff;
  }
}

// 테이블 안 select is-disabled style - [공시실] CUSI150212010000
.c-table {
  .listbox.is-disabled .listbox__button{
    background: #f8f8f8;
  }
}

// hover accordion - [금융상품]fp020000000000, [윤리경영]coo030100000000
.c-accordion--type3.hover-board {
  $root: '.c-accordion';

  #{$root}__head:hover {
    background: #f8f8f8;
  }

  #{$root}__title {
    display: inline-block;
    max-width: 100%;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }

  #{$root}__title.show {
    color: #0079cc;
  }
}

// 게시판 다운로드용 sub title 요소 추가 - [공시실] CUSI150413020100
.board-detail__sub-title {
  margin-right: 30px;
}

// search-form 안 select박스 width 고정, 정렬, option text-align - [공시실] CUSI150102010101
.search-form {
  &--sm {
    .listbox {
      width: 300px;
    }
  }

  .listbox {
    display: inline-block;
    vertical-align: middle;
    
    &-panel__item {
      text-align: left;
    }
  }
}

// heading-right, text-right
.text-right,
.heading-right {
  .c-button-wrap {
    .o-button:nth-of-type(n+2) {
      margin-left: 30px;
    }
  }

  .o-text {
    display: inline-block;
    margin-top: 0;
  }
}

.text-right {
  text-align: right;
}

// ========================================
// ============== 02.보험상품 ==============
// [건강보장]나를위한암보험, 암보험, 성인병, 메디컬, 치아, 정기
// [사망보장]종신, 종신라이트, 종신선지급
// [노후자금]연금, 연금하이파이브
// [목적자금]저축
// [기업복지]단체상해, 연금, 연금저축

// [02.보험상품 공통] 보장내역 - 보장내용, 보험료예시, 해지환급금예시
.guarantee-info {
  margin-top: 55px;
  padding: 35px 40px 35px 50px;
  border-radius: 15px;
  border-bottom-right-radius: 40px;
  color: $white;
  text-align: center;
  background-color: #004b75;
  font-size: 18px;
  line-height: 32px;

  &:nth-of-type(n+2) {
    margin-top: 100px;
  }

  strong {
    display: block;
    font-size: 28px;
    margin-bottom: 15px;

    &:only-child {
      margin: 0;
    }
  }
}

.criteria {
  margin-left: 16px;
  color: #666;
  font-size: 16px;
  font-family: $font-family-base;
}

.source-box {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 12px 0;
  margin-top:160px;
  text-align: center;
  color: #666;
  background: #f8f8f8;
  font-size: 13px;

  &::before {
    content: "";
    position: absolute;
    display: block;
    top: -80px;
    width: 100%;
    height: 1px;
    background: #d8d8d8;
  }
}

.order-list {
  &__item:not(:last-child)::after {
    content: "";
    display: block;
    width: 1px;
    height: 1px;
    margin: 10px auto;
    border-left: 10px solid #d2d2d2;
    border-top: 10px solid transparent;
    transform: rotate(-45deg);
  }
}

// ===================================
// =========== 03.금융상품 ============

// 삭제예정
.dummy {
  width: 100%;
  height: 300px;
  margin-top: 98px;
  padding: 140px 0;
  text-align: center;
  background: rgba(255, 0, 0, 0.205);
}

// 금융상품 list
.loan-list {
  &--limit {
    margin: 0 -10px;
    text-align: center;
    font-size: 17px;

    @include clearfix;

    &__item {
      position: relative;
      float: left;
      width: 380px;
      height: 210px;
      padding: 40px;
      margin: 0 10px;
      background: #f8f8f8;

      .tit {
        display: block;
        margin-bottom: 10px;
        font-size: 14px;
      }
    }
  }

  &--bt {
    padding-top: 30px;
    margin-top: 30px;
    border-top: 1px solid #666;
  }
}

// [03. 금융상품] Main - FP010000000000
.loan-main {
  .guide-text {
    margin: 70px 0 60px;
    font-size: 19px;
    color: #007688;
    text-align: center;
  }

  .inside-list {
    $root: '.inside-list';
    height: 334px;
    margin: 0;

    &__item {
      display: table;
      width: 100%;
      padding-left: 0;
      padding-right: 0;
    }

    &__item:not(:first-child) {
      border-top: 1px solid #e8e8e8;
    }

    &__title,
    &__text {
      display: table-cell;
      float: none;
      vertical-align: middle;
    }

    &__text {
      text-align: right;
      width: auto;
      color: #0076c0;
      font-size: 30px;
      font-family: youandiB;

      small {
        font-size: 25px;
      }
    }

    &__subtext {
      position: relative;
      top: -3px;
      font-size: 19px;
      font-family: youandiR;
    }

    .o-text--star {
      margin-top: 0;
    }

    &--row3 {
      #{$root}__item {
        height: 33.333333%;
      }
    }

    &--row4 {
      #{$root}__item {
        height: 25%;
      }
    }
  }

  &-head {
    position: relative;
    padding: 80px 0 60px;
    text-align: center;
    color: #fff;
    background: #00a19c;

    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: -100%;
      right: -100%;
      height: 100%;
      background: #00a19c;
      z-index: -1;
    }

    &__title {
      margin-bottom: 28px;
      font-size: 34px;
      font-family: youandiB;
    }

    &__text {
      font-size: 23px;
      font-family: youandiR;
    }
  }

  &-menu {
    $root : '.loan-main-menu';
    display: flex;
    justify-content: center;
    margin-bottom: 100px;

    &__item {
      width: 540px;
      margin: 0 10px;
      border: 1px solid #cbcbcb;
      border-radius: 10px;
      transition: all 0.3s;

      &:hover {
        border: 1px solid #0076c0;
        box-shadow: 20px 25px 40px rgba(0, 0, 0, .14);
      }
    }

    &__head {
      position: relative;
      padding: 30px;
      background: #fff;
      border-radius: 10px;

      #{$root}__anchor {
        display: inline-block;
        padding: 0 18px 10px 0;
        transition: all 0.3s;

        &:hover {
          color: #0076c0;
        }
      }

      #{$root}__title {
        font-size: 21px;
        font-family: youandiB;
      }

      #{$root}__img {
        position: absolute;
        display: block;
        top: 42px;
        right: 30px;
      }

      #{$root}__text {
        display: block;
        font-size: 15px;
      }
    }

    &__panel {
      position: relative;
      padding: 0 30px;
      border-bottom-left-radius: 10px;
      border-bottom-right-radius: 10px;
      background: #f8f8f8;


      #{$root}__anchor {
        font-size: 15px;
        padding: 10px 10px 10px 0;
      }
    }
  }
}

// [03. 금융상품] Submain FAQ - FP020000000000
.faq-list {
  margin-top: 25px;
}

// [03.금융상품] 지도영역 - 주택담보대출(서울, 부산, 중부, 안양) - cusi160101000000
.map {
  width: 100%;
  height: 290px;
  margin-top: 26px;
  overflow: hidden;
}

// =======================================
// ============= 07.고객안내 ==============
// [공시실] announcement (public announcement place)
.search-form {
  @include clearfix;
}

.heading-right--select {
  @include clearfix;

  .o-heading {
    float: left;
  }

  .listbox {
    float: right;
    margin-top: 60px;
  }
}

// [공시실] 변액보험 td에 button여러개 들어가있는 경우 - CUSI150109010000
.c-table.announcement {
  .o-button.sm {
    margin: 4px 0;
  }
}

// [공시실] 직업분류 및 위험등급안내 - CUSI150111000000
.announcement-select1 {
  padding: 38px;
  height: 326px;
  border: 1px solid #0076c0;
  border-radius: 15px;
  overflow-y: auto;

  &__item {
    display: block;
    width: 100%;
    text-align: left;
    overflow: hidden;
    font-size: 17px;

    &:nth-child(n+2) {
      margin-top: 30px;
    }

    &:focus,
    &:hover {
      color: #0076c0;
    }
  }
}

.announcement-select2 {
  max-height: 355px;
  overflow-x: hidden;
  overflow-y: auto;

  tr:hover {
    background: #f8f8f8;
  }
}

// [공시실] 보험료 계산하기 - CUSI150212010000
.info-wrap--type4.announcement {
  $root: '.info-wrap';
  margin-top: 100px;
  padding: 56px 90px;

  #{$root}__item {
    display: flex;

    &:nth-child(2) {
      padding-bottom: 25px;
      margin: 18px 0 28px;
      border-bottom: 1px solid #d2d2d2;
    }
  }

  #{$root}__title {
    font-size: 23px;
  }

  #{$root}__content {
    color: #0076c0;
    margin-left: auto;
    font-size: 19px;
  }

  .o-button {
    position: initial;
    transform: none;
  }

  .sns {
    margin-left: auto;

    &__label {
      margin-right: 15px;
    }

    .o-button {
      width: 104px;
      font-size: 14px;
      font-family: $font-family-base;

      .o-icon--sms {
        position: relative;
        right: -18px;
      }
    }
  }
}

// [공시실] 테이블 상세보기 클릭시 tr추가되는 유형 - 상품목록 cusi150102010101
.c-table--announcement {
  .has-button {
    position: relative;
    padding-right: 113px;

    .o-button {
      margin: 0;
      position: absolute;
      top: 50%;
      right: 10px;
      transform: translateY(-50%);
    }
  }

  .product-detail {
    background: #f8f8f8;
    color: #666;

    &:first-child {
      position: relative;
      padding-left: 20px;
    }
  }
}

// ====================================
// ============= 11.기타 ==============
// [윤리경영] COO030100000000
.ethical-mgt {
  .c-tab--type2 {
    text-align: center;

    // reset css heading 태그는 왼쪽 정렬
    h4, h5, h6 {
      text-align: left;
    }
  }

  &__tit {
    margin: 65px 0 55px;
    text-align: center;
    font-family: youandiR;
    font-size: 34px;
  }

  &__maincopy {
    display: block;
    font-size: 27px;
    margin-bottom: 65px;
  }

  &__subcopy {
    margin-bottom: 65px;
    color: #666;
    font-size: 18px;
  }

  .label {
    display: block;
    background: linear-gradient(90deg, #0076c0 0, #00a19c 100%);
    color: $white;

    &--concept {
      position: absolute;
      top: 50%;
      right: -90px;
      padding: 12px 42px;
      border-radius: 25px;
      transform: rotate(90deg);
      font-family: youandiB;
      font-size: 18px;
    }

    &--action {
      position: absolute;
      width: 360px;
      left: 50%;
      transform: translateX(-50%);
      padding: 22px 0;
      margin: 0 auto;
      border-radius: 45px;
      font-size: 26px;
      font-family: youandiR;
      box-shadow: 20px 25px 40px rgba(0, 0, 0, .14);
      z-index: 2;
    }
  }

  .c-text-list {
    &.c-text-list--col1,
    &.c-text-list--col3 {
      padding: 26px 40px;
      background: #f8f8f8;
      margin-bottom: 100px;
    }

    &.c-text-list--col1{
      margin-top: 25px;
    }

    &.c-text-list--col3{

      @include clearfix;

      .c-text-list__item {
        float: left;
        width: 33.333333%;

        &:nth-of-type(n+4) {
          margin-top: 7px;
        }

        &:nth-of-type(-n+3) {
          margin-top: 0;
        }
      }
    }
  }

  // 기본개념 -  기본개념
  .concept {
    width: 645px;
    height: 369px;
    margin: 0 auto;
    background: url('../img/com/img_ethical-mgt_bg.jpg') no-repeat 0 0/100% auto;

    @include clearfix;

    &-list {
      width: 460px;
      height: 100%;
      float: left;
      padding-right: 30px;
      text-align: right;

      &__item {
        line-height: 90px;
        font-size: 18px;

        &:nth-of-type(2),
        &:nth-of-type(3) {
          color: $white;
        }

        &:nth-of-type(4) {
          color: #666;
        }
      }
    }

    .utility-wrapper {
      position: relative;
      float: right;
      width: 185px;
      height: 100%;
    }

    .arrow {
      position: absolute;
      display: block;
      bottom: 0;
      width: 16px;
      background: #e8e8e8;

      &::before,
      &::after {
        content: "";
        position: absolute;
        display: block;
      }

      &::before {
        top: -5px;
        left: -2px;
        width: 0px;
        height: 0px;
        border-top: 20px solid transparent;
        border-right: 20px solid #e8e8e8;
        transform: rotate(225deg);
      }

      &::after {
        top: 50%;
        left: -42px;
        width: 100px;
        transform: rotate(90deg);
      }

      &:nth-of-type(1) {
        left: 42px;
        height: 170px;

        &::after {
          content: "준법경영";
        }
      }

      &:nth-of-type(2) {
        left: 92px;
        height: 350px;

        &::after {
          content: "윤리경영";
        }
      }
    }
  }

  // 기본개념 - 실천활동
  .action {
    position: relative;
    width: 1180px;
    height: 547px;
    overflow: hidden;

    @include clearfix;

    &__section {
      position: absolute;
      float: left;
      bottom: 0;
      width: 480px;
      height: 480px;
      padding-top: 75px;
      background: #f8f8f8;
      border-radius: 50%;
      z-index: 1;

      &:nth-of-type(1) {
        left: 0;
      }

      &:nth-of-type(2) {
        left: 50%;
        transform: translateX(-50%);
      }

      &:nth-of-type(3) {
        right: 0;
      }

      &-tit {
        position: relative;
        display: inline-block;
        padding-bottom: 22px;
        margin-bottom: 30px;
        color: #0076c0;
        font-size: 23px;

        &::after {
          content: "";
          position: absolute;
          display: block;
          left: 50%;
          transform: translateX(-50%);
          bottom: 0;
          width: 30px;
          height: 2px;
          background: #0076c0;
        }
      }
    }

    &__list {
      &-tit {
        display: block;
        color: #000;
      }

      &-item {
        width: 210px;
        margin: auto;
        color: #666;

        &:nth-of-type(n+2) {
          margin-top: 22px;
        }
      }
    }
  }

  // 기본개념 - 활동히스토리
  .c-accordion--type3.hover-board.history {
    $root: '.c-accordion';

    #{$root}__head {
      text-align: left;

      > span {
        vertical-align: top;
        margin-right: 58px;
      }
    }

    #{$root}__title {
      max-width: 850px;
    }
  }

  // 공정거래 자율준수 프로그램 - 기본개념
  .concept-list--program {
    margin: 0 -10px;

    @include clearfix;

    &__item {
      position: relative;
      float: left;
      width: 280px;
      height: 300px;
      padding: 51px 30px 0;
      margin: 20px 10px 0;
      font-size: 15px;
      color: #666;
      background-color: #f8f8f8;
      border-radius: 8px;
    }

    &__image {
      display: block;
      margin: 0 auto 15px;
    }

    &__tit {
      display: block;
      margin-bottom: 16px;
      font-size: 19px;
      line-height: 26px;
      font-family: youandiB;
      color: #000;
    }
  }

  // 공정거래 자율준수 프로그램 - 자율준수 선언문
  .declaration {
    padding: 75px;
    border: 1px solid #e8e8e8;
    border-radius: 10px;
  }

  .cp-list {
    padding-top: 35px;
    margin-top: 34px;
    border-top: 1px solid #eee;
  }

  // 공정거래 자율준수 프로그램 - 불공정거래 제보/상담
  //  사이버감사실 - 부정행위 제보/상담
  .border-b {
    padding-bottom: 35px;
    margin-bottom: 35px;
    border-bottom: 1px solid #e8e8e8;
  }
}
