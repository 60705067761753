/* 공시실, 공지사항 공통 */
.heading-right + .editor-wrap {
  padding-top: 24px;
}

.editor-wrap {
  /* as-is */
  @import "asis";

  /* to-be */
  overflow: hidden;
  font-family: $font-family-base !important;
  font-weight: 400;
  font-size: 15px;
  line-height: inherit;

  th, td {
    font-weight: 400;
  }

  legend, 
  caption {
    position: absolute !important;
    width: 1px !important;
    height: 1px !important;
    padding: 0 !important;
    margin: -1px !important;
    overflow: hidden !important;
    clip: rect(0, 0, 0, 0) !important;
    white-space: nowrap !important;
    border: 0 !important;
  }

  /* 타이틀 */
  //tab title
  h1.title-disclosure,
  h2.title-disclosure {
    display: none;

    +.tit-disclosure {
      margin-top: 0 !important;
    }
  }

  .sub {
    position: static;
    display: block;
    margin: 55px 0 24px;
    font-family: youandiB;
    font-size: 19px;
    line-height: 28px;
    background: none;
    text-align: left;
  }

  .dl-dis,
  [class*="dl-dis"] {
    dt {
      margin: 55px 0 24px;
      font-family: youandiB;
      font-size: 19px;
      line-height: 28px;

      a {
        color: $blue !important;
      }
    }

    dd {
      position: relative;
      font-size: 17px;
      text-align: left;
      line-height: 28px;
    }
  }

  //.heading-right {
  .table-sup,
  .table-sup2 {
    margin: 30px 0 24px;
  }

  .sub +.sup,
  p.fz11:not(:first-child) {
    margin: -54px 0 24px;
  }

  .sub +.sup,
  .table-sup2,
  p.fz11 {
    display: block;
    font-size: 23px;
    line-height: 30px;
    text-align: right;
    font-size: 17px;
    color: #666;
  }
  
  /* 버튼 */
  //.c-button-wrap
  .btnUtil {
    display: block;
    overflow: initial;

    //.o-button.sm.white
    a {
      em {
        position: static;
        left: 0;
      }
    }
    
    a:not(.pdf-down):not(.contractView):not(.list) {
      display: inline-block;
      min-width: 34px;
      min-height: 34px;
      width: auto;
      height: auto;
      padding: 0 10px;
      border-radius: 5px;
      font-size: 15px;
      font-family: youandiR;
      line-height: 32px !important;
      border: 1px solid #b8b8b8;
      background-color: #fff;
      color: #000;
      text-align: center;
      vertical-align: middle;
      appearance: none;
      transition: background 200ms;
      font-weight: 400;
      color: inherit;
      background: none;
    }

    //.o-icon.o-icon--download
    .down {
      width: 12px;
      height: 15px;
      margin-left: -2px;
      margin-right: -2px;
      background: url(../img/com/ico_download.png) no-repeat 50% 50% !important;
      background-position: 0 0;

      em {
        position: absolute !important;
        width: 1px !important;
        height: 1px !important;
        padding: 0 !important;
        margin: -1px !important;
        overflow: hidden !important;
        clip: rect(0, 0, 0, 0) !important;
        white-space: nowrap !important;
        border: 0 !important;
      }
    }

    //.o-icon.o-icon--pdf.o-icon--large
    .pdf-down {
      width: 23px;
      height: 26px;
      background: url(../img/com/ico_pdf_large.png) no-repeat 50% 50%;
      background-position: 0 0;

      em {
        position: absolute !important;
        width: 1px !important;
        height: 1px !important;
        padding: 0 !important;
        margin: -1px !important;
        overflow: hidden !important;
        clip: rect(0, 0, 0, 0) !important;
        white-space: nowrap !important;
        border: 0 !important;
      }
    }

    //.o-button.link.blue.bb-0
    .contractView {
      text-align: right;
      font-size: 15px;
      padding-right: 10px;
      background: none;
      width: auto;
      height: auto;
      
      &::before {
        content: "";
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%) rotate(45deg);
        width: 5px;
        height: 5px;
        border-top: 2px solid $blue;
        border-right: 2px solid $blue;
      }
    }

    //.o-button.xl.white
    .list {
      display: block;
      margin: 50px auto 0;
      text-align: center;
      -webkit-appearance: none;
      appearance: none;
      font-weight: 400;
      width: 300px;
      height: 56px;
      padding: 0 40px;
      border-radius: 8px;
      font-size: 19px;
      font-family: youandiB;
      line-height: 54px;
      border: 1px solid #b8b8b8;
      background: none;
      background-color: #fff;
      color: #000;
    }
  }

  /* 텍스트 */
  //.o-text.o-text--1
  .txt-dis,
  h2.title-disclosure ~ p,
  h2.title-disclosure ~ .reference,
  h5 {
    padding: 0;
    font-size: 17px;
    line-height: 28px;

    a {
      display: inline-block;
    }
  }

  .txtAccservice {
    strong  {
      font-weight: bold;
    }
  }

  //.c-text-list
  .list-type {
    > li {
      margin: 55px 0 24px;
      font-size: 17px;
      text-align: left;
      line-height: 28px;

      //.o-heading.o-heading--3
      > strong {
        font-family: youandiB;
        font-size: 19px;
        line-height: 28px;
      }

      > ul {
        margin-top: 10px;
      }
    }
  }

  //.c-text-list.c-text-list--dot
  .reference,
  .notc_list {
    > li {
      position: relative;
      font-size: 17px;
      text-align: left;
      line-height: 28px;
      padding-left: 13px;
      background: none !important;
      
      &::before {
        content: "";
        display:block;
        position: absolute;
        top: 12px;
        left: 0;
        width: 3px;
        height: 3px;
        border-radius: 50%;
        background-color: #000;
      }

      ~ li {
        margin-top: 10px;
      }

      .reference2 {
        margin-top: 10px;
      }
    }
  }

  //.c-text-list.c-text-list--star
  .reference2 {
    margin-top: 17px;

    > li {
      position: relative;
      text-align: left;
      padding-left: 13px;
      background: none !important;
      padding-left: 10px;
      font-size: 13px;
      line-height: 18px;
      color: #666;

      &::before {
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        content: "*";
      }

      ~ li {
        margin-top: 10px;
      }
    }
  }

  .tbot_ment:not(ul):not(ol):not(dl) {
    margin-top: 10px;
    position: relative;
    display: block;
    padding-left: 10px;
    font-size: 13px;
    line-height: 18px;
    color: #666;

    &::before {
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      content: "*";
    }
  }

  //.c-text-list.c-text-list--hyphen
  .list-dis02 {
    > li {
      position: relative;
      font-size: 17px;
      text-align: left;
      line-height: 28px;
      padding-left: 13px;
      font-size: 15px;
      line-height: 24px;
      color: #666;
      background: none !important;
      margin-top: 10px;

      &::before {
        display: block;
        position: absolute;
        content: "";
        top: 11px;
        left: 0;
        width: 6px;
        height: 2px;
        background-color: #000;
      }

      a {
        color: $blue !important;
        padding-right: 10px;
        border-bottom: 0;
      
        &::before {
          content: "";
          position: absolute;
          right: 0;
          top: 50%;
          transform: translateY(-50%) rotate(45deg);
          width: 5px;
          height: 5px;
          border-top: 2px solid $blue;
          border-right: 2px solid $blue;
        }
      }

      ul {
        margin-top: 10px;

        li {
          padding-left: 0;

          ~ li {
            margin-top: 10px;
          }

          .reference {
            padding-left: 13px;
    
            > li {
              font-size: 15px;
            }
          }
        }
      }
    }
  }

  /* 탭 */
  //.c-tab--type1
  [class^="tab-"]
  .selectLink {
    display: none;
  }

  // .c-tab--type4
  .tab_sub {
    font-size: 0;
    text-align: center;
    margin: 0 auto 60px;

    ul {
      display: flex;
      justify-content: center;
    }
      
    li {
      position: relative;
      display: inline-block;
      padding: 0 30px;
      font-family: youandiR;
      font-size: 17px;

      ~ li {
        &::before {
          content: "";
          position: absolute;
          top: 50%;
          left: 0;
          transform: translateY(-50%);
          width: 1px;
          height: 16px;
          background-color: #d8d8d8;
          outline: none;
        }
      }

      &.active {
        a {
          display: inline-block;
          border-bottom: 1px solid $blue;
          color: $blue !important;
        }
      }
    }

    a {
      color: #000 !important;
    }
  }

  /* 테이블 */
  //.c-table
  .tbs02,
  .tbl_bg,
  .disclosure_table {
    width: 100%;
    overflow: hidden;
    margin: 25px 0;
    border-top: 1px solid #666;
    border-bottom: 0;

    > table {
      position: relative;
      width: calc(100% + 1px);
      table-layout: auto;
      border: 0;

      &::after {
        display: block;
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 1px;
        background-color: #d2d2d2;
        content: "";
      }

      > thead,
      > tbody,
      > tfoot {
        > tr {
           > th,
           > td {
            border-right: 1px solid #e8e8e8;
            border-bottom: 1px solid #e8e8e8;
           }
        }
      }
    }

    th,
    td {
      padding: 19px 20px;
      line-height: 21px;
      text-align: center;

      ul {
        li {
          position: relative;
          font-size: 15px;
          text-align: left;
          line-height: 28px;
        }
      }
    }

    th {
      font-weight: normal;
      color: #666;
    }

    td {
      height: 60px;
      font-size: 15px;
      color: #000;
    }

    thead {
      th {
        height: 48px;
        padding-top: 12px;
        padding-bottom: 12px;
        background-color: #f8f8f8;
        text-align: center;
        font-size: 13px;
        line-height: 18px;
        text-align: center;
        font-weight: normal;
        font-size: 14px !important;
        color: #666;
      
        * {
          font-size: inherit;
        }
      }
    }

    tbody {
      td,
      th {
        font-weight: 400;
        font-size: 15px !important;
        
        * {
          font-size: inherit;
        }
      }

      th {
        font-size: 14px;
      }
      
      td {
      color: #000;
      }
    }

    tfoot {
      th,
      td {
        height: 74px !important;
      }

      th {
        background-color: #f8f8f8 !important;
        color: #000 !important;
      }

      td {
        text-align: right !important;
        color: #0076c0 !important;
      }
    }

    //align
    .paragR {
      text-align: right;
    }

    .paragL,
    .paragB {
      text-align: left;
    }

    .paragC {
      text-align: center;
    }

    //c-text-list
    .parag {
      text-align: left;
      
      ~.parag {
        margin-top: 10px;
      }
    }

    //table + .c-text-list--star
    + .reference2 {
      margin-top: -8px;

      > li {
        position: relative;
        text-align: left;
        padding-left: 10px;
        font-size: 13px;
        line-height: 18px;
        color: #666;

        &::before {
          display: block;
          position: absolute;
          top: 0;
          left: 0;
          content: "*";
          width: auto;
          height: auto;
          background: none !important;
          border: 0;
        }
      }
    }

    + p:not(.ta_l) {
      position: relative;
      display: block;
      margin-top: -8px;
      font-weight: normal !important;
      font-size: 13px;
      line-height: 18px;
      color: #666 !important;
    }
  }

  table.tbl_bg,
  table.disclosure_table {
    position: relative;
    width: calc(100% + 1px);

    &::after {
      display: block;
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 1px;
      background-color: #d2d2d2;
      content: "";
    }

    > thead,
    > tbody,
    > tfoot {
      > tr {
          > th,
          > td {
          border-right: 1px solid #e8e8e8;
          border-bottom: 1px solid #e8e8e8;
          }
      }
    }
  }

  table.disclosure_table {
    th, td {
      text-align: left;
    }
  }

  .box_liner_1 {
    padding: 0 !important;

    .reference_1 {
      position: static;
    }
  }

  .c-table--scroll {
    margin-top: 25px;
    margin-bottom: 25px;
    border-top: 1px solid #666;

    table {
      position: relative;
      width: 100%;
      table-layout: fixed;

      &::after {
        display: block;
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 1px;
        background-color: #d2d2d2;
        content: '';
      }

      &:before {
        content: '';
        display: block;
        position: absolute;
        right: 0;
        top: 0;
        width: 1px;
        height: 100%;
        background-color: $white;
      }
    }
  }

  /* 안내 */
  //.info-wrap
  .list-dis {
    margin-top: 30px;
    position: relative;
    padding: 15px 40px 14px;
    background-color: #f8f8f8;
    text-align: center;

    > li {
      font-size: 15px;
      font-family: youandiR;
      line-height: 24px;
      background: none !important;
      padding: 0;
    }
  }

  /* 게시판 */
  //.board-detail__content
  .dis-susi {
    padding: 17px 0;
    
    > table:not(.tbl_bg) {
      th, td {
        height: auto;
        padding: 0;
        border: 0;
      }
    }
  }

  /* 컨텐츠 */
  //.sr-only
  .ir_formula_1 {
    overflow: hidden !important;
    clip: rect(0, 0, 0, 0) !important;
    white-space: nowrap !important;
    border: 0 !important;
    margin: 30px auto 0;
    height: 0;
    padding-top: 147px;
    background: url(https://dhp.fubonhyundai.com:9081/images/disclosure/txt_retire01_04_01.gif) #f8f8f8 no-repeat 50% 50%;
  }

  .hd-area {
    position: absolute !important;
    width: 1px !important;
    height: 1px !important;
    padding: 0 !important;
    margin: -1px !important;
    overflow: hidden !important;
    clip: rect(0, 0, 0, 0) !important;
    white-space: nowrap !important;
    border: 0 !important;
  }

  /*  연금저축 계좌이체제도/수수료  */
  .cmsWrap .bg_diagram {
    overflow: hidden !important;
    clip: rect(0, 0, 0, 0) !important;
    white-space: nowrap !important;
    border: 0 !important;
    margin: 30px auto 0;
    height: 0;
    padding-top: 301px;
    background:url(https://dhp.fubonhyundai.com:9081/images/disclosure/diagram_acc_transfer_service.gif) no-repeat 50% 50%;
  }

  /* 공시실_운용설명서 */
  .h330_new02 {
    font-size: 0;
    
    .data {
      text-align: center;
    }
    
    .cont {
      ul {
        border: 0;
        @include clearfix;

        ~ ul {
          margin-top: 10px;
        }
      }

      li {
        float: left;
        width: auto;
        height: auto;
        padding: 0;
        border: 0;
        margin-right: 20px;
      }
    }

    .stl_open {
      background: none;
      padding: 0;
    }

    .o_h {
      display: inline-block;
      overflow: initial;
      height: auto;
    }
  }

  /* 공지사항 */
  .cf2,
  .tx_org {
    color: $blue !important;
  }

  .div_fl,
  .div_fr {
    @include clearfix;
  }
}

.c-tab__panel {
  >.editor-wrap {
    >h3.tit-disclosure2,
    >.tit-default,
    >h4:not(.c-accordion__title),
    >h3.disclosure,
    >h5.fw-bold {
      &:first-child {
        margin-top: 0;
      }
    }
  }
}

/* 팝업 */
.c-modal__body {
  .editor-wrap {
    h1 {
      display: none;
    }

    h2 {
      position: absolute !important;
      width: 1px !important;
      height: 1px !important;
      padding: 0 !important;
      margin: -1px !important;
      overflow: hidden !important;
      clip: rect(0, 0, 0, 0) !important;
      white-space: nowrap !important;
      border: 0 !important;
    }
  }
}

/* 공시실 */
.editor-wrap:not(.editor-wrap--notice) {
  a {
    position: relative;
    display: inline-block;
    font-size: inherit;
    vertical-align: top;
    line-height: inherit;
    border: none;
    background-color: transparent;
    color: #0076c0;
    border: none;
  }

  //.o-heading--2
  h3.tit-disclosure,
  .noti_inner h3 {
    padding: 0;
    margin: 86px 0 24px;
    font-family: youandiR;
    font-weight: 400;
    font-size: 23px;
    line-height: 30px;
    background: none;

    &:first-child {
      margin-top: 0;
    }
  }

  //.o-heading--3
  h3.tit-disclosure2,
  .tit-default,
  h4:not(.c-accordion__title),
  h3.disclosure,
  h5.fw-bold {
    padding: 0;
    margin: 55px 0 24px;
    font-family: youandiB;
    font-weight: 400 !important;
    font-size: 19px;
    line-height: 28px;
    background: none;
  }

  /* 텍스트 */
  //.o-text.o-text--1
  p {
    padding: 0;
    font-size: 17px;
    line-height: 28px;

    a {
      display: inline-block;
    }
  }

  // [공시실] 연금저축 계좌이체 절차 CUSI150108010100
  .announcement-step {
    margin: 0 -35px;

    @include clearfix;

    &__item {
      position: relative;
      float: left;
      width: 212px;
      margin: 0 35px;

      &:not(:nth-child(3)) {
        margin-top: 142px;
      }

      &:not(:last-child)::after {
        content: "";
        position: absolute;
        display: block;
        top: 50%;
        right: -40px;
        width: 0;
        height: 0;
        border-right: 18px solid #e8e8e8;
        border-bottom: 18px solid transparent;
        transform: rotate(45deg);
      }
    }

    &__item.wide {
      margin-top: 6px;
      width: 334px;
    }

    &--detail__item:nth-child(n+2) {
      .step-card {
        margin-top: 20px;
      }
    }

    .step-card {
      $root : '.step-card';
      width: 100%;
      text-align: center;
      font-family: youandiR;

      &__head {
        display: block;
        padding: 10px 0;
        border-top-left-radius: 15px;
        border-top-right-radius: 15px;
        font-family: youandiB;
      }

      &__content {
        padding: 22px 0;
        border-bottom-left-radius: 15px;
        border-bottom-right-radius: 15px;
        font-size: 19px;
      }

      &.green-type {
        #{$root}__head {
          color: #fff;
          background: #8bd2d0;
        }

        #{$root}__content {
          color: #fff;
          background: #17a6a2;
        }
      }

      &.blue-type {
        #{$root}__head {
          color: #0076c0;
          background: #cce4f2;
        }

        #{$root}__content {
          color: #fff;
          background: #0076c0;
        }
      }
    }
  }

  >.o-heading:first-child {
    margin-top: 0 !important;
  }
}

.cusi-detail {
  .editor-wrap {
    > table {
      table-layout: auto;
      
      > tbody > tr {
        //.o-text.o-text--1
        > td {
          font-size: 17px;
          line-height: 28px;
          text-align: left;
        }
      }
    }
  }
}

/* 공지사항 */
.editor-wrap--notice {
  >[class*="notice"],
  #notice_area,
  >[class*="notice"] > [class*="notice"],
  >[id*="notice"],
  > [id*="notice"] > [class*="noti_"] {
    width: 100% !important;
    padding: 0 !important;
    font-weight: 400;
    line-height: inherit;
    color: #666;

    strong {
      font-weight: bold;
    }

    > h3 {
      font-family: youandiB;
    }

    .mb40 {
      margin-bottom: 40px;
    }

    .mb10 {
      margin-bottom: 10px;
    }
  }

  .pkment_01 {
    line-height: 1.5;
  }

  .notice_parking .pkf_tb .pkf_td.lab {
    box-sizing: content-box;
    word-break: keep-all;
  }

  .tbs02 {
    th, td {
      text-align: center;
      padding: 12px 10px;
    }
  }
}