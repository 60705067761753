/* 게시판 */
.board-list {
  border-top: 1px solid #666;
  font-size: 0;

  $root: ".board-list";

  &__item {
    position: relative;
    border-bottom: 1px solid #d2d2d2;
  }

  &__label {
    position: absolute;
    top: 50%;
    right: 20px;
    transform: translateY(-50%);
    display: inline-block;
    padding: 0 10px;
    line-height: 22px;
    border: 1px solid $blue;
    border-radius: 12px;
    background: $white;
    color: $blue;
    font-size: 13px;
  }

  &__anchor {
    display: block;
    padding: 24px 20px;

    &:hover,
    &:focus {
      background-color: #f8f8f8;
    }
  }

  &__title {
    display: block;
    margin-bottom: 12px;
    font-size: 17px;
  }

  &__text {
    overflow: hidden;
    display: block;
    width: calc(100% - 150px);
    text-overflow: ellipsis;
    white-space: nowrap;
    font-size: 15px;
    color: #666;
  }

  +.paging {
    margin-top: 73px;
  }
}

.c-tab__panel .board-list {
  margin-top: 70px;
}

.board-detail,
.event-view {
  margin-top: 30px;
  border-top: 1px solid #000;
  border-bottom: 1px solid #e8e8e8;

  $root: ".board-detail";

  &__head {
    position: relative;
    padding: 24px 20px;
  }

  &__content {
    min-height: 300px;
    padding: 36px 20px;
    border-top: 1px solid #e8e8e8;

    img {
      max-width: 100%;
    }
  }

  &__sub {
    margin: 0 20px;
    padding: 13px 0;
    border-top: 1px solid #e8e8e8;
  }

  &__title {
    display: inline-block;
    line-height: 26px;
    font-size: 17px;

    &:not(:only-child) {
      margin-bottom: 7px;
    }
  }

  &__text,
  &__date {
    display: block;
    margin-right: 20px;
    color: #666;
    line-height: 26px;
    font-size: 15px;
  }

  // &__date {
  //   position: absolute;
  //   top: 30px;
  //   right: 20px;
  //   color: #666;
  //   font-size: 13px;
  // }

  &__label {
    position: absolute;
    top: 50%;
    right: 20px;
    transform: translateY(-50%);
    display: inline-block;
  }

  #{$root}__label {
    padding: 0 10px;
    line-height: 22px;
    border: 1px solid $blue;
    border-radius: 12px;
    background: $white;
    color: $blue;
    font-size: 13px;
  }

  &__button {
    & ~ & {
      margin-left: 4px;
    }
  }
}

/* 텍스트 배경 있는 박스 */
.text-bg-wrap {
  $root: ".text-bg-wrap";
  margin-left: -14px;

  &__list {
    display: flex;
    flex-wrap: wrap;
  }

  &__item {
    display: block;
    flex: 1;
    margin-left: 14px;
    padding: 50px;
    background: #f2f8fc;
    text-align: center;
  }

  &__title {
    display: block;
    font-size: 14px;
  }

  &__text {
    font-size: 18px;
  }

  &__image {
    display: block;
    margin: 0 auto 25px;
    width: 65px;
    height: 65px;
  }

  img {
    display: block;
  }

  &--type2 {
    #{$root}__item {
      padding: 50px 30px 35px;
      border-radius: 10px;
      background: #f8f8f8;
      text-align: left;
    }

    #{$root}__image {
      display: block;
      margin: 0 0 7px;
    }

    #{$root}__title {
      display: block;
      font-family: youandiB;
      font-size: 19px;
    }

    #{$root}__text {
      margin-top: 10px;
      display: block;
      font-family: youandiR;
      font-size: 15px;

      .co-blue {
        display: block;
        margin-top: 10px;

        .o-icon--right-arrow::before, .o-icon--right-arrow::after {
          background-color: $blue;
        }
      }
    }
  }

  &--type3 {
    margin: -20px 0 0 -10px;

    #{$root}__item {
      padding: 46px 40px 40px;
      margin: 20px 0 0 10px;
      border-radius: 10px;
      background: #f8f8f8;
      text-align: center;

      &.bg-blue {
        background-color: #f2f8fc !important;
      }
    }

    #{$root}__title {
      display: block;
      font-family: youandiB;
      font-size: 19px;
    }

    #{$root}__text {
      margin-top: 11px;
      display: block;
      font-size: 15px;
      color: #666;
    }

    #{$root}__button {
      margin-top: 26px;
    }

    &#{$root}--col3 {
      margin-left: -20px;

      #{$root}__item {
        flex: none;
        padding: 56px 40px 60px;
        margin: 20px 0 0 20px;
        width: calc(33.3% - 20px);
      }

      #{$root}__text {
        margin-top: 15px;
      }
    }
  }
}

.o-text--1 + .text-bg-wrap {
  margin-top: 60px;
}

/* 카드 */
.product-card + .o-text--1,
.o-text--1 + .product-card {
  margin-top: 24px;
}


.heading-right + .product-wrap {
  padding: 0 0 60px 0;
  border-bottom: 1px solid #e8e8e8;

  .product-card {
    &:first-child {
      margin-top: 30px;
    }
    &:last-child {
      margin-bottom: 0;
    }
  }
}

/* 배너 */
.banner {
  display: flex;
  align-items: center;
  margin-top: 100px;
  font-size: 0;

  $root: ".banner";

  &--type1 {
    display: block;
    box-shadow: 0px 15px 25px 0px rgba(18,99,149,0.15);
  }

  &--type2 {
    justify-content: center;
    position: relative;
    height: 70px;
    background: url("../img/com/bg_banner_type2.png") no-repeat;
    background-size: 100%;

    #{$root}__text {
      position: relative;
      padding-right: 22px;
      font-family: youandiB;
      font-size: 20px;
      color: $white;

      &::before {
        content: "";
        position: absolute;
        top: 50%;
        right: 0;
        transform: translateY(-50%) rotate(45deg);
        width: 8px;
        height: 8px;
        border-top: 2px solid $white;
        border-right: 2px solid $white;
      }
    }
  }

  &--type3 {
    padding: 0 38px;
    height: 80px;
    border-radius: 10px;
    background: url("../img/com/bg_banner_type3.png") #2a567e no-repeat;
    background-size: 100%;

    #{$root}__text {
      font-family: youandiR;
      font-size: 16px;
      color: $white;
    }

    #{$root}__highlight {
      display: inline-block;
      margin-right: 20px;
      font-family: youandiB;
      font-size: 19px;
    }
  }
}

/* 개인형IRP 가입하기 - 온라인 가입 청약서 */
.online-join {
  padding: 44px 60px 0;
  overflow: hidden;
  border: 1px solid #666;
  border-radius: 15px;
  text-align: center;

  $root: ".online-join";

  &__title {
    font-family: youandiR;
    font-size: 23px;
  }

  &__foot {
    position: relative;
    padding: 60px 0;
    margin-top: 60px;
    font-family: youandiR;
    font-size: 23px;

    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: -60px;
      right: -60px;
      bottom: 0;
      background: #f8f8f8;
      z-index: -1;
    }
  }

  .o-button + #{$root}__data {
    margin-top: 45px;
  }

  &__text {
    display: inline-block;
    position: relative;
    margin-top: 7px;
    color: $blue;

    &::before {
      content:"";
      position: absolute;
      right: -120px;
      bottom: -14px;
      width: 109px;
      height: 112px;
      background: url("../img/content/PMP150200000000_1.gif") no-repeat;
      z-index: -1;
    }

    small {
      font-size: 20px;
    }
  }
}

/* 직업찾기 - 검색결과 리스트 */
.search-list {
  margin-top: 40px;
  // padding: 10px 0;
  max-height: 358px;
  overflow-y: auto;
  border-top: 1px solid #000;
  border-bottom: 1px solid #d2d2d2;

  &__button {
    position: relative;
    display: block;
    width: 100%;
    padding: 16px 50px 17px 20px;
    font-size: 15px;
    text-align: left;

    &::before {
      content: "";
      position: absolute;
      right: 20px;
      top: 50%;
      width: 7px;
      height: 7px;
      border-top: 2px solid #000;
      border-right: 2px solid #000;
      transform: translateY(-50%) rotate(45deg);
    }

    &:not(:disabled):hover,
    &:not(:disabled):focus,
    &:not(:disabled).is-active {
      background: #f8f8f8;
      // text-decoration: underline;
      color: $blue;

      &::before {
        border-color:$blue;
      }
    }

    &:disabled {
      cursor: default;

      &::before {
        display: none;
      }
    }

    &~& {
      border-top: 1px solid #e8e8e8;
    }
  }

  &__sub {
    display: block;
    margin-bottom:10px;
    font-size: 13px;
    color: inherit;
  }
}

.o-heading--4 + .search-list {
  margin-top: -20px;
}

/* 개인형 IRP - 메인 */
.irp-main {
  .page-head {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0;
    margin: 0;
    height: 353px;
    color: $white;

    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 50%;
      bottom: 0;
      transform: translateX(-50%);
      width: 100vw;
      height: 353px;
      min-width: 1280px;
      background: url(../img/com/bg_top_1.gif), url(../img/com/bg_top_2.gif), #00a19c;
      background-repeat: no-repeat;
      background-position: top left, top right, center;
      z-index: -1;
    }
  }

  .page-head__description {
    font-size: 25px;

    &:first-child {
      margin-top: 0;
    }
  }

  .o-heading {
    margin-top: 20px;
    font-size: 48px;
    font-family: youandiB;

    + .page-head__description {
      margin-top: 40px;
      font-size: 17px;
    }
  }

  &__visual {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: -40px;
    padding: 77px 130px 42px;
    background: $white;
    border-radius: 12px;
  }

  &__visual-text {
    li {
      padding: 14px 0 22px;
      font-size: 23px;
      line-height: 32px;

      ~li {
        border-top: 1px solid #e8e8e8;
      }
    }

    .o-text--underline {
      font-size: 14px;
    }
  }

  &__visual-img {
    display: inline-block;
  }

  &__button-wrap {
    position: relative;
    text-align: center;

    &::before {
      content: "";
      position: absolute;
      top: 50%;
      left: 120px;
      right: 120px;
      border-top: 1px solid #e8e8e8;
      z-index: -1;
    }
  }
}

/* 개인형IRP - 스크래핑 팝업 */
.modal-scraping {
  text-align: center;

  &__image {
    display: block;
    margin-bottom: 11px;
  }

  &__title {
    display: block;
    margin-bottom: 10px;
    font-family: youandiB;
    font-size: 18px;
  }

  &__loading {
    position: relative;
    display: block;
    margin: 0 auto 16px;

    .c-loading__bars {
      position: inherit;
    }
  }
}

/* 퇴직연금 - 시뮬레이션 */
.simulation {
  position: relative;
  margin: 0 auto;
  width: 600px;
  @include clearfix;

  $root: ".simulation";

  &__item {
    float: left;
    text-align: center;

    &:nth-child(2) {
      float: right;
    }
  }

  &__image {
    display: block;
    margin: 0 auto 10px;
    text-align: center;
  }

  &__title {
    display: block;
    font-size: 15px;
    font-family: youandiR;
    color: #666;
  }

  &__text {
    font-size: 32px;
    font-family: youandiB;
  }

  &-result {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    #{$root}-result__text {
      position: relative;
      color: #fff;
      font-size: 18px;
      line-height: 50px;

      &::before {
        content: "";
        position: absolute;
        top: 0;
        left: 50%;
        transform: translateX(-50%) skew(20deg);
        width: 234px;
        height: 50px;
        background: #666;
        z-index: -1;
      }

      &:nth-child(2)::before {
        transform: translateX(-50%) skew(-20deg);
        background: $blue;
      }
    }
  }
}

.graph {
  margin: 40px auto 30px;
}

.simulation-graph-wrap {
  font-size: 0;
  @include clearfix;
}

.simulation-graph {
  position: relative;
  float: left;
  width: 400px;
  height: 235px;

  $root: '.graph';

  #{$root} {
    padding: 0 30px;
    font-size: 0;
    border-bottom: 1px solid $blue;
    background-image: repeating-linear-gradient(#fff, #fff 20px, #e8e8e8 20px, #e8e8e8 21px);
  }

  #{$root}__item {
    position: relative;
    display: inline-block;
    width: 150px;
    height: 186px;

    ~ #{$root}__item {
      margin-left: 40px;
    }
  }

  #{$root}__title {
    position: absolute;
    left: 0;
    right: 0;
    bottom: -35px;
    display: block;
    font-family: youandiR;
    font-size: 13px;
    text-align: center;
    color: #666;
  }

  #{$root}__value {
    position: static;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    color: $white;
    opacity: 0;
    transition: opacity 2s .5s;
    font-family: 'youandiR';
    font-size: 18px;

    &--diff {
      position: relative;
      height: 92px;
      background: #d9f1f0;
      border: 1px solid #17a6a2;
      color: #007688;
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;

      // &::before {
      //   content: "";
      //   position: absolute;
      //   bottom: 0;
      //   left: -1px;
      //   right: -1px;
      //   background: #fff;
      //   height: 4px;
      //   border-top: 1px solid #17a6a2;
      // }

      span:not(.sr-only) {
        display: block;
        font-size: 15px;
      }
    }

    &--base {
      align-items: center;
      flex: 1;
    }

    > span:not(.sr-only) {
      display: block;
      font-size: 15px;
    }
  }

  #{$root}__bar {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 0;
    box-sizing: border-box;
    transition: 1s;
    display: flex;
    flex-direction: column;
    justify-content: center;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    overflow: hidden;
    color: #fff;
    font-family: youandiB;
    font-size: 19px;
    transition: height 2s .5s;

    &--1 {
      background-color: #666;
    }

    &--2 {
      background-color: $blue;
    }

    // &.full {
    //   height: 100%;
    // }
  }

  &--case1 {
    #{$root}__bar--1 {
      height: 100%;
    }
  
    #{$root}__bar--2 {
      height: 100%;
    }

    .graph__value,
    .graph__value {
      opacity: 1;
    }
  }

  &--case2 {
    #{$root}__bar--1 {
      height: 96px;
    }
  
    #{$root}__bar--2 {
      height: 100%;
    }

    .graph__value--diff {
      display: none !important;
    }

    .graph__value,
    .graph__value {
      opacity: 1;
    }
  }

  &--case3 {
    #{$root}__bar--1 {
      height: 100%;
    }
  
    #{$root}__bar--2 {
      height: 100%;
    }

    .graph__value--diff {
      display: none !important;
    }

    .graph__value,
    .graph__value {
      opacity: 1;
    }
  }

  // #{$root}__bar--1.active {
  //   height: 80%;
  // }

  // #{$root}__bar--2.active {
  //   height: 100%;
  // }

  // .active .graph__value,
  // .full .graph__value {
  //   opacity: 1;
  // }
}

.simulation-graph-info {
  float: right;
  width: calc(100% - 501px);
  margin-left: 50px;
  padding-left: 50px;
  box-sizing: content-box;
  border-left: 1px solid #e8e8e8;

  $root: ".simulation-graph-info";

  &__item {
    padding: 22px 20px 24px;
    text-align: right;
    @include clearfix;

    ~ #{$root}__item {
      border-top: 1px solid #e8e8e8;
    }

    // &#{$root}__item--type1 {
    //   #{$root}__icon {
    //     background: #0079cc;
    //   }
    // }

    // &#{$root}__item--type2 {
    //   #{$root}__icon {
    //     background: #17a6a2;
    //   }
    // }

    &#{$root}__item--type3 {
      // background: #f8f8f8;
      // border: 1px solid #17a6a2;
      // color: #17a6a2;

      // #{$root}__icon {
      //   background: #d9f1f0;
      //   border: 1px solid #17a6a2;
      // }
    }
  }

  // &__icon {
  //   display: inline-block;
  //   position: relative;
  //   margin: 0 20px 0 -8px;
  //   width: 16px;
  //   height: 16px;
  //   vertical-align: middle;
  // }

  &__title {
    display: block;
    float: left;
    font-size: 17px;
    padding-right: 20px;
  }

  &__text {
    display: inline-block;
    max-width: calc(100% - 85px);
    font-size: 19px;
    color: #000;
    overflow: hidden;
    white-space: nowrap;
  }

  &__button {
    display: inline-block;
    max-width: calc(100% - 85px);
    font-size: 19px !important;
    overflow: hidden;
    white-space: nowrap;
  }
}

.modal-simulation {
  position: relative;
  @include clearfix;

  $root: ".modal-simulation";

  &::before {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateY(-50%);
    width: 1px;
    height: 190px;
    background: #00a19c;
  }

  &__heading {
    font-family: youandiR;
    font-size: 23px;
    margin-bottom: 25px;
    color: #666;
  }

  &__content {
    float: left;
    width: 240px;

    ~ #{$root}__content {
      float: right;
    }
  }

  &__item {
    padding: 0 20px;
    line-height: 46px;
    border: 1px solid #e8e8e8;
    border-radius: 4px;
    background: #fff;
    @include clearfix;

    &~& {
      margin-top: 6px;
    }
  }

  &__title {
    float: left;
    display: block;
    font-size: 15px;
    color: #666;
  }

  &__text {
    float: right;
    display: block;
    font-size: 18px;
    color: $blue;
  }

  &__percent {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    width: 92px;
    height: 108px;
    background: #00a19c;
    line-height: 24px;
    font-family: youandiR;
    font-size: 18px;
    color: $white;

    &::before {
      content: "";
      position: absolute;
      top: -24px;
      left: 0;
      width: 0;
      height: 0;
      border-left: 46px solid transparent;
      border-right: 46px solid transparent;
      border-bottom: 24px solid #00a19c;
    }

    &::after {
      content: "";
      position: absolute;
      bottom: -24px;
      left: 0;
      width: 0;
      height: 0;
      border-left: 46px solid transparent;
      border-right: 46px solid transparent;
      border-top: 24px solid #00a19c;
    }
  }
}

/* 보험상품 메인 */
.product-main {
  position: relative;
  
  &__title {
    font-family: youandiB;
    font-size: 34px;
  }

  .page-head {
    position: relative;
    padding: 81px 70px 70px;
    color: $white;
    margin-bottom: 0;

    &::before {
      content: "";
      position: absolute;
      top: 0;
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);
      width: 100vw;
      min-width: 1280px;
      background: linear-gradient(90deg, $blue, #00a19c);
      z-index: -1;
    }

    &__description {
      font-size: 19px;
    }
  }
}

.product-main-content {
  position: relative;
  padding-top: 70px;
  z-index: 1;

  &.active {
    animation: mainBanner .8s .3s forwards;
  }

  &::before {
    content:"";
    position: absolute;
    top: 0;
    left: 50%;
    bottom: 0;
    transform: translateX(-50%);
    width: 100vw;
    min-width: 1280px;
    background: $white;
    z-index: -1;
  }

  @keyframes mainBanner {
    from {
      transform: translateY(0);
    }

    to {
      transform: translateY(60px);
    }
  }
}

.product-main-swiper {
  position: relative;
  margin-top: 38px;

  $root: ".product-main-swiper";

  &__item {
    display: block;
    padding: 36px 160px 37px 40px;
    width: 340px;
    height: 160px;
    background: $white;
    border-radius: 10px;
    text-align: left;
    font-family: youandiR;
    font-size: 15px;

    &:hover,
    &:focus {
      #{$root}__title::before {
        content: "";
        position: absolute;
        bottom: 0;
        left: -100%;
        z-index: -1;
        width: 100%;
        height: 8px;
        background-color: #f5f17c;
        animation-fill-mode: forwards;
        animation-name: text-underline;
        animation-duration: .7s;
      }

      @keyframes text-underline {
        from {
          left: -100%;
        }

        to {
          left: 0;
        }
      }
    }
  }

  &__title {
    position: relative;
    overflow: hidden;
    z-index: 1;
    display: inline-block;
    line-height: 1.3;
    white-space: nowrap;
    font-size: 15px;
  }

  &__text {
    display: block;
    margin-top: 11px;
    font-size: 17px;
    word-break: keep-all;
  }

  &__sub {
    display: block;
    margin-top: 4px;
    font-size: 15px;
    color: #666;
    white-space: nowrap;
    overflow: hidden;
  }

  &__icon {
    position: absolute;
    right: 40px;
    top: 50%;
    transform: translateY(-50%);
    width: 80px;
    height: 80px;
  }

  &__button {
    display: block;
    padding: 30px 50px 37px;
    width: 340px;
    height: 160px;
    background: $white;
    border-radius: 10px;

    &:hover,
    &:focus {
      .o-icon--add {
        transform: rotate(360deg);
      }
    }

    #{$root}__title {
      font-family: youandiB;
    }

    #{$root}__text {
      margin-top: 5px;
      font-size: 19px;
      color: #007688;
    }

    .o-icon--add {
      display: block;
      margin: 0 auto 14px;
      transform: rotate(0deg);
      transition: transform .5s .1s ease-in;
    }
  }
}

.product-main-banner {
  position: absolute;
  top: 451px;
  display: block;
  width: 100%;
  height: 60px;
  line-height: 60px;
  text-align: center;
  font-size: 15px;
  color: $white;

  &::before {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 100vw;
    min-width: 1280px;
    height: 60px;
    background: rgba(0,0,0,.3);
    z-index: -1;
  }

  &::after {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 100vw;
    min-width: 1280px;
    height: 60px;
    background: linear-gradient(90deg, $blue, #00a19c);
    z-index: -2;
  }

  &__highlight {
    margin-left: 10px;
    font-size: 17px;
    color: #92fbf8;
  }

  .o-icon--right-arrow {
    &::before,
    &::after {
      background-color: $white;
    }
  }
}

.product-main-menu {
  position: relative;
  overflow: hidden;
  border: 1px solid #e8e8e8;
  border-radius: 10px;
  font-family: youandiR;

  $root: ".product-main-menu";

  & ~ & {
    margin-top: 30px;
  }

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    width: 298px;
    background: #f8f8f8;
  }

  &:hover,
  &:focus {
    border-color: #18b3af;
    box-shadow: 0px 25px 35px 0px rgba(151,151,151,.15);
    transition: border-color box-shadow .5s ease-in-out;

    &::before {
      background-color: #18b3af;
      transition: background-color .5s ease-in-out;
    }

    #{$root}__head {
      color: $white;
    }

    #{$root}__head-title {
      font-family: youandiB;
    }

    #{$root}__head-text {
      display: block;
    }
  }

  &__head {
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    width: 298px;
    text-align: center;
    color: #007688;
  }

  &__head-title {
    font-size: 20px;
  }

  &__head-text {
    display: none;
    margin-top: 10px;
    font-size: 18px;
  }

  &__list {
    width: calc(100% - 298px);
    float: right;
    padding: 10px 50px 10px 30px;
    @include clearfix;
  }

  &__anchor {
    position: relative;
    float: left;
    width: calc(50% - 20px);
    margin: 0 10px;
    padding: 30px 50px 30px 24px;

    &:hover,
    &:focus {
      #{$root}__title {
        color: #007688;
        transition: .3s;

        &::before {
          border-color: #007688;
          transition: .3s;
        }
      }
    }

    &:not(:nth-last-of-type(1)):not(:nth-last-of-type(2)),
    &:nth-last-of-type(2):nth-of-type(2n) {
      &::before {
        content :"";
        position: absolute;
        bottom: -1px;
        left: 0;
        right: 0;
        border-bottom: 1px solid #e8e8e8;
      }
    }
  }

  &__icon {
    position: absolute;
    top: calc(50% + 5px);
    right: 10px;
    transform: translateY(-50%);
    width: 50px;
    height: 50px;

    img {
      width: 100%;
    }
  }

  &__title {
    position: relative;
    padding-right: 18px;
    overflow: hidden;
    white-space: nowrap;
    line-height: 1;
    font-size: 19px;

    &::before {
      content: "";
      position: absolute;
      top: 50%;
      right: 0;
      transform: translateY(-50%) rotate(45deg);
      width: 6px;
      height: 6px;
      border-top: 2px solid #000;
      border-right: 2px solid #000;
    }
  }

  &__text {
    display: block;
    margin-top: 11px;
    overflow: hidden;
    white-space: nowrap;
    line-height: 1;
    font-size: 15px;
    color: #666;
  }
}

/* 내게 맞는 보험상품 찾기 */
.product-spec {
  $root: '.product-spec';

  > li {
    position: relative;
    display: table;
    width: 100%;
    table-layout: fixed;
    min-height: 98px;
    padding: 25px 10px;
    padding-left: 88px;

    ~li {
      border-top: 1px solid #e8e8e8;
    }
  }

  &__text {
    display: table-cell;
    width: 100%;
    font-size: 17px;
    vertical-align: middle;

    .o-text {
      margin-top: 4px;
      font-size: 15px;
      color: #666;
    }
  }

  &__image {
    flex-shrink: 0;
    position: absolute;
    top: 25px;
    left: 10px;
    display: block;
    width: 50px;
    height: 50px;

    img {
      width: 100%;
    }
  }
}

.card-wrap {
  overflow: hidden;
  border: 1px solid #e8e8e8;
  border-radius: 10px;
  font-size: 0;

  $root: ".card-wrap";

  &__heading {
    position: relative;
    height: 60px;
    line-height: 60px;
    background: #0089d1;
    text-align: center;
    font-family: 'youandiR';
    font-size: 19px;
    color: $white;
  }

  &__content {
    padding: 25px 50px;
  }

  #{$root}__button-wrap {
    display: flex !important;
    margin: 0 !important;
    padding: 40px 50px 30px;
    background: #f8f8f8;

    .o-button {
      width: 100%;
      flex: 1;

      ~.o-button {
        margin-left: 10px;
      }
    }
  }

  &--type2 {
    border: 0;

    #{$root}__heading::before {
      content: "Q";
      position: absolute;
      top: 0;
      left: 0;
      width: 60px;
      height: 60px;
      background: #0077b6;
      text-align: center;
    }

    #{$root}__content {
      padding: 40px;
      background: #f8f8f8;
    }

    #{$root}__input {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      margin: 0 -10px;

      > div {
        flex: 1;
        margin: 0 10px;
      }

      .o-input--check {
        + label {
          &::before {
            top: 20px;
            left: 20px;
          }

          &::after {
            top: 33px;
            left: 24px;
          }

          &:checked + label {
            border-color: $blue;
          }
        }
      }

      label {
        width: 100%;
        margin: 0 !important;
        padding: 0;
        height: 80px;
        line-height: 80px;
        border: 1px solid #e8e8e8;
        border-radius: 10px;
        background: $white;
        text-align: center;
        font-size: 23px;

        + .o-input + label {
          margin-left: 20px !important;
        }
      }

      &--type2 {
        margin-top: -20px;

        > div {
          margin-top: 20px;
          min-width: 220px;
        }

        label {
          position: relative;
          margin: 0;
          padding: 161px 40px 0;
          width: 100%;
          height: 230px;
          line-height: 24px;
          font-family: youandiR;
          font-size: 15px;
        }

        i {
          position: absolute;
          top: 46px;
          left: 50%;
          transform: translateX(-50%);
          display: block;
        }
      }
    }

    + .c-button-wrap {
      margin-top: 80px;
    }
  }
}

/* 고객안내 - 셀렉트탭 */
.select-tab {
  &__list {
    width: 380px;
    margin-bottom: 30px;
  }

  &__content {
    padding-top: 30px;
    border-top: 1px solid #555;
  }
}

/* 회사소개 */
.page-head {
  $root:".page-head";

  &.ceo-page-head {
    position: relative;
    height: 540px;
    padding: 132px 0 100px;
    text-align: left;

    &::before {
      background-color: #eceeee;
    }

    .o-heading {
      font-family: youandiB;
      font-size: 42px;
    }

    #{$root}__image {
      display: block;
      position: absolute;
      right: 0;
      bottom: 0;
      z-index: -1;
    }

    #{$root}__description {
      margin-top: 86px;
      line-height: 38px;
      font-family: youandiR;
      font-size: 23px;
    }

    #{$root}__sign {
      margin-top: 40px;
      font-size: 14px;
      color: #666;

      img {
        display: block;
      }
    }
  }
}

.ci-text-wrap:not(.text-wrap) {
  margin-top: 30px;
  background:#f9fafc;
  border: 1px solid #eaedf3;
  border-radius: 10px;
  font-size: 17px;
  text-align: center;

  $root: ".ci-text-wrap";

  #{$root}__list {
    margin: 60px;
    display: flex;
    flex-wrap: wrap;
  }

  #{$root}__item {
    flex: 1;

    >img {
      display: block;
      margin: 0 auto 25px;
    }
  }

  #{$root}__foot {
    margin-top: -20px;
    padding: 36px 40px;
    background: $white;
    border-top: 1px solid #eaedf3;
    border-bottom-right-radius: 10px;
    border-bottom-left-radius: 10px;
    text-align: center;

    .o-text {
      text-align: center;
    }
  }
}

.text-wrap.ci-text-wrap {
  .c-text-list {
    &[class*="--col"] {
      display: flex;
      flex-wrap: wrap;
      margin-top: -16px;

      > .c-text-list__item {
        margin-top: 16px;
      }
    }

    &.c-text-list--col2 {
      > .c-text-list__item {
        width: 50%;

        &:last-child {
          width: 100%;
        }
      }
    }
  }
}

.ci-info {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  padding: 60px 0 53px;
  text-align: center;

  p {
    position: relative;
    flex: 1;
    font-size: 17px;

    i {
      display: block;
      margin: 0 auto 11px;
    }
  }

  span {
    display: block;
    font-size: 15px;
    color: #666;

    &:last-child {
      margin-top: 22px;
    }
  }

  .o-icon {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);

    &--plus {
      left: calc(33.3% - 17px);
    }

    &--next,
    &--equals {
      right: calc(33.3% - 17px);
    }
  }
}

/* 회사소개 - 인재채용 */
.recruit {
  position: relative;

  $root: ".recruit";

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 100vw;
    min-width: 1920px;
    height: 480px;
    background-image: url("../img/content/bg_recruit_visual.gif");
    background-size: cover;
  }

  &__content {
    margin: 85px auto 0;
    width: 1000px;

    .o-text--1.co-gray {
      padding-left: 40px;
    }
  }
}

.recruit-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  $root: ".recruit-list";

  &__item {
    width: 260px;
    font-size: 15px;

    &:not(:nth-child(1)):not(:nth-child(2)):not(:nth-child(3)):not(:nth-child(4)) {
      margin-top: 67px;
    }
  }

  &__anchor {
    display: block;

    &:hover,
    &:focus {
      #{$root}__image {
        position: relative;
        transition: border-radius .2s;
        border-bottom-right-radius: 50px;

        &::before {
          content: "";
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          background-color: rgba(0,118,192,.3);
        }
      }

      #{$root}__name {
        transition: color .2s;
        color: $blue;
      }
    }
  }

  &__image {
    display: block;
    margin: 0 auto 26px;
    width: 260px;
    height: 320px;
    overflow: hidden;
    background: #e0e3e2;
    border-radius: 10px;

    img {
      width: 100%;
    }
  }

  &__name {
    display: block;
    margin-top: 3px;
    color: #666;
  }
}

.recruit-visual {
  position: relative;
  padding: 185px 50px 0;
  margin: 0 auto;
  width: 1000px;
  height: 480px;
  font-family: youandiR;
  text-align: left;

  &__image {
    position: absolute;
    bottom: 0;
    right: 0;
    z-index: -1;
  }

  &__text {
    font-size: 28px;
    color: inherit;
  }

  &__info {
    display: block;
    margin: 30px 0 3px;
    font-size: 15px;
    color: #666;
  }

  &__name {
    display: block;
    margin-top: 5px;
    font-size: 21px;
    color: #000;
  }
}

.recruit-video {
  position: relative;
  width: 1000px;
  height: 560px;
  margin: 80px auto 0;

  &__img,
  &__poster {
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 10;

    img {
      width: 100%;
      height: 100%;
    }
  }

  iframe,
  .youtube {
    position: absolute;
    width: 100%;
    height: 100%;
  }
}

.recruit-swiper {
  padding: 0 50px;
  margin: 0 auto;

  .swiper-custom-controller {
    position: absolute;
    top: 226px;
    left: 50%;
    transform: translateX(-50%);
    width: 100vw;
    min-width: 1280px;
    max-width: 1920px;
    z-index: 10;
  }

  .swiper-custom-pagination {
    position: absolute;
    top: 132px;
    left: 135px !important;
    padding: 0;
  }

  // .swiper-slide:not([class*="active"]), .fade {
  //   animation-name: fade-in;
  //   animation-duration: .5s;
  //   transition: ease-out;

  //   @keyframes fade-out {
  //     0% {
  //       opacity: 1;
  //     }

  //     50% {
  //       opacity: 0;
  //     }

  //     100% {
  //     }
  //   }
  // }

  // .swiper-slide:not(.swiper-slide-active) {
  //   opacity: 0;
  // }

  // .swiper-slide-active {
  //   opacity: 1;
  // }
}

.text-box {
  margin-top: -30px;
  display: flex;
  flex-wrap: wrap;

  &__item {
    margin-top: 30px;
    padding: 40px 38px;
    width: 280px;
    border: 1px solid #eaedf3;
    border-radius: 10px;
    text-align: left;

    &:not(:nth-child(1)):not(:nth-child(5n)) {
      margin-left: 20px;
    }
  }

  &__image {
    display: block;
    margin: 0 0 26px -8px;
  }

  &__title {
    display: block;
    font-family: youandiR;
    font-size: 19px;
  }

  &__text {
    display: block;
    margin-top: 10px;
    line-height: 24px;
    font-size: 15px;
    color: #666;
  }
}

/* 회사소개 - IR */
.ci-mail {
  width: 800px;
  padding: 70px 70px 112px 70px;
  margin: 0 auto;
  border: 10px solid #f8f8f8;
  text-align: center;

  .o-icon--mail {
    display: block;
    margin: 0 auto 40px;
  }

  strong {
    font-family: youandiB;
    font-size: 23px;
  }

  p {
    margin-top: 20px;
    font-size: 17px;
    color: #666;
  }

  a {
    position: relative;
    display: inline-block;
    margin-left: 16px;
    padding-left: 20px;

    &::before {
      content: "";
      position: absolute;
      top: 50%;
      left: 0;
      transform: translateY(-50%);
      width: 1px;
      height: 14px;
      background: #d2d2d2;
    }
  }
}

/* 회사소개 - 조직문화 */
.ci-value {
  margin-top: 30px;
  text-align: center;

  $root: ".ci-value";

  &__heading {
    display: inline-block;
    min-width: 360px;
    height: 50px;
    line-height: 48px;
    margin: 70px 0;
    border: 1px solid $blue;
    border-radius: 25px;
    text-align: center;
    font-family: youandiR;
    font-size: 17px;
    color: $blue;
  }

  .text-box__item {
    background-color: #f9fafc;
  }

  &__content {
    position: relative;
    width: 1180px;
    height: 800px;
  }

  &__integrity {
    .text-box__item {
      &:nth-child(1),
      &:nth-child(2),
      &:nth-child(6) {
        background-color: $white;

        .text-box__title {
          color: $blue;
        }
      }
    }
  }

  &__sincerity {
    .text-box__item {
      &:nth-child(2),
      &:nth-child(3),
      &:nth-child(5),
      &:nth-child(7) {
        background-color: $white;

        .text-box__title {
          color: $blue;
        }
      }
    }
  }

  &__professionalism {
    .text-box__item {
      &:nth-child(3),
      &:nth-child(4),
      &:nth-child(5),
      &:nth-child(7) {
        background-color: $white;

        .text-box__title {
          color: $blue;
        }
      }
    }
  }

  &__innovation {
    .text-box__item {
      &:nth-child(4),
      &:nth-child(6),
      &:nth-child(8) {
        background-color: $white;

        .text-box__title {
          color: $blue;
        }
      }
    }
  }
}

.ci-value-swiper {
  position: absolute !important;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
  width: 300px;
  height: 220px;
  border-radius: 10px;

  .swiper-slide {
    text-align: center;
  }

  i {
    display: block;
    margin: 0 auto 20px;
  }

  strong {
    display: block;
    margin-bottom: 20px;
    font-family: youandiB;
    font-size: 21px;
  }

  .swiper-custom-controller {
    z-index: 5;
  }

  .swiper-custom-pagination {
    padding: 8px 30px;
  }
}

/* 회사소개 - 연혁 */
.history {
  margin: 100px 50px 0;

  $root: ".history";

  &__year {
    display: block;
    padding-left: 90px;
    margin-top: -4px;
    float: left;
    line-height: 1;
    font-family: youandiB;
    font-size: 36px;
  }

  &__list {
    position: relative;
    padding-left: 267px;

    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 278px;
      bottom: 0;
      border-left: 1px solid #e8e8e8;
    }
  }

  &__item {
    position: relative;
    padding: 0 255px 90px 85px;
    font-size: 15px;
    @include clearfix;

    &:not([class*="--type2"]) {
      &::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 23px;
        height: 23px;
        background: #f8f8f8;
        border: 1px solid #e8e8e8;
        border-radius: 50%;
      }

      &::after {
        content: "";
        position: absolute;
        top: 8px;
        left: 8px;
        width: 7px;
        height: 7px;
        background: $blue;
        border-radius: 50%;
      }
    }

    &--type2 {
      padding: 35px 40px;
      margin: 0 0 90px -267px;
      background: $white;
      border: 10px solid #f8f8f8;
      border-radius: 10px;
      @include clearfix;

      &::before {
        top: 0;
      }

      #{$root}__title {
        float: left;
      }

      #{$root}__text {
        float: right;
      }
    }

    &.border-none {
      #{$root}__date {
        &::before {
          content: "";
          position: absolute;
          top: 23px;
          left: 11px;
          bottom: 0;
          border-left: 1px solid $white;
        }
      }
    }
  }

  &__date {
    display: block;
    margin-bottom: 20px;
    font-family: youandiR;
    font-size: 17px;
    color: $blue;
  }

  &__image {
    position: absolute;
    top: 6px;
    right: 85px;
    width: 160px;
    height: 160px;
    overflow: hidden;
  }

  &__title {
    display: block;
    margin-bottom: 20px;
    font-family: youandiR;
    font-size: 19px;
  }

  &__text {
    display: block;
    line-height: 28px;
    font-size: 15px;
    color: #000;

    &.c-text-list--hyphen,
    &.c-text-list--dot {
      color: #666;

      > .c-text-list__item::before {
        background-color: #666;
      }
    }

    > .c-text-list__item {
      font-size: inherit;
    }

    > .c-text-list__item:nth-child(n+2) {
      margin-top: 4px;
    }

    + #{$root}__text {
      margin-top: 8px;
    }
  }
}

/* 고객안내 - 메인 */
.customer-main {
  .page-head {
    position: relative;
    padding: 81px 70px 70px;
    color: $white;

    &::before {
      content: "";
      position: absolute;
      top: 0;
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);
      width: 100vw;
      min-width: 1280px;
      background: linear-gradient(90deg, $blue, #00a19c);
      z-index: -1;
    }
  }

  .search-form {
    margin: 24px auto 0;
    width: 640px;

    &__input {
      background-color: $white;
    }
  }

  .search-keyword {
    margin-top: 12px;

    .search-keyword__button {
      height: 22px;
      font-size: 14px;
      color: $white;
      border: none;

      &:hover,
      &:focus {
        border-bottom: 1px solid $white;
      }

      ~ .search-keyword__button {
        position: relative;
        margin-left: 40px;

        &::before {
          content: "";
          position: absolute;
          top: 50%;
          left: -20px;
          transform: translateY(-50%);
          width: 1px;
          height: 12px;
          background: rgba(255,255,255,.15);
        }
      }
    }
  }
}

.customer-main-menu {
  display: inline-block;
  width: 769px;
  font-size: 0;

  $root: ".customer-main-menu";

  &__item {
    display: block;
    position: relative;
    padding: 36px 40px 40px 118px;
    border: 1px solid #d8d8d8;
    border-radius: 10px;
    background: #f8f8f8;

    &::before {
      content: "";
      position: absolute;
      top: 50%;
      right: 40px;
      transform: translateY(-50%) rotate(45deg);
      width: 10px;
      height: 10px;
      border-top: 2px solid #666;
      border-right: 2px solid #666;
    }

    &:hover,
    &:focus {
      background-color: $white;
      border-color: $blue;
      box-shadow: 0px 45px 35px 0px rgba(0,0,0,.1);
      transition: .4s ease-in-out;
      z-index: 1;

      #{$root}__title {
        color: $blue;
        transition: color .4s ease-in-out;
      }
    }

   &~& {
     margin-top: 10px;
   }
  }

  &__image {
    position: absolute;
    top: 50%;
    left: 34px;
    transform: translateY(-50%);
    width: 65px;
    height: 65px;
  }

  &__title {
    font-family: youandiR;
    font-size: 20px;
  }

  &__text {
    margin-top: 10px;
    font-size: 17px;
    color: #666;
  }
}

.customer-main-info {
  padding: 5px 30px;
  width: 360px;
  border: 1px solid #d8d8d8;
  border-radius: 10px;
  font-size: 0;

  &__item {
    position: relative;
    padding-left: 46px;
    height: 70px;
    line-height: 70px;
    font-size: 15px;

    &~& {
      border-top: 1px solid #e8e8e8;
    }
  }

  &__image {
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    width: 24px;
    height: 24px;
    line-height: 1;
  }

  &__button {
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
  }


}

.customer-main-notice {
  margin-top: 46px;
  width: 360px;
  font-size: 0;

  $root: ".customer-main-notice";

  &__title {
    position: relative;
    display: inline-block;
    margin-bottom: 20px;
    padding-right: 25px;
    font-family: youandiR;
    font-size: 19px;

    &::before {
      content: "";
      position: absolute;
      top: 50%;
      right: 0;
      transform: translateY(-50%) rotate(45deg);
      width: 10px;
      height: 10px;
      border-top: 2px solid #666;
      border-right: 2px solid #666;
    }
  }

  &__item {
    &~& {
      margin-top: 7px;
    }
  }

  &__anchor {
    position: relative;
    display: block;
    height: 22px;
    line-height: 22px;
    font-size: 14px;
    color: #666;
    @include clearfix;

    &:hover,
    &:focus {
      color: $blue;

      #{$root}__text {
        border-bottom: 1px solid $blue;
        background-color: transparent !important;
        color: inherit;
      }
    }
  }

  &__text {
    display: inline-block;
    max-width: 270px;
    height: 22px;
    white-space: nowrap;
    overflow: hidden;
  }

  &__date {
    float: right;
    height: 22px;
  }
}

/* 고객안내 - 모바일 접수방법 안내 */
.cusi-step {
  width: 510px;
  margin: 0 auto;
  padding-top: 80px;
  font-size: 0;

  &__item {
    position: relative;
    @include clearfix;
  }

  &__title {
    float: right;
    margin-top: -6px;
    width: calc(100% - 217px);
    font-family: youandiB;
    font-size: 18px;
    color: $blue;
    text-align: center;
  }

  .c-text-list {
    position: absolute;
    top: 36px;
    left: 217px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: calc(100% - 217px);
    height: 100px;
    background: #f8f8f8;
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
    text-align: center;
    color: #666;
  }

  .c-text-list__item {
    line-height: 22px;
    font-size: 13px;

    + .c-text-list__item {
      margin-top: 0;
    }
  }
}

/* 고개안내 - 보험금청구안내 */
.cusi-info-wrap {
  &.info-wrap--type2 {
    background-color: $blue;
    padding: 0 30px;
    height: 70px;
    line-height: 70px;

    +.c-table {
      margin-top: 30px;

      ~.c-table {
        margin-top: 30px;
      }
    }

    .info-wrap__title {
      font-size: 19px;
    }

    .info-wrap__text {
      font-size: 15px;
    }
  }

  &.info-wrap--type3 {
    text-align: left;
    padding:32px 40px 34px;
    border-radius: 5px;
    box-shadow: 4px 9px 30px 0px rgba(0,79,121,.2);

    .info-wrap__highlight {
      display: block;
      font-size: 30px;
      margin-bottom: 6px;
    }
  }
}

.cusi-text-bg-wrap {
  &.text-bg-wrap--type2 {
    .text-bg-wrap__item {
      padding-top: 35px;
    }
  }

  .text-bg-wrap__info {
    font-size: 0;

    dt {
      margin-top: 23px;
      font-size: 15px;
    }

    dd {
      margin-top: 13px;
      font-size: 13px;
      color: #666;

      .link {
        font-size: inherit;
      }
    }
  }
}

/* 컨설턴트 - 메인 */
.csult-main {
  position: relative;
  font-size: 0;

  $root: ".csult-main";

  &__toggle {
    position: absolute;
    top: 80px;
    left: 50%;
    transform: translateX(-50%);
    color: $white;
    z-index: 1;

    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      border: 1px solid rgba(255,255,255,.7);
      border-radius: 7px;
      z-index: -1;
    }

    input[type="radio"] {
      display: inline-block;
      overflow: hidden;
      position: absolute;
      width: 1px;
      height: 1px;
      opacity: 0;

      &:focus + label {
        outline: 1px dotted #767676;
        outline: -webkit-focus-ring-color auto 1px;
      }

      +label {
        display: inline-block;
        min-width: 66px;
        height: 46px;
        line-height: 46px;
        padding: 0 20px;
        border-radius: 6px;
        font-family: youandiR;
        font-size: 15px;
        text-align: center;
        cursor: pointer;
      }

      &:checked + label {
        background: $white;
        font-family: youandiB;
        color: #007ab5;
      }
    }
  }

  &__content {
    position: relative;
    padding: 180px 0 120px;
    color: $white;
    text-align: center;

    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 50%;
      bottom: 0;
      transform: translateX(-50%);
      width: 100vw;
      min-width: 1280px;
      background: linear-gradient(to left, #18b3af, #0079cc);
      z-index: -1;
    }
  }

  &__title {
    overflow: hidden;
    line-height: 52px;
    font-family: youandiB;
    font-size: 36px;
  }

  &__animation {
    display: block;
    position: relative;
    top: 100%;
  }

  &__text {
    margin: 15px 10px 34px;
    font-family: youandiR;
    font-size: 19px;
  }

  &__button {
    width: 229px;
    border: 1px solid $white;
    font-size: 17px;
    color: $white;

    ~#{$root}__button {
      margin-left: 10px !important;
    }
  }
}

.csult-main-visual {
  display: flex;
  justify-content: center;
  padding: 40px;
  margin: 54px auto 40px;
  width: 793px;
  background: $white;
  border-radius: 40px;

  $root: ".csult-main-visual";

  &__item {
    flex: 1;

    ~#{$root}__item {
      border-left: 1px solid #efefef;
    }
  }

  &__image {
    margin: 0 auto;
    display: block;
  }

  &__title {
    display: block;
    font-size: 19px;
    color: #000;
  }

  &__text {
    display: block;
    margin-top: 10px;
    font-size: 16px;
    color: #666;
  }
}

/* 컨설턴트 - TM */
.csult-text {
  display: block;
  margin: 41px 0 15px;
  font-family: youandiR;
  font-size: 19px;
}

.csult-info-wrap {
  padding: 44px;
  text-align: center;
  background: #ebf8f7;

  &__text {
    font-family: youandiB;
    font-size: 19px;
  }

  &__sub {
    display: block;
    margin-top: 10px;
    font-family: $font-family-base;
    font-size: 15px;
    color: #666;
  }
}

.csult-text-wrap {
  padding: 25px 40px;
  background: #f8f8f8;
  border-radius: 6px;

  .c-text-list + img {
    margin-top: 30px;
  }

  .c-text-list {
    &[class*="--col"] {
      display: flex;
      flex-wrap: wrap;
      margin-top: -7px;

      .c-text-list__item {
        margin-top: 7px;
      }
    }

    &.c-text-list--col4 {
      .c-text-list__item {
        width: 25%;
      }
    }

    &.c-text-list--col2 {
      .c-text-list__item {
        width: 50%;
      }
    }
  }
}

img + .csult-text-wrap {
  margin-top: 30px;
}

.csult-image-wrap {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  font-size: 0;
}

.csult-process {
  margin-top: 24px;
  display: flex;

  li {
    position: relative;
    flex: 1;
    height: 111px;
    border: 1px solid #d8d8d8;
    text-align: center;
    border-radius: 4px;
    font-size: 17px;
    color: #0076c0;

    ~li {
      margin-left: 10px;
    }

    > span {
      width: 100%;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }

  .co-gray {
    display: block;
    margin-top: 12px;
    font-size: 15px;
  }
}

.csult-list {
  margin-top: -40px;
  text-align: right;

  >.o-text--star {
    display: inline-block;
    margin: 0 0 28px;
    font-size: 15px;
  }

  ul {
    display: flex;
    justify-content: space-between;
  }

  li {
    position: relative;
    float: left;
    width: 386px;
    height: 131px;
    padding: 34px 50px;
    border-radius: 4px;
    text-align: left;

    &:nth-of-type(1) {
      background: #f8f7e9;
      color: #4c4333;
    }

    &:nth-of-type(2) {
      background: rgba(0,161,156,.15);
      color: #007688;
    }

    &:nth-of-type(3) {
      background: rgba(0,118,192,.2);
      color: #004b75;
    }
  }

  &__title {
    display: block;
    margin-bottom: 2px;
    font-size: 17px;
    color: #000;
  }

  &__text {
    font-size: 23px;
  }

  &__image {
    position: absolute;
    top: 50%;
    right: 40px;
    transform: translateY(-50%);
  }
}

.image-card {
  margin-left: -10px;
  text-align: center;
  font-size: 0;
  @include clearfix;

  $root: ".image-card";

  &__item {
    float: left;
    margin: 0 0 30px 10px;
  }

  &__text {
    position: relative;
    margin: -35px auto 0;
    padding: 16px 20px;
    width: calc(100% - 40px);
    font-size: 15px;
    color: #666;
    z-index: 1;

    &::before {
      content: "";
      background: $white;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: -1;
    }

    strong {
      display: block;
      margin-bottom: 6px;
      font-family: youandiR;
      font-size: 17px;
      color: #000;
    }
  }
}

.process {
  margin-top: 24px;
  @include clearfix;

  $root: ".process";

  &__item {
    position: relative;
    float: left;
    padding: 36px 40px;
    width: 380px;
    height: 134px;
    background: #e8e8e8;
    border-radius: 10px;
    text-align: center;
    font-size: 17px;
    color: #666;

    ~#{$root}__item {
      margin-left: 18px;

      &::before {
        content: "";
        position: absolute;
        top: 50%;
        left: 0;
        transform: translateY(-50%);
        width: 0;
        height: 0;
        border-top: 33px solid transparent;
        border-left: 14px solid $white;
        border-bottom: 33px solid transparent;
      }
    }

    &:not(:last-child) {
      &::after {
        content: "";
        position: absolute;
        top: 50%;
        right: -14px;
        transform: translateY(-50%);
        width: 0;
        height: 0;
        border-top: 33px solid transparent;
        border-left: 14px solid #e8e8e8;
        border-bottom: 33px solid transparent;
      }
    }

    &:nth-child(2) {
      background-color: #ddf0f0;
      color: #007688;

      &::after {
        border-left-color: #ddf0f0;
      }
    }

    &:nth-child(3) {
      background-color: #d0e3f0;
      color: #004b75;

      &::after{
        border-left-color: #d0e3f0;
      }
    }
  }

  &__title {
    display: block;
    margin-bottom: 9px;
    font-family: youandiB;
    font-size: 19px;
    color: #000;
  }
}

.csult-fp {
  position: relative;
  height: 254px;
  box-sizing: content-box;
  padding: 0 590px 70px 40px;
  margin-top: 80px;
  border-bottom: 1px solid #e8e8e8;
  font-size: 0;

  .o-heading--1 {
    padding-top: 24px;
    margin-bottom: 32px;
    font-family: youandiB;
    line-height: 48px;
  }

  .blue-fp {
    position: absolute;
    top: 0;
    right: 40px;
  }

  .co-blue {
    font-family: youandiR;
    font-size: 19px;
  }

  p {
    margin-top: 18px;
    line-height: 28px;
    font-size: 17px;
    color: #666;
  }
}

.blue-fp {
  position: relative;
  padding: 40px;
  width: 500px;
  border-radius: 28px;
  background: linear-gradient(-45deg,#00b293, #0076c0);
  @include clearfix;

  i {
    position: absolute;
    top: 50%;
    left: 90px;
    transform: translateY(-50%);
  }

  ul {
    float: right;
  }

  li {
    width: 200px;
    line-height: 36px;
    background: #fff;
    border-radius: 16px;
    font-family: youandiR;
    font-size: 15px;
    color: $blue;
    text-align: center;

    ~li {
      margin-top: 10px;
    }
  }
}

/* 금융상품 */
.product-info {
  display: inline-block;
  margin: 55px auto 0;
  @include clearfix;

  $root: ".product-info";

  &__item {
    position: relative;
    float: left;
    padding: 0 50px;
    font-family: youandiR;
    font-size: 23px;

    ~#{$root}__item {
      &::before {
        content: "";
        position: absolute;
        top: 50%;
        left: 0;
        transform: translateY(-50%);
        width: 1px;
        height: 50px;
        background: rgba(0,0,0,.3);
      }
    }
  }

  &__title {
    display: block;
    margin-bottom: 10px;
    font-size: 18px;
  }

  .c-tooltip {
    margin-left: 0;
  }
}

.product-submain {
  .product-info {
    margin: -23px auto 48px;

    .product-info__item {
      color: $white;

      ~.product-info__item:before {
        background: rgba(255,255,255,.3);
      }
    }

    .c-tooltip__button.is-active {
      background-image: url("../img/com/ico_tooltip_type2.png");
      background-position: 0 0;
    }
  }

  .info .c-text-list {
    margin-top: 0;
  }
}

.page-content {
  margin-top: -100px;
  padding: 0 50px 100px;
}

.fp-step {
  margin-top: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #e8e8e8;
  border-radius: 12px;

  > li {
    position: relative;
    flex: 1;
    padding: 34px 0 40px;
    text-align: center;

    &:not(:last-child) {
      &::before {
        content: "";
        position: absolute;
        top: 50%;
        right: -14px;
        transform: translateY(-50%);
        width: 0;
        height: 0;
        border-top: 33px solid transparent;
        border-left: 14px solid $white;
        border-bottom: 33px solid transparent;
        z-index: 1;
      }
    }

    &:nth-child(even) {
      background-color: #f8f8f8;

      &::before {
        border-left-color: #f8f8f8;
      }
    }
  }

  .co-blue {
    display: block;
  }

  i {
    display: block;
    margin: 0 auto 15px;
    width: 30px;
    height: 25px;
  }
}

.fp-button-wrap {
  margin-top: 20px;
  font-size: 0;

  button,
  a {
    position: relative;
    width: calc(50% - 15px);
    height: 64px;
    margin-top: 16px;
    padding: 0 27px;
    line-height: 62px;
    text-align: left;
    font-size: 18px;
    border: 1px solid #d8d8d8;
    border-radius: 6px;

    &::before {
      content: "";
      position: absolute;
      top: 50%;
      right: 32px;
      transform: translateY(-50%) rotate(45deg);
      width: 8px;
      height: 8px;
      border-top: 2px solid #666;
      border-right: 2px solid #666;
    }

    &:nth-child(2n) {
      margin-left: 30px;
    }
  }
}

/* 공시실 - 보험료계산하기 */
.c-tooltip-wrap {
  position: relative;
  margin-left: 0;

  .c-tooltip {
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
  }

  .input-text {
    width: calc(100% - 30px)
  }

  .c-tooltip__button:disabled {
    display: none;
  }

  &.disabled {
    .input-text {
      width: 100%;
    }
  }
}

/* 모달 - 타이틀 */
.title-wrap {
  &__title {
    font-family: youandiB;
    font-size: 32px;
    line-height: 45px;
    color: $blue;

    &:only-child {
      margin-bottom: 38px;
    }
  }

  &__text {
    margin-top: 8px;
    font-family: youandiR;
    font-size: 21px;
    line-height: 30px;
  }

  +.c-form {
    margin-top: 74px;
  }

  +.o-heading--2 {
    margin-top: 70px;
  }
}

/* 퇴직연금 - 예상은퇴생활비 */
.living {
  overflow: hidden;
  margin-top: 23px;
  border: 1px solid #d8d8d8;
  border-radius: 5px;

  &__list {
    padding: 12px 40px;
  }

  &__item {
    position: relative;
    padding: 19px 80px 18px 60px;

    &:nth-child(n+2) {
      border-top: 1px solid #e8e8e8;
    }

    &--plus {
      margin-top: 29px;

      &::before {
        position: absolute;
        top: -30px;
        left: 0;
        width: calc(100% + 80px);
        height: 30px;
        margin: 0 -40px;
        background: #f8f8f8 url("../img/com/bg_living_plus.png") no-repeat 50% 50%;
        content: "";
      }
    }
  }

  &__image {
    position: absolute;
    top: 50%;
    left: 10px;
    transform: translateY(-50%);
    width: 30px;

    img {
      width: 100%;
      vertical-align: top;
    }
  }

  &__title {
    font-size: 15px;
    font-family: youandiR;
    line-height: 28px;
  }

  &__sub-text {
    display: block;
    font-size: 13px;
    color: #666;
  }

  &__text {
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
    font-size: 15px;
    font-family: youandiR;
    color: $blue;
    line-height: 21px;
  }

  &__sum {
    padding: 26px 0 24px;
    background-color: $blue;
    font-size: 15px;
    font-family: youandiR;
    text-align: center;
    color: #fff;
  }

  &__highlight {
    display: block;
    font-size: 21px;
  }
}

/* 고객안내 - 금융소비자보호 */
.cs-process-wrap {
  position: relative;
  margin-top: 44px;
  text-align: center;

  &__text {
    display: inline-block;
    height: 28px;
    padding: 0 20px;
    margin-bottom: 20px;
    line-height: 26px;
    border: 1px solid $blue;
    border-radius: 15px;
    background: #fff;
    font-family: youandiR;
    font-size: 14px;
    color: $blue;

    &::before {
      content: "";
      position: absolute;
      top: 6px;
      left: 0;
      width: 1180px;
      height: 18px;
      background: url("../img/com/bg_cs_process_wrap.gif") no-repeat;
      z-index: -1;
    }
  }
}

.cs-process {
  position: relative;
  display: flex;
  border: 1px solid #e8e8e8;
  border-radius: 12px;
  font-size: 0;
  
  > li {
    position: relative;
    flex: 1;
    padding: 35px 40px 56px;
    font-size: 14px;
    color: #666;

    &:not(:last-child) {
      &::before {
        content: "";
        position: absolute;
        top: 50%;
        right: -14px;
        transform: translateY(-50%);
        width: 0;
        height: 0;
        border-top: 33px solid transparent;
        border-left: 14px solid $white;
        border-bottom: 33px solid transparent;
        z-index: 1;
      }
    }

    &:nth-child(even) {
      background-color: #f8f8f8;

      &::before {
        border-left-color: #f8f8f8;
      }
    }
  }

  strong {
    font-size: 17px;
    color: $blue;
  }

  i {
    display: inline-block;
  }

  &:not([class*="type"]) {
    margin: 40px 0;
    
    &::before {
      content: "";
      position: absolute;
      bottom: -40px;
      left: 0;
      width: 100%;
      height: 45px;
      background: url("../img/content/bg_cs_process.png") no-repeat;
    }

    > li {
      &:first-child {
        strong {
          color: #007688;
        }
      }
    }

    strong {
      display: block;
      margin-bottom: 17px;
    }

    i {
      margin-bottom: 16px;
    }
  }

  &--type2 {
    > li {
      display: flex;
      align-items: center;
      padding: 35px 27px;
    }

    i {
      margin-right: 20px;
    }
  }
}

/* 찐 트리플 양로저축보험 */
.real-triple {
  font-size: 0;

  ul {
    margin: -10px auto 0;
    width: 516px;

    @include clearfix;

    li {
      float: left;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      position: relative;
      width: calc(50% - 6px);
      height: 80px;
      padding: 0 20px;
      margin: 10px 0 0 6px;
      border: 1px solid $blue;
      border-radius: 12px;
      background: #f8f8f8;
      box-shadow: 5px 12px 18px 0px rgba(211,211,211,.16);
      text-align: center;
      font-family: youandiB;
      font-size: 15px;
      color: $blue;

      &:nth-child(1) {
        border-color:#7ab5db;
      }

      &:nth-child(2) {
        background: $blue;
        color: #fff;

        &::before {
          content: "";
          position: absolute;
          top: 50%;
          left: -1px;
          transform: translateY(-50%);
          width: 10px;
          height: 20px;
          background: #fff;
        }

        &::after {
          content: "";
          position: absolute;
          top: 50%;
          left: 5px;
          transform: translateY(-50%);
          width: 0;
          height: 0;
          border-top: 14px solid transparent;
          border-left: 13px solid #fff;
          border-bottom: 14px solid transparent;
        }

        span {
          color: inherit;
        }
      }
    }

    span {
      display: block;
      font-family: $font-family-base;
      font-size: 14px;
      color: #666;
    }
  }

  ol {
    position: relative;
    width: 540px;
    height: 105px;
    margin: 25px auto 0;
    padding: 58px 37px 0;
    text-align: left;

    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 540px;
      height: 48px;
      background: url("../img/insurance/bg_real_triple.png");
    }

    li {
      display: inline-block;
      font-family: youandiR;
      font-size: 16px;

      &:nth-child(3) {
        position: absolute;
        top: 58px;
        left: 50%;
        transform: translateX(-50%);

        span {
          display: block;
          position: absolute;
          bottom: -38px;
          left: 50%;
          transform: translateX(-50%);
          width: 172px;
          line-height: 28px;
          background: #ffde00;
          border-radius: 15px;
          text-align: center;
          font-size: 14px;
        }
      }
    }
  }

  + .c-text-list--star {
    .c-text-list__item {
      font-size: 12px;
    }
  }
}

/* 윤리경영 */
.direction {
  position: relative;
  padding: 47px 50px 50px;
  margin: 0 -20px;
  border-radius: 10px;
  font-size: 0;
  text-align: left;

  i {
    position: absolute;
    top: 50px;
    left: 83px;
  }

  > strong {
    display: inline-block;
    width: 178px;
    margin-left: 103px;
    font-family: youandib;
    font-size: 17px;
    text-align: left;

    span {
      display: block;
      font-family: $font-family-base;
      font-size: 13px;
    }

    +  ul {
      display: inline-block;
      @include clearfix;

      li {
        float: left;
        padding: 0 10px;
        margin-top: 11px;
        background: #fff;
        border: 1px solid #d8d8d8;
        border-radius: 15px;
        height: 26px;
        line-height: 24px;
        font-size: 13px;
        color: #666;

        ~ li {
          margin-left: 4px;
        }
      }
    }
  }

  &:nth-child(1) {
    margin-top: -30px;
  }

  &:nth-child(2) {
    background: #ebf4fa;
  }

  &__sub {
    display: flex;
    width: 100%;
    padding: 27px 30px 25px;
    margin-top: 37px;
    background: #fff;
    border-radius: 10px;

    > li {
      position: relative;
      width: 230px;
      padding: 0 32px;

      ~ li {
        &::before {
          content: "";
          position: absolute;
          top: 50%;
          left: 0;
          transform: translateY(-50%);
          width: 1px;
          height: 98px;
          background: #e8e8e8;
        }
      }
    }

    strong {
      font-family: youandiB;
      font-size: 15px;
    }

    .c-text-list {
      margin-top: 11px;
    }

    .c-text-list__item {
      margin: 0;
      font-size: 13px;
      color: #666;

      &::before {
        background-color: #666;
      }
    }
  }
}

/* 개인형IRP가입하기 - 완료 */
.complete {
  $root: ".complete";

  &--type3 {
    position: relative;
    padding-top: 213px;
    background: none;

    &.bg-full::before {
      background: linear-gradient(to bottom, #0076c0 80%, #00a19c 100%);
    }

    #{$root}__msg {
      color: #fff;
    }

    #{$root}__text {
      margin-top: 22px;
      color: #fff;
    }

    .o-button.link {
      position: relative;
      width: 480px;
      height: 101px;
      margin-top: 44px;
      padding: 25px 30px 26px;
      border: 0;
      border-radius: 10px;
      background: url(../img/com/bg_complete_link.png) #fff no-repeat;
      background-size: cover;
      text-align: left;
      font-family: youandiB;
      font-size: 17px;
      color: #004b75;

      &::before {
        content: "";
        position: absolute;
        right: 30px;
        top: 50%;
        transform: translateY(-50%) rotate(45deg);
        width: 7px;
        height: 7px;
        border-top: 2px solid $blue;
        border-right: 2px solid $blue;
      }
    }

    .c-button-wrap {
      margin-top: 75px;

      .o-button {
        min-width: 240px;
        width: auto;
        height: 72px;
      }
    }
  }
}

.complete__msg {
  position: relative;
}
.fetits-element {
  left: 50%;
}

.ani-wrap {
  position: absolute;
  top: 123px;
  left: 50%;
  transform: translate(-50%, 0);
  width: 65px;
  height: 46px;
  // background-color: rgba(red, 0.2);

  .line-1 {
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 1;
    width: 43px;
    height: 39px;
    background: url('../img/com/bg_pmp150601000000_1.png') no-repeat;
    background-size: 43px;
    opacity: 0;
    animation: .7s .5s ease-in-out forwards ani1;
  }

  .line-2 {
    position: absolute;
    bottom: 7px;
    left: 15px;
    width: 50px;
    height: 39px;
    background: url('../img/com/bg_pmp150601000000_2.png') no-repeat;
    background-size: 50px;
    opacity: 0;
    animation: .7s .8s forwards ani2;
  }
}

@keyframes ani1 {
  0% {
    transform: translate(-50%, -50%);
    opacity: 0;
  }

  100% {
    transform: translate(0, 0);
    opacity: 1;
  }
}

@keyframes ani2 {
  0% {
    width: 0;
    opacity: 0;
  }

  100% {
    width: 50px;
    opacity: 1;
  }
}

.login {
  .c-radio-box {
    font-size: 0;
  }
  
  .c-radio-box__item:nth-child(n+2)::before {
    display: none;
  }
}

.info-service-hours {
  &__image {
    position: absolute;
    top: 50%;
    right: 50px;
    transform: translateY(-50%);
  }

  .text-wrap {
    position: relative;

    .o-heading--3 {
      margin: 0 0 7px;
    }
  }
  .c-table {
    margin-top: 40px;
    td {
      .co-blue {
        display: block;
        float: right;
        width: calc(100% - 200px);
      }
    }
  }
}

/* 공시실 서브메인 */
.disclosure-main {
 .page-head {
   color: #fff;

   &::before {
    background: linear-gradient(90deg, $blue, #00a19c);
   }
 }
}

.disclosure-main-menu {
  margin-left: -20px;
  font-size: 0;

  $root: ".disclosure-main-menu";

  &__anchor {
    position: relative;
    display: block;
    height: 70px;
    padding: 0 30px;
    line-height: 70px;
    font-family: youandiR;
  }

  a.disclosure-main-menu__anchor {
    &::before {
      content: "";
      position: absolute;
      top: 50%;
      right: 30px;
      transform: translateY(-50%) rotate(45deg);
      width: 6px;
      height: 6px;
      border-top: 2px solid #000;
      border-right: 2px solid #000;
    }
    
    &:hover {
      color: $blue;

      &::before {
        border-color: $blue;
      }
    }
  }

  &__1depth {
    width: 1180px;
    margin-left: 20px;
    border: 1px solid #d8d8d8;
    border-radius: 10px;
    overflow: hidden;

    #{$root}__anchor {
      font-size: 17px;
    }

    &:not(:first-child) {
      display: inline-block;
      width: 380px;
      margin-top: 30px;
    }
  }

  &__2depth {
    padding: 0 19px;
    margin-bottom: -1px;
    background: #f8f8f8;

    #{$root}__anchor {
      display: inline-block;
      width: 340px;
      padding: 0 10px;
      margin: 0 20px;
      border-bottom: 1px solid #e8e8e8;
      font-size: 15px;
    }

    a.disclosure-main-menu__anchor {
      &::before {
        right: 10px;
      }
    }
  }
}

// 마케팅동의팝업 - [선택]금융상품 소개 등을 위한 개인(신용)정보 처리 동의서
.agree-check--type2 {
  margin-top: 33px;
  border: 0;

  > .agree-check__item {
    position: relative;
    border: 1px solid #d8d8d8;
    border-radius: 6px;

    > label {
      width: auto;
    }

    > .agree-check__sub {
      margin: 0;
      position: absolute;
      top: 0;
      right: 0;

      .o-text {
        display: inline-block;
        margin-left: -36px;
        line-height: 62px;
      }

      .input-group {
        display: inline-block;
        margin: 0 30px 0 0;
        line-height: 62px;

        label {
          margin-left: 32px;
          font-size: 15px;
        }
      }

      &.is-disabled {
        .o-text {
          color: #c8c8c8;
        }

        label {
          color: #c8c8c8;
          cursor: default;
        }
      }
    }
  }

  >.c-accordion--type2 {
    margin-top: 20px;

    .c-accordion__head {
      label {
        width: 100%;
        z-index: 1;
      }
    }

    .c-accordion__sub {
      padding: 34px 0 14px 40px;
      border-top: 1px solid #d8d8d8;

      .o-text--dot {
        margin-bottom: 25px;
      }
  
      .input-group {
        margin: 0 0 25px -50px;
  
        >div {
          margin-top: 20px;
        }

        .o-input--check {
          + label {
            width: auto;
          }

          &:first-child + label {
            margin-left: 38px;
          }
        }
  
        .o-input--radio {
          + label {
            margin-top: 0;
          }

          &:first-child + label {
            margin-left: 68px;
          }
        }
      }
    }
  
    .c-accordion__inside {
      padding: 30px 40px 20px;
      margin-top: 0;
  
      >*:first-child {
        margin-top: 0;
      }
  
      .o-heading--3 {
        margin: 20px 0 16px;
      }
    }

    .c-accordion__panel {
      position: relative;
      padding-bottom: 20px;
      border: 0;

      &::before {
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        width: 100%;
        height: 100%;
        background-color: #f8f8f8;
        content: "";
      }
    }
  }
}

// 홈페이지리뉴얼 이벤트 관련
.event-modal {
  margin: 38px 0 0 0 !important;
  font-size: 0;

  $root: ".event-modal";

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: #f3f7fa;
    z-index: -1;
  }

  &__head {
    position: relative;
    padding: 42px 20px 48px;
    font-family: youandiR;
    color: #fff;
    z-index: 1;

    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: -40px;
      right: -40px;
      bottom: 0;
      background: $blue;
      z-index: -1;
    }

    #{$root}__highlight {
      position: relative;
      font-size: 20px;
      padding-bottom: 26px;
      border-bottom: 2px solid #fff;
      margin-bottom: 26px;
    }

    #{$root}__text {
      font-size: 14px;
    }
  }

  &__content {
    position: relative;
    padding: 60px 20px;
    z-index: 1;

    > img {
      display: block;
    }
  }
}

.event-renewal {
  margin-top: 40px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  > li {
    position: relative;
    width: 240px;
    border-radius: 15px;
    border-bottom-right-radius: 35px;
    box-shadow: 0px 15px 45px 0px rgba(39,96,136,.15);

    > a {
      position: absolute;
      bottom: 51px;
      left: 50%;
      transform: translateX(-50%);
      width: 170px;
      height: 42px;
      z-index: 2;
    }

    &.is-disabled {
      a {
        cursor: default;
      }
    }

    ~:not(:nth-child(1)):not(:nth-child(2)):not(:nth-child(3)) {
      margin-top: 20px;
    }
  }
}

/* 홈페이지 리뉴얼 이벤트 동의팝업 */
.event-swiper {
  margin: 30px 0 70px;
  position: relative;

  .swiper-container {
    width: 440px;
    height: 160px;
    margin: 0 auto;
    font-size: 0;
    border-radius: 10px;
    box-shadow: 0px 18px 10px 0px rgba(0,0,0,.1);

    .swiper-slide {
      &:last-child {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        border: 1px solid #eee;
        border-radius: 10px;
      }

      img {
        width: 100%;
      }

      p {
        font-family: youandiR;
        font-size: 18px;
        text-align: center;
      }

      .o-button {
        margin: 10px auto 0;
      }
    }
  }

  .swiper-custom-controller {
    bottom: -50px;
  }
}

.c-table--event {
  margin-top: 0;
  border-top: 0;

  table::after {
    display: none;
  }

  th,
  td {
    border-right: 0;
  }

  tr:last-child {
    th, 
    td {
      border-bottom: 0;
    }
  }
}

.event-info {
  margin-top: 16px;

  .o-label {
    margin-right: 14px;
    border-radius: 4px;
    padding: 0 4px;
  }

  li ~ li {
    margin-top: 10px;
  }
}