.main {
  margin-bottom: -100px;
}

.main-head {
  position: relative;
  padding-top: 410px;

  &__bg {
    overflow: hidden;
    position: fixed;
    width: 100%;
    height: 410px;
    top: 0;
    left: 0;
    background: linear-gradient(120deg, #0076c0 60%, #00a19c 100%);

    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      opacity: 0;
      transition: background-color 1s, opacity 1s;
    }


    &.bg1::before {
      opacity: 1;
      background-color: #0076c0;
    }

    &.bg2::before {
      opacity: 1;
      background-color: #ffe403;
    }

    &.bg3::before {
      opacity: 1;
      background-color: #d2d2d2;
    }
  }

  &__shape-wrap {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 1920px;
    height: 100%;
    margin: 0 auto;
  }

  &__shape {
    position: absolute;
    background-repeat: no-repeat;

    &--left-top {
      top: 41px;
      left: 96px;
      width: 279px;
      height: 279px;
      background-image: url("../img/com/bg_main_left_top.png");
    }

    &--left-bottom {
      top: 198px;
      left: -156px;
      width: 275px;
      height: 275px;
      background-image: url("../img/com/bg_main_left_bottom.png");
    }

    &--right-top {
      top: -92px;
      right: 498px;
      width: 323px;
      height: 448px;
      background-image: url("../img/com/bg_main_right_top.png");
    }

    &--right-bottom {
      top: 252px;
      right: -64px;
      width: 321px;
      height: 331px;
      background-image: url("../img/com/bg_main_right_bottom.png");
    }
  }
}

.main-content {
  position: relative;
  padding: 40px 0 120px;
  z-index: 0;

  &::before {
    display: block;
    position: absolute;
    top: 0;
    left: 50%;
    z-index: -1;
    transform: translateX(-50%);
    min-width: 1280px;
    width: 100vw;
    height: 100%;
    background-color: #f8f8f8;
    content: "";
  }
}

// 로그인
.main-login {
  $root : ".main-login";

  position: fixed;
  top: 160px;
  height: 321px;

  &.is-active {
    #{$root}__title {
      opacity: 0;
    }

    #{$root}__list {
      opacity: 0;
    }

    #{$root}__text {
      opacity: 0;
    }
  }

  &__label {
    display: inline-block;
    margin: 14px 0 11px;
    padding: 0 10px;
    border-radius: 12px;
    background-color: #fff;
    font-family: youandiR;
    font-size: 14px;
    line-height: 24px;
    color: $blue;
  }

  &__head {
    height: 125px;
  }

  &__title {
    display: block;
    margin-bottom: 24px;
    font-family: youandiB;
    font-size: 29px;
    color: #fff;
  }

  &__text {
    display: inline-block;
    font-family: youandiR;
    font-size: 18px;
    line-height: 32px;
    color: #fff;
    vertical-align: top;
  }

  &__tab {
    position: relative;

    #{$root}__text {
      margin-bottom: 26px;
      padding-left: 145px;
    }
  }

  &__list {
    position: absolute;
    top: 1px;
    left: 0;
    width: 110px;
    height: 30px;
    background-color: rgba(0, 0, 0, .3);
    border-radius: 15px;

    &::before {
      position: absolute;
      top: -1px;
      left: 0;
      width: 60px;
      height: 32px;
      border-radius: 16px;
      transition: transform 150ms ease-in-out;
      background-color: #fff;
      content: "";
    }

    &.right::before {
      transform: translateX(100%);
      left: -10px;
    }
  }

  &__button {
    position: absolute;
    line-height: 30px;
    font-size: 15px;
    font-family: youandIB;
    color: #9bd0f5;
    text-align: center;
    width: 60px;

    &:nth-child(1) {
      left: 0;
    }

    &:nth-child(2) {
      right: 0;
    }

    span {
      position: relative;
      z-index: 1;
    }

    &.is-active {
      color: $blue;
    }
  }

  &__anchor {
    display: inline-block;
    height: 30px;
    margin: 1px 0 0 16px;
    padding: 0 10px;
    border: 1px solid #fff;
    border-radius: 4px;
    font-size: 14px;
    line-height: 28px;
    color: #fff;

    &:hover,
    &:focus {
      background-color: #fff;
      color: $blue;
    }
  }

  &__recommend {
    position: relative;
    margin-top: 60px;
    margin-bottom: -60px;
    width: 1180px;
    height: 50px;
    padding-left: 20px;
    background: url("../img/com/bg_main_login_recommend.png") no-repeat 0 50%;
    font-family: youandiR;
    font-size: 15px;
    line-height: 50px;
    color: #fff;

    &::before {
      display: block;
      position: absolute;
      top: 0;
      left: 50%;
      z-index: -1;
      transform: translateX(-50%);
      min-width: 1280px;
      width: 100vw;
      height: 100%;
      background: rgba(0, 0, 0, .25);
      content: "";
    }
  }

  &__highlight {
    color: #4af2ee;
  }
}

.login-list {
  $root : ".login-list";

  display: flex;
  width: 590px;
  height: 65px;
  border: 1px solid rgba(255, 255, 255, .2);
  border-radius: 6px;
  background-color: rgba(0, 0, 0, .1);

  &__item {
    flex: 1;
  }

  .main-login.is-active & {
    border-color: #333;
    background-color: #333;
    box-shadow:20px 25px 25px rgba(0, 0, 0, .2);

    #{$root}__anchor {
      color: #9c9c9c;

      &:focus,
      &:hover {
        color: #fff;
      }
    }

    // 카카오페이
    #{$root}__item--type2 {
      #{$root}__anchor:focus,
      #{$root}__anchor:hover {
        color: #fbe44d !important;
      }
    }
  }

  &__item {
    &:nth-child(n+2) {
      #{$root}__anchor {
        position: relative;

        &::after {
          display: block;
          position: absolute;
          top: 50%;
          left: 0;
          transform: translateY(-50%);
          width: 1px;
          heighT: 10px;
          background-color: rgba(255, 255, 255, .3);
          content: "";
        }
      }
    }

    &.is-active {

      #{$root}__panel {
        display: block;
      }
    }

    // 카카오페이
    &--type2.is-active {
      #{$root}__title {
        height: 43px;
        padding-top: 7px;
      }

      #{$root}__text {
        color: #333;
      }
    }

    // 공인인증서, 휴대폰
    &--type3.is-active {

      #{$root}__title,
      #{$root}__text {
        color: #000;
      }
    }
  }

  &__anchor {
    display: block;
    font-family: youandiR;
    font-size: 15px;
    line-height: 63px;
    color: #fff;
    text-align: center;
  }

  &__panel {
    display: none;
    position: absolute;
    top: -67px;
    left: 0;
    z-index: 1;
    width: 100%;
    height: 120px;
  }

  &__title {
    display: block;
    margin-bottom: 24px;
    font-family: youandiB;
    font-size: 29px;
    color: #fff;
  }

  &__text {
    font-family: youandiR;
    font-size: 18px;
    line-height: 32px;
    color: #fff;
  }


  // 로그인 후
  &--after {
    #{$root}__item {
      position: relative;
      text-align: right;

      &:nth-child(n+2) {
        &::after {
          display: block;
          position: absolute;
          top: 50%;
          left: 0;
          transform: translateY(-50%);
          width: 1px;
          heighT: 10px;
          background-color: rgba(255, 255, 255, .3);
          content: "";
        }
      }
    }

    #{$root}__title {
      position: absolute;
      top: 0;
      left: 30px;
      font-family: youandiR;
      font-size: 15px;
      line-height: 63px;
      color: #fff;
    }

    #{$root}__anchor {
      display: inline-block;
      padding: 0 30px;
      font-family: youandiR;
      font-size: 15px;
      line-height: 63px;
      color: #fff;
      text-decoration: underline;

      &:hover,
      &:focus {
        text-decoration: underline;
      }
    }

    #{$root}__join {
      display: inline-block;
      height: 30px;
      margin: 16px 30px 0 0;
      padding: 0 10px;
      border: 1px solid #fff;
      border-radius: 4px;
      font-size: 14px;
      line-height: 28px;
      color: #fff;

      &:hover,
      &:focus {
        background-color: #fff;
        color: $blue;
      }
    }
  }
}

// 자주찾는 메뉴 & 고객안내 메뉴
.main-menu {
  $root : ".main-menu";

  position: fixed;
  top: 150px;
  left: 50%;
  z-index: 1;
  width: 380px;
  height: 420px;
  margin-left: 210px;
  border-radius: 20px 20px 50px 20px;
  // box-shadow: 10px 20px 30px rgba(0, 0, 0, .15);
  box-shadow: 0px -1px 20px 3px rgba(148, 148, 148, 0.2);

  &__list {
    height: 80px;
    font-size: 0;
    background-color: #fff;
    border-radius: 20px 20px 0 0;
  }

  &__button {
    position: relative;
    display: inline-block;
    width: 50%;
    height: 80px;
    background-color: #f5f5f5;
    font-size: 15px;
    font-family: youandiR;
    vertical-align: top;
    color: #666;
    box-shadow: 5px -5px 20px 8px rgba(148, 148, 148, 0.3) inset;

    &::before {
      display: block;
      position: absolute;
      bottom: 0;
      z-index: 1;
      width: 20px;
      height: 20px;
      background: url("../img/com/bg_main_menu_corner.png") no-repeat 0 0;
      content: "";
    }

    &:nth-child(1) {
      border-radius: 20px 0 0 0;

      &:before {
        right: -1px;
        transform: rotate(-90deg);
      }
    }

    &:nth-child(2) {
      border-radius: 0 20px 0 0;

      &:before {
        left: -1px;
      }
    }

    &.is-active {
      background-color: #fff;
      color: $blue;
      box-shadow: none;

      &:nth-child(1),
      &:nth-child(2) {
        &:before {
          display: none;
        }
      }

      #{$root}__underline {
        border-bottom: 2px solid $blue;
      }
    }
  }

  &__underline {
    padding: 8px 0;
  }

  &__panel {
    padding: 0 30px;
    height: 230px;
    background-color: #fff;
    font-size: 0;
  }

  &__anchor {
    position: relative;
    display: inline-block;
    width: 50%;
    height: 115px;
    padding: 37px 20px 0;
    font-size: 15px;
    line-height: 20px;
    color: #666;

    &:nth-child(n+3) {
      border-top: 1px solid #e8e8e8;
    }
  }

  &__image {
    position: absolute;
    top: 40px;
    right: 20px;
  }
}

.main-menu-swiper {
  position: relative;
  height: 122px;

  &__wrapper {
    height: 110px !important;
  }

  &__anchor {
    display: block;
    overflow: hidden;
    height: 110px;
    border-radius: 0 0 50px 20px;

    img {
      width: 100%;
    }
  }

  &__controller {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    z-index: 1;
    width: 92px;
    height: 24px;
    border-radius: 12px;
    background-color: #fff;
    text-align: center;
  }

  &__button {
    position: absolute;
    top: 0;
    width: 30px;
    height: 24px;

    &::before {
      display: block;
      position: absolute;
      top: 50%;
      left: 50%;
      border-width: 3px;
      border-style: solid;
      border-color: transparent transparent #cfcfcf #cfcfcf;
      content: "";
    }

    &--prev {
      left: 0;

      &::before {
        transform: translate(-50%, -50%) rotate(45deg);
      }
    }

    &--next {
      right: 0;

      &::before {
      transform: translate(-50%, -50%) rotate(225deg);
      }
    }
  }

  &__page {
    position: static;
    display: inline-block;
    width: auto !important;
    font-family: youandiB;
    font-size: 12px;
    color: #666;
    line-height: 24px;
    vertical-align: top;
  }

  .swiper-pagination-current {
    color: $blue;
  }
}

// 공지사항/이벤트
.main-board {
  width: 590px;

  &__item {
    position: relative;
    padding-left: 112px;

    &::before {
      display: block;
      position: absolute;
      top: 14px;
      left: 0;
      width: 2px;
      height: 3px;
      background-color: #000;
      content: "";
    }
  }

  &__date {
    display: inline-block;
    position: absolute;
    top: 7px;
    left: 22px;
    font-family: youandiR;
    font-size: 14px;
    color: #666;
  }

  &__anchor {
    overflow: hidden;
    display: block;
    width: 100%;
    padding: 6px 0 5px;
    font-family: youandiR;
    font-size: 14px;
    text-overflow: ellipsis;
    white-space: nowrap;
    vertical-align: top;

    &:hover,
    &:focus {
      color: $blue;
    }
  }
}

// 상품
.main-product {
  height: 258px;
  margin-top: 88px;

  &__button {
    font-size: 25px;
    font-family: youandiR;

    &.is-active,
    &:hover,
    &:focus {
      color: $blue;
    }

    &:nth-child(n+2) {
      margin-left: 40px;
    }
  }

  &__text {
    margin-top: 22px;
    font-size: 15px;
    color: #666;
  }

  &__list {
    margin-top: 26px;
    font-size: 0;
  }

  &__item {
    display: inline-block;
    width: 380px;
    height: 160px;
    vertical-align: top;
    border-radius: 12px 12px 40px 12px;
    box-shadow: 10px 20px 30px rgba(0, 0, 0, .1);

    &:nth-child(n+2) {
      margin-left: 20px;
    }
  }

  &__anchor {
    overflow: hidden;
    display: block;
    border-radius: 12px 12px 40px 12px;

    img {
      width: 100%;
    }
  }
}

.main {
  .top-banner {
    position: fixed;
    width: 100%;
    top: 0;
    z-index: -1;
  }

  .l-wrap {
    position: relative;
  }

  .l-wrap,
  .l-head--fixed,
  .main-login,
  .main-head__bg,
  .main-menu,
  .main-head__shape {
    transition: transform .5s ease-out;
  }

  &.slide-down {
    .l-wrap {
      margin-top: 175px;
    }

    .l-head,
    .main-login,
    .main-head__bg,
    .main-menu {
      transform: translate(0, 175px);
    }
  }
}

// 로그인 후 개인
.login-after-person {

  .main-head {
    height: 460px;
  }

  .main-head__bg {
    height: 460px;
  }
}
