.o-dimmed {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 900;
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,.6);
  opacity: 0;
  transition: opacity 0ms ease-out;

  &--transition {
    transition-duration: 200ms;
  }

  &.is-active {
    opacity: 1;
  }
}
