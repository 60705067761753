.step {
  position: relative;
  margin-top: 36px;

  &__list {
    font-size:0;
    text-align: center;
  }

  &__item {
    position: relative;
    display: inline-block;
    width: 36px;
    height: 36px;
    margin-right: 24px;
    background-color: #fff;
    border: 1px solid #d8d8d8;
    border-radius: 25px;
    font-family: youandiR;
    font-size: 14px;
    color: #666;
    text-align: center;
    line-height: 34px;
    vertical-align: top;

    &::after {
      position: absolute;
      top: 50%;
      left: calc(100% + 1px);
      width: 24px;
      height: 1px;
      background-color: #d8d8d8;
      content: "";
    }

    &:last-child {
      margin-right: 0;

      &::after {
        display: none;
      }
    }

    &--complete {
      border-color: #0076c0;
      background: #fff url('../img/com/bg_step.png') no-repeat 50% 50%;

      &::after {
        background-color: #0076c0;
      }
    }

    &--current {
      width: auto;
      padding: 0 30px;
      border-color: transparent;
      background: linear-gradient(90deg, #0076c0 0, #00a19c 100%);
      font-family: youandiB;
      color: #fff;
      font-size: 15px;
      box-shadow: 2px 4px 12px rgba(0, 64, 98, .15);
    }
  }
}
