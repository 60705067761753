.o-label {
  display: inline-block;
  padding: 0 12px;
  border: 1px solid #d8d8d8;
  border-radius: 15px;
  background-color: #f8f8f8;
  font-size: 14px;
  color: #666;
  text-align: center;
  line-height: 28px;

  &--small {
    line-height: 22px;
  }

  &--blue {
    background-color: #fff;
    color: #0076c0;
  }

  &--gradient {
    border: none;
    background: linear-gradient(90deg, #0076c0 0, #00a19c 100%);
    color: #fff;
  }
}
