// 추후 리셋으로 옮김
body {
  font-family: $font-family-base;
  font-size: $font-size-base;
  font-weight: $font-weight-base;
  line-height: $line-height-base;
  color: $black;
  word-wrap: break-word;
  word-break: keep-all;
}

@mixin h1-hb {
  font-family: youandiB;
  font-size: (42px / 2);
}

@mixin h1-hr {
  font-family: youandiR;
  font-size: (42px / 2);
}

@mixin h2-hb {
  font-family: youandiB;
  font-size: (34px / 2);
}

@mixin h3-hb {
  font-family: youandiB;
  font-size: (30px / 2);
}

.h1-hb {
  @include h1-hb;
}

.h1-hr {
  @include h1-hr;
}

.h2-hb {
  @include h2-hb;
}

.h3-hb {
  @include h3-hb;
}

.h1 {
  margin-top: 50px;
  margin-bottom: 30px;
  @include h2-hb;
  line-height: 1;
}
