/* ---------------------------------------------------------------------------------------------------------------------
Heading
--------------------------------------------------------------------------------------------------------------------- */
.o-heading {
  display: block;

  &--1 {
    font-family: youandiR;
    font-size: 30px;
    line-height: 30px;
  }

  &--2 {
    margin: 86px 0 24px;
    font-family: youandiR;
    font-size: 23px;
    line-height: 30px;

    .page-head + & {
      margin-top: 0;
    }
  }

  &--3 {
    margin: 55px 0 24px;
    font-family: youandiB;
    font-size: 19px;
    line-height: 28px;
  }

  &--4 {
    margin: 32px 0 33px;
    font-size: 15px;
    line-height: 28px;
  }

  &--5 {
    margin: 100px 0 31px;
    font-family: youandiR;
    font-size: 25px;
    line-height: 21px;
  }

  &--line {
    padding-bottom: 24px;
    border-bottom: 1px solid #000;
  }

  .o-label {
    margin-right: 10px;
  }
}

/* ---------------------------------------------------------------------------------------------------------------------
Text
--------------------------------------------------------------------------------------------------------------------- */

.webfont-r {
  font-family: youandiR !important;
}

.webfont-b {
  font-family: youandiB;
}

.font-basic {
  font-family: $font-family-base;
}

.fs-13 {
  font-size: 13px !important;
}

.fs-15 {
  font-size: 15px !important;
}

// 해당 부분은 추후 variables.scss 에 정리 필요
.co-gray {
  color: #666 !important;
}

.co-black {
  color: #000 !important;
}

.co-red {
  color: #ff3c50 !important;
}

.o-text {
  $root : ".o-text";

  display: block;
  text-align: left;

  &--1 {
    font-size: 17px;
    line-height: 28px;

    .o-button.link {
      line-height: 28px;
    }

    .o-heading + & {
      margin-top: -6px;
    }
  }

  &--2 {
    font-size: 15px;
    line-height: 24px;
  }

  &--3 {
    font-size: 14px;
    line-height: 22px;
    color: #666;
  }

  &--4 {
    font-size: 21px;
    line-height: 40px;
  }

  &--dot {
    position: relative;
    display: block;
    padding-left: 13px;
    font-size: 17px;
    line-height: 28px;

    &::before {
      display: block;
      position: absolute;
      top: 12px;
      left: 0;
      width: 3px;
      height: 3px;
      border-radius: 50%;
      background-color: #000;
      content: '';
    }

    #{$root}--hyphen:first-child {
      margin-top: 9px;
    }
  }

  &--hyphen {
    position: relative;
    display: block;
    padding-left: 13px;
    font-size: 15px;
    line-height: 24px;
    color: #666;

    &::before {
      display: block;
      position: absolute;
      top: 11px;
      left: 0;
      width: 6px;
      height: 2px;
      background-color: #000;
      content: '';
    }
  }

  &--star {
    position: relative;
    display: block;
    margin-top: 17px;
    padding-left: 10px;
    font-size: 13px;
    line-height: 18px;
    color: #666;

    &::before {
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      content: "*";
    }

    .o-button.link {
      margin-left: 5px;
      font-size: 13px;
      line-height: 18px;
    }

    .c-table + & {
      margin-top: -8px;
    }
  }

  &--underline {
    position: relative;
    overflow: hidden;
    z-index: 1;
    display: inline-block;
    font-family: youandiB;
    font-size: 14px;
    line-height: 1.3;

    &::before {
      content: '';
      position: absolute;
      bottom: 0;
      left: -100%;
      z-index: -1;
      width: 100%;
      height: 8px;
      background-color: #f5f17c;
      animation-fill-mode: forwards;
      animation-name: text-underline;
      animation-duration: .7s;
    }

    @keyframes text-underline {
      from {
        left: -100%;
      }

      to {
        left: 0;
      }
    }

    @for $i from 1 through 10 {
      &:nth-of-type(#{$i}) {
        &::before {
          animation-delay: #{$i*300-100}ms;
        }
      }
    }
  }
}

/* ---------------------------------------------------------------------------------------------------------------------
Text List
--------------------------------------------------------------------------------------------------------------------- */
.c-text-list {
  $root : ".c-text-list";

  font-size: 0;

  &  & {
    margin-top: 10px;
  }

  &__item {
    position: relative;
    font-size: 17px;
    text-align: left;
    line-height: 28px;

    &::before {
      display: block;
      position: absolute;
      content: '';
    }

    & + & {
      margin-top: 10px;
    }
  }

  [class="c-text-list"] &__item >  & {
    padding-left: 13px;
  }

  &--star {
    margin-top: 17px;

    & > #{$root}__item {
      padding-left: 10px;
      font-size: 13px;
      line-height: 18px;
      color: #666;

      &::before {
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        content: "*";
      }
    }

    .c-table + & {
      margin-top: -8px;
    }
  }

  &--dot > &__item {
    padding-left: 13px;

    &::before {
      top: 12px;
      left: 0;
      width: 3px;
      height: 3px;
      border-radius: 50%;
      background-color: #000;
    }
  }

  &--hyphen > &__item {
    padding-left: 13px;
    font-size: 15px;
    line-height: 24px;
    color: #666;

    &::before {
      top: 11px;
      left: 0;
      width: 6px;
      height: 2px;
      background-color: #000;
    }

    .o-button.link {
      font-size: 15px;
    }
  }

  .o-button.link {
    margin-left: 5px;
    font-size: 17px;
  }
}

.o-text + .o-text,
.o-text + .c-text-list,
.c-text-list + .o-text {
  margin-top: 10px;
}

// 예외처리
.terms-wrap,
.text-wrap--product,
.info,
.c-accordion--type2 .c-accordion__inside,
.c-accordion--type3 .c-accordion__inside {
  & > *:first-child {
    margin-top: 0;
  }

  .o-text--1 {
    margin: 18px 0;

    &:first-child {
      margin-top: 0;
    }
  }

  .o-text--dot,
  .c-text-list > .c-text-list__item {
    font-size: 15px;
    line-height: 24px;
    color: #666;

    &::before {
      top: 11px;
    }

    .o-button.link {
      font-size: 15px;
      line-height: 24px;
    }
  }

  .o-text--hyphen,
  .c-text-list--hyphen > .c-text-list__item {
    font-size: 13px;
    line-height: 20px;
    color: #666 !important;

    &::before {
      top: 9px;
    }

    .o-button.link {
      font-size: 13px;
      line-height: 20px;
    }
  }
}

.c-accordion--type3 {
  .c-text-list__item {
    color: #000;
  }
}

dl.c-text-list {

  dt.c-text-list__item ~ dd {
    padding-left: 13px;

    &::before {
      content: none;
    }
  }
}
