.c-table {
  $root: '.c-table';

  margin-top: 25px;
  margin-bottom: 25px;
  border-top: 1px solid #666;

  table {
    position: relative;
    width: calc(100% + 1px);

    &::after {
      display: block;
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 1px;
      background-color: #d2d2d2;
      content: '';
    }

    &:before {
      content: '';
      display: block;
      position: absolute;
      right: 0;
      top: 0;
      width: 1px;
      height: 100%;
      background-color: $white;
    }
  }

  th,
  td {
    padding: 19px 20px;
    background: #fff;
    border-bottom: 1px solid #e8e8e8;
    border-right: 1px solid #e8e8e8;
    line-height: 21px;
    text-align: left;
  }

  th {
    font-weight: normal;
    font-size: 14px;
    color: #666;
  }

  td {
    height: 60px;
    font-size: 17px;
    color: #000;
  }

  &__tfoot,
  tfoot {
    th,
    td {
      height: 74px;
    }

    th {
      background-color: #f8f8f8;
      color: #000;
    }

    td {
      // font-size: 18px;
      color: #0076c0;
    }
  }

  &--row {
    th {
      height: 60px;
      background-color: #f8f8f8;
      font-size: 14px;
    }

    td {
      font-size: 17px;
      font-weight: 700;
    }
  }

  &--col {
    th,
    td {
      padding-left: 10px;
      padding-right: 10px;
    }

    thead th {
      height: 48px;
      padding-top: 12px;
      padding-bottom: 12px;
      background-color: #f8f8f8;
      font-size: 13px;
      line-height: 18px;
      text-align: center;
    }

    td {
      font-size: 15px;
    }
  }

  &--center {
    th,
    td {
      text-align: center;
    }
  }

  &--scroll {
    position: relative;
    overflow: hidden;
    padding-bottom: 20px;

    #{$root}__shadow {
      display: block;
      position: absolute;
      top: 0;
      right: 0;
      z-index: 1;
      width: 20px;
      height: calc(100% - 20px);
      background: linear-gradient(90deg, transparent 0%, #000 100%);
      opacity: 0.2;
      content: '';
    }
  }

  .o-input--radio,
  .o-input--check {
    & + label {
      margin: -2px 0;
      padding-left: 24px;
    }
  }

  .o-input--text {
    margin: -15px 0 -14px;
  }

  .listbox {
    margin: -15px 0 -16px;
    vertical-align: top;
  }

  .o-label,
  .o-button.sm {
    margin: -9px 0 -7px;
  }

  .o-heading + &,
  .c-tooltip + & {
    margin-top: 0 !important;
  }

  .o-button.link,
  .o-text,
  .c-text-list__item {
    font-size: 15px !important;
    line-height: 21px;
  }

  .o-text--dot,
  .c-text-list--dot .c-text-list__item {
    &::before {
      top: 9px;
    }
  }

  & + .c-button-wrap.sm {
    margin-top: -4px;
  }

  // 금액이 조단위까지 갈 경우 줄바꿈 처리 안되게 수정 200817
  .unit-control {
    letter-spacing: -1.4px;
  }
}
