/* ---------------------------------------------------------------------------------------------------------------------
<input type="radio" class="o-input o-input--radio" />
<input type="checkbox" class="o-input o-input--checkbox" />
<input type="text" class="o-input o-input--text"/>
<select class="o-input o-input--select"></select>
--------------------------------------------------------------------------------------------------------------------- */
input,
textarea {
  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus {
    -webkit-box-shadow: 0 0 0px 1000px #fff inset;
    transition: background-color 5000s ease-in-out 0s;
  }
}

.o-input {
  $root : ".o-input";

  border: 0;
  border-radius: 0;
  background-color: transparent;
  vertical-align: middle;
  color: $black;
  -webkit-transition: border 200ms ease-in-out;
  transition: border 200ms ease-in-out;

  &::-webkit-input-placeholder {
    font-weight: normal;
    color: #666;
  }

  &:-ms-input-placeholder {
    font-weight: normal;
    color: #666;
  }

  &::-moz-placeholder {
    font-weight: normal;
    color: #666;
  }

  &::-ms-clear {
    display: none;
  }

  &--radio,
  &--check {
    display: inline-block;
    overflow: hidden;
    position: absolute;
    width: 1px;
    height: 1px;
    opacity: 0;

    label + & + label {
      margin-left: 50px;
    }

    & + label {
      display: inline-block;
      position: relative;
      min-width: 30px;
      min-height: 30px;
      padding-left: 45px;
      font-size: 17px;
      line-height: 30px;
      vertical-align: top;
      color: #222;
      cursor: pointer;

      &::before {
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        width: 30px;
        height: 30px;
        background-color: $white;
        border: 1px solid #d8d8d8;
        border-radius: 50%;
        content: "";
      }

      &::after {
        display: block;
        position: absolute;
        content: "";
      }
    }

    &:focus + label {
      outline: 1px dotted #767676;
    }

    &:checked + label {
      color: #0076c0;

      &::before {
        background-color: #0076c0;
      }
    }

    &.has-error + label {
      &::before {
        border-color: #ff3c50;
      }
    }

    &#{$root}--small {
      & + label {
        min-width: 24px;
        min-height: 24px;
        padding-left: 39px;
        line-height: 24px;

        &::before {
          width: 24px;
          height: 24px;
        }
      }
    }
  }

  &--radio {
    & + label::after {
      top: 8px;
      left: 8px;
      width: 14px;
      height: 14px;
      border-radius: 50%;
      background-color: #e8e8e8;
    }

    &:checked + label::after {
      background-color: $white;
    }

    &:disabled + label {
      &::before {
        background-color: #e8e8e8;
      }

      &::after {
        background-color: $white;
      }
    }

    &:disabled:checked + label {
      color: #c8c8c8;

      &::before {
        background-color: #e8e8e8;
      }

      &::after {
        background-color: #0076c0;
      }
    }

    &#{$root}--small {
      & + label::after {
        top: 6px;
        left: 6px;
        width: 12px;
        height: 12px;
      }
    }
  }

  &--check {
    & + label::after {
      top: 15px;
      left: 7px;
      width: 16px;
      height: 12px;
      margin-top: -6px;
      background: url("../img/com/bg_checkbox.png") no-repeat 0 0;
    }

    &:checked + label {
      color: #0076c0;

      &::after {
        background-position: -16px 0;
      }
    }

    &:disabled + label {
      &::before {
        background-color: #e8e8e8;
      }

      &::after {
        background-position: -16px 0;
      }
    }

    &:disabled:checked + label {
      color: #c8c8c8;

      &::after {
        background-position: -32px 0;
      }
    }

    &#{$root}--small {
      & + label::after {
        top: 13px;
        left: 5px;
        width: 14px;
        height: 11px;
        background-size: 42px auto;
      }

      &:checked + label,
      &:disabled + label {
        &::after {
          background-position: -14px 0;
        }
      }

      &:disabled:checked + label {
        &::after {
          background-position: -28px 0;
        }
      }
    }
  }

  &--text {
    display: inline-block;
    width: 100%;
    height: 48px;
    padding: 0 20px;
    border: 1px solid #d8d8d8;
    border-radius: 6px;
    background-color: #fff;
    font-weight: 700;
    font-size: 15px;
    line-height: 46px;
    text-align: left;
    outline: none;

    &[aria-placeholder] {
      color: #666;
    }

    &:hover,
    &:focus {
      border-color: #0076c0;
    }

    &[readonly],
    &:disabled {
      border-color: #d8d8d8;
      background-color: #f8f8f8;
      color: #666;
      cursor: default;
    }

    &.has-error {
      border-color: #ff3c50;
    }
  }

  &--text {
    &::-webkit-search-cancel-button {
      display: none;
    }

    &[type="number"]::-webkit-inner-spin-button {
      display: none;
    }
  }

  &--textarea {
    display: block;
    width: 100%;
    height: 132px;
    padding: 18px 20px;
    border: 1px solid #d8d8d8;
    border-radius: 6px;
    background-color: #f8f8f8;
    font-size: 15px;
    line-height: 21px;
    color : #666;
    resize: none;
  }
}

// date
.input-date {
  position:relative;
  width: 100%;

  .o-input {
    padding-right: 69px;
  }

  &__button {
    display: block;
    position: absolute;
    top: 0;
    right: 1px;
    width: 68px;
    height: 100%;
    background: url('../img/com/bg_date.png') no-repeat 50% 50%;
  }

}

// select
.listbox {
  $root : ".listbox";

  position: relative;
  height: 48px;

  select {
    opacity: 0;
  }

  &.is-active {
    #{$root}__button {
      border-color: #0076c0;
      border-bottom: none;
      border-radius: 6px 6px 0 0;
      background-color: #fff;

      &::after {
        top: 52%;
        transform: translateY(-50%) rotate(225deg);
        border-color: transparent #0076c0 #0076c0 transparent;
      }
    }
  }

  &.is-disabled {
    background-color: #f8f8f8;

    #{$root}__button {
      cursor: default;
    }
  }

  &__button {
    overflow: hidden;
    width: 100%;
    padding: 0 40px 0 20px;
    border: 1px solid #d8d8d8;
    border-radius: 6px;
    font-weight: 700;
    font-size: 15px;
    line-height: 46px;
    color: #000;
    text-align: left;
    text-overflow: ellipsis;
    white-space: nowrap;

    &::after {
      display: block;
      position: absolute;
      top: 48%;
      right: 20px;
      transform: translateY(-50%) rotate(45deg);
      border-width: 3px;
      border-style: solid;
      border-color: transparent #000 #000 transparent;
      transition-property: transform;
      transition-duration: 200ms;
      content: "";
    }

    &:hover:not(:disabled),
    &:focus {
      border-color: #0076c0;
    }
  }

  &.has-error {
    #{$root}__button {
      border-color: #ff3c50;
    }
  }
}

.listbox-panel {
  display: none;
  position: absolute;
  // top: 100%;
  left: 0;
  z-index: 1;
  width: 100%;
  max-height: 50vh;
  overflow-y: auto;
  padding: 15px 20px;
  border: 1px solid #0076c0;
  border-top: none;
  border-radius: 0 0 6px 6px;
  background-color: #fff;
  box-shadow: 2px 6px 10px rgba(0, 0, 0, .15);

  .listbox.has-error & {
    border-color: #ff3c50;
  }

  &::before {
    display: block;
    position: absolute;
    top: 0;
    left: 20px;
    width: calc(100% - 40px);
    height: 1px;
    background-color: #e8e8e8;
    content: "";
  }

  .is-active > & {
    display: block;
    z-index: 100;
  }

  &__item {
    padding: 9px 0;
    font-weight: 700;
    font-size: 15px;
    line-height: 24px;
    color: #000;

    &:hover:not(:disabled),
    &:focus {
      color: #0076c0;
      text-decoration: underline;
    }
  }
}

// radio toggle
.c-radio-box {
  $root : ".c-radio-box";

  display: inline-block;
  position: relative;
  margin-top: 30px;
  font-size: 0;
  border: 1px solid #d8d8d8;
  border-radius: 6px;

  &.has-error {
    border: 1px solid #ff004e;
  }

  &__item {
    display: inline-block;

    &:nth-child(n+2) {
      position: relative;

      &::before {
        position: absolute;
        top: 50%;
        left: 0;
        width: 1px;
        height: 16px;
        background-color: #d8d8d8;
        transform: translateY(-50%);
        content: "";
      }
    }

    &:first-child label {
      border-radius: 6px 0 0 6px;
    }

    &:last-child label {
      border-radius: 0 6px 6px 0;
    }
  }

  &__input {
    overflow: hidden;
    position: absolute;
    width: 1px;
    height: 1px;
    opacity: 0;
  }

  &__input + label {
    display: inline-block;
    padding: 0 16px;
    background-color: #fff;
    font-family: youandiR;
    font-size: 15px;
    line-height: 46px;
    text-align: center;
    color: #666;
    cursor: pointer;
  }

  &__input:hover + label,
  &__input:focus + label {
    outline: 1px dotted #767676;
    color: #0076c0;
  }

  &__input:checked + label {
    position: relative;
    z-index: 1;
    margin: -1px;
    border: 1px solid #0076c0;
    border-radius: 6px;
    color: #0076c0;
  }

  &__input:disabled + label {
    background-color: #f8f8f8;
    border-color: #d8d8d8;
    color: #c8c8c8;

    &:hover + label,
    &:focus + label {
      color: #c8c8c8;
    }
  }

  &--type2 {
    margin-top: 0;
    margin-right: 10px;
    border: none;
    border-radius: 0;
    vertical-align: middle;

    #{$root}__item {
      &::before {
        display: none;
      }

      &:nth-child(n+2) {
        margin-left: 4px;
      }
    }

    #{$root}__input + label {
      width: 54px;
      padding: 0;
      border: 1px solid #d8d8d8;
      border-radius: 6px;
      font-size: 14px;
      line-height: 38px;
    }

    #{$root}__input:checked + label {
      margin: 0;
    }

    #{$root}__input:focus + label {
      outline: 1px dotted #767676;
    }
  }

  &--type3 {
    overflow: hidden;
    width: 192px;
    margin-top: 0;
    margin-right: 10px;
    vertical-align: middle;

    #{$root}__item {
      width: 50%;

      &::before {
        display: none;
      }

      &:nth-child(even) {
        border-left: 1px solid #d8d8d8;
      }

      &:nth-child(n+3) {
        padding-top: 1px;
        border-top: 1px solid #d8d8d8;
      }
    }

    #{$root}__input + label {
      width: 100%;
      padding: 0;
      font-size: 14px;
      line-height: 30px;
    }

    #{$root}__input:checked + label {
      margin: 0;
      border: none;
    }

    #{$root}__input:focus + label {
      outline: 1px dotted #767676;
    }
  }

  &--full {
    display: flex;

    #{$root}__item {
      flex: 1;
    }

    label {
      display: block;
    }
  }
}

/* ---------------------------------------------------------------------------------------------------------------------
기타
--------------------------------------------------------------------------------------------------------------------- */
// 에러 메시지
.message-error {
  display: block;
  width: 100%;
  margin-top: 7px;
  padding-left: 23px;
  font-size: 13px;
  color: #ff3c50;
  background: url("../img/com/ico_error_red.png") no-repeat 0 50%;
}

// 필수입력
.require {
  color: #ff3c50;

  .input-text > & {
    position: absolute;
    top: 50%;
    left:0;
    margin-top: 3px;
    line-height: 1;
    vertical-align: top;
    transform: translate(10px, -50%);
  }
}

// 단일 사용, 하단 라인 & 간격 필요
/*
.input-line {
  margin: 0 -20px 40px;
  padding: 30px 20px;
  border-bottom: 8px solid #f0f0f0;
}
*/

// input + text 조합
.input-text {
  position: relative;

  &__unit {
    position: absolute;
    top: 50%;
    right: 20px;
    transform: translateY(-50%);

    .o-input:disabled + & {
      color: #666;
    }
  }

  &__button {
    position: absolute;
    top: 0;
    right: 20px;
    height: 100%;
  }

  .o-input--text {
    padding-right: 40px;
  }
}

// radio, check group
.input-group {
  margin: 10px 0 30px -50px;

  & + & {
    margin-top: -20px;
  }

  &__item {
    display: inline-block;
    margin-top: 19px;
    margin-left: 15px;

    .o-input + label {
      margin-top: 0 !important;
      margin-left: 15px !important;
      vertical-align: middle;
    }

    .o-input:first-child + label {
      margin-left: 0 !important;
    }
  }

  .o-input + label {
    margin-top: 20px;
  }

  .o-input:first-child + label {
    margin-left: 50px;
  }

  .c-tooltip + &,
  .o-heading + & {
    margin-top: -20px;
  }

  &[class*="col"] {
    display: flex;
    flex-wrap: wrap;
  }

  &--col2 {
    > div {
      width: calc(50% - 5px);
    }
  }

  &--col3 {
    > div {
      width: calc(33.33% - 6.7px);
    }
  }

  &--col4 {
    > div {
      width: calc(25% - 7.5px);
    }
  }
}

// 동의 체크
.agree-check {
  $root : ".agree-check";

  &__inside {
    margin-top: 30px;

    #{$root}__item {
      background-color: #fff;
    }
  }

  &__item {
    position: relative;
    border: 1px solid #d8d8d8;
    border-radius: 6px;

    &:nth-child(n+2) {
      margin-top: 10px;
    }

    &--all {
      margin-top: -51px;
      padding-right: 0 !important;
      border: none;
      text-align: right;

      .o-input--check + label {
        width: auto !important;
        padding: 0 0 0 40px !important;

        &::before {
          left: 0 !important;
        }

        &::after {
          left: 5px !important;
        }
      }
    }
  }

  &__button {
    display: block;
    position: absolute;
    top: 0;
    right: 1px;
    width: 68px;
    height: 100%;

    &::before,
    &::after {
      position: absolute;
      right: 30px;
      width: 8px;
      height: 2px;
      background-color: #666;
      content:"";
    }

    &::before {
      top: 50%;
      transform: translateY(-100%) rotate(45deg);
    }

    &::after {
      top: 50%;
      transform: translateY(100%) rotate(-45deg);
    }
  }

  &--link {
    #{$root}__item {
      padding-right: 70px;
    }

    .o-input--check + label {
      overflow: hidden;
      width: 100%;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }

  &--line {
    margin-top: 30px;
    padding-top: 40px;
    border-top: 1px solid #e8e8e8;
  }

  .o-input--check + label {
    width: 100%;
    padding: 19px 30px 19px 74px;
    font-size: 18px;
    font-weight: 700;
    color: #000;

    &::before {
      top: 50%;
      left: 30px;
      transform: translateY(-50%);
    }

    &::after {
      top: 50%;
      left: 35px;
      margin-top: -5px;
    }
  }

  .o-input--check:checked + label {
    color: #000;
  }

  .c-form + & {
    margin-top: 15px;
  }
}

.checkbox-area {
  &__inside {
    position: relative;
    padding: 34px 40px 40px;
    border-radius: 6px;

    &::before {
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: -1;
      width: 100%;
      height: 100%;
      background-color: #f8f8f8;
      content: "";
    }

    .o-text {
      margin-bottom: 24px;
    }

    .input-group {
      margin: -20px 0 24px -50px;

      &:last-child {
        margin-bottom: 0;
      }

      >div:not(:first-child) .o-input + label {
        margin-top: 20px;
      }
    }

    .o-input--radio + label,
    .o-input--check + label {
      font-size: 15px;
    }

    label + .o-input--radio.o-input--small + label,
    label + .o-input--check.o-input--small + label {
      margin-left: 45px;
    }

    +.agree-check {
      margin-top: 10px;
    }
  }

  > .agree-check:not(:first-child) {
    > .agree-check__inside {
      margin-top: 0;
    }
  }
}

/* ---------------------------------------------------------------------------------------------------------------------
Form
--------------------------------------------------------------------------------------------------------------------- */
.c-form {
  $root : ".c-form";
  display: flex;
  align-items: flex-start;
  margin-top: 30px;
  text-align: left;

  & + & {
    margin-top: 15px;
  }

  &__field {
    flex: 1;
    position: relative;
    padding: 0 20px;
    background-color: $white;
    border: 1px solid #d8d8d8;
    border-radius: 6px;


    &:hover:not(.is-disabled) {
      border-color: #0076c0;

      #{$root}__label {
        color: #0076c0;
      }
    }

    &--col2 {
      flex: none;
      width: calc(50% - 5px);
    }

    &--col3 {
      flex: none;
      width: calc(33.33% - 6.7px);
    }

    &--col4 {
      flex: none;
      width: calc(25% - 7.5px);
    }

    &--col5 {
      flex: none;
      width: calc(20% - 8px);
    }

    & + * {
      margin-left: 10px;
    }
  }

  &__label {
    position: absolute;
    top: 50%;
    left: 20px;
    z-index: 3;
    font-size: 18px;
    font-family: youandiR;
    transform: translateY(-50%);
    transition: all 100ms;
  }

  &__row {
    display: flex;
    font-size: 0;

    #{$root}__label + & {
      #{$root}__text,
      .o-input {
        height: 62px;
        padding-top: 25px;
        line-height: 37px;
      }
    }

    & + & {
      border-top: 1px solid #eee;
    }

    & > *:nth-child(n+2) {
      display: none;
      margin-left: 20px;
    }
  }

  &__auto {
    flex: none !important;
  }

  &__text {
    flex: none !important;
    font-family: youandiR;
    font-size: 18px;
    line-height: 37px;
    color: #000;
  }

  &__button {
    position: absolute;
    top: 25px;
    right: 0;
    width: 120px;
    height: 64px;
    margin-left: 0;
    border-radius: 8px;
    background-color: #3f3f3f;
    font-family: youandiR;
    font-size: 19px;
    color: #fff;
  }

  &--filter {
    position: relative;
    margin: 60px 0 70px;
    padding: 25px 180px 25px 0;

    &::before {
      display: block;
      position: absolute;
      top: 0;
      left: 50%;
      z-index: 0;
      transform: translateX(-50%);
      min-width: 1280px;
      width: 200vw;
      height: 100%;
      background-color: #f8f8f8;
      content: "";
    }

    #{$root}__field:last-of-type {
      margin-right: 0;
    }
  }

  &--short {
    margin: 24px 0;
    padding-left: 40px;
    padding-right:220px;
    background-color: #f8f8f8;

    &::before {
      display: none;
    }

    #{$root}__button {
      right: 40px;
    }
  }

  .o-input--text,
  .o-input--textarea {
    padding: 0;
    border: none;
    background-color: transparent;
    font-weight: normal;
    font-family: youandiR;
    font-size: 18px;
    color: transparent;


    &::-webkit-input-placeholder {
      color: transparent;
    }

    &:-ms-input-placeholder {
      color: transparent;
    }

    &::-moz-placeholder {
      color: transparent;
    }
  }

  .o-input--text {
    background-color: transparent !important;
  }

  .c-radio-box--type2,
  .o-button.md {
    margin-top: 12px;
  }

  .o-button.lg {
    width: 287.5px;
    margin-top: 5px;
  }

  .input-date {
    &__button {
      right: -20px;
    }
  }

  .listbox {
    flex: 1;
    width: 100%;
    height: 64px;
    margin: -1px -21px;

    &__button {
      color: transparent;
      padding-top: 25px;
      font-size: 18px;
      font-family: youandiR;
      font-weight: normal;
      line-height: 37px;
    }

    &__item {
      font-family: youandiR;
    }
  }

  // 상태
  &__field {
    &.has-focus,
    &.has-value,
    &.has-value.is-disabled {
      #{$root}__row {
        & > * {
          display: block;
          flex: 1;
        }
      }

      #{$root}__label {
        top: 0;
        font-size: 12px;
        transform: translateY(8px);
      }

      #{$root}__auto {
        width: auto;
      }

      .o-input {
        color: #000;

        &::-webkit-input-placeholder {
          color: #666;
        }

        &:-ms-input-placeholder {
          color: #666 !important;
        }

        &::-moz-placeholder {
          color: #666;
        }
      }

      .listbox {
        &__button {
          color: #000;
        }
      }

      /*.address__button {
        background-position: right 0 top 46px;
      }*/

      .identifier__first {
        width: 70px;
      }

      .identifier__last {
        width: 80px;
        margin-left: 20px;
      }

      .identifier__only {
        width: 20px;
        margin-left: 20px;
      }
    }

    &.has-focus,
    &.has-value {
      .o-input--textarea {
        height: 132px;
        margin-top: 30px;
        padding-top: 0;
        font-family: youandiR;
        font-size: 17px;
        line-height: 24px;
      }
    }

    &.has-focus {
      border-color: #0076c0;

      #{$root}__label {
        color: #0076c0;
      }

      .listbox {
        &__button {
          border-color: #0076c0;
        }
      }
    }

    &.has-error {
      margin-bottom: 27px;
      border-color: #ff3c50;

      #{$root}__label {
        color: #ff3c50;
      }

      .message-error {
        position: absolute;
        bottom: -27px;
        left: 2px;
      }

      .listbox__button,
      .listbox-panel {
        border-color: #ff3c50;
      }
    }

    &.is-disabled {
      background-color: #f8f8f8;

      #{$root}__label {
        z-index: 1;
      }
    }
  }
}

.form-result {
  height: 62px;
  margin-top: 13px;
  margin-right: 10px;
  border-radius: 6px;
  background-color: #262a45;
  color: $white;

  &--col2 {
    width: calc(50% - 10px);
  }
}

// 검색
.search {
  $root : ".search";

  position: relative;
  width: 100%;
  height: 62px;

  &__button {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    background: transparent url("../img/com/bg_search.png") no-repeat right 0 top 50%;
  }

  &__text {
    overflow: hidden;
    padding: 25px 35px 0 0;
    font-size: 18px;
    font-family: youandiR;
    line-height: 37px;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  &--address {
    #{$root}__text {
      width: 543px;
    }

    .c-form__field--col3 & {
      #{$root}__text {
        width: 344px;
      }
    }
  }
}

// 첨부파일
.c-form__field.attach {
  height: 48px;
  padding-right: 94px;
  overflow: hidden;
  background-color: #f8f8f8;

  .c-form__label {
    top: 13px;
    transform: translateY(0);
    transition: none;
    font-family: $font-family-base;
    font-size: 15px;
  }

  &.is-disabled {
    .c-form__label,
    .attachment__add {
      color: #c8c8c8;
    }

    .attachment__label {
      display: none;
    }

    .attachment__add {
      background-color: #f8f8f8;
    }
  }
}

.attachment {
  $root : ".attachment";
  position: relative;
  height: 100%;

  &__name {
    overflow: hidden;
    width: calc(100% - 30px);
    font-size: 17px;
    font-weight: 600;
    color: $black;
    text-overflow: ellipsis;
    white-space: nowrap;
    line-height: 50px;
  }

  &__input {
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    height: 48px !important;
    padding-top: 0 !important;
    opacity: 0;

    &:focus + label {
      outline: 1px dotted #767676;
    }
  }

  &__label {
    position: absolute;
    top: 50%;
    right: 10px;
    padding: 0 8px;
    height: 30px;
    transform: translateY(-50%);
    font-weight: 600;
    font-size: 14px;
    line-height: 28px;
    border-radius: 5px;
    background-color: $white;
    border: 1px solid #d8d8d8;
    cursor: pointer;
    color: $dark;
  }

  &__delete {
    position: absolute;
    bottom: 9px;
    right: -5px;
    width: 30px;
    height: 30px;
    background: transparent url("../img/com/bg_attachment.png") no-repeat 50% 50%;
    background-size: 17px auto;
    content: "";
  }

  &__button {
    position: relative;
    width: 490px;
    height: 48px;
  }

  &__add {
    display: block;
    position: absolute;
    right: 0;
    top: 0;
    width: 94px;
    height: 100%;
    border-left: 1px solid #d8d8d8;
    background-color: $white;
    font-family: youandiR;
    font-size: 17px;

    #{$root}__label {
      display: block;
      width: 100%;
      font-size: 14px;
      font-weight: 600;
      text-decoration: underline;
      line-height: 50px;
      text-align: center;
      color: #0076c0;
    }
  }

  &.has-file {
    #{$root}__button {
      display: none;
    }

    #{$root}__item:first-child {
      padding-top: 25px;
      border-top: none;
    }
  }
}

// 등록, 신규 계좌
.slct-account {
  margin-top: -50px;

  .c-tab__list {
    text-align: right !important;
  }

  .c-tab__panel {
    margin-top: 30px !important;
    border-top: 1px solid #666;
  }

  .c-tab__button:last-child {
    margin-right: 0;
  }
}

// 신원확인
.identifier-form {
  position: relative;
  padding: 25px 40px 55px 346px;
  border: 10px solid #f8f8f8;

  &__image {
    position: absolute;
    top: 50%;
    left: 46px;
    transform: translateY(-50%);
  }

  .o-button {
    margin-top: 15px;
  }
}
