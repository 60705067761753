.swiper-custom-controller {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 28px;
  font-size: 0;
  text-align: center;
}

.swiper-custom-button {
  display: inline-block;
  position: relative;
  width: 28px;
  height: 28px;
  border: 1px solid #d8d8d8;
  border-radius: 4px;

  &::after {
    position: absolute;
    top: 8px;
    display: block;
    padding: 4px;
    width: 0;
    border: solid #666;
    border-width: 0 2px 2px 0;
    font-size: 0;
    content: "";
  }

  &:hover,
  &:focus {
    border-color: #0076c0;

    &::after {
      border-color: #0076c0;
    }
  }

  &.swiper-button-disabled {
    opacity: 0.5;

    border-color: #d8d8d8;

    &::after {
      border-color: #666;
    }
  }

  &--prev {
    &::after {
      left: 50%;
      transform: translateX(-25%) rotate(135deg);
    }
  }

  &--next {
    &::after {
      right: 50%;
      transform: translateX(25%) rotate(-45deg);
    }
  }

  &--type2 {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 48px;
    height: 48px;
    border-radius: 50%;
    background: rgba(0,75,117,.5);
    z-index: 10;
    cursor: pointer;
  
    &:hover,
    &:focus {
      background-color: #00dad2 !important;
      box-shadow: 0px 15px 12px 0px rgba(0,0,0,.1);
      transition: background-color .5s;
    }
  
    &::after {
      content: "";
      position: absolute;
      top: 50%;
      width: 33px;
      height: 7px;
      background: url(../img/com/ico_swiper.png) no-repeat;
      font-size: 0;
    }
  
    &--next {
      right: -90px;
  
      &::after {
        left: 8px;
        transform: translate(-50%,-50%) rotate(180deg);
      }
  
      &:hover,
      &:focus {
        &::after {
          left: 50%;
          width: 23px;
          background-image: url(../img/com/ico_swiper_active.png);
          transform: translate(-50%,-50%) rotate(0);
          transition: left .5s;
        }
      }
    }
  
    &--prev {
      left: -90px;
  
      &::after {
        right: -25px;
        transform: translate(-50%,-50%);
      }
  
      &:hover,
      &:focus {
        &::after {
          right: 0;
          width: 23px;
          background-image: url(../img/com/ico_swiper_active.png);
          transform: translate(-50%,-50%) rotate(180deg);
          transition: right .5s;
        }
      }
    }
  
    &.swiper-button-disabled {
      display: none;
    }
  }

  &--type3 {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 48px;
    height: 48px;
    border-radius: 50%;
    background-color: rgba(255,255,255,.5);
    z-index: 10;
    cursor: pointer;

    &:hover,
    &:focus {
      background-color: $blue !important;
      box-shadow: 0px 15px 12px 0px rgba(0,0,0,.1);
      transition: background-color .5s;
    }

    &::after {
      content: "";
      position: absolute;
      top: 50%;
      width: 33px;
      height: 7px;
      background: url(../img/com/ico_swiper_2.png) no-repeat;
      font-size: 0;
    }

    &--next {
      right: 40px;

      &::after {
        left: 8px;
        transform: translate(-50%,-50%) rotate(180deg);
      }

      &:hover,
      &:focus {
        &::after {
          left: 50%;
          width: 23px;
          background-image: url(../img/com/ico_swiper_active_2.png);
          transform: translate(-50%,-50%) rotate(0);
          transition: left .5s;
        }
      }
    }

    &--prev {
      left: 40px;

      &::after {
        right: -25px;
        transform: translate(-50%,-50%);
      }

      &:hover,
      &:focus {
        &::after {
          right: 0;
          width: 23px;
          background-image: url(../img/com/ico_swiper_active_2.png);
          transform: translate(-50%,-50%) rotate(180deg);
          transition: right .5s;
        }
      }
    }

    &.swiper-button-disabled {
      display: none;
    }
  }
}

.swiper-custom-pagination {
  display: inline-block;
  position: static;
  width: auto;
  padding: 8px 60px;
  vertical-align: top;

  .swiper-pagination-bullet {
    width: 12px;
    height: 12px;
    margin: 0 5px;
    border: 1px solid #d8d8d8;
  }
  .swiper-pagination-bullet-active {
    background-color: #0076c0;
  }

  &--type2 {
    .swiper-pagination-bullet {
      width: 6px;
      height: 6px;
    }
  
    .swiper-pagination-bullet-active {
      background-color: #00a19c !important;
      border-color: #00a19c !important;
    }
  }

  &--type3 {
    .swiper-pagination-bullet {
      width: 6px;
      height: 6px;
      margin: 0 7px !important;
      background-color: #c0c0c0;
      border-color: #c0c0c0;
      opacity: 1;
    }

    .swiper-pagination-bullet-active {
      background-color: $blue !important;
      border-color: $blue !important;
    }
  }
}

.swiper-custom-page {
  position: static;
  font-family: youandiR;
  font-size: 15px;
  text-align: center;
  line-height: 28px;
  color: #d8d8d8;

  .swiper-pagination-current {
    margin-right: 5px;
    font-family: youandiB;
    color: #0076c0;
  }

  .swiper-pagination-total {
    margin-left: 5px;
    color: #000;
  }

}
