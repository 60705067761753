.o-button {
  display: inline-block;
  border: 0;
  border-radius: 0;
  background-color: transparent;
  text-align: center;
  vertical-align: middle;
  -webkit-appearance: none;
  appearance: none;
  -webkit-transition: background 200ms;
  transition: background 200ms;
  font-weight: 400;

  &:disabled,
  &.is-disabled {
    border: 1px solid #d8d8d8 !important;
    background: #f8f8f8 !important;
    color: #c8c8c8 !important;;
    cursor: default;

    .o-icon {
      opacity: .2;
    }
  }

  &.sm {
    min-width: 34px;
    height: 34px;
    padding: 0 10px;
    border-radius: 5px;
    font-size: 15px;
    font-family: youandiR;
    color: #333;
    line-height: 32px;
  }

  &.md {
    height: 44px;
    padding: 0 20px;
    border-radius: 6px;
    font-size: 17px;
    font-family: youandiR;
    line-height: 40px;

    & + & {
      margin-left: 4px;
    }
  }

  &.lg {
    min-width: 200px;
    height: 54px;
    padding: 0 40px;
    border-radius: 6px;
    font-size: 19px;
    font-family: youandiR;
    line-height: 52px;
  }

  &.xl {
    width: 300px;
    height: 56px;
    padding: 0 40px;
    border-radius: 8px;
    font-size: 19px;
    font-family: youandiB;
    line-height: 54px;
  }

  &.full {
    display: block;
    width: 100%;
  }

  &.white {
    border: 1px solid #b8b8b8;
    background-color: #fff;
    color: #000;

    &.sm,
    &.md {
      &:focus,
      &:hover {
        border-color: #0076c0;
        color: #0076c0;
      }
    }

    // &.lg {
    //   &:focus,
    //   &:hover {
    //     border-color: #666;
    //     background-color: #666;
    //     color: #fff;
    //   }
    // }

    &.lg {
      border: 1px solid $blue;
      background-color: #fff;
      color: $blue;

      &:focus,
      &:hover {
        border-color: $blue;
        background-color: $blue;
        color: #fff;
      }
    }

    &.xl {
      &:focus,
      &:hover {
        box-shadow: 4px 7px 20px rgba(51, 51, 51, .15);
      }
    }
  }

  &.gray {
    border: 1px solid #666;
    background-color: #666;
    color: #fff;

    &.xl {
      &:focus,
      &:hover {
        box-shadow: 4px 7px 20px rgba(51, 51, 51, .15);
      }
    }
  }

  &.blue {
    border: 1px solid #0076c0;
    background-color: #fff;
    color: #0076c0;

    &.lg {
      &:focus,
      &:hover {
        border-color: #0076c0;
        background-color: #0076c0;
        color: #fff;
      }
    }
  }

  &.gradient {
    border: none;
    background: linear-gradient(90deg, #0076c0 0, #00a19c 100%);
    color: #fff;

    &.xl {
      &:focus,
      &:hover {
        box-shadow: 4px 7px 20px rgba(0, 121, 204, .15);
      }
    }
  }

  // 더보기
  &.more {
    display: block;
    width: 100%;
    margin: 40px auto 0;
    padding: 0 20px;
    border: 1px solid #b8b8b8;
    border-radius: 6px;
    background-color: #f8f8f8;
    font-family: youandiR;
    font-size: 15px;
    line-height: 56px;

    &:hover,
    &:focus {
      border-color: #0076c0;
      background-color: #fff;
      color: #0076c0;
    }
  }

  // link
  &.link {
    border-bottom: 1px solid #000;
    font-size: 15px;
    vertical-align: top;
    line-height: inherit;

    &:hover,
    &:focus {
      border-bottom: 1px solid #0076c0;
      color: #0076c0;
    }

    &.blue {
      border: none;
      border-bottom: 1px solid #0076c0;
      background-color: transparent !important;
      color: #0076c0;
    }

    &:disabled,
    &.is-disabled {
      border: none !important;
    }
  }

  // 말줄임
  &.ellipsis {
    overflow: hidden;
    display: block;
    width: 100%;
    text-overflow: ellipsis;
    white-space: nowrap;
    text-align: left;

    & > span {
      display: inline;
    }

    &:hover,
    &:focus {
      color: #0076c0;
    }
  }

  // ie effect 효과 제거
  > span {
    position: relative;
    display: inline-block;
    line-height: 1;
  }
}

.c-button-wrap {
  font-size: 0;
  text-align: center;

  .o-button {
    &:nth-child(n+2) {
      margin-left: 4px;
    }

    &.xl:not(:only-child) {
      width: 220px;
    }

    &.xl + .xl {
      margin-left: 10px;
    }
  }

  &.sm {
   margin-top: 20px;
  }

  &.md {
    margin-top: 50px;
  }

  &.lg {
    margin-top: 100px;
  }

  &--line {
    margin-top: 30px;
    border-top: 1px solid #e8e8e8;

    .o-button.lg {
      margin-top: 40px;
    }

    .o-button.md {
      margin-top: 20px;
    }
  }

  &--flex {
    display: flex;
    margin: 0 -6px;

    .o-button {
      flex: 1;
      margin: 0 6px;
    }
  }
}

// 하단 고정 메뉴 (푸터상단)
.c-bottom-menu {
 margin-top: 60px;
 text-align: center;

  li {
    display: inline-block;
    position: relative;
    padding: 0 15px 0 20px;

    &+li {
      margin-left: 20px;

     &:after {
        content: '';
        position: absolute;
        display: block;
        left: 0;
        top: 5px;
        width: 1px;
        height: 12px;
        background-color: #d8d8d8;
      }
    }
  }

  &__anchor {
    display: block;

    &:before {
      content: '';
      display: block;
      position: absolute;
      right: 0;
      top: 8px;
      width: 0;
      height: 0;
      padding: 2.5px;
      border-top: 2px solid #666;
      border-right: 2px solid #666;
      transform: rotate(45deg);
    }

    &:hover {
      color: $blue;
      text-decoration: underline;

      &:before {
        border-color: $blue;
      }
    }
  }
}

