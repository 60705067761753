// skip navigation
.skip {
  position:fixed;
  top: 0;
  left: 0;
  z-index: 999;
  width: 100%;

  &__anchor {
    display:block;
    width:0;
    height:0;
    font-size:1px;
    opacity:0;

    &:hover,
    &:focus,
    &:active {
      display: block;
      width: 100%;
      height: 50px;
      background-color: #333;
      font-size: 15px;
      text-align: center;
      color: #fff;
      line-height: 50px;
      opacity: 1;
    }
  }
}

// layout
.l-wrap {
  overflow: hidden;
  min-width: 1280px;
}

// header
.l-head {
  $root : ".l-head";

  position: fixed;
  z-index: 1200;
  width: 100%;
  min-width: 1280px;
  height: 89px;
  border-bottom: 1px solid #eee;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 1;
    background-color: #fff;
    transition: opacity .3s ease-in;
  }


  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 50%;
    width: 100%;
    height: 3px;
    background-color: #0083bb;
    transform: translateX(-50%);
  }

  &__inside {
    position: relative;
    max-width: 1920px;
    min-width: 1280px;
    margin: 0 auto;
  }

  &__util {
    position: absolute;
    top: 0;
    right: 20px;
    font-size: 0;
    text-align: right;
  }

  &__search {
    display: inline-block;
    width: 63px;
    height: 88px;
    background: url("../img/com/ico_search.png") no-repeat 50% 50%;
  }

  &__side {
    display: inline-block;
    width: 76px;
    height: 88px;
    background: url("../img/com/ico_side.png") no-repeat 50% 50%;
  }

  &--fixed {
    position: fixed;
    top: 0;
  }

  &--main {
    border-bottom-color: rgba(255, 255, 255, .1);
    background-color: rgba(0, 0, 0, .05);

    &::before {
      opacity: 0;
    }

    .logo__anchor {
      background-image: url("../img/com/img_logo_main.png");
    }

    .gnb__anchor--1depth {
      color: #fff;

      &:hover,
      &:focus {
        color: #fff;

        &::before {
          display: none;
        }
      }
    }

    .head-login__button {
      background-image: url("../img/com/ico_login_main.png");
    }

    .head-login__state--on {
      background-color: #fff;
      color: $blue;
    }

    #{$root}__search {
      background-image: url("../img/com/ico_search_main.png");
    }

    #{$root}__side {
      background-image: url("../img/com/ico_side_main.png");
    }
  }
}

body.has-modal {
  .l-head {
    z-index: 200;
  }
}

// logo
.logo {
  display: inline-block;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 50;

  &__anchor {
    display: block;
    width: 238px;
    height: 88px;
    background: url("../img/com/img_logo.png") no-repeat 40px 50%;
    background-size: 158px auto;
  }
}

@media (min-width: 1400px) {
  .logo__anchor {
    width: 263px;
    background-size: 183px auto;
  }
}

// login
.head-login {
  display: inline-block;
  position: relative;
  vertical-align: top;

  &__button {
    display: inline-block;
    width: 59px;
    height: 88px;
    background: url("../img/com/ico_login.png") no-repeat 50% 50%;

    &:hover,
    &:focus {
      background-image: url("../img/com/ico_login_active.png");
    }
  }

  &__alarm {
    display: block;
    position: absolute;
    top: 50%;
    right: 50%;
    width: 4px;
    height: 4px;
    margin: -15px -12px 0 0;
    border-radius: 50%;
    background-color: #ea3e33;
  }

  &__state {
    display: block;
    position: absolute;
    bottom: 50%;
    right: 0;
    width: 30px;
    height: 16px;
    margin: 0 -1px -21px 0;
    border-radius: 8px;
    font-family: youandiB;
    font-size: 10px;
    line-height: 16px;
    text-align: center;

    &--off {
      background-color: #666;
      color: #fff;
    }

    &--on {
      background-color: #3275ba;
      color: #fff;
    }
  }

  &__panel {
    display: none;
    position: absolute;
    top: 78px;
    left: 50%;
    transform: translateX(-50%);
    min-width: 100px;
    white-space: nowrap;
    border: 1px solid #3275ba;
    border-radius: 6px;
    background-color: #fff;

    &.is-active {
      display: block;
      z-index: 5;
    }

    &::before {
      position: absolute;
      top: -10px;
      left: 50%;
      transform: translateX(-50%);
      width: 11px;
      height: 10px;
      background: url("../img/com/bg_head_login_arrow.png") no-repeat 0 0;
      content: "";
    }
  }

  &__user {
    display: block;
    padding: 0 10px;
    font-family: youandiR;
    font-size: 14px;
    color: #3275ba;
    line-height: 40px;
    text-align: center;
  }

  &__anchor {
    display: block;
    width: 100%;
    border-top: 1px solid #eee;
    font-family: youandiR;
    font-size: 14px;
    color: #333;
    line-height: 40px;
    text-align: center;
  }
}

// gnb
.gnb {
  $root : ".gnb";

  position: relative;
  font-size: 0;
  text-align: center;

  &__panel {
    display: none;
    position: absolute;
    top: 88px;
    left: 0;
    z-index: 1;
    width: 100%;
    border-top: 1px solid #eee;
    background-color: #fff;

    &::before {
      display: block;
      position: absolute;
      top: 0;
      left: 50%;
      z-index: -1;
      transform: translateX(-50%);
      min-width: 1280px;
      width: 100vw;
      height: 100%;
      background-color: #fff;
      content: "";
    }

    &::after {
      display: block;
      position: absolute;
      bottom: 0;
      left: 50%;
      z-index: -2;
      transform: translateX(-50%);
      min-width: 1280px;
      width: 100vw;
      height: 50%;
      box-shadow: 0 0 30px rgba(0, 0, 0, .18);
      content: "";
    }
  }

  &__inside {
    overflow-y: auto;
    max-height: 100%;
    padding: 46px 0 56px;
    text-align: center;
    width: 1180px;
    margin: 0 auto;
    @include clearfix;

    // 고객안내
    &#{$root}__inside--customer {
      height: 432px;

      .gnb__list {
        position: relative;
        padding-right: 450px;
      }

      .gnb__item--col2 {
        position: absolute;
        top: 0;
        right: 50px;
        width: 405px;
        padding: 0;
      }
    }
  }

  &__list {
    display: flex;
    flex-wrap: wrap;
    margin: 0 auto -24px;
    padding-left: 5px;
    width: 1180px;
    text-align: left;

    &--center {
      text-align: center;
    }

    > li {
      width: 180px;
      margin: 0 0 24px 45px;
    }
  }

  &__item {
    display: inline-block;
    vertical-align: top;
  }

  &__item--2depth {
    display: inline-block;
    text-align: left;
    vertical-align: top;
    overflow: hidden;

    > ul {
      padding-top: 14px;
      margin-top: 11px;
      border-top: 1px solid #e8e8e8;
    }

    &[class*="col"] {
      #{$root}__anchor--2depth {
        font-family: youandiB;
        font-size: 13px;
      }

      > ul {
        margin-left: -45px;
        @include clearfix;

        > #{$root}__item--category {
          float: left;
          width: 180px;
          margin-left: 45px;
          margin-bottom: 9px;
        }
      }
    }

    &#{$root}__item--col3 {
      width: 630px;
    }

    &#{$root}__item--col2 {
      width: 450px;
    }
  }

  &__item--2depth,
  &__item--category {
    >ul > li {
      margin-top: 4px;
    }
  }

  &__item--category {
    > #{$root}__anchor--3depth {
      margin-bottom: 9px;
      color: #000;
    }
  }

  &__anchor {
    display: inline-block;
    font-family: youandiR;
  }

  &__anchor--1depth {
    position: relative;
    padding: 0 25px;
    font-size: 17px;
    line-height: 88px;

    &::before {
      display: none;
      position: absolute;
      z-index: 2;
      bottom: -1px;
      left: 50%;
      transform: translateX(-50%);
      width: calc(100% - 40px);
      height: 2px;
      background-color: $blue;
      content: "";
    }

    &:hover,
    &:focus,
    &.is-active {
      color: $blue;

      &::before {
        display: block;
      }
    }

    &.is-active + #{$root}__panel {
      display: block;
    }

    &.is-current {
      color: $blue;
    }
  }

  &__anchor--2depth {
    font-family: youandiB;
    font-size: 13px;
    white-space: nowrap;
  }

  a#{$root}__anchor--2depth {
    &:hover,
    &:focus {
      margin-bottom: -1px;
      color: $blue;
      border-bottom: 1px solid $blue;
    }
  }

  &__anchor--3depth {
    font-size: 13px;
    color: #666;
  }

  a#{$root}__anchor--3depth {
    &:hover,
    &:focus {
      color: $blue;
      text-decoration: underline;
    }
  }

  // 대출상품
  &__list--product {
    flex-direction: column;
    padding-left: 446px;

    #{$root}__item {
      margin-left: 0;
    }
  }

  // 혜택서비스
  &__list--benefit {
    flex-direction: column;
    padding-left: 663px;

    #{$root}__item {
      margin-left: 0;
    }
  }
}

// @media (min-width: 1400px) {
//   .gnb__anchor--1depth {
//     padding: 0 25px;
//     font-size: 17px;
//   }

//   // 대출상품
//   .gnb__list--product {
//     padding-left: 381px;
//   }

//   // 혜택서비스
//   .gnb__list--benefit {
//     padding-left: 600px;
//   }
// }

// side
.l-side {
  $root : ".l-side";

  position: fixed;
  top: 0;
  right: 0;
  width: 100%;
  z-index: 901;

  &__inside {
    position: relative;
    max-width: 1920px;
    min-width: 1280px;
    height: 100%;
    margin: 0 auto;
  }

  &__title {
    position: relative;
    border-bottom: 1px solid #e8e8e8;
    font-size: 21px;
    font-family: youandiR;
    line-height: 88px;
    text-align: center;
  }

  &__close {
    position: absolute;
    top: 0;
    right: 0;
    display: inline-block;
    width: 112px;
    height: 88px;

    &::before,
    &::after {
      display: block;
      position: absolute;
      top: 50%;
      left: 50%;
      width: 3px;
      height: 28px;
      border-radius: 2px;
      background-color: #000;
      content: "";
    }

    &::before {
      transform: translate(-50%, -50%) rotate(45deg);
    }

    &::after {
      transform: translate(-50%, -50%) rotate(135deg);
    }
  }

  &--alarm {
    display: none;
    width: 520px;
    height: 100%;
    transform: translate3d(100%, 0, 0);
    transition-property: transform;
    transition-duration: 200ms;
    background-color: #fff;

    &.is-active {
      transform: translate3d(0, 0, 0);
    }

    #{$root}__panel {
      position: relative;
      overflow-y: auto;
      height: calc(100% - 89px);
    }
  }

  &--search {
    display: none;
    opacity: 0;
    transition: opacity .3s ease-in;

    &.is-active {
      opacity: 1;
    }

    #{$root}__title {
      &::before {
        display: block;
        position: absolute;
        top: 0;
        left: 50%;
        z-index: -1;
        transform: translateX(-50%);
        min-width: 1280px;
        width: 100vw;
        height: 100%;
        background-color: #fff;
        content: "";
      }
    }

    #{$root}__panel {
      position: relative;
      overflow-y: auto;
      max-height: calc(100vh - 220px);
    }

    #{$root}__content {
      max-width: 1180px;
      margin: 0 auto;
    }
  }

  &--all {
    display: none;
    height: 100%;
    opacity: 0;
    transition: opacity .3s ease-in;

    &::before {
      display: block;
      position: absolute;
      top: 0;
      left: 50%;
      z-index: -1;
      transform: translateX(-50%);
      min-width: 1280px;
      width: 100vw;
      height: 100%;
      background-color: #fff;
      content: "";
    }

    &.is-active {
      opacity: 1;
    }

    #{$root}__close {
      &::before,
      &::after {
        background-color: #fff;
      }
    }

    #{$root}__title {
      color: #fff;

      &::before {
        display: block;
        position: absolute;
        top: 0;
        left: 50%;
        z-index: -1;
        transform: translateX(-50%);
        min-width: 1280px;
        width: 100vw;
        height: 100%;
        background-color: $blue;
        content: "";
      }
    }

    #{$root}__panel {
      position: relative;
      overflow-y: auto;
      height: calc(100% - 89px);
    }

    #{$root}__content {
      max-width: 1180px;
      margin: 0 auto;
    }
  }
}

// 알림메시지
.alarm {
  &__search {
    padding: 30px 40px;
    background-color: #f8f8f8;
  }

  &__row {
    display: flex;
  }

  &__button {
    display: block;
    width: 100%;
    height: 44px;
    margin-top: 20px;
    border-radius: 6px;
    background-color: $blue;
    font-size: 17px;
    font-family: youandiR;
    color: #fff;
  }

  .input-date {
    flex: 1;

    &:nth-child(2) {
      margin-left: 7px;
    }
  }

  .c-radio-box--type2 {
    display: flex;
    margin-top: 10px;
    margin-right: 0;

    .c-radio-box__item {
      flex: 1;

      &:nth-child(n+2) {
        margin-left: 7px;
      }
    }

    .c-radio-box__input + label {
      width: 100%;
    }

    .c-radio-box__input:checked + label {
      border-color: $blue;
    }
  }

  &__empty {
    margin-top: 200px;
    font-size: 15px;
    color: #666;
    text-align: center;
  }

  &__list {
    border-top: 1px solid #e8e8e8;
  }

  &__item {
    position: relative;
    padding: 34px 0 35px;
    border-bottom: 1px solid #e8e8e8;
  }

  &__date {
    font-size: 19px;
    font-family: youandiR;
    color: $blue;
  }

  &__new {
    display: inline-block;
    width: 4px;
    height: 4px;
    margin-top: 2px;
    border-radius: 50%;
    background-color: #ea3e33;
    vertical-align: top;
  }

  &__detail {
    position: absolute;
    top: 41px;
    right: 0;
    font-size: 13px;
    font-family: youandiR;
    color: #666;
  }

  &__title {
    display: block;
    margin-top: 16px;
    font-size: 17px;
  }

  &__text {
    margin-top: 10px;
    font-size: 15px;
    line-height: 24px;
    color: #666;
  }

  .o-button.more {
    margin-bottom: 40px;
    line-height: 42px;
  }
}

.alarm-tab {
  $root : ".alarm-tab";

  padding: 0 40px;

  &__list {
    font-size: 0;
    text-align: center;
  }

  &__button {
    padding: 0 30px;
    font-size: 15px;
    font-family: youandiR;
    line-height: 74px;

    &.is-active {
      #{$root}__underline {
        border-bottom: 2px solid $blue;
        color: $blue;
      }
    }

    &:nth-child(n+2) {
      position: relative;

      &::before {
        display: block;
        position: absolute;
        top: 50%;
        left: 0;
        transform: translateY(-50%);
        width: 1px;
        height: 16px;
        background-color: #d8d8d8;
        content: "";
      }
    }
  }

  &__underline {
    position: relative;
    display: inline-block;
    line-height: 1.4;
  }
}

// 메뉴 검색
.menu-search {
  position: relative;

  &::before {
    display: block;
    position: absolute;
    top: 0;
    left: 50%;
    z-index: -1;
    transform: translateX(-50%);
    min-width: 1280px;
    width: 100vw;
    height: 100%;
    background-color: #fff;
    content: "";
  }

  &__input {
    position: relative;
    height: 80px;

    &::before {
      display: block;
      position: absolute;
      top: 0;
      left: 50%;
      z-index: -1;
      transform: translateX(-50%);
      min-width: 1280px;
      width: 100vw;
      height: 100%;
      background-color: $blue;
      content: "";
    }

    .o-input--text {
      height: 80px;
      padding-left: 118px;
      padding-right: 118px;
      border: none;
      background-color: transparent;
      font-size: 19px;
      font-family: youandiR;
      font-weight: normal;
      line-height: 80px;
      text-align: center;
      color: #fff;

      &::-webkit-input-placeholder {
        color: #fff;
      }

      &:-ms-input-placeholder {
        color: #fff;
      }

      &::-moz-placeholder {
        color: #fff;
      }

      &::-ms-clear {
        display: none;
      }
    }
  }

  &__reset {
    position: absolute;
    top: 0;
    right: 78px;
    width: 40px;
    height: 80px;
  }

  &__icon {
    display: inline-block;
    margin-top: 3px;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background-color: #333;
    transform: rotate(45deg);

    &::before,
    &::after {
      display: block;
      position: absolute;
      top: 5px;
      left: 9px;
      width: 2px;
      height: 10px;
      border-radius: 5px;
      background-color: #fff;
      content: "";
    }

    &::after {
      transform: rotate(90deg);
    }
  }

  &__button {
    position: absolute;
    top: 0;
    right: 34px;
    width: 44px;
    height: 80px;
    background: transparent url("../img/com/ico_menu_search.png") no-repeat 50% 50%;
  }

  &__result {
    display: none;
    position: relative;
    width: 100%;
    height: 50px;
    font-size: 15px;
    line-height: 50px;
    color: #fff;
    text-align: center;
    opacity: 0;
    transition: opacity .3s ease-in;

    &.is-active {
      opacity: 1;
      z-index: 1;
    }

    &::before {
      display: block;
      position: absolute;
      top: 0;
      left: 50%;
      z-index: -1;
      transform: translateX(-50%);
      min-width: 1280px;
      width: 100vw;
      height: 100%;
      background-color: #006bb2;
      content: "";
    }
  }

  .l-side__panel {
    display: none;
    opacity: 0;
    transform: translate3d(0, -30%, 0);
    transition: all .3s ease-in;

    &.is-active {
      opacity: 1;
      transform: translate3d(0, 0, 0);
    }
  }

  &__list {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    width: 1020px;
    margin: 0 auto;
    padding: 30px 0 70px;
    font-size: 0;

    &::before {
      content: "";
      position: absolute;
      bottom: 70px;
      left: 0;
      right: 0;
      border-bottom: 1px solid #fff;
      z-index: 1;
    }
  }

  &__item {
    position: relative;
    display: inline-block;
    width: calc(50% - 40px);
    min-height: 108px;
    vertical-align: top;
    border-bottom: 1px solid #e8e8e8;

    &:nth-child(odd) {
      margin-right: 80px;
    }
  }

  mark {
    background-color: transparent;
    color: $blue;
  }

  &__anchor {
    width: 100%;
    height: 100%;
    display: block;
    padding: 24px 30px 60px 0;
    font-size: 19px;

    &::after {
      content: "";
      position: absolute;
      top: 50%;
      right: 12px;
      transform: translateY(-50%) rotate(45deg);
      width: 9px;
      height: 9px;
      border-top: 2px solid #666;
      border-right: 2px solid #666;
    }

    &:hover,
    &:focus {
      color: $blue;
      text-decoration: underline;

      &::after {
        border-color: $blue;
      }
    }
  }

  &__breadcrumb {
    position: absolute;
    bottom: 30px;
    left: 0;
    margin-top: 11px;
    font-size: 0;
  }

  &__text {
    display: inline-block;
    font-size: 12px;
    color: #666;
    vertical-align: top;

    &:nth-child(n+2) {
      position: relative;
      margin-left: 6px;
      padding-left: 13px;

      &::before {
        display: block;
        position: absolute;
        top: 50%;
        left: 0;
        width: 0;
        padding: 2px;
        border: solid #666;
        border-width: 0 1px 1px 0;
        transform: translateY(-50%) rotate(-45deg);
        content: '';
      }
    }
  }
}

// 전체메뉴
.all-menu {
  $root : ".all-menu";

  position: relative;
  width: 1180px;
  margin: 0 auto;
  font-size: 0;

  &__item {
    &--customer {
      height: 445px;

      > ul {
        position: relative;
        padding-right:380px;

        .all-menu__item--col2 {
          position: absolute;
          top: 0;
          right: 0;
        }
      }
    }
  }

  &__item--1depth {
    position: relative;
    display: block;
    padding: 58px 0 56px 200px;
    @include clearfix;

    &~& {
      border-top: 1px solid #e8e8e8;
    }

    > ul {
      display: flex;
      flex-wrap: wrap;
      margin: 0 0 -24px -20px;

      > li {
        width: 180px;
        margin: 0 0 24px 20px;
      }
    }
  }

  &__item--2depth {
    display: inline-block;
    text-align: left;
    vertical-align: top;
    overflow: hidden;

    > ul {
      padding-top: 14px;
      margin-top: 11px;
      border-top: 1px solid #e8e8e8;
    }

    &[class*="col"] {
      #{$root}__anchor--2depth {
        font-family: youandiB;
        font-size: 13px;
      }

      > ul {
        margin-left: -20px;
        @include clearfix;

        > #{$root}__item--category {
          float: left;
          width: 180px;
          margin-left: 20px;
        }
      }
    }

    &#{$root}__item--col3 {
      width: 580px;
    }

    &#{$root}__item--col2 {
      width: 380px;

      #{$root}__item--category {
        margin-bottom: 9px;
      }
    }
  }

  &__item--2depth,
  &__item--category {
    >ul > li {
      margin-top: 4px;
    }
  }

  &__item--category {
    > #{$root}__anchor--3depth {
      margin-bottom: 9px;
      color: #000;
    }
  }

  &__anchor {
    display: inline-block;
    font-family: youandiR;
  }

  &__anchor--1depth {
    width: 180px;
    position: absolute;
    top: 55px;
    left: 0;
    font-size: 18px;
  }

  a#{$root}__anchor--1depth {
    &:hover,
    &:focus {
      color: $blue;

      .o-icon--right-arrow {
        &::before,
        &::after {
          background-color: $blue;
        }
      }
    }
  }

  &__anchor--2depth {
    font-family: youandiB;
    font-size: 13px;
    white-space: nowrap;
  }

  a#{$root}__anchor--2depth {
    &:hover,
    &:focus {
      margin-bottom: -1px;
      color: $blue;
      border-bottom: 1px solid $blue;
    }
  }

  &__anchor--3depth {
    font-size: 13px;
    color: #666;
  }

  a#{$root}__anchor--3depth {
    &:hover,
    &:focus {
      color: $blue;
      text-decoration: underline;
    }
  }
}

// footer
.l-foot {
  position: relative;
  background-color: #fff;

  &__inside {
    width: 1180px;
    margin: 0 auto 90px;
  }
}

.foot-menu {
  position: relative;
  z-index: 1;
  height: 48px;
  font-size: 0;

  &::before {
    display: block;
    position: absolute;
    top: 0;
    left: 50%;
    z-index: -1;
    transform: translateX(-50%);
    min-width: 1280px;
    width: 100vw;
    height: 100%;
    background-color: #3f3f3f;
    content: "";
  }

  &__anchor {
    display: inline-block;
    font-size: 14px;
    font-family: youandiR;
    color: #fff;
    text-transform: uppercase;
    line-height: 48px;

    &:nth-child(n+2) {
      position: relative;
      margin-left: 68px;

      &::before {
        position: absolute;
        top: 50%;
        left: -35px;
        width: 1px;
        height: 14px;
        background-color: #fff;
        transform: translateY(-50%) rotate(35deg);
        content: "";
      }
    }

    &:last-child {
      float: right;

      &::before {
        display: none;
      }
    }
  }
}

.foot-ars {
  position: relative;
  margin-top: 29px;
  font-size: 0;

  &__title {
    display: inline-block;
    font-family: youandiR;
    font-size: 21px;
    line-height: 79px;
    vertical-align: top;
  }

  &__text {
    display: inline-block;
    margin-left: 30px;
    font-size: 15px;
    color: #666;
    line-height: 79px;
    vertical-align: top;
  }

  &__tel {
    position: absolute;
    top: 0;
    right: 0;
  }

  &__number {
    font-family: youandiB;
    font-size: 17px;
    line-height: 79px;

    & + & {
      margin-left: 35px;
    }
  }

  &__country {
    margin-right: 5px;
  }
}

.foot-info {
  padding-top: 37px;
  border-top: 1px solid #ddd;
  font-size: 0;

  &__item {
    &:nth-child(n+2) {
      margin-top: 11px;
    }
  }

  &__title {
    display: inline-block;
    width: 68px;
    font-family: youandiR;
    font-size: 15px;
    color: #666;
    vertical-align: top;
  }

  &__anchor {
    display: inline-block;
    padding: 0 10px;
    font-family: youandiR;
    font-size: 13px;
    line-height: 22px;
    color: #333;

    & + & {
      position: relative;

      &::before {
        display: block;
        position: absolute;
        top: 5px;
        left: 0;
        width: 1px;
        height: 10px;
        background-color: #333;
        content: "";
      }
    }
  }
}

.foot-company {
  margin-top: 22px;
  font-size: 0;

  &__item {
    display: inline-block;
    font-family: youandiR;
    font-size: 13px;
    color: #666;

    &:nth-child(n+2) {
      margin-left: 20px;
    }
  }
}

.foot-util {
  font-size: 0;
  margin-top: 46px;
  padding-bottom: 80px;

  &__item {
    display: inline-block;

    &:nth-child(n+2) {
      margin-left: 20px;
    }
  }
}

.foot-copyright {
  /* 추후 스크립트 작업시 노출
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: -1;
  */
  position: relative; // 임시 노출 - 스크립트 작업 시 삭제
  margin-top: -90px; // 임시 노출 - 스크립트 작업 시 삭제
  z-index: 1; // 임시 노출 - 스크립트 작업 시 삭제
  width: 100%;
  height: 90px;

  &::before {
    display: block;
    position: absolute;
    top: 0;
    left: 50%;
    z-index: -1;
    transform: translateX(-50%);
    min-width: 1280px;
    width: 100vw;
    height: 100%;
    background: linear-gradient(90deg, $blue 0, #00a19c 100%);
    content: "";
  }

  &__inside {
    width: 1280px;
    padding: 0 50px;
    margin: 0 auto;
  }

  &__slogan {
    position: relative;
    margin-right: 20px;
    font-family: youandiR;
    font-size: 18px;
    color: #fff;
    line-height: 90px;

    &::after {
      display: block;
      position: absolute;
      top: -8px;
      right: -9px;
      width: 10px;
      height: 13px;
      background: url("../img/com/bg_copyright_slogan.png") no-repeat 0 0;
      content: "";
    }
  }

  &__text {
    font-family: youandiR;
    font-size: 16px;
    color: #fff;
    line-height: 90px;
  }
}

// content
.l-content {
  body:not(.main) & {
    padding-top:  129px;
  }

  padding-bottom: 100px;
}

.page {
  width: 1280px;
  margin: 0 auto;
  padding: 0 50px;

  &--full {
    width: 100%;

    .inner {
      width: 1180px;
      margin: 0 auto;
    }
  }
}

// breadcrumb
.breadcrumb {
  $root : ".breadcrumb";

  position: fixed;
  top: 88px;
  left: 0;
  z-index: 80;
  width: 100%;
  border-bottom: 1px solid #eee;
  background-color: #fff;

  &__list {
    max-width: 1920px;
    margin: 0 auto;

    padding: 11px 40px;
    font-size: 0;
  }

  &__item {
    display: inline-block;
    font-size: 12px;
    &:nth-child(n+2) {
      position: relative;
      margin-left: 10px;
      padding-left: 15px;
      background: url("../img/com/bg_breadcrumb.png") no-repeat 0 50%;
    }
  }

  &__anchor {
    display: inline-block;

    color: #666;

    #{$root}__item:last-child & {
      color: #333;
    }

    &:hover,
    &:focus {
      margin-bottom: -1px;
      color: $blue !important;
      border-bottom: 1px solid $blue;
    }
  }
}

// 상단 배너
.top-banner {
  position: relative;

  &::before {
    display: block;
    position: absolute;
    top: 0;
    left: 50%;
    z-index: -1;
    transform: translateX(-50%);
    min-width: 1280px;
    width: 100vw;
    height: 100%;
    background-color: #333;
    content: "";
  }

  &__inside {
    position: relative;
    max-width: 1920px;
    margin: 0 auto;
    padding: 50px 320px 50px 40px;
    color:#fff;
  }

  &__sub {
    position: absolute;
    top: 0;
    right: 0;
  }

  &__today {
    &.o-input--check + label {
      padding-left: 35px !important;
      margin-top: 31px;
      font-size: 15px;
      color: #fff;

      &::before {
        border-color: #fff;
        background-color: transparent;
      }

      &::after {
        background-image: none;
      }
    }

    &.o-input--check:checked + label {
      color: #fff;

      &::before {
        border-color: #fff;
        background-color: transparent;
      }

      &::after {
        background-image: url("../img/com/bg_checkbox.png");
      }
    }
  }

  &__close {
    position: relative;
    display: inline-block;
    width: 100px;
    height: 86px;

    &::before,
    &::after {
      display: block;
      position: absolute;
      top: 50%;
      left: 50%;
      width: 3px;
      height: 28px;
      border-radius: 2px;
      background-color: #fff;
      content: "";
    }

    &::before {
      transform: translate(-50%, -50%) rotate(45deg);
    }

    &::after {
      transform: translate(-50%, -50%) rotate(135deg);
    }
  }
}

// .top-banner + .l-wrap {
//   transform: translateY(-175px); // 임시
//   animation: top-banner-down .8s forwards;

//   @keyframes top-banner-down {
//     to {
//       transform: translateY(0);
//     }
//   }
// }

// 하단 플로팅 배너
.floating-banner {
  position: fixed;
  bottom: 0;
  right: 0;
  z-index: 800;
  width: 520px;
  padding: 40px 40px 90px;
  border: 1px solid #e8e8e8;
  border-radius: 16px 0 0 0;
  background-color: #fff;
  transform: translate3d(100%, 0, 0);
  transition: transform .5s ease-in;

  &.is-active {
    transform: translate3d(0, 0, 0);
  }

  &__title {
    display: block;
    font-size: 17px;
    line-height: 28px;
  }

  &__underline {
    position: relative;

    &::before {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      z-index: -1;
      width: 100%;
      height: 11px;
      background-color: #f5f17c;
    }
  }

  &__text {
    margin-top: 20px;
    font-size: 14px;
    color: #666;
    line-height: 24px;
  }

  &__sub {
    position: absolute;
    left: 40px;
    right: 40px;
    bottom: 40px;
    @include clearfix;
  }

  &__today {
    &.o-input--check + label {
      padding-left: 30px !important;
      margin-top: 3px;
      font-size: 15px;
      color: #666;
    }
  }

  &__close {
    float: right;
    height: 30px;
    padding: 0 10px;
    border-radius: 5px !important;
    font-size: 14px;
    color: #333 !important;
  }
}

.skip-link {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
}

.fade-in {
  animation: .3s ease-out forwards fade;
}

.fade-out {
  animation: .3s ease-out reverse forwards fade;
}

.gnb__panel {
  opacity: 0;
  transition: opacity 3s ease-out;
}
.gnb__anchor--1depth.is-active + .gnb__panel {
  opacity: 1;
}

// @keyframes duration | timing-function | delay | iteration-count | direction | fill-mode | play-state | name
// animation 3s ease-in 1s 2 reverse both paused slidein
@keyframes fade {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

// pContents 100vh
#pContents {
  min-height: 100vh;
}


.foot-family-site {
  $root: '.foot-family-site';
  position: absolute;
  right: 50%;
  bottom: 167px;
  width: 160px;
  transform: translate(590px, 0);
  line-height: 34px;

  &:focus {
    border-color: #0076c0;

    #{$root}__button {
      border-top: 1px solid #e8e8e8;
    }
  }

  &:before {
    content: '';
    position: absolute;
    top: 50%;
    right: 0;
    z-index: 3;
    border: 3px solid #000;
    border-top-color: transparent;
    border-right-color: transparent;
    transform: translate(-20px, -50%) rotate(-45deg);
  }

  &__button {
    position: relative;
    z-index: 2;
    height: 36px;
    line-height: 34px;
    width: 100%;
    padding: 0 15px;
    text-align: left;
    border: 1px solid #b8b8b8;
    border-radius: 6px;
    background-color: #fff;
  }

  &__nav {
    display: none;
    position: absolute;
    bottom: 36px;
    width: 100%;
    padding: 10px 0;
    border: 1px solid #b8b8b8;
    border-radius: 6px;
    background-color: #fff;

    a {
      display: block;
      padding: 0 15px;
      line-height: 2;
    }
  }

  a:focus {
    color: #0076c0;
  }

  &.is-active {

    &:before {
      margin-top: 3px;
      transform: translate(-20px, -50%) rotate(135deg);
    }

    #{$root}__button {
      border-top: 0;
      border-color: #0076c0;
      border-top-left-radius: 0;
      border-top-right-radius: 0;

      &::before {
        content: '';
        position: absolute;
        top: 0;
        width: calc(100% - 30px);
        height: 1px;
        background-color: #d8d8d8;
      }
    }

    #{$root}__nav {
      display: block;
      border-bottom: 0;
      border-color: #0076c0;
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
      box-shadow: 0 -10px 35px rgba(0, 0, 0, 0.2);
    }
  }
}
