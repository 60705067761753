.paging {
  font-size: 0;
  text-align: center;
  margin-top: 38px;

  &__anchor {
    position: relative;
    display: inline-block;
    height: 25px;
    padding: 0 10px;
    font-family: youandiR;
    font-size: 15px;
    vertical-align: top;
    line-height: 25px;

    &:hover,
    &:focus {
      text-decoration: underline;
      color: #0076c0;
    }

    &--current {
      font-family: youandiB;
      color: #0076c0;

      &:hover,
      &:focus {
        text-decoration: none;
      }
    }

    &--first,
    &--prev,
    &--next,
    &--last {
      position: relative;

      &::before,
      &::after {
        position: absolute;
        top: 0;
        left: 10px;
        width: 8px;
        height: 25px;
        background: url("../img/com/bg_paging.png") no-repeat 50% 50%;
        content: '';
      }

      &:hover,
      &:focus {
        &::before,
        &::after {
          background-image: url("../img/com/bg_paging_active.png");
        }
      }
    }

    &--prev {
      width: 28px;
      margin-right: 10px;

      &::after {
        display: none;
      }
    }

    &--next {
      width: 28px;
      margin-left: 10px;

      &::after {
        display: none;
      }
    }

    &--next,
    &--last {
      &::before,
      &::after {
        transform: rotate(180deg);
      }
    }

    &--first,
    &--last {
      width: 34px;

      &::after {
        display: block;
        left: 15px;
      }
    }
  }
}
