/* ---------------------------------------------------------------------------------------------------------------------
공통
--------------------------------------------------------------------------------------------------------------------- */
// background
.bg-full {
  position: relative;

  &::before {
    display: block;
    position: absolute;
    top: 0;
    left: 50%;
    z-index: -1;
    transform: translateX(-50%);
    min-width: 1280px;
    width: 100vw;
    height: 100%;
    background-color: #f8f8f8;
    content: '';
  }

  &.bg-gray {
    &::before {
      background-color: #f8f8f8;
    }
  }

  &.bg-gradient {
    &::before {
      background: linear-gradient(90deg, $blue 0, #00a19c 100%);
    }
  }
}

// 안내
.info {
  $root: '.info';

  position: relative;
  margin-top: 60px;
  padding: 40px 0;
  font-size: 15px;
  color: #666;

  &::before {
    display: block;
    position: absolute;
    top: 0;
    left: 50%;
    z-index: -1;
    transform: translateX(-50%);
    min-width: 1280px;
    width: 1300px;
    height: 100%;
    background-color: #f8f8f8;
    content: '';
    border-radius: 10px;
  }

  &--line {
    margin-top: 70px;
    padding: 30px 50px !important;
    border: 10px solid #f8f8f8;
    background-color: #fff !important;

    &::before {
      display: none;
    }

    #{$root}__tit {
      color: #000;
      margin-bottom: 20px;
    }
  }

  & > .c-text-list > li {
    font-size: 14px !important;
    line-height: 20px !important;
  }
}

// 조회 내역&결과 없음
.empty {
  $root: '.empty';
  margin-top: 30px;
  border-top: 1px solid #666;
  border-bottom: 1px solid #d8d8d8;
  padding: 40px 0;
  text-align: center;

  &__text {
    font-size: 15px;
    line-height: 18px;

    &:only-child {
      padding: 13px 0;
    }
  }

  .o-button {
    margin-top: 18px;
  }

  &--type2 {
    margin-top: 80px;
    padding: 0;
    border: none;

    #{$root}__text {
      padding: 0;
      font-size: 17px;
    }
  }

  &--type3 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: 200px;
    background: #f8f8f8;
    border: 0;
    border-radius: 12px;
    text-align: center;
    font-size: 0;

    #{$root}__image {
      display: block;
      margin-bottom: 17px;
    }

    #{$root}__text {
      font-size: 15px;
      color: #000;
    }
  }
}

// 완료
.complete {
  padding: 212px 0 54px;
  background: url('../img/com/bg_complete.gif') no-repeat 50% 80px;
  text-align: center;

  $root: ".complete";

  &--type2 {
    background-image: url('../img/com/bg_complete_type2.gif');
  }

  &__msg {
    font-size: 34px;
    font-family: youandiB;
    line-height: 46px;
    color: $blue;
  }

  &__text {
    margin-top: 14px;
    font-size: 19px;
    line-height: 32px;
  }

  &__error {
    position: relative;
    display: inline-block;
    height: 60px;
    line-height: 58px;
    margin-top: 42px;
    padding: 0 40px 0 80px;
    border: 1px solid #ff3c50;
    color: #ff3c50;
    font-size: 15px;
    border-radius: 30px;

    &::before {
      content: "";
      position: absolute;
      top: 50%;
      left: 40px;
      transform: translateY(-50%);
      width: 28px;
      height: 24px;
      background: url('../img/com/ico_complete_error.png') no-repeat;
    }
  }

  &__box {
    display: inline-block;
    min-width: 270px;
    margin-top: 28px;
    padding: 26px 15px;
    background-color: #f8f8f8;
    color: #666;
    line-height: 21px;

    &--full {
      width: 100%;
      display: block;
      padding: 35px 15px;
      text-align: center;

      .o-text {
        text-align: inherit;
      }

      .o-text--em {
        display: block;
        line-height: 1.6;
        font-size: 23px;
        color: $black;
      }
    }
  }

  .o-button {
    margin-top: 24px;

    & + .o-button {
      margin-top: 0;
    }
  }
}

// 그래프 영역
.graph {
  overflow: hidden;
}

// 이미지 영역
.image-wrap {
  img {
    max-width: 100%;
    vertical-align: top;
  }
}

// 약관 스크롤 영역
.terms-wrap {
  overflow-y: auto;
  height: 370px;
  margin-bottom: 40px;
  padding: 40px 20px;
  border-top: 1px solid #000;

  & > *:first-child {
    margin-top: 0;
  }
}

// 상단 텍스트 영역
.info-wrap {
  $root: '.info-wrap';
  position: relative;
  padding: 15px 40px 14px;
  background-color: #f8f8f8;
  text-align: center;

  &__text {
    font-size: 15px;
    font-family: youandiR;
    line-height: 24px;
  }

  &__button {
    position: absolute;
    top: 50%;
    right: 40px;
    transform: translateY(-50%);
  }

  &__list {
    margin: 0 auto;

    > li {
      position: relative;
      display: inline-block;
      padding: 0 30px;
      font-size: 14px;
      color: #666;

      ~ li {
        &::before {
          content: "";
          position: absolute;
          top: 50%;
          left: 0;
          transform: translateY(-50%);
          width: 1px;
          height: 48px;
          background: #e8e8e8;
        }
      }

      .co-blue {
        display: block;
        margin-top: 14px;
        font-size: 15px;
      }
    }
  }

  &__foot {
    padding: 36px 30px 40px;
    background: #f8f8f8;
    border: 1px solid #e8e8e8;
    border-top: 0;
  }

  &.bg-none {
    background: none;
  }

  &--type2 {
    padding: 32px 40px 33px;
    background: #333;
    border-radius: 6px;
    color: #fff;
    text-align: right;

    @include clearfix;

    #{$root}__col {
      position: relative;
      float: left;
      width: calc(50% - 40px);

      &:nth-child(2) {
        margin-left: 40px;
        padding-left: 40px;

        &::before {
          position: absolute;
          top: 50%;
          left: 0;
          transform: translateY(-50%);
          width: 1px;
          height: 16px;
          background-color: #5c5c5c;
          content: '';
        }
      }
    }

    #{$root}__title {
      float: left;
      max-width: 50%;
      font-family: youandiB;
      font-size: 17px;
    }

    #{$root}__text {
      display: inline-block;
      font-family: youandiR;
      font-size: 17px;
    }
  }

  &--type3 {
    margin-top: 90px;
    font-size: 0;
    position: relative;
    padding: 49px 40px;
    border-radius: 10px;
    background-color: $blue;
    color: #fff;
    text-align: center;

    #{$root}__title {
      font-family: youandiB;
      font-size: 32px;
      line-height: 38px;

      + .info-wrap__text {
        margin-top: 10px;
      }
    }

    #{$root}__text {
      font-size: 23px;
      line-height: 45px;
    }

    #{$root}__highlight {
      font-family: youandiB;
      font-size: 32px;
    }

    #{$root}__underline {
      position: relative;
      display: inline-block;

      &::before {
        content: '';
        position: absolute;
        bottom: 3px;
        left: 0;
        right: 0;
        border-bottom: 1px solid #fff;
      }
    }

    #{$root}__button {
      position: absolute;
      top: 50%;
      right: 40px;
      transform: translateY(-50%);
    }

    .c-text-list--dot {
      margin-top: 6px;

      > .c-text-list__item::before {
        background-color: #fff;
      }
    }

    #{$root}__foot {
      border-bottom-left-radius: 9px;
      border-bottom-right-radius: 9px;
      margin: 30px -40px -49px;
    }
  }

  &--type4 {
    position: relative;
    margin-top: 50px;
    padding: 56px 60px 56px;
    border-radius: 10px;
    background-color: #f8f8f8;
    text-align: center;

    #{$root}__title {
      display: block;
      font-size: 19px;
      font-family: youandiB;
      line-height: 28px;

      + #{$root}__text {
        margin-top: 12px;
      }
    }

    #{$root}__text {
      display: block;
      line-height: 36px;
      font-size: 25px;
      color: #666;
    }

    #{$root}__sub-text {
      display: block;
      margin-top: 10px;
      font-family: $font-family-base;
      font-size: 15px;
      color: #666;
    }

    #{$root}__button {
      position: static;
      margin: 30px auto 0;
      transform: none;
    }

    #{$root}__sub {
      margin-top: 50px;
      padding: 56px 60px;
      background: #fff;
      border-radius: 10px;
      text-align: left;
      @include clearfix;

      #{$root}__text {
        font-family: $font-family-base;
        font-size: 17px;
        line-height: 28px;
      }

      #{$root}__image {
        display: block;
        margin-left: 50px;
      }
    }

    #{$root}__sub-text {
      padding: 14px 20px;
      background: #fff;
      color: #000;
    }

    > #{$root}__image {
      display: block;
      margin: 0 auto 24px;
    }

    > .c-text-list {
      margin-top: 12px;

      .c-text-list__item {
        font-size: 13px;
        color: #666;

        + .c-text-list__item {
          margin-top: 0;
        }
      }
    }

    + .c-text-list {
      margin-top: 26px;
    }

    .o-heading--4 {
      margin-bottom: 13px;
    }
  }

  &--type5 {
    padding: 34px 0 37px;
    border-radius: 6px;
    background-color: $blue;
    text-align: center;

    #{$root}__title {
      display: block;
      font-size: 21px;
      font-family: youandiR;
      color: #fff;
    }

    #{$root}__text {
      margin-top: 9px;
      font-family: inherit;
      font-size: 17px;
      color: #fff;
    }
  }

  &--type6 {
    padding: 30px;
    border-radius: 6px;

    #{$root}__text {
      font-family: inherit;
      font-size: 13px;
      line-height: 20px;
      text-align: left;
    }
  }
}

.info + .info-wrap {
  margin-top: 50px;
}

.c-modal__body {
  .info-wrap--type3 {
    margin-top: 0;
  }

  .info-wrap--type4 {
    padding: 32px 40px 60px
  }
}

.info-list {
  font-size: 0;

  $root: '.info-list';

  &__item {
    display: inline-block;
    ~ #{$root}__item {
      margin-left: 50px;
    }
  }

  &__title {
    margin-right: 30px;
    font-size: 13px;
  }

  &__text {
    font-family: youandiR;
    font-size: 17px;
  }
}

// 기타 - heading 레벨과 동일 선상 우측 버튼
.heading-right {
  margin-top: -53px;
  text-align: right;
  font-size: 17px;

  .input-group {
    padding-top: 0;

    label {
      margin-top: 0;
    }
  }

  .search-form {
    position: relative;
    top: -10px;
  }

  &--line {
    padding-bottom: 24px;
    border-bottom: 1px solid #000;
  }

  .o-button.link {
    vertical-align: middle;

    .o-icon--right-arrow {
      margin-top: -4px;
    }
  }
}

// 기타 - text에 맞춰 동일 우측 버튼
.text-right {
  margin-top: -25px;

  .o-button.link {
    vertical-align: middle;

    .o-icon--right-arrow {
      margin-top: -3px;
    }
  }

  .md {
    margin-top: 7px;
  }

  .input-group + & {
    margin-top: -53px;

    .o-text {
      display: inline-block;
      text-align: right;
    }
  }
}

// 페이지 상단
.page-head {
  margin-bottom: 70px;
  padding: 68px 0 0;
  font-size: 0;
  text-align: center;

  $root: '.page-head';

  &__text {
    display: block;
    margin-top: 23px;
    font-size: 20px;
    font-family: youandiR;
    color: $blue;

    .o-label + & {
      display: inline-block;
      margin-left: 20px;
    }
  }

  &__description {
    display: block;
    margin-top: 23px;
    font-size: 18px;

    .o-button.link {
      font-size: 18px;
    }
  }

  &__sub {
    padding: 0 40px;
    margin: 40px auto 0;
    width: 780px;
    height: 60px;
    line-height: 60px;
    border-radius: 30px;
    background: #0089d1;
    font-family: youandiR;
    color: #fff;
    text-align: right;

    @include clearfix;
  }

  &__sub-title {
    float: left;
    font-size: 19px;
  }

  &__sub-copy {
    font-size: 25px;
  }

  &__sub-text {
    font-size: 15px;

    &__sub-highlight {
      margin-left: 14px;
    }
  }

  &__sub-highlight {
    font-size: 21px;
    font-family: youandiB;
  }

  &.bg-full {
    padding-bottom: 60px;
  }

  .o-label {
    margin-right: 4px;
  }

  .search-form {
    margin-top: 30px;
  }
}

// 테두리 text wrap
.text-wrap {
  $root: '.text-wrap';

  padding: 30px 50px;
  border: 10px solid #f8f8f8;

  &--type1 {
    position: relative;
    margin-top: 40px;

    #{$root}__title {
      display: block;
      margin-bottom: -6px;
      font-family: youandiR;
      font-size: 18px;
    }

    #{$root}__text {
      position: absolute;
      top: 50%;
      right: 50px;
      transform: translateY(-50%);
      font-family: youandiR;
      font-size: 23px;
    }

    #{$root}__price {
      font-family: youandiB;
      font-size: 30px;
      color: $blue;
      vertical-align: middle;
    }
  }

  &--type2 {
    #{$root}__title {
      font-family: youandiB;
      font-size: 26px;
      line-height: 26px;
      color: $blue;
    }

    #{$root}__text {
      margin-top: 5px;
      font-size: 17px;
      line-height: 28px;
      color: #666;
    }
  }

  &--type3 {
    position: relative;
    margin-top: 90px;
    padding-left: 40px;
    padding-right: 40px;
    border-radius: 7px;
    border-width: 1px;
    border-color: #d8d8d8;

    .o-button {
      position: absolute;
      top: 50%;
      right: 40px;
      transform: translateY(-50%);
    }
  }

  &--type4 {
    text-align: center;

    #{$root}__title {
      line-height: 28px;
      font-family: youandiR;
      font-size: 21px;
      color: $blue;
    }

    #{$root}__text {
      margin-top: 25px;
      font-size: 17px;
    }

    .o-button.md {
      min-width: 160px;
    }
  }

  &--product {
    margin-top: 70px;
    font-size: 15px;
    color: #777;

    &__tit {
      color: #000;
      margin-bottom: 20px;
    }
  }

  .o-heading:first-child {
    margin-top: 0;
  }

  .c-text-list--dot {
    > .c-text-list__item.co-blue::before {
      background-color: $blue;
    }
  }
}

// 검색
.search-form {
  $root: '.search-form';
  font-size: 0;

  &--sm {
    #{$root}__input {
      width: 300px;
    }
  }

  &--md {
    #{$root}__input {
      width: 640px;
    }
  }

  &--center {
    text-align: center;
  }

  &--right {
    text-align: right;
  }

  & > *:nth-child(n + 2) {
    margin-left: 10px;
  }

  &__input {
    position: relative;
    display: inline-block;
    width: 100%;
    border: 1px solid #d8d8d8;
    border-radius: 6px;
    background: transparent;

    input {
      position: relative;
      width: 100%;
      padding-right: 108px;
      background: transparent;
      border: 0;
    }

    &.is-active {
      #{$root}__reset {
        display: block;
      }
    }
  }

  &__reset {
    position: absolute;
    top: 50%;
    right: 44px;
    transform: translateY(-50%);
    width: 40px;
    height: 48px;
    text-align: center;
  }

  &__icon {
    display: inline-block;
    margin-top: 3px;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background-color: #666;
    transform: rotate(45deg);

    &::before,
    &::after {
      display: block;
      position: absolute;
      top: 4px;
      left: 9px;
      width: 2px;
      height: 12px;
      border-radius: 5px;
      background-color: #fff;
      content: '';
    }

    &::after {
      transform: rotate(90deg);
    }
  }

  &__button {
    position: absolute;
    top: 50%;
    right: 4px;
    transform: translateY(-50%);
    width: 40px;
    height: 48px;
    text-align: center;
    background: transparent url('../img/com/bg_search.png') no-repeat 50% 50%;
  }

  &__select {
    display: flex;
    margin-bottom: 10px;

    .listbox {
      flex: 1;

      &:nth-child(n + 2) {
        margin-left: 10px;
      }
    }
  }
}

// 상담신청내역
.advice {
  $root: '.advice';

  &__head {
    position: relative;
    padding-bottom: 24px;
    border-bottom: 1px solid #000;
  }

  &__state {
    position: absolute;
    top: 0;
    left: 0;
    display: inline-block;
    padding: 0 12px;
    border: 1px solid #d8d8d8;
    border-radius: 15px;
    background-color: #f8f8f8;
    font-size: 14px;
    color: #666;
    text-align: center;
    line-height: 28px;

    &--complete {
      background-color: #fff;
      color: $blue;
    }
  }

  &__title {
    display: block;
    padding: 0 100px 0 90px;
    font-size: 23px;
    line-height: 30px;
  }

  &__date {
    position: absolute;
    top: 3px;
    right: 0;
    font-size: 15px;
    color: #666;
  }

  &__question {
    padding: 23px 0;
    font-size: 17px;
    line-height: 28px;
  }

  &__answer {
    padding: 23px 30px 33px 60px;
    border-top: 1px solid #d8d8d8;
    background: #f8f8f8 url('../img/com/bg_advice_answer.png') no-repeat left 30px top 30px;
    font-size: 17px;
    line-height: 28px;
  }

  &__empty {
    padding: 105px 0;
    border-top: 1px solid #d8d8d8;
    background-color: #f8f8f8;
    font-size: 17px;
    text-align: center;
    color: #666;
  }

  &__answer-date {
    display: block;
    margin-bottom: 15px;
    font-size: 15px;
    color: #666;
  }

  &--modal {
    #{$root}__head {
      padding: 0 0 25px;
      border-width: 2px;
    }

    #{$root}__state {
      position: static;
      line-height: 22px;
    }

    #{$root}__title {
      margin-top: 25px;
      padding: 0;
      font-family: youandiB;
      line-height: 32px;
    }

    #{$root}__date {
      position: static;
      display: block;
      margin-top: 11px;
      font-family: youandiR;
    }

    #{$root}__question {
      padding: 33px 0;
      font-family: youandiR;
    }

    #{$root}__answer {
      padding-top: 33px;
      background-position: left 30px top 40px;
      font-family: youandiR;
    }

    #{$root}__empty {
      padding: 135px 0;
      font-family: youandiR;
    }

    #{$root}__answer-date {
      font-family: youandiR;
    }
  }
}

// 보안프로그램설치
.program-text {
  margin-bottom: 40px;
  border-radius: 5px;
  background-color: #f8f8f8;
  font-size: 19px;
  font-family: youandiR;
  color: $blue;
  line-height: 117px;
  text-align: center;
}

/* ---------------------------------------------------------------------------------------------------------------------
Mypage - 계약조회 (기업)
--------------------------------------------------------------------------------------------------------------------- */
.product-name {
  margin-bottom: 30px;
  font-size: 14px;
  font-family: youandiR;
  color: #004b75;
  line-height: 54px;
  text-align: center;

  &__number {
    display: inline-block;
    margin-right: 20px;
  }
}

/* ---------------------------------------------------------------------------------------------------------------------
Mypage - 퇴직연금 (개인, 기업)
--------------------------------------------------------------------------------------------------------------------- */
// 조회 - 퇴직연금현황 - 자산현황, 운용상품현황,가입자교육이수현황
.status-wrap {
  display: flex;

  .o-heading--2 {
    margin-top: 0;
  }

  &__item {
    flex: 1;

    &:nth-child(n + 2) {
      margin-left: 50px;
    }
  }

  &__panel {
    height: 433px;
    border: 1px solid #666;
    border-radius: 14px;
  }
}

.status-type {
  $root: '.status-type';

  &--1 {
    position: relative;

    #{$root}__head {
      display: block;
      padding-left: 30px;
      background-color: #f8f8f8;
      border-radius: 14px 14px 0 0;
      font-size: 16px;
      font-weight: 700;
      line-height: 62px;
    }

    #{$root}__list {
      display: flex;
      flex-direction: column;
      height: 356px;
      margin-top: 6px;
      padding: 0 30px;
    }

    #{$root}__item {
      position: relative;
      flex: 1;
      display: flex;
      align-items: center;

      &:nth-child(n + 2) {
        border-top: 1px solid #eee;
      }
    }

    #{$root}__title {
      display: inline-block;
      font-size: 14px;
      color: #666;
      line-height: 50px;
      vertical-align: middle;
    }

    #{$root}__text {
      position: absolute;
      top: 50%;
      right: 0;
      transform: translateY(-50%);
      font-size: 17px;
      font-weight: 700;
    }

    .o-button.link {
      position: absolute;
      top: 0;
      right: 30px;
      line-height: 62px;
    }
  }

  &--2 {
    #{$root}__head {
      position: relative;
      padding: 0 30px;
      border-radius: 14px 14px 0 0;
      background-color: $blue;
    }

    #{$root}__title {
      color: #fff;
      font-size: 14px;
      line-height: 62px;
    }

    #{$root}__text {
      position: absolute;
      top: 0;
      right: 30px;
      font-size: 17px;
      font-weight: 700;
      line-height: 61px;
      color: #fff;
    }
  }

  &--3 {
    #{$root}__head {
      padding: 34px 30px;
      border-radius: 14px 14px 0 0;
      background-color: #f8f8f8;
      font-size: 15px;
      line-height: 26px;
      color: #666;
    }

    #{$root}__date {
      margin-top: 8px;
    }

    #{$root}__list {
      padding: 0 30px;
    }

    #{$root}__item {
      position: relative;
      height: 125px;
      padding-top: 30px;

      &:nth-child(n + 2) {
        border-top: 1px solid #eee;
      }
    }

    #{$root}__text {
      font-size: 17px;
    }

    .o-label {
      display: block;
      width: 65px;
      margin-bottom: 14px;
    }

    .o-button.link {
      position: absolute;
      bottom: 26px;
      right: 0;
    }
  }
}

.status-swiper {
  height: 340px;
  padding-bottom: 58px !important;

  &__item {
    position: relative;
    height: 141px;
    border-bottom: 1px solid #eee;
  }

  &__title {
    position: absolute;
    top: 50%;
    left: 30px;
    transform: translateY(-50%);
    padding-right: 95px;
    font-size: 17px;
    line-height: 28px;
  }

  &__text {
    position: absolute;
    top: 50%;
    right: 30px;
    transform: translateY(-50%);
    font-size: 17px;
    font-weight: 700;
  }

  .swiper-custom-button--prev {
    position: absolute;
    left: 30px;
    top: 0;
  }

  .swiper-custom-button--next {
    position: absolute;
    right: 30px;
    top: 0;
  }
}

// 서비스/기타 - 목표수익률 알림 - 수익률 설정
.target-return {
  font-size: 0;

  &__item {
    position: relative;
    display: inline-block;
    width: 438px;
    padding-left: 50px;

    &:nth-child(n + 2) {
      margin-left: 50px;
    }
  }

  &__label {
    position: absolute !important;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    padding-left: 30px !important;
  }

  .c-form {
    margin-top: 0;
  }
}

// 신청/변경 - 투자성향 분석
.join-process {
  overflow: hidden;
  display: flex;
  border: 1px solid #e8e8e8;
  border-radius: 18px;

  &__item {
    position: relative;
    flex: 1;
    padding: 124px 10px 45px 50px;

    &::before {
      content: '';
      position: absolute;
      top: 50%;
      right: -14px;
      transform: translateY(-50%);
      width: 0;
      height: 0;
      border-top: 33px solid transparent;
      border-left: 14px solid $white;
      border-bottom: 33px solid transparent;
      z-index: 1;
    }

    &:nth-child(even) {
      background-color: #f8f8f8;

      &::before {
        border-left-color: #f8f8f8;
      }
    }

    &:last-child {
      &::before {
        display: none;
      }
    }
  }

  &__image {
    position: absolute;
    top: 44px;
    left: 50px;
  }

  &__title {
    display: block;
    font-family: youandiR;
    font-size: 17px;
  }

  &__text {
    margin-top: 15px;
    font-size: 14px;
    color: #666;
    line-height: 24px;
  }
}

// Modal : 조회 > 퇴직연금현황 > 나의 문의내용 > 상담신청
.advice-head {
  margin-bottom: -20px;

  &__text {
    margin-top: 8px;
    font-family: youandiR;
    font-size: 21px;
    line-height: 30px;
  }
}

// Modal : 신청/변경 > 투자성향 > 투자성향 분석 > 고객투자성향진단
.investment-form {
  &__item {
    position: relative;
    padding: 30px 270px 30px 30px;
    border: 1px solid #d8d8d8;
    border-radius: 6px;

    &:nth-child(n + 2) {
      margin-top: 10px;
    }
  }

  &__title {
    font-size: 17px;
  }

  &__input {
    position: absolute;
    top: 50%;
    right: 30px;
    transform: translateY(-50%);
  }
}

// (기업) modal : 증명서 발급
.certificate-radio {
  display: flex;
  margin-top: 24px;

  &__item {
    flex: 1;
  }

  &__input {
    overflow: hidden;
    position: absolute;
    width: 1px;
    height: 1px;
    opacity: 0;
  }

  &__input + label {
    position: relative;
    display: block;
    height: 188px;
    padding-top: 119px;
    border: 1px solid #d2d2d2;
    background-color: #fff;
    font-family: youandiB;
    font-size: 18px;
    text-align: center;
    cursor: pointer;
  }

  &__input:checked + label {
    border: 1px solid $blue;
    color: $blue;
  }

  &__image {
    position: absolute;
    top: 45px;
    left: 50%;
    transform: translateX(-50%);
  }
}

// (기업) modal : 제안결과
.result-proposal {
  padding: 40px 40px 36px;
  border-radius: 10px;
  background: #f8f8f8;
  text-align: center;

  &__image {
    display: block;
    margin: 0 auto;
    margin-bottom: 10px;
  }

  .o-text {
    text-align: center;
  }
}

/* ---------------------------------------------------------------------------------------------------------------------
혜택서비스
--------------------------------------------------------------------------------------------------------------------- */
// 고객 혜택
.benefit-content {
  $root: '.benefit-content';

  position: relative;

  & + & {
    margin-top: 100px;
  }

  &--hcoin {
    #{$root}__heading {
      background-color: #272a44;
    }
  }

  &__heading {
    padding-left: 40px;
    border-radius: 10px;
    background-color: $blue;
    font-size: 23px;
    font-family: youandiR;
    line-height: 72px;
    color: #fff;
  }

  &__anchor {
    position: absolute;
    top: 0;
    right: 0;
    padding: 0 40px;
    color: #fff;
    line-height: 72px !important;

    .o-icon--right-arrow {
      margin-top: -2px;
      &::before,
      &::after {
        background-color: #fff;
      }
    }
  }

  &__list {
    display: flex;
    margin-top: 40px;
  }

  &__item {
    position: relative;
    flex: 1;
    height: 160px;
    padding: 37px 30px 0 140px;
    border-radius: 6px;
    border: 1px solid #eaedf4;

    &:nth-child(n + 2) {
      margin-left: 20px;
    }
  }

  &__image {
    position: absolute;
    top: 40px;
    left: 30px;
  }

  &__title {
    display: block;
    font-size: 19px;
    font-family: youandiR;
  }

  &__text {
    margin-top: 9px;
    font-size: 15px;
    line-height: 24px;
  }

  &__sub-text {
    margin-top: 5px;
    font-size: 13px;
    color: #666;
  }
}

.benefit-discount {
  height: 180px;
  margin-top: 100px;
  padding: 44px 0 0 60px;
  border-radius: 10px;
  background: #f8f8f8 url('../img/com/bg_benefit_discount.png') no-repeat right bottom;

  &__heading {
    display: block;
    font-size: 22px;
    font-family: youandiB;
    color: #271414;
  }

  &__title {
    margin-top: 6px;
    display: block;
    font-size: 21px;
    font-family: youandiR;
  }

  &__text {
    margin-top: 4px;
    font-size: 15px;
    font-family: youandiR;
    color: #666;
  }
}

// 고객 혜택 - 헬스케어 서비스
.health-head {
  position: relative;
  height: 249px;
  padding-top: 80px;
  text-align: center;

  &::before {
    display: block;
    position: absolute;
    top: 0;
    left: 50%;
    z-index: -1;
    transform: translateX(-50%);
    min-width: 1280px;
    width: 100vw;
    height: 100%;
    background: linear-gradient(90deg, $blue 0, #00a19c 100%);
    content: '';
  }

  &__title {
    font-family: youandiB;
    font-size: 44px;
    color: #fff;
  }

  &__text {
    margin-top: 13px;
    font-family: youandiR;
    font-size: 19px;
    color: #fff;
  }
}

.health-care {
  margin-top: 20px;

  &__item {
    position: relative;
    padding: 45px 0 50px 360px;
    border-bottom: 1px solid #e8e8e8;
  }

  &__heading {
    position: absolute;
    top: 43px;
    left: 0;
    padding-left: 30px;
    font-family: youandiR;
    font-size: 21px;

    &::before {
      display: block;
      position: absolute;
      top: 50%;
      left: 0;
      transform: translateY(-50%);
      width: 2px;
      height: 20px;
      background-color: $blue;
      content: '';
    }
  }

  &__title {
    display: block;
    font-family: youandiR;
    font-size: 19px;
    color: $blue;
  }

  &__text {
    margin-top: 12px;
    font-family: youandiR;
    font-size: 17px;
    line-height: 27px;
  }

  &__service {
    margin: 15px 0 34px;
    padding: 9px 0 9px 20px;
    background-color: #ebf4fa;
    font-size: 0;

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__hashtag {
    display: inline-block;
    margin-right: 20px;
    font-family: youandiR;
    font-size: 15px;
    color: $blue;
  }
}

// H-Coin 안내
.hcoin-join {
  padding: 50px 0;
  border-radius: 22px 22px 0 0;
  background-color: #272a44;
  text-align: center;

  &__title {
    display: block;
    margin-bottom: 22px;
    font-family: youandiR;
    font-size: 30px;
    color: #fff;
  }

  &__hashtag {
    display: inline-block;
    margin: 0 2px;
    padding: 0 20px;
    border-radius: 15px;
    background-color: #3d4057;
    font-size: 15px;
    font-family: youandiR;
    line-height: 30px;
    color: #fff;
  }

  &__anchor {
    background-color: #ff8300;
    color: #fff;
  }
}

.hcoin-info {
  position: relative;
  padding: 28px 350px 23px 288px;
  border-radius: 0 0 22px 22px;
  background: #12152d url('../img/com/bg_hcoin_info.png') no-repeat 60px 29px;

  &__heading {
    position: absolute;
    top: 50%;
    left: 128px;
    transform: translateY(-50%);
    font-family: youandiB;
    font-size: 21px;
    color: #ff8300;
  }

  &__text {
    position: relative;
    padding-left: 41px;
    font-family: youandiR;
    font-size: 15px;
    color: #fff;
    line-height: 28px;

    &::before {
      display: block;
      position: absolute;
      top: 50%;
      left: 0;
      transform: translateY(-50%);
      width: 1px;
      height: 30px;
      background-color: rgba(255, 255, 255, 0.3);
      content: '';
    }
  }

  &__anchor {
    position: absolute;
    top: 50%;
    right: 60px;
    transform: translateY(-50%);
    padding: 0 40px;
    border: 1px solid #ff8300;
    border-radius: 5px;
    background-color: #171a36;
    font-family: youandiR;
    font-size: 19px;
    line-height: 52px;
    color: #ff8300;
  }
}

.hcoin-item {
  margin-top: 93px;
  text-align: center;

  &__heading {
    font-family: youandiB;
    font-size: 21px;
  }

  &__text {
    margin-top: 18px;
    font-family: youandiR;
    font-size: 17px;
  }
}

.hcoin-save {
  $root: '.hcoin-save';

  display: flex;
  margin-top: 56px;
  padding: 60px 0;
  border-radius: 10px;
  background-color: #f8f8f8;

  &__item {
    flex: 1;
    padding: 0 108px;

    &:nth-child(n + 2) {
      border-left: 1px solid #f0f0f0;
    }
  }

  &__title {
    display: block;
    margin-top: -6px;
    color: #272a44;
    font-size: 23px;
    font-family: youandiR;
    line-height: 36px;
  }

  &__orange {
    color: #ff8300;
  }

  &__blue {
    color: $blue;
  }

  &__card {
    overflow: hidden;
    position: relative;
    height: 200px;
    margin-top: 31px;
    padding-top: 34px;
    border-radius: 20px;
    box-shadow: 10px 10px 20px rgba(0, 0, 0, 0.1);

    &--new {
      background-color: #272a44;

      #{$root}__foot {
        background-color: #f47e02;
      }
    }

    &--mpoint {
      background: #333 url('../img/com/bg_hcoin_save_mpoint.png') no-repeat 50% 0;

      #{$root}__foot {
        background-color: #000;
      }
    }
  }

  &__text {
    font-family: youandiR;
    font-size: 21px;
    color: #fff;
  }

  &__highlight {
    display: block;
    font-family: youandiB;
    font-size: 25px;
  }

  &__foot {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 74px;
  }

  &__number {
    font-size: 25px;
    font-family: youandiB;
    line-height: 74px;
  }

  &__anchor {
    display: inline-block;
    margin-top: 17px;
    padding: 0 20px;
    border-radius: 5px;
    background-color: #fff;
    font-size: 15px;
    font-family: youandiB;
    line-height: 40px;
  }

  .c-text-list--star {
    margin-top: 32px;
  }

  .c-text-list__item {
    display: inline-block;
    font-size: 15px;

    & + .c-text-list__item {
      margin-top: 7px;
    }
  }
}

.hcoin-use {
  margin: 36px 0 100px;

  &__list {
    display: flex;
  }

  &__item {
    overflow: hidden;
    flex: 1;
    position: relative;
    height: 180px;
    padding: 48px 0 0 268px;
    border: 1px solid #e8e8e8;
    border-radius: 5px;
    background-color: #f8f8f8;
    font-size: 0;

    &:nth-child(n + 2) {
      margin-left: 18px;
    }
  }

  &__logo {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 268px;
    height: 100%;
    background-color: #fff;

    img {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }

  &__title {
    display: block;
    margin-bottom: 16px;
    font-size: 17px;
    font-family: youandiR;
  }

  &__anchor {
    display: inline-block;
    padding: 0 20px;
    border: 1px solid #b8b8b8;
    border-radius: 5px;
    background-color: #fff;
    font-size: 15px;
    font-family: youandiR;
    line-height: 38px;

    &:nth-child(n + 2) {
      margin-left: 4px;
    }
  }

  &__content {
    display: inline-block;
    margin-top: 30px;
    padding: 0 10px;
    border-radius: 20px;
    background-color: #f8f8f8;
    font-size: 0;
  }

  &__text {
    display: inline-block;
    padding: 0 20px;
    font-size: 15px;
    color: #666;
    line-height: 40px;

    &:nth-child(n + 2) {
      position: relative;

      &::before {
        display: block;
        position: absolute;
        top: 50%;
        left: 0;
        transform: translateY(-50%);
        width: 1px;
        height: 12px;
        background-color: #d2d2d2;
        content: '';
      }
    }
  }
}

// H-Coin 사용
.hcoin-info-wrap {
  position: relative;
  padding: 45px 50px;
  border-radius: 10px;
  background-color: #272a44;

  &__title {
    display: block;
    font-size: 23px;
    font-family: youandiR;
    color: #fff;
  }

  &__text {
    margin-top: 3px;
    font-size: 17px;
    font-family: youandiR;
    color: #fff;
  }

  &__number {
    position: absolute;
    top: 50%;
    right: 50px;
    transform: translateY(-50%);
    font-size: 34px;
    font-family: youandiB;
    color: #ff8300;
  }
}

.hcoin-complete {
  height: 487px;
  padding-top: 223px;
  border-radius: 10px;
  background: #272a44 url('../img/com/bg_hcoin_complete.png') no-repeat 50% 80px;
  text-align: center;

  &__heading {
    font-size: 30px;
    font-family: youandiB;
    color: #ff8300;
  }

  &__text {
    margin: 11px 0 56px;
    font-size: 15px;
    color: #fff;
  }

  &__anchor {
    background-color: #ff8300;
    color: #fff;
  }
}

// H-Coin 가입하기
.hcoin-join-wrap {
  position: relative;
  padding: 34px 0 35px;
  text-align: center;

  &::before {
    display: block;
    position: absolute;
    top: 0;
    left: 50%;
    z-index: -1;
    transform: translateX(-50%);
    min-width: 1280px;
    width: 100vw;
    height: 100%;
    background-color: #272a44;
    content: '';
  }

  &__title {
    display: block;
    font-size: 23px;
    font-family: youandiR;
    color: #ff8300;
  }

  &__text {
    margin-top: 9px;
    font-size: 17px;
    color: #fff;
  }

  &__description {
    margin-top: 19px;
    font-size: 15px;
    color: #fff;
    line-height: 24px;
  }
}

// 이벤트
.event-list {
  display: flex;
  flex-wrap: wrap;
  font-size: 0;

  &__item {
    width: 340px;
    margin-left: 80px;
    vertical-align: top;

    &:nth-child(3n-2) {
      margin-left: 0;
    }

    &:nth-child(n + 4) {
      margin-top: 56px;
    }
  }

  &__inside {
    position: relative;
  }

  &__image {
    display: block;

    img {
      width: 100%;
      border-radius: 12px;
    }
  }

  &__anchor {
    display: block;
    margin-top: 24px;
    font-size: 23px;
    font-family: youandiR;
    line-height: 34px;

    &::after {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      content: '';
    }
  }

  &__text {
    margin-top: 8px;
    font-size: 15px;
    color: #666;
  }

  &__date {
    display: block;
    margin-top: 20px;
    font-size: 13px;
  }
}

/* ---------------------------------------------------------------------------------------------------------------------
로그인
--------------------------------------------------------------------------------------------------------------------- */
.owner-agree {
  margin-top: 60px;
  padding: 28px 0;
  background-color: #f8f8f8;
  text-align: center;

  &__title {
    display: inline-block;
    font-size: 17px;
  }

  &__radio {
    display: inline-block;
    margin-left: 15px;
  }
}

/* ---------------------------------------------------------------------------------------------------------------------
고객안내
--------------------------------------------------------------------------------------------------------------------- */
// 보안카드 분실신고
.security-card {
  margin-top: -10px;
  padding: 60px 0 35px;
  border-top: 1px solid #e8e8e8;
  text-align: center;

  &__title {
    font-family: youandiB;
    font-size: 34px;
    color: $blue;
  }

  &__text {
    margin-top: 13px;
    font-size: 19px;
  }
}

// 지점안내
.service-hours {
  display: flex;

  &__item {
    flex: 1;
    overflow: hidden;
    border: 1px solid #d8d8d8;
    border-radius: 14px;
    text-align: center;

    &:nth-child(n + 2) {
      margin-left: 18px;
    }
  }

  &__label {
    display: inline-block;
    margin-right: 16px;
    padding: 0 7px;
    border-radius: 6px;
    background-color: $blue;
    font-family: youandiR;
    font-size: 15px;
    line-height: 26px;
    color: #fff;
  }

  &__text {
    font-family: youandiB;
    font-size: 32px;
    line-height: 110px;
    color: $blue;
    vertical-align: middle;
  }

  &__panel {
    padding: 24px 0;
    background-color: #f8f8f8;
  }

  .c-text-list__item {
    display: inline-block;

    &:nth-child(n + 2) {
      margin-top: 0;
      margin-left: 40px;
    }
  }
}

// 고객상담
.customer-counseling {
  $root: '.customer-counseling';

  display: flex;
  margin-top: 25px;

  &__item {
    flex: 1;

    &:nth-child(n + 2) {
      margin-left: 20px;
    }
  }

  #{$root}__text {
    margin-top: 10px;
    font-size: 15px;
    color: #666;
  }

  &--type1 {
    #{$root}__item {
      position: relative;
      padding: 33px 30px 37px 120px;
      border: 1px solid #d8d8d8;
      border-radius: 6px;
    }

    #{$root}__image {
      position: absolute;
      top: 50%;
      left: 45px;
      transform: translateY(-50%);
    }

    #{$root}__title {
      font-family: youandiR;
      font-size: 19px;
    }

    #{$root}__anchor {
      display: block;

      &::before {
        display: block;
        position: absolute;
        top: 50%;
        right: 42px;
        width: 0;
        padding: 4px;
        border: solid #666;
        border-width: 0 2px 2px 0;
        transform: translateY(-50%) rotate(-45deg);
        content: '';
      }

      &::after {
        position: absolute;
        top: 0;
        left: 0;
        display: block;
        width: 100%;
        height: 100%;
        content: '';
      }
    }

    #{$root}__text {
      margin-top: 11px;
    }

    .o-button.link {
      margin-top: 13px;
    }
  }

  &--type2 {
    #{$root}__item {
      padding: 36px 40px;
      border-radius: 6px;
      border-bottom-right-radius: 30px;
      background-color: #f8f8f8;
    }

    #{$root}__title {
      font-family: youandiR;
      font-size: 19px;
      line-height: 28px;
    }

    #{$root}__number {
      display: block;
      font-family: youandiR;
      font-size: 19px;
      line-height: 28px;
      color: $blue;
    }

    #{$root}__text {
      .fw-bold {
        color: #000;
      }
    }

    .o-button.link {
      margin-top: 10px;
    }
  }
}

// modal : 고객상담 > ARS 상담안내
.ars-tab {
  $root: '.ars-tab';

  margin-top: 24px;

  &__list {
    display: flex;
    position: relative;
    font-size: 0;
    border: 1px solid #d8d8d8;
    border-radius: 6px;
  }

  &__button {
    flex: 1;
    display: block;
    padding: 17px 0;
    background-color: #fff;
    font-size: 15px;
    font-family: youandiR;
    color: #666;
    text-align: center;

    &:nth-child(n + 2) {
      position: relative;

      &::before {
        position: absolute;
        top: 50%;
        left: 0;
        width: 1px;
        height: 52px;
        background-color: #d8d8d8;
        transform: translateY(-50%);
        content: '';
      }
    }

    &:first-child {
      border-radius: 6px 0 0 6px;
    }

    &:last-child {
      border-radius: 0 6px 6px 0;
    }

    &.is-active {
      position: relative;
      z-index: 1;
      margin: -1px;
      border: 1px solid #0076c0;
      border-radius: 6px;
      color: $blue;

      #{$root}__number {
        color: $blue;
      }
    }
  }

  &__number {
    display: block;
    margin-bottom: 8px;
    font-size: 23px;
    font-family: youandiB;
    color: #666;
  }
}

.ars-tab-content {
  margin-top: 20px;
  padding: 36px 0;
  border-radius: 6px;
  background-color: #f8f8f8;
  text-align: center;

  &__title {
    display: block;
    margin-bottom: 8px;
    font-family: youandiR;
    font-size: 19px;
  }

  &__text {
    font-size: 17px;
  }

  &__list {
    font-size: 0;
    text-align: left;
  }

  &__item {
    display: inline-block;
    position: relative;
    width: 33.3%;
    padding-left: 78px;
    vertical-align: top;
    font-size: 17px;

    &:nth-child(n + 4) {
      margin-top: 20px;
    }
  }

  &__number {
    position: absolute;
    top: 0;
    left: 40px;
    color: $blue;
  }

  .c-text-list {
    margin-top: 7px;

    .c-text-list__item:nth-child(n + 2) {
      margin-top: 4px;
    }
  }
}

/* ---------------------------------------------------------------------------------------------------------------------
퇴직연금
--------------------------------------------------------------------------------------------------------------------- */
// 퇴직연금 subMain
.irp-main {
  &__button {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    display: block;
    margin: -27px auto 0;
    width: 220px;
    line-height: 54px;
    border-radius: 5px;
    background-color: #00a19c;
    font-family: youandiB;
    font-size: 19px;
    color: #fff;
  }
}

.irp-main-head {
  position: relative;
  padding: 81px 70px 70px;

  &::before {
    display: block;
    position: absolute;
    top: 0;
    left: 50%;
    z-index: -1;
    transform: translateX(-50%);
    min-width: 1280px;
    width: 100vw;
    height: 100%;
    background: linear-gradient(90deg, $blue 0, #00a19c 100%);
    content: '';
  }

  &__title {
    font-family: youandiB;
    font-size: 34px;
    color: #fff;
    text-align: center;
  }

  &__text {
    margin-top: 23px;
    font-size: 19px;
    font-family: youandiR;
    color: #fff;
    text-align: center;
  }
}

.irp-main-banner {
  $root: '.irp-main-banner';

  display: flex;
  margin-top: 38px;

  &__anchor {
    position: relative;
    flex: 1;
    padding: 36px 140px 37px 40px;
    height: 160px;
    background-color: #fff;
    border-radius: 10px;

    &:nth-child(n + 2) {
      margin-left: 10px;
    }

    &:hover,
    &:focus {
      #{$root}__title::before {
        content: '';
        position: absolute;
        bottom: 0;
        left: -100%;
        z-index: -1;
        width: 100%;
        height: 8px;
        background-color: #f5f17c;
        animation-fill-mode: forwards;
        animation-name: text-underline;
        animation-duration: 0.7s;
      }

      @keyframes text-underline {
        from {
          left: -100%;
        }

        to {
          left: 0;
        }
      }
    }
  }

  &__title {
    position: relative;
    overflow: hidden;
    z-index: 1;
    display: inline-block;
    font-size: 15px;
    font-family: youandiR;
    line-height: 1.3;
    white-space: nowrap;
  }

  &__text {
    margin-top: 17px;
    font-family: youandiR;
    font-size: 17px;
    line-height: 24px;
  }

  &__image {
    position: absolute;
    right: 40px;
    top: 50%;
    transform: translateY(-50%);
    width: 80px;
    height: 80px;
  }
}

.irp-main-list {
  display: flex;
  flex-wrap: wrap;
  margin-top: 70px;
  font-size: 0;

  $root: ".irp-main-list";

  &__item {
    overflow: hidden;
    display: inline-block;
    width: 380px;
    margin-left: 20px;
    border: 1px solid #e8e8e8;
    border-radius: 5px;
    overflow: hidden;

    &:nth-child(3n + 1) {
      margin-left: 0;
    }

    &:nth-child(n + 4) {
      margin-top: 30px;
    }

    &:last-child {
      #{$root}__content {
        padding: 0 30px;
        background: #004b75;
        text-align: center;
      }

      #{$root}__title {
        font-size: 15px;
        color: #fff;
      }

      #{$root}__text {
        font-size: 25px;
        font-family: youandiB;
        color: #fff;
      }

      #{$root}__foot {
        padding: 0;
        text-align: center;
      }

      #{$root}__anchor {
        img {
          vertical-align: middle;
        }

        ~#{$root}__anchor {
          margin-left: 30px;
        }
      }
    }
  }

  &__content {
    position: relative;
    height: 122px;
    padding: 0 100px 0 30px;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  &__title {
    display: block;
    font-family: youandiR;
    font-size: 19px;
  }

  &__text {
    margin-top: 4px;
    font-size: 14px;
    color: #666;
  }

  &__image {
    position: absolute;
    top: 50%;
    right: 30px;
    transform: translateY(-50%);
    width: 50px;
    height: 50px;

    img {
      width: 100%;
    }
  }

  &__foot {
    height: 69px;
    padding-left: 30px;
    background-color: #f8f8f8;
  }

  &__anchor {
    display: inline-block;
    font-size: 15px;
    line-height: 69px;

    &~& {
      margin-left: 40px;
    }

    .o-icon--right-arrow {
      &::before,
      &::after {
        background-color: #000;
      }
    }

    &:hover,
    &:focus {
      color: $blue;
      text-decoration: underline;

      .o-icon--right-arrow {
        &::before,
        &::after {
          background-color: $blue;
        }
      }
    }

    .o-icon--right-arrow {
      margin-top: -2px;
    }
  }
}

// 푸본현대생명 퇴직연금, 나에게 맞는 퇴직연금
.irp {
  $root: '.irp';

  &__half {
    display: flex;

    #{$root}__item {
      flex: 1;

      &:nth-child(1) {
        margin-right: 40px;
      }
    }
  }

  &__item {
    margin-top: 94px;
  }

  &__title {
    font-family: youandiR;
    font-size: 23px;
  }

  &__point {
    display: block;
    margin-bottom: 5px;
    font-family: youandiB;
    font-size: 18px;
    color: $blue;
  }

  &__text {
    margin-top: 17px;
    font-size: 17px;
    color: #666;
  }
}

// 푸본현대생명 퇴직연금
.irp-why {
  padding: 53px 0 55px;
  border-radius: 5px;
  background: linear-gradient(90deg, $blue 0, #00a19c 100%);
  text-align: center;
  box-shadow: 5px 10px 20px rgba(38, 91, 118, 0.15);

  &__title {
    display: block;
    font-size: 24px;
    font-family: youandiR;
    color: #fff;
  }

  &__text {
    margin-top: 25px;
    font-size: 17px;
    line-height: 28px;
    color: #fff;
  }
}

.irp-partner {
  display: table;
  table-layout: fixed;
  width: 100%;
  margin-top: 33px;
  border: 1px solid #e8e8e8;
  border-radius: 5px;
  background: url('../img/com/bg_irp_partner.png') no-repeat 50% 50%;

  &__item {
    display: table-cell;
    padding: 47px 0 49px;
    text-align: center;

    &:nth-child(1) {
      padding-right: 19px;
    }

    &:nth-child(2) {
      padding-left: 19px;
    }
  }

  &__image {
    display: inline-block;
    vertical-align: middle;
  }

  &__content {
    display: inline-block;
    margin-left: 40px;
    vertical-align: middle;
  }

  &__title {
    display: block;
    font-size: 16px;
  }

  &__text {
    margin-top: 6px;
    font-size: 15px;
    line-height: 24px;
    color: #666;
  }
}

.irp-performance {
  overflow: hidden;
  margin-top: 33px;
  border: 1px solid #e8e8e8;
  border-radius: 5px;
  text-align: center;

  &__summary {
    display: flex;
    background-color: #f8f8f8;
  }

  &__item {
    flex: 1;
    padding: 34px 0 36px;

    &:nth-child(2) {
      position: relative;

      &::before {
        display: block;
        position: absolute;
        top: 50%;
        left: 0;
        transform: translateY(-50%);
        width: 1px;
        height: 54px;
        background-color: $blue;
        content: '';
      }
    }
  }

  &__title {
    display: block;
    font-size: 17px;
  }

  &__highlight {
    display: block;
    font-size: 25px;
    font-family: youandiB;
    color: $blue;
  }

  &__text {
    display: block;
    margin-top: 2px;
    font-size: 14px;
    color: #666;
  }

  &__image {
    padding: 40px 0;
  }
}

.irp-consulting {
  position: relative;
  margin-top: 73px;

  &__image {
    position: absolute;
    bottom: 0;
    right: 80px;
  }

  &__item {
    position: relative;
    padding-left: 34px;

    &::before {
      position: absolute;
      top: 50%;
      left: 0;
      transform: translateY(-50%);
      width: 2px;
      height: 53px;
      background-color: $blue;
      content: '';
    }

    &:nth-child(n + 2) {
      margin-top: 40px;
    }
  }

  &__title {
    display: block;
    font-family: youandiR;
    font-size: 21px;
  }

  &__text {
    margin-top: 12px;
    font-family: youandiR;
    font-size: 14px;
    color: #666;
  }
}

// 나에게 맞는 퇴직연금
.irp-versus {
  position: relative;
  padding: 35px 0 30px;
  border-radius: 5px;
  background: linear-gradient(90deg, $blue 0, #00a19c 100%);
  text-align: center;
  box-shadow: 5px 10px 20px rgba(38, 91, 118, 0.15);

  &__title {
    display: block;
    padding-top: 38px;
    font-size: 18px;
    font-family: youandiR;
    color: #fff;
    line-height: 24px;
  }

  &__vs {
    display: block;
    position: absolute;
    top: 35px;
    left: 50%;
    transform: translateX(-50%);
    width: 54px;
    height: 29px;
    background: url('../img/com/bg_irp_versus.png') no-repeat 0 0;
  }

  &__text {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 50%;
    font-size: 23px;
    font-family: youandiR;
    color: #fff;

    &--db {
      left: 0;
    }

    &--dc {
      right: 0;
    }
  }
}

.irp-system {
  display: flex;
  margin-top: 80px;
  padding: 50px;
  border: 10px solid #e5f1f9;
  border-radius: 5px;

  &__item {
    flex: 1;

    &:nth-child(1) {
      .irp-graph__bar--gradient {
        text-align: left;
      }
    }

    &:nth-child(2) {
      margin-left: 60px;
      border-left: 2px solid #f4f4f4;
      padding-left: 60px;
    }
  }

  &__title {
    margin-top: -6px;
    font-size: 23px;
    font-family: youandiR;
  }

  &__text {
    height: 112px;
    margin-top: 35px;
    font-size: 15px;
    line-height: 28px;
    letter-spacing: -0.2px;
  }

  &__sum {
    margin-top: 23px;
    border-radius: 7px;
    background-color: #edf5fb;
    font-size: 18px;
    line-height: 60px;
    text-align: center;
  }

  &__example {
    margin: 24px 0 -4px;
    font-size: 16px;
    color: #666;
  }
}

.irp-graph {
  margin-top: 40px;
  padding-top: 40px;
  border-top: 1px solid #e8e8e8;

  &__item {
    display: flex;

    &:nth-child(n + 2) {
      margin-top: 8px;
    }
  }

  &__bar {
    flex: 1;
    padding: 0 23px;
    border-radius: 6px;
    background-color: #d9f1f0;
    font-size: 16px;
    line-height: 57px;
    color: #007688;

    & + & {
      margin-left: 10px;
    }

    &--fixed {
      flex-grow: 0;
      flex-basis: auto;
    }

    &--blue {
      background-color: #cce4f2;
      color: #004b75;
    }

    &--gradient {
      background: linear-gradient(90deg, $blue 0%, #00a19c 100%);
      color: $white;
      text-align: center;
    }
  }
}

.irp-compare {
  $root: '.irp-compare';

  margin-top: 26px;

  &__list {
    display: flex;
    padding: 45px 20px 47px;
    border: 10px solid #e5f1f9;
    border-radius: 5px;
    background: url('../img/com/bg_irp_compare.png') no-repeat 50% 50%;
    text-align: center;
  }

  &__item {
    flex: 1;

    &--db {
      #{$root}__type,
      #{$root}__title {
        color: $blue;
      }
    }

    &--dc {
      #{$root}__type,
      #{$root}__title {
        color: #00a19c;
      }
    }
  }

  &__type {
    display: block;
    font-family: youandiR;
    font-size: 18px;
  }

  &__title {
    display: block;
    margin-top: 6px;
    font-family: youandiB;
    font-size: 25px;
  }

  &__text {
    margin-top: 8px;
    font-size: 15px;
    color: #666;
  }

  .c-text-list__item {
    margin-top: 11px;
    font-size: 14px;
    color: #666;

    &::before {
      top: 13px;
    }

    & + .c-text-list__item {
      margin-top: 0;
    }
  }
}

.irp-product {
  $root: '.irp-product';

  display: flex;
  margin-top: 26px;

  &__item {
    position: relative;
    flex: 1;
    padding: 46px 180px 46px 150px;
    border: 1px solid #e8e8e8;
    border-radius: 5px;

    &:nth-child(2) {
      margin-left: 20px;
    }

    &--type1 {
      #{$root}__highlight {
        color: $blue;
      }
    }

    &--type2 {
      #{$root}__highlight {
        color: #17a6a2;
      }
    }
  }

  &__image {
    position: absolute;
    top: 50%;
    left: 40px;
    transform: translateY(-50%);
  }

  &__text {
    font-size: 15px;
    line-height: 28px;
    color: #666;
  }

  &__highlight {
    display: block;
    font-size: 19px;
  }

  &__button {
    display: block;
    position: absolute;
    top: 50%;
    right: 40px;
    transform: translateY(-50%);
    height: 30px;
    padding: 0 10px;
    border: 1px solid #d8d8d8;
    border-radius: 5px;
    font-size: 14px;
    color: #333;
  }
}

// modal : 나에게 맞는 퇴직연금 > 상품
.modal-irp-product {
  .o-heading {
    margin-top: 0;
  }

  &__sub-text {
    font-size: 17px;
    color: #666;
  }

  &__text {
    margin-bottom: 26px;
    font-size: 17px;
    letter-spacing: -0.2px;
  }

  &__item {
    margin-top: 20px;
    padding: 36px 40px 35px;
    border-radius: 10px;
    background-color: #f8f8f8;
  }
}

// 퇴직연금 자료실 > 정보
.board-download {
  padding: 0 20px;

  &__item {
    position: relative;
    border-top: 1px solid #e8e8e8;
  }

  &__text {
    font-size: 15px;
    font-family: youandiR;
    line-height: 60px;
  }

  .o-button {
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
  }
}

// 퇴직연금 자료실 > 용어사전
.glossary {
  overflow: hidden;
  margin-bottom: 90px;
  border: 1px solid #d8d8d8;
  border-left: 0;
  border-radius: 5px;
  font-size: 0;

  &__item {
    display: inline-block;
    width: 12.5%;
    border-left: 1px solid #d8d8d8;
    vertical-align: top;

    &:nth-child(n + 9) {
      border-top: 1px solid #d8d8d8;
    }

    &--last {
      width: 25%;
    }
  }

  &__button {
    display: block;
    width: 100%;
    font-size: 15px;
    font-family: youandiR;
    color: #666;
    text-align: center;
    line-height: 46px;
    cursor: pointer;

    &.is-active {
      background-color: $blue;
      color: #fff;
    }
  }
}

// 개인형퇴직연금(IRP) > IRP란?
.irp-description {
  padding: 54px 20px 20px;
  border-radius: 10px;
  background: linear-gradient(90deg, $blue 0, #00a19c 100%);
  text-align: center;
  box-shadow: 5px 20px 30px rgba(38, 91, 118, 0.15);

  &__title {
    font-size: 21px;
    font-family: youandiR;
    color: #fff;
  }

  &__text {
    margin-top: 18px;
    font-size: 17px;
    line-height: 28px;
    color: #fff;
  }

  &__image {
    margin-top: 45px;
    padding: 50px 0 40px;
    border-radius: 5px;
    background-color: #fff;
  }

  &__strength {
    display: flex;
    justify-content: space-between;
    margin-top: 95px;
    padding: 30px 60px;
    background: #ebf4fa;
    border-radius: 8px;

    dl {
      dt {
        margin-top: 45px;
        font-family: youandiB;
      }

      dd {
        font-size: 17px;
        color: #666;
      }
    }

    .irp-description__strength--video {
      position: relative;
      width: 464px;
      height: 260px;

      .irp-description__strength--video-poster {
        display: block;
        position: absolute;
        width: 100%;
        height: 100%;
        z-index: 10;

        img {
          width: 100%;
          height: 100%;
        }
      }

      iframe,
      .youtube {
        position: absolute;
        width: 100%;
        height: 100%;
      }
    }
  }
}

.irp-benefit {
  display: flex;

  &__item {
    flex: 1;
    padding: 35px 20px 35px 40px;
    border: 1px solid #e8e8e8;
    border-radius: 5px;

    &:nth-child(n + 2) {
      margin-left: 20px;
    }
  }

  &__title {
    font-size: 17px;
    font-family: youandiR;
  }

  &__text {
    margin-top: 20px;
    font-size: 14px;
    line-height: 24px;
    color: #666;
  }
}

.irp-example {
  overflow: hidden;
  margin: 30px 0 12px;
  padding-top: 55px;
  border: 1px solid #e8e8e8;
  border-radius: 5px;
  background-color: #f8f8f8;
  text-align: center;

  &__title {
    font-size: 17px;
    font-family: youandiR;
  }

  &__image {
    margin-top: 55px;
  }

  &__text {
    margin-top: 70px;
    background-color: #fff;
    font-size: 15px;
    color: #666;
    line-height: 60px;
  }

  &__highlight {
    position: relative;
    overflow: hidden;
    z-index: 1;
    display: inline-block;
    font-family: youandiB;
    line-height: 1.5;
    vertical-align: text-top;

    &::before {
      content: '';
      position: absolute;
      bottom: 0;
      left: -100%;
      z-index: -1;
      width: 100%;
      height: 8px;
      background-color: #f5f17c;
      animation-fill-mode: forwards;
      animation-name: text-underline;
      animation-duration: .7s;
    }
  }
}

// 200805 추가 - 퇴직연금 > Sub Main > 퇴직연금 서비스 > 제도도입가이드
.irp-main-head__text2 {
  margin-top: 28px;
  font-size: 17px;
  color: #000;
}

.irp__jaedo {
  margin-top: 65px;
}

.irp__jaedo--content {
  display: flex;
  border: 1px solid #eaedf4;
  border-radius: 5px;

  & + .irp__jaedo--content {
    margin-top: 20px;
  }

  .irp__jaedo-title {
    position: relative;
    flex-basis: 236px;
    border-right: 1px solid #eaedf4;
    text-align: center;
    font-size: 21px;
    background: #f9fafc;

    span {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      min-width: 150px;

      &::before {
        content: '';
        display: block;
        margin: 0 auto 38px;
        background: url('../img/content/IRP040100000000.png') no-repeat 0 0;
      }
    }

    &.irp__jaedo-title__icon1 span::before {
      width: 49px;
      height: 50px;
      background-position: 0 0;
    }

    &.irp__jaedo-title__icon2 span::before {
      width: 41px;
      height: 47px;
      background-position: -50px 0;
    }

    &.irp__jaedo-title__icon3 span::before {
      width: 40px;
      height: 45px;
      background-position: -91px 0;
    }

    &.irp__jaedo-title__icon4 span::before {
      width: 45px;
      height: 45px;
      background-position: -131px 0;
    }
  }

  .irp__jaedo--inner {
    width: 942px;
    padding: 58px;

    dl {
      & + dl {
        margin-top: 55px;
      }

      dt {
        padding-bottom: 22px;
        font-size: 19px;
        font-weight: 600;
      }
    }
  }
}

.irp__item--jaedo {
  margin: 60px -60px 0;
  padding: 35px 60px;
  background: #f8f8f8;
  border-radius: 5px;

  .c-text-list__item {
    font-size: 14px;
  }
}
