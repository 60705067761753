.c-tooltip {
  $root : ".c-tooltip";

  display: inline-block;
  position: relative;
  margin-left: 10px;
  vertical-align: middle;
  height: 20px;

  &__button {
    position: relative;
    display: inline-block;
    width: 20px;
    height: 20px;
    background: url("../img/com/ico_tooltip.png") no-repeat 0 0;
    vertical-align: top;

    &.is-active {
      background-position-x: -20px;
    }
  }

  &__panel {
    display: none;
    position: absolute;
    z-index: 100;
    width: auto;
    padding: 20px;
    border: 1px solid #0076c0;
    border-radius: 4px;
    background-color: #fff;
    text-align: left;
    font-size: 15px;
    color: #000;
    white-space: nowrap;
    opacity: 0;
    transition: opacity .15s linear;

    .arrow {
      position: absolute;
      z-index: 101;
      width: 12px;
      height: 12px;
      border: 1px solid #0076c0;
      background-color: #fff;
      border-top: 0;
      border-right: 0;
    }

    &.top {
      bottom: 35px;
      left: 50%;
      transform: translateX(-50%);

      .arrow {
        bottom: -7px;
        left: 50%;
        margin-left: -7px;
        transform: rotate(-45deg);
      }
    }

    &.bottom {
      top: 35px;
      left: 50%;
      transform: translateX(-50%);

      .arrow {
        top: -7px;
        left: 50%;
        margin-left: -7px;
        transform: rotate(135deg);
      }
    }

    &.top,
    &.bottom {

      &.right {
        left: 0;
        transform: translateX(-40px);

        .arrow {
          left: 49px;
        }
      }

      &.left {
        right: 100%;
        left: inherit;
        transform: translateX(60px);

        .arrow {
          right: 44px !important;
          left: inherit;
        }
      }
    }

    &.right:not(.top):not(.bottom) {
      left: 100%;
      margin-top: -11px;
      transform: translate(15px, -50%);

      .arrow {
        top: 50%;
        left: 0;
        margin-top: -7px;
        transform: translateX(-7px) rotate(45deg);
      }
    }

    &.left:not(.top):not(.bottom) {
      right: 100%;
      margin-top: -11px;
      transform: translate(-15px, -50%);

      .arrow {
        top: 50%;
        right: 0;
        margin-top: -7px;
        transform: translateX(7px) rotate(-135deg);
      }
    }
  }

  &__button.is-active + &__panel {
    display: block;
    opacity: 1;
  }

  .o-heading {
    display: inline-block;
    margin-left: -10px;

    &+#{$root}__button {
      margin: -4px 0 0 5px;
    }
  }

  & + .c-form {
    margin-top: 0;
  }

  &--ellipsis {

    #{$root}__button {
      width: auto;
      height: 34px;
      padding-left: 15px;
      padding-right: 40px;
      border-radius: 5px;
      border: 1px solid #d8d8d8;
      background: url("../img/com/ico_tooltip_ellipsis.gif") no-repeat 100% 0/40px auto;

      &.is-active {
        background-position-y: -32px;
        color: $blue;
      }

      &:disabled {
        background-color: #f8f8f8;
        background-position-y: -64px;
        color: #c8c8c8;
      }
    }
  }
}
