.product-wrap {
  $root: '.product-wrap';

  margin-top: 30px;

  &--bottom {
    margin-bottom: -100px;
  }

  &.bg-full {
    margin-top: 0;
    padding: 80px 0;
  }

  &.bg-gradient {
    #{$root}__check {
      margin-bottom: 26px;

      .o-input--check + label,
      .o-input--check:checked + label {
        font-family: youandiR;
        font-size: 23px;
        color: #fff;
      }
    }

    #{$root}__title {
      margin-bottom: 25px;
      line-height: 30px;
      font-family: youandiR;
      font-size: 23px;
      color: #fff;

      ~ #{$root}__title {
        margin-top: 60px;
      }

      +.heading-right {
        color: #fff;
        margin-bottom: 25px;
        line-height: 30px;
      }
    }

    .o-button.more {
      width: 300px;
      border-color: #fff;
      background-color: transparent;
      color: #fff;
    }
  }

  .page-head + & {
    margin-top: -70px;
  }

  .empty {
    margin: 0;
    padding: 0;
    border: none;

    .empty__text {
      color: $white;
    }
  }

  .input-wrap {
    position: relative;
    min-height: 109px;
    padding: 30px 40px;
    border-radius: 15px;
    background-color: $white;
    font-size: 0;

    &::before {
      content: "";
      min-height: 48px;
      font-size: 0;
    }

    ~.input-wrap {
      margin-top: 10px;
    }

    >.input-text:first-child {
      display: inline-block;
      width: 50%;

      +.button-box {
        margin-left: 10px;
      }
    }

    .button-box {
      display: inline-block;

      .o-button {
        min-width: 67px;
        padding: 0 12px;
        font-family: youandiB;
        font-size: 15px;
      }
    }

    > .o-button {
      position: absolute;
      top: 50%;
      right: 40px;
      transform: translateY(-50%);
      padding: 0 30px !important;
      height: 48px !important;
      line-height: 46px !important;
      border: 1px solid #3f3f3f;
      background-color: #3f3f3f;
      font-family: youandiB !important;
      font-size: 17px !important;
      color: $white;
    }

    +.product-card,
    + .o-heading {
      position: relative;
      margin-top: 60px;

      &::after {
        content:"";
        position: absolute;
        top: -30px;
        left: 0;
        right: 0;
        width: 100%;
        height: 1px;
        background: rgba(225,225,225,.3);
      }
    }
  }
}

.o-heading + .product-wrap,
.c-form--filter + .product-wrap {
  padding: 0;
}

.product-card {
  $root: '.product-card';
  $type2: '.product-card--type2';
  $type3: '.product-card--type3';
  position: relative;
  display: table;
  table-layout: fixed;
  width: 100%;
  min-height: 158px;
  padding: 35px 40px 35px 50px;
  border-radius: 15px;
  border-bottom-right-radius: 40px;
  background-color: #fff;

  & + & {
    margin-top: 10px;
  }

  &.bo {
    border-color: #d8d8d8 !important;
  }

  // ie flex min-height bug
  &::before {
    content: '';
    min-height: 88px;
    font-size: 0;
  }

  &__input {
    position: absolute;
    top: 0;
    left: 0;
    width: 70px;
    height: 100%;
    background-color: #f8f8f8;
    border-top-left-radius: 15px;
    border-bottom-left-radius: 15px;

    input,
    label {
      position: absolute;
      top: 50%;
      left: 50%;
      padding-left: 30px;
      transform: translate(-50%, -50%);
    }

    ~ * {
      margin-left: 55px;
    }
  }

  &__head {
    display: table-cell;
    width: 50%;
    vertical-align: middle;

    #{$root}__button {
      display: inline-block;
      margin-bottom: 0;
      margin-left: 17px;
      vertical-align: middle;

      ~ #{$root}__button {
        margin-left: 7px;
      }
    }
  }

  &__panel {
    display: table-cell;
    width: 50%;
    vertical-align: middle;

    &--type2 {
      .inside-list__item {
        width: calc(100% - 188px) !important;
        padding-left: 0 !important;
        padding-right: 0 !important;
      }

      #{$root}__button {
        position: absolute;
        top: 50%;
        right: 40px;
        transform: translateY(-50%);
        width: 109px;
        height: 80px;
        padding: 0 20px;
        border-radius: 6px;
        font-size: 15px;
        font-family: youandiR;
      }
    }
  }

  &__row {
    display: flex;
    margin-top: 25px;

    .input-text {
      flex: 1;
    }

    .c-radio-box {
      margin-right: 10px;
    }

    .listbox {
      width: 50%;
      margin-right: 10px;
    }
  }

  &__foot {
    width: 100%;
    padding-left: 50%;
  }

  &__label {
    display: inline-block;
    margin-bottom: 18px;
    padding: 0 12px;
    border: 1px solid #d8d8d8;
    border-radius: 15px;
    background-color: #f8f8f8;
    font-size: 14px;
    color: #666;
    text-align: center;
    line-height: 22px;

    &--blue {
      background-color: #fff;
      color: #0076c0;
    }
  }

  &__number {
    display: block;
    margin-bottom: 7px;
    color: #666;
    font-family: youandiR;
    line-height: 1;

    #{$type2} & {
      &:first-child {
        margin-bottom: 13px;
        font-size: 15px;
        font-family: youandiB;
      }

      &:last-child {
        display: inline-block;
        margin-bottom: 0;
        margin-left: 23px;
        font-size: 23px;
        line-height: 1;
        color: $blue;
        vertical-align: middle;
      }
    }

    #{$type3} & {
      display: inline-block;
      color: #fff;
    }
  }

  &__title {
    color: #000;
    font-size: 20px;
    font-family: youandiR;
    line-height: 28px;

    #{$type2} & {
      display: inline-block;
      font-size: 18px;
    }

    #{$type3} & {
      display: inline-block;
      font-size: 18px;

      #{$root}__button {
        color: #fff;
      }
    }
  }

  &__anchor {
    color: $blue;

    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }

  &--disabled {
    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: #fff;
      border-radius: 15px;
      border-bottom-right-radius: 40px;
      opacity: 0.5;
    }

    #{$root}__head::before {
      content: '선택불가';
      overflow: hidden;
      display: inline-block;
      height: 24px;
      margin-bottom: 18px;
      padding-left: 12px;
      padding-right: 12px;
      border-radius: 12px;
      background-color: #666;
      line-height: 24px;
      font-size: 13px;
      color: #fff;
      vertical-align: top;
    }

    a {
      color: #666;
      cursor: default;
    }
  }

  // product-card--type2
  &--type2 {
    min-height: 100px;
    border: 1px solid #d8d8d8;
    border-radius: 7px;

    // ie min-height align-items center
    &::before {
      content: '';
      min-height: 52px;
      font-size: 0;
    }

    #{$root}__input {
      border-radius: 7px 0 0 7px;
      top: 1px;
      left: 1px;
      height: calc(100% - 2px);
    }
  }

  a.product-card__button {
    display: table;

    span {
      display: table-cell;
      vertical-align: middle;
    }
  }

  // etc
  .c-radio-box {
    margin-top: 0;
  }
}

/* 신규계좌등록/출금이체동의 신청 및 철회 결과 */
.c-modal--lg {
  .product-card--except {
    .product-card__head {
      width: 50% !important;
    }

    .product-card__panel {
      width: 50% !important;
      padding: 12px 20px;

      .inside-list {
        display: table;
        table-layout: fixed;
        width: 100%;

        .inside-list__item {
          display: table-row;

          .inside-list__title,
          .inside-list__text {
            display: table-cell;
            padding: 8px 0;
          }

          .inside-list__title {
            width: 40%;
          }

          .inside-list__text {
            width: 60%;
            padding-left: 10px;
            text-align: right;
          }
        }
      }
    }
  }
}

.product-card .inside-list {
  $root: '.inside-list';

  display: flex;
  flex-wrap: wrap;
  margin: -12px 0;

  &__item {
    width: 50%;
    padding: 8px 0;
    @include clearfix;

    &:nth-child(odd) {
      padding-right: 20px;
    }

    &:nth-child(even) {
      padding-left: 20px;
    }
  }

  &__title {
    float: left;
    // width: 105px;
    font-size: 14px;
    color: #666;
  }

  &__text {
    float: right;
    width: calc(100% - 105px);
    overflow: hidden;
    font-size: 14px;
    text-align: right;
    color: #000;
    text-overflow: ellipsis;

    &--underline {
      position: relative;

      &::before {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 1px;
        background-color: #000;
      }

      &:hover {
        color: $blue;

        &::before {
          background-color: $blue;
        }
      }
    }
  }
}

.c-modal__body {
  .product-card--type2 {
    display: flex;
    align-items: center;
    width: 100%;

    .product-card__head {
      display: block;
      width: 480px;
    }

    .product-card__panel {
      display: block;
      width: calc(100% - 480px);
    }

    .inside-list__text--underline {
      width: auto;

      &:hover {
        color: inherit;

        &::before {
          background-color: #000;
        }
      }
    }
  }
}

.product-accordion {
  $root: '.product-accordion';
  position: relative;
  display: flex;
  align-items: center;
  height: 90px;
  margin: 10px 0 70px;
  padding: 34px 82px 32px 40px;
  border-radius: 20px;
  box-shadow: 3px 20px 20px 0 rgba(59, 136, 203, 0.15);
  background: linear-gradient(90deg, #0076c0 0, #00a19c 100%);
  color: #fff;
  font-family: youandiR;
  transition: height 0.3s ease-in-out;

  &__title {
    margin-right: 30px;
  }

  &__button--more {
    position: absolute;
    top: 15px;
    right: 22px;
    width: 60px;
    height: 60px;
    font-size: 0;

    &::before,
    &::after {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 24px;
      height: 4px;
      background-color: #fff;
      content: '';
      transition-property: transform;
      transition-duration: 200ms;
    }

    &::before {
      opacity: 0.5;
    }

    &::after {
      transform: translate(-50%, -50%) rotate(90deg);
    }
  }

  &__item {
    &:not(:first-child) {
      display: none;
    }
    height: 28px;

    &:only-child {
      font-size: 18px;
    }
  }

  &__number {
    display: inline-block;
    margin-right: 30px;
    font-size: 15px;
  }

  &__button {
    position: relative;
    height: 28px;
    font-size: 18px;
    color: #fff;

    &::before {
      content: '';
      position: absolute;
      bottom: 4px;
      left: 0;
      width: 100%;
      height: 1px;
      background-color: #fff;
    }
  }

  &.is-active {
    display: block;
    padding-top: 36px;
    padding-bottom: 14px;
    height: auto;

    #{$root}__title {
      margin-bottom: 50px;
    }

    #{$root}__item {
      display: block;
      margin: 38px 0;
    }

    #{$root}__button--more::after {
      transform: translate(-50%, -50%) rotate(0deg);
    }
  }

  .page-head.bg-full + & {
    margin-top: -70px;
  }

  // pmp020704000000
  .page-head.bg-full + .c-tab--type1 .c-tab__panel &:first-of-type {
    margin-top: -60px;
  }

  .page-head.bg-full + &:before,
  .page-head.bg-full + .c-tab--type1 .c-tab__panel &:first-of-type::before {
    content: '';
    display: block;
    position: absolute;
    bottom: 50%;
    left: 50%;
    transform: translateX(-50%);
    min-width: 1280px;
    width: 100vw;
    height: 50%;
    background-color: #f8f8f8;
    z-index: -1;
  }
}

// login
.login {
  $root: '.login';

  .c-radio-box {
    margin-top: 28px;
  }

  .o-text {
    margin-top: 25px;
    margin-bottom: -4px;
    font-size: 17px;
    font-family: youandiR;
    text-align: center;
  }

  .c-text-list {
    margin-top: 27px;
  }

  .c-text-list__item {
    font-size: 14px !important;
    color: #666;

    &:nth-child(n + 2) {
      margin-top: 12px;
    }
  }

  .input-text {
    margin: 10px 0;
  }

  &__tab {
    overflow: hidden;
    position: relative;
    display: flex;
    width: 670px;
    height: 365px;
    margin: 0 auto;
    background-color: #f8f8f8;
    border: 1px solid #d8d8d8;
    border-radius: 15px;

    &-list {
      display: flex;
      flex-direction: column;
      width: 180px;
      background-color: #fff;

      button {
        position: relative;
        flex: 1;
        padding-left: 40px;
        text-align: left;
        font-size: 18px;
        font-family: youandiR;
        border-top: 1px solid #d8d8d8;
        border-right: 1px solid #d8d8d8;
        transition: background-color 0.2s linear;

        &:first-child {
          border-top: 0;
        }

        &.is-active {
          background-color: #0076c0;
          color: #fff;
        }

        &::before {
          content: '';
          position: absolute;
          top: 50%;
          left: 29px;
          width: 2px;
          height: 17.77%;
          background-color: #fff;
          transform: translate(0, -50%);
          border-radius: 30%;
        }
      }
    }

    &-panel {
      width: 480px;
      text-align: center;

      p:first-of-type {
        margin-top: 54px;
        margin-bottom: 35px;
        font-size: 24px;
        font-family: youandiR;
        line-height: 32px;
        color: #3c89c4;
      }

      p:nth-child(3) {
        margin-top: -15px;
        margin-bottom: 35px;
        color: #666;
        line-height: 24px;
      }

      .o-button {
        &.xl {
          height: 60px;
          line-height: 60px;
        }

        &.link {
          display: block;
          margin: 18px auto 55px;
          color: #666;
          border-bottom: 0;

          &:hover,
          &:focus {
            color: $blue;

            .o-icon--right-arrow::before,
            .o-icon--right-arrow::after {
              background-color: $blue;
            }
          }
        }
      }
    }
  }

  &__title {
    margin-top: -4px;
    margin-bottom: 23px;
    font-size: 21px;
    font-family: youandiR;
    color: #0076c0;
    line-height: 32px;
    text-align: center;
  }

  &__content {
    width: 300px;
    margin: 0 auto;
  }

  &__input {
    position: relative;
    z-index: 1;
    height: 72px;
    border: 0;
    color: transparent;
    padding: 0 50%;
    background-color: transparent;

    &:disabled {
      background-color: transparent;
    }
  }

  &__error {
    margin-top: 11px;
    font-size: 13px;
    color: #ff3c50;
    text-align: center;

    & + .c-button-wrap {
      margin-top: 50px;
    }
  }

  &__password {
    position: relative;
    font-size: 0;

    &.has-focus {
      .login__dots {
        border: 1px solid #000;
      }
    }
  }

  &__dots {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: space-between;
    top: 0;
    left: 0;
    width: 100%;
    height: 72px;
    padding: 0 50px;
    border-radius: 10px;
    background-color: #0076c0;
  }

  &__dot {
    display: inline-block;
    width: 16px;
    height: 16px;
    background-color: #fff;
    border-radius: 50%;
    opacity: 0.5;
    transition: opacity 0.2s;

    &.is-active {
      opacity: 1;
    }
  }

  // COM020201010000 - 카카오페이 로그인
  &__form {
    .login__title + & {
      margin-top: 23px;
    }

    > .o-input--text ~.o-input--text {
      margin-top: 10px;
    }

    .identifier .c-form__text {
      padding-top: 35px;
    }
  }

  .agree-wrap {
    .o-text.ta-sm-left {
      margin-top: -10px;
      line-height: 1.5;
      color: #666;
    }
  }

  ~ .agree-wrap {
    .o-text.ta-sm-left {
      font-family: youandiR;
      margin-top: 10px;
      line-height: 1.5;
      color: #666;
    }
  }
}

// com020102000000 - 간편비밀번호 로그인
.defferent-certification {
  display: flex;
  min-height: 60px;
  align-items: center;
  justify-content: center;
  flex: 1;
  background-color: #f8f8f8;
  cursor: pointer;
}

.certification-button {
  display: none;
  width: 100%;
  padding-left: 120px;
  padding-right: 120px;

  .o-button {
    position: relative;
    flex: 1;
    border-bottom: 0;
    outline: 0 !important;
    line-height: 60px;

    &:hover,
    &:focus {
      border-bottom: 0;
    }

    &::before {
      content: '';
      position: absolute;
      top: 50%;
      left: 0;
      width: 1px;
      height: 18px;
      background-color: #000;
      opacity: 0.3;
      transform: translate(0, -50%) rotate(25deg);
    }

    &:first-child::before {
      display: none;
    }
  }
}

// modal content

// COM020202010000 - 인증확인
.certification {
  &.o-text--1 {
    margin-top: -4px;
    margin-bottom: 34px;
    font-size: 17px;
    text-align: center;
  }
}

// COM020504010000 - 공인인증서 복사/등록 안내
// 법인 COM020602030201
// 개인 COM020601010101
.info-certificate {
  &__text {
    margin-bottom: -5px;
    font-size: 17px;
    text-align: center;
  }

  &__row {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    .info-certificate__col {
      &:nth-child(1),
      &:nth-child(2) {
        margin-bottom: 20px;
      }
    }
  }

  &__col {
    width: 580px;
    padding: 50px 60px;
    background: #f8f8f8;

    .img-wrap {
      margin-top: 25px;
    }
  }

  &__desc {
    line-height: 24px;

    .co-blue {
      display: block;
      margin-bottom: 13px;
      font-size: 19px;
      font-family: youandiR;
    }

    .c-text-list__item,
    .o-text--dot {
      font-size: 14px;
      color: #666;
    }
  }

  .c-button-wrap {
    margin-bottom: 60px;
  }

  &__tab1 {
    .info-certificate__col {
      &:nth-child(1) {
        .img-wrap {
          margin-left: -20px;
        }
      }

      &:nth-child(2) {
        .img-wrap {
          margin-left: -18px;
          margin-top: 60px;
        }
      }

      &:nth-child(3) .img-wrap,
      &:nth-child(4) .img-wrap {
        margin-left: -10px;
      }
    }
  }

  &__tab2 {
    .info-certificate__col {
      &:nth-child(1) {
        .img-wrap {
          margin-left: -20px;
          margin-top: 60px;
        }
      }

      &:nth-child(2) {
        .img-wrap {
          margin-left: -10px;
        }
      }

      &:nth-child(3) .img-wrap {
        margin-left: -10px;
        margin-top: 45px;
      }

      &:nth-child(4) .img-wrap {
        margin-left: -25px;
      }
    }
  }

  &__tab2_1 {
    .info-certificate__col {
      &:nth-child(1) {
        .img-wrap {
          margin-left: -15px;
        }
      }
    }
  }

  &__tab2_2 {
    .info-certificate__col {
      &:nth-child(1) {
        .img-wrap {
          margin-left: -12px;
        }
      }
    }
  }

  &__tab3_1 {
    .info-certificate__col {
      &:nth-child(1),
      &:nth-child(3) {
        .img-wrap {
          margin-left: -7px;
        }
      }

      &:nth-child(2) {
        .img-wrap {
          margin-left: -12px;
        }
      }

      &:nth-child(4) {
        .img-wrap {
          margin-left: -12px;
        }
      }
    }
  }

  &__tab3_2 {
    .info-certificate__col {
      &:nth-child(1),
      &:nth-child(3) {
        .img-wrap {
          margin-left: -7px;
        }
      }
    }
  }
}

.img-wrap {
  position: relative;
  width: 100%;

  // &::before {
  //   content: '이미지 영역';
  //   position: absolute;
  //   top: 50%;
  //   left: 50%;
  //   font-size: 300%;
  //   line-height: 1;
  //   color: rgba(#ff3c50, 0.9);
  //   transform: translate(-50%, -50%);
  // }
}

img[height] {
  opacity: 0.1;
  width: 100%;
  max-width: 100%;
}

/* scrollbar */
.scroll-wrapper {
  position: relative;
  overflow: auto;

  -ms-overflow-style: none;
  scrollbar-width: 0;
  -webkit-overflow-scrolling: touch;

  &::-webkit-scrollbar {
    display: none;
  }

  ::-webkit-scrollbar {
    display: none;
  }
}

.scrollbar {
  position: absolute;
  $root: '.scrollbar';
  z-index: 1;

  &::before {
    content: '';
    position: absolute;
    background-color: #f0f0f0;
  }

  &__inner {
    position: absolute;
    background-color: #999;
  }

  &:hover {
    #{$root}__inner {
      background-color: #0078ca;
    }
  }

  &--vertical {
    top: 0;
    right: 0;
    width: 26px;

    &::before {
      top: 0;
      right: 10px;
      bottom: 0;
      width: 6px;
      height: 100%;
    }

    #{$root}__inner {
      top: 0;
      right: 10px;
      width: 6px;
    }
  }

  &--horizontal {
    right: 0;
    bottom: 0;
    left: 0;
    height: 20px;

    &::before {
      bottom: 0;
      left: 0;
      width: 100%;
      height: 6px;
    }

    #{$root}__inner {
      bottom: 0;
      left: 0;
      height: 6px;
    }
  }
}

// 임시처리
.window-popup {
  background-color: #000;
}

.c-modal--popup {
  display: block;
  position: relative;
  z-index: initial;
  overflow: initial;

  .c-modal__content {
    height: 700px;
    box-shadow: none;
    position: relative;

    &:after {
      content: "";
      display: block;
      width: 100%;
      height: 30px;
      overflow: hidden;
    }
  }
}
