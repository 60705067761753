// Shorthand

.po-fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: $zindex-fixed;
}

.po-fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: $zindex-fixed;
}

// Responsive sticky top
@supports (position: sticky) {
  @each $breakpoint in map-keys($grid-breakpoints) {
    @include media-breakpoint-up($breakpoint) {
      $infix: breakpoint-infix($breakpoint, $grid-breakpoints);

      .po-sticky#{$infix}-top {
        position: sticky;
        top: 0;
        z-index: $zindex-sticky;
      }
    }
  }
}
