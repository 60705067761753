.c-accordion {
  $root: ".c-accordion";

  &__item {
    background-color: #fff;
  }

  &__head {
    position: relative;
  }

  &__title {
    display: block;
  }

  &__button {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    &::before,
    &::after {
      position: absolute;
      top: 50%;
      right: 30px;
      transform: translateY(-50%);
      width: 14px;
      height: 2px;
      border-radius: 2px;
      background-color: #0076c0;
      content: "";
      transition-property: transform;
      transition-duration: 200ms;
    }

    &::after {
      transform: translateY(-50%) rotate(0deg);
    }

    &.collapsed {
      &::before,
      &::after {
        background-color: #666;
      }

      &::after {
        transform: translateY(-50%) rotate(90deg);
      }
    }
  }

  &__text {
    display: block;
    font-size: 13px;
    color: #666;
  }

  &__panel  {
    &.collapse {
      &:not(.show) {
        display: none;
      }
    }

    &.collapsing {
      height: 0;
      overflow: hidden;
      @include transition(height .35s ease);
    }

    &.bg-gray {
      padding-top: 30px;

      .product-card {
        background-color: $white;
      }

      #{$root}__inside > .product-card:first-child{
        margin-top: 0;
      }
    }
  }

  &__foot {
    margin: 20px -20px -20px;
    padding: 20px;
    background-color: #f8f8f8;
  }

  &--type1 {
    margin-top: 30px;

    #{$root}__button {
      top: 40px;
      bottom: 0;
      height: auto;
    }

    #{$root}__item {
      border-bottom: 1px solid #d2d2d2;
      background-color: #fff;

      &.is-active {
        border-bottom-color: $blue;
      }
    }

    #{$root}__head {
      padding: 65px 52px 25px 0;
    }

    #{$root}__title {
      font-family: youandiR;
      font-size: 23px;
      // line-height: 24px;
    }

    #{$root}__inside {
      padding-bottom: 60px;

      & > :first-child {
        margin-top: 0;
      }
    }

    .c-tab__panel &:first-of-type {
      margin-top: -60px;
    }
  }

  &--type2 {
    margin-top: 15px;

    #{$root}__item {
      overflow: hidden;
      border: 1px solid #d8d8d8;
      border-radius: 6px;

      &:nth-child(n+2) {
        margin-top: 15px;
      }
    }

    #{$root}__head {
      padding: 19px 60px 19px 30px;
    }

    #{$root}__title {
      font-size: 18px;
      font-weight: 700;
    }

    #{$root}__panel {
      border-top: 1px solid #e8e8e8;
      background-color: #fff;
    }

    #{$root}__inside {
      position: relative;
      overflow-y: hidden;
      margin: 30px 0 20px;
      padding: 0 40px;
    }

    .o-input--check.o-input--small + label {
      z-index: 1;
      width: auto;
      margin:  -19px 0 -19px -30px;
      padding: 19px 30px 19px 70px;
      font-size: 18px;
      font-weight: 700;
      color: #000;

      &::before {
        top: 50%;
        left: 30px;
        transform: translateY(-50%);
      }

      &::after {
        top: 50%;
        left: 35px;
      }
    }

    .o-input--check:checked + label {
      color: #000;
    }
  }

  &--type3 {
    #{$root}__head {
      padding: 19px 60px 19px 30px;

      &:hover {
        background-color: #f8f8f8;
      }
    }

    #{$root}__item {
      border-bottom: 1px solid #eee;

      &:first-child {
        border-top: 1px solid #555;
      }

      &.is-active #{$root}__title {
        color: $blue;
      }
    }

    #{$root}__title {
      font-size: 15px;
    }

    #{$root}__button {
      &::before,
      &::after {
        width: 12px;
      }

      &.collapsed::before,
      &.collapsed::after {
        background-color: #999;
      }
    }

    #{$root}__panel {
      background-color: #f8f8f8;
    }

    #{$root}__inside {
      margin: 0;
      padding: 40px;
    }
  }

  &.question {
    margin-top: 0;

    #{$root}__head {
      padding-left: 120px;

      &::after {
        display: block;
        clear: both;
        content: "";
      }
    }

    .question-mark {
      position: absolute;
      top: 16px;
      left: 30px;
      font-family: youandiB;
      font-size: 17px;
      color: $blue;
      text-decoration: none;
    }

    #{$root}__text {
      float: left;
      width: 150px;
      font-size: 15px;
      color: #666;
    }

    #{$root}__title {
      display: inline-block;
      font-size: 15px;
      line-height: 21px;
    }

    #{$root}__text + #{$root}__title {
      width: 850px;
    }

    .answer {
      font-size: 15px;
      line-height: 24px;
    }
  }
}
