
/* 모니터링 질문 pmp020702000000 */
.survey-list {
  $root: '.survey-list';

  &__item {
    position: relative;
    padding-right: 230px;

    &+& {
      margin-top: 20px;
    }
  }

  &__question {
    display: table;
    overflow: hidden;
    width: 100%;
    min-height: 112px;
    border-radius: 10px;
    border: 1px solid #d8d8d8;

    .question-label {
      display: table-cell;
      vertical-align: middle;
      width: 140px;
      border-radius: 10px 0 0 10px;
      background-color: #f8f8f8;
      text-align: center;
      font-family: 'youandiR';
    }

    .question-content {
      display: table-cell;
      vertical-align: middle;
      padding: 25px 30px;
      font-size: 17px;
    }

    p {
      margin-top: 10px;
      font-size: 15px;
    }
  }

  &__check {
    position: absolute;
    top: 0;
    right: 0;
    width: 220px;
    height: 100%;
    border-radius: 10px;
    color: #666;

    .check-item {
      width: 100%;
      height: 50%;

      input {
        overflow: hidden;
        opacity: 0;
        position: absolute;

        &:checked+label {
          position: relative;
          background-image: url('../img/com/bg_monitoring_check_checked.png');
          border-color: $blue;
          color: $blue;
        }

        &:focus+label {
          outline: 1px dotted #767676;
        }
      }

      label {
        display: flex;
        width: 100%;
        height: 100%;
        padding-left: 64px;
        align-items: center;
        background: url('../img/com/bg_monitoring_check.png') no-repeat 31px 50%;
        border: 1px solid #d8d8d8;
        cursor: pointer;
      }

      &:nth-child(1) label {
        border-radius: 10px 10px 0 0;
      }

      &:nth-child(2) {
        height: calc(50% + 1px);

        label {
          margin-top: -1px;
          border-radius: 0 0 10px 10px;
        }
      }
    }
  }

  &.checked .check-item {
    background-color: #f8f8f8;

    label {
      color: #c8c8c8;
    }
  }

  &-noti {
    background-color: $blue;
    border-radius: 8px;
    padding: 18px;
    text-align: center;
    font-size: 17px;
    color: $white;
    margin-bottom: 55px;

    & ~ & {
      margin-top: 80px;
    }
  }

  .inner-table {

    &:not(:only-child) {
      margin-top: 10px;
    }

    .c-table {
      margin-top: 10px;
      margin-bottom: 0;
    }
  }
}

.survey-divider {
  margin-top: 80px;
  border-top: 16px solid #f8f8f8;
}

/* step 1 - 선택계약 */
.selected-contracts {
  margin-top: -15px;
  text-align: center;

  .o-label {
    margin-right: 5px;
  }

  &__number {
    display: inline-block;
    margin-bottom: 10px;
    font-family: youandiR;
    font-size: 14px;
  }

  &__title {
    font-family: 'youandiR';
    font-size: 19px;
  }

  .c-table {
    th {
     padding-left: 0;
    }

    td {
      padding-right: 0;
    }
  }

  &__button {
    display: inline-block;
    width: 28px;
    height: 28px;
    border-radius: 4px;
    border: 1px solid #d8d8d8;
    background: url('../img/com/bg_paging.png') no-repeat 50% 50%;
    vertical-align: inherit;
    cursor: pointer;

    &.button-next {
      transform: rotate(180deg);
    }
  }
}

// 첨부서류
.item-list-wrap {
  background-color: #f8f8f8;

  &--type1 {
    padding: 0 40px;

    .c-text-list {
      display: flex;
      flex-wrap: wrap;
      padding-bottom: 13px;
    }

    .c-text-list__item {
      width: 20%;
      padding-right: 30px;
      margin-top: 13px !important;
    }
  }

  &--type2 {
    margin-top: 24px;
    padding: 24px 40px;
  }

  &--half {
    .c-text-list__item {
      display: inline-block;
      width: 50%;

      &:nth-child(2) {
        margin-top: 0;
      }
    }
  }
}

.product-card .product-card__row>.c-radio-box:only-child {
  display: flex;
  flex: 1;
  margin: 0;

  .c-radio-box__item {
    flex: 1;

    &:last-child label {
      border-radius: 6px;
    }
  }
}

// 금액 계산 결과 pmp030101000000
.info-result {
  @include clearfix;
  height: 62px;
  margin-top: 13px;
  margin-right: 10px;
  padding: 20px;
  border-radius: 6px;
  background-color: #262a45;
  text-align: right;
  color: $white;

  &__title {
    float: left;
  }

  &--col2 {
    width: calc(50% - 10px);
  }

  .unit {
    padding-left: 12px;
  }
}

// 사고보험금 청구 (pmp100301000000)
.step-guide {
  $root: ".step-guide";
  margin: 0 -2px;

  &__list {
    display: flex;
  }

  &__item {
    flex: 1;
    position: relative;
    margin: 0 2px;
    padding: 15px;
    border-radius: 8px;
    background-color: rgba(0, 118, 192, .1);
    text-align: center;
    line-height: 1.4;
    font-family: youandiB;
    font-size: 21px;
    color: $blue;

    &:not(:last-child):before {
      content: '';
      position: absolute;
      left: 100%;
      top: 50%;
      display: block;
      width: 14px;
      height: 26px;
      margin-top: -13px;
      background: url('../img/com/bg_step_guide.png');
      z-index: 1;
    }
  }

  &__number {
    display: block;
    font-family: youandiR;
    font-size: 14px;
    color: $black;

    &::before {
      content: 'STEP ';
    }
  }

  &--type2 {
    #{$root}__item {
      padding-top: 27px;
      padding-bottom: 25px;
      font-size: 19px;
      font-family: youandiR;
    }

    #{$root}__number {
      margin-bottom: 10px;
    }
  }
}

// 첨부파일 목록 (pmp100301000000)
.attach-list {
  $root: '.attach-list';
  margin: 15px 0 0;
  padding: 30px;
  border-radius: 6px;
  border: 1px solid #d8d8d8;

  &.file {
    @include clearfix;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    padding-top: 0;
    padding-bottom: 0;

    #{$root}__item {
      position: relative;
      width: 50%;
      padding: 20px 30px 20px 0;

      &:nth-child(2n)+#{$root}__item:before {
        content: '';
        position: absolute;
        top: 0;
        display: block;
        width: 200%;
        height: 1px;
        border-top: 1px solid #e8e8e8;
      }

      &:nth-child(even) {
        padding-left: 20px;
        padding-right: 0;

        &:after {
          content: '';
          display: block;
          position: absolute;
          top: 50%;
          left: 0;
          transform: translateY(-50%);
          width: 1px;
          height: 20px;
          background-color: #e8e8e8;
        }
      }

      .o-icon {
        margin-left: 0;
      }
    }

    #{$root}__delete {
      width: 20px;
      height: 20px;
      background: url("../img/com/bg_attachment.png") no-repeat 0 0;
      vertical-align: middle;
      margin-left: 25px;
    }
  }

  &__file {
    display: inline-block;
    max-width: 80%;
    overflow: hidden;
    margin-left: 4px;
    text-overflow: ellipsis;
    vertical-align: middle;
  }
}

.photo {

  &__list {
    @include clearfix;
    margin: 0 -20px;
  }

  &__item {
    position: relative;
    width: 16.66%;
    padding: 30px 20px;
    float: left;

    &:nth-child(6n) + li:before {
      content: '';
      position: absolute;
      left: 20px;
      top: 0;
      display: block;
      width: calc(600% - 40px);
      height: 1px;
      border-top: 1px solid #e8e8e8;
    }
  }

  &__delete {
    position: absolute;
    right: 10px;
    top: 20px;
    width: 20px;
    height: 20px;
    background: url('../img/com/bg_attachment.png');
  }

  &__image {
    position: relative;
    overflow: hidden;
    width: 150px;
    height: 200px;
    margin: 0 auto;
    background: #f8f8f8 url("../img/com/bg_photo_image.png") no-repeat 50% 50%;
    border: 1px solid #eee;

    img {
      position: absolute;
      top: 50%;
      left: 50%;
      width: 100%;
      transform: translate(-50%, -50%);
    }
  }
}

// 사고보험금 청구현황 (pmp100401000000)
.progress-step {
  $root: '.progress-step';
  border-radius: 6px;
  background-color: #f8f8f8;
  border: 1px solid #e8e8e8;
  overflow: hidden;

  &__item {
    position: relative;
    height: 120px;
    float: left;
    width: 13.33%;
    background-color: #006aac;
    transition: width .5s;

    &:not(:first-child)::before {
      content: '';
      position: absolute;
      left: 0;
      top: 50%;
      margin-top: -15px;
      display: block;
      width: 1px;
      height: 31px;
      background-color: #d8d8d8;
      z-index: 2;
    }

    &.is-active:before {
      content: none;
    }
  }

  &__number {
    display: block;
    margin-bottom: 8px;
    font-size: 14px;

    &:before {
      content: 'STEP ';
    }
  }

  &__button {
    position: relative;
    display: block;
    float: left;
    width: 158px;
    height: inherit;
    background-color: #f8f8f8;
    font-family: youandiR;
    font-size: 19px;
    color: #666;
    z-index: 1;

    &::before {
      content: none;
      display: block;
      position: absolute;
      right: -6px;
      top: 50%;
      margin-top: -6px;
      width: 12px;
      height: 12px;
      background-color: $blue;
      transform: rotate(45deg);
    }
  }

  #current-step .progress-step__button {
    color: $blue;

    &:after {
      content: '';
      display: block;
      position: absolute;
      right: 30px;
      top: 30px;
      width: 5px;
      height: 5px;
      background-color: $blue;
      border-radius: 50%;
    }
  }

  .inner-list {
    display: flex;
    align-items: center;
    height: inherit;
    background-color: inherit;

    & > li {
      flex: 1;
      text-align: center;
      color: $white;
    }

    &__title {
      display: block;
      font-size: 14px;
    }

    &__text {
      font-size: 17px;
    }
  }
}

// pmp100401010000
.text-bar {
  margin-right: 5px;
  padding-right: 10px;
  border-right: 2px solid $black;
}

// 활성화
.progress-step__item.is-active {
  width: 60%;

  button {
    background-color: $blue;
    font-family: youandiB;
    color: $white !important;

    &:before {
      content: '';
    }
  }

  &+.progress-step__item:before {
    content: none;
  }

  .inside-panel {
    display: block;
  }
}

.inside-panel {
  width: calc(100% - 158px);
  display: none;
  padding: 0 30px 0 35px;
  float: left;
  opacity: 0;
  height: inherit;
}

// pmp090101000000
.data-filter {
  position: relative;
  display: table;
  width: 100%;
  padding: 30px 40px;
  border-radius: 7px;
  border: 1px solid #d8d8d8;

  .c-form {
    width: 100%;
    margin-top: 0;
  }

  &__target {
    display: table-cell;
    width: 210px;
    vertical-align: middle;
    font-size: 17px;
    color: $blue;

    .amount {
      display: block;
    }
  }

  .c-form__button {
    right: 40px;
    top: 30px;
    width: 90px;
    height: 64px;
    background-color: $white;
    border: 1px solid $blue;
    border-radius: 6px;
    font-size: 15px;
    color: $blue;
  }
}

.change-detail {

  dd {
    display: inline-block;
  }

  &__result:before {
    content: '';
    display: inline-block;
    margin: 0 40px;
    border-left: 6px solid $black;
    border-top: 5px solid transparent;
    border-bottom: 5px solid transparent;
  }
}

// 본인인증 (pmp090201000000)
.user-certification {
  font-size: 0;

  &__button {
    position: relative;
    display: inline-block;
    width: calc(50% - 5px);
    height: 64px;
    padding-left: 58px;
    border-radius: 6px;
    border: 1px solid #d8d8d8;
    text-align: left;
    background: url('../img/com/img_arr_right_7x12.png') no-repeat 555px 25px;
    font-size: 18px;

    &:before {
      content: '';
      position: absolute;
      display: block;
      left: 21px;
      top: 50%;
      transform: translateY(-50%);
      width: 21px;
      height: 24px;
      background-repeat: no-repeat;
      background-position: 0 0;
    }

    &:not([disabled]):hover {
      border-color: $blue;
      color: $blue;
    }

    &.t-phone:before {
      background-image: url('../img/com/ico_certification_phone.png');
    }

    &.t-card:before {
      background-image: url('../img/com/ico_certification_card.png');
    }

    &+button {
      margin-left: 10px;
    }
  }

  &__button[disabled] {
    background-color: #f8f8f8;
    background-image: none;
    color: #c8c8c8;

    &:before {
      background-position-y: -24px;
    }
  }

  &__button[data-status="complete"] {
    border-color: $blue;
    background: url('../img/com/bg_check_blue.png') no-repeat 505px 25px;

    &::after {
      content: '완료';
      display: block;
      position: absolute;
      top: 50%;
      right: 20px;
      transform: translateY(-50%);
      color: $blue;
    }
  }
}

// 공통 보안카드 인증 popup (com040300000000)
.certifi-security {
  $root: '.certifi-security';

  &__ref {
    padding: 40px 0;
    border-radius: 6px;
    border: 1px solid $blue;
    background-image: url('../img/com/bg_certifi_card_head.gif'),
    url('../img/com/bg_certifi_card_foot.gif');
    background-repeat: no-repeat, no-repeat;
    background-position: 0 0, 0 100%;
    background-size: 100% 40px;
    overflow: hidden;

    table {
      width: 100%;
      margin-left: -1px;
      border-bottom: 1px solid #e8e8e8;

      th {
        width: 39px;
        background-color: #f8f8f8;
        border-left: 1px solid #e8e8e8;
        border-right: 1px solid #e8e8e8;
        font-weight: normal;
      }

      td:before {
        content: '****';
        display: block;
        padding: 7px 6px;
        height: 21px;
        letter-spacing: 1px;
      }

      th, td {
        height: 24px;
        padding: 0;
        vertical-align: middle;
        font-family: youandiR;
        line-height: 1;
        color: #666;
      }

      tr:first-of-type th,
      tr:first-of-type td {
        padding-top: 6px;
      }

      tr:last-of-type th,
      tr:last-of-type td {
        padding-bottom: 6px;
      }

      .target + td {
        text-align: left;
        color: $white;

        &::before {
          content: '**';
          display: block;
          background-color: $blue;

        }
      }

      .target:before {
        display: inline-block;
        background-color: $blue;
        width: 100%;
        height: 21px;
        color: $white;
        content: '**';
      }

      .target:nth-of-type(2):before {
        text-align: right;
      }
    }
  }

  &__img {
    display: block;
    font-size: 0;

    img {
      width: 100%;
    }
  }

  &__form {
    width: 100%;
    margin-top: 20px;
    border-radius: 6px;
    border: 1px solid #d8d8d8;
    overflow: hidden;
  }

  &__item {
    position: relative;
    padding: 0 62px 0 160px;
    width: 100%;
    height: 60px;
    font-size: 0;
    overflow: hidden;
  }

  &__item:nth-child(2) {
    border-top: 1px solid #e8e8e8;
  }

  &__label {
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    width: 160px;
    height: 100%;
    padding-top: 17px;
    padding-left: 20px;
    background-color: #f8f8f8;
    font-size: 17px;
  }

  &__input {
    position: relative;
    width: 148px;
    height: 100%;
    margin: 0 auto;
    padding-top: 6px;
    background: url('../img/com/bg_security_number.gif') no-repeat 100% 50%;
    vertical-align: middle;
    overflow: hidden;


    #{$root}__item:nth-child(2) & {
      background-position-x: -10px;

      .o-input {
        float: right;
      }
    }

    .o-input {
      position: relative;
      z-index: 1;
      width: 70px;
      background-color: transparent;
      padding-top: 48px;
      border:0;
      color: rgba(0, 0, 0, 0);
      text-indent: -999px;
    }

    &.has-focus #{$root}__dots {
      border-color: #0076c0;
    }

    #{$root}__dots {
      position: absolute;
      width: 70px;
      height: 48px;
      padding-top: 18px;
      text-align: center;
      font-size: 0;
      border: 1px solid #d8d8d8;
      border-radius: 6px;

      #{$root}__item:nth-child(2) & {
        right: 0;
      }
    }

    #{$root}__dot {
      display: inline-block;
      width: 11px;
      height: 11px;
      margin: 0 5px;
      background-color: $black;
      border-radius: 50%;
      opacity: .2;

      &+#{$root}__dot {
        margin-left: 6px;
      }

      &.is-active {
        background-color: #0076c0;
        opacity: 1;
      }
    }
  }

  &__button {
    display: block;
    position: absolute;
    right: 0;
    top: 0;
    width: 62px;
    height: 100%;
    float: right;
    background: #f8f8f8 url('../img/com/ico_mouse_keypad.png') no-repeat 50% 50%;

    &.is-active {
      background-image: url('../img/com/ico_mouse_keypad_on.png');
    }
  }
}

// 적합성 진단 (pmp090303020000)
.compatibility-test {

  .listbox {
    width: 585px;
    height: 64px;

    &__button {
      height: inherit;
    }
  }
}

// 변액보험 펀드 성향 pmp090303030000
.fund-type {
  @include clearfix;
  position: relative;
  height: 406px;
  margin-top: 40px;
  padding-left: 180px;
  border: 1px solid #d8d8d8;
  border-radius: 15px;
  overflow: hidden;

  &__tab {
    position: absolute;
    left: 0;
    top: 0;
    display: flex;
    height: 100%;
    flex-direction: column;
  }

  &__button {
    flex: 1;
    width: 180px;
    min-height: 80px;
    padding-left: 30px;
    text-align: left;
    font-size: 18px;
    color: #666;

    &+button {
      border-top: 1px solid #d8d8d8;
    }

    &.is-active {
      background-color: #0076c0;
      color: $white;
    }

    &.myType span {
      display: block;
      position: relative;
      padding-left: 10px;

      &:before {
        content: '';
        position: absolute;
        left: 0;
        top: 3px;
        display: block;
        width: 2px;
        height: 16px;
        background-color: #0076c0;
      }
    }

    &.myType.is-active span:before {
      background-color: $white;
    }
  }

  &__panel {
    height: 100%;
    padding: 30px;
    border-left: 1px solid #d8d8d8;

    .o-heading {
      margin-bottom: 5px;
      font-size: 19px;

      &:first-of-type {
        margin-top: 20px;
      }
    }

    .c-text-list {
      @include clearfix;
      margin-top: 15px;

      li {
        display: inline-block;
        margin-right: 10px;
        min-width: 30%;
      }
    }
  }
}

.bb-over:hover {
  border-bottom: 1px solid $blue !important;
  height: 21px;
}

.column-container {
  display: flex;
  margin: 0 -10px;

  .column-item {
    margin: 0 10px;
    flex: 1;
  }
}

.divider {
  margin-top: 30px;
  border-top: 1px solid #666;
}

.download-list {
  @include clearfix;

  &__item {
    position: relative;
    width: calc(50% - 10px);
    height: 100px;
    margin: 10px 0;
    padding: 0 40px;
    border-radius: 5px;
    border: 1px solid #d8d8d8;
    background: transparent;
    float: left;
    display: flex;
    align-items: center;

    &:nth-child(2n) {
      float: right;
    }

    &:hover {
      border-color: $blue;
      color: $blue;
    }
  }

  &__title {
    display: inline-block;
    padding-right: 30px;
    flex: 1;
    font-size: 17px;
  }

  .o-button {
    border: 0;
    padding: 0;

    &::before {
      content: "";
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      height: 100%;
    }

    .o-icon--download {
      margin: 0;
      position: absolute;
      top: 50%;
      right: 28px;
      transform: translateY(-50%);
    }
  }
}

.page-link {
  margin: 0 -10px;
  font-size: 0;
  @include clearfix;

  a {
    position: relative;
    border-radius: 6px;
    display: table;
    float: left;
    width: 580px;
    height: 100px;
    margin: 10px;
    border: 1px solid #d8d8d8;

    span {
      display: table-cell;
      padding: 0 40px;
      height: 100%;
      vertical-align: middle;
      font-size: 17px;
    }

    &:hover {
      border-color: $blue;
      color: $blue;
    }

    &:after {
      content: '';
      position: absolute;
      right: 40px;
      top: 50%;
      margin-top: -3px;
      display: block;
      width: 0;
      padding: 3px;
      border: solid #666;
      border-width: 0 2px 2px 0;
      transform: rotate(-45deg);
    }
  }
}

// 마이페이지 submain

.noti-top {
  position: relative;
  display: block;
  width: 100%;
  height: 50px;
  padding-top: 13px;
  background-color: #f8f8f8;
  text-align: center;
  background-color: #004b75;
  color: #92fbf8;
  // z-index: -1;

  &::before {
    content: '';
    position: absolute;
    display: block;
    min-width: 1280px;
    width: 100vw;
    height: 100%;
    left: 50%;
    top: 0;
    transform: translateX(-50%);
    background-color: inherit;
    z-index: -1;
  }

  .o-icon--right-arrow::before,
  .o-icon--right-arrow::after {
    background-color: #92fbf8;
  }
}

.mypage-main {
  $root: '.mypage-main';
  transform: translateY(-50px);
  animation: slide-down .8s forwards;

  #{$root}__title {
    margin: 64px 0 24px;
    font-family: youandiR;
    font-size: 23px;
  }

  @keyframes slide-down {
    to {
      transform: translateY(0);
    }
  }

  .mypage-head {
    @include clearfix;
    position: relative;
    padding: 70px 0 60px;
    text-align: left;
    color: $white;

    &.bg-full::before {
      background: linear-gradient(90deg, #0076c0 0, #00a19c 100%);
    }

    &__title {
      margin-top: 0;
      margin-bottom: 30px;
      font-family: youandiB;
      font-size: 34px;
      color: $white;
    }

    &__info {
      display: inline-block;
      min-height: 95px;
    }

    &__button {
      display: inline-block;
      min-width: 70px;
      height: 30px;
      margin-top: 15px;
      padding: 0 8px;
      border-radius: 5px;
      border: 1px solid $white;
      text-align: center;
      line-height: 27px;
      font-weight: 300;
      font-size: 14px;
      color: $white;
    }

    &__name {
      display: block;
      padding-bottom: 5px;
      line-height: 1;
      font-size: 19px;
    }

    &__text {
      font-family: 'youandiR';
      font-size: 14px;
      color: #fff;
    }
  }

  .mypage-quickmenu {
    position: absolute;
    right: 0;
    top: 70px;
    margin-right: -4px;
    font-size: 0;

    &__anchor,
    &__item {
      position: relative;
      display: inline-block;
      width: 134px;
      height: 175px;
      margin: 0 4px;
      padding: 25px 20px;
      background-color: $white;
      border-radius: 10px;
      vertical-align: top;
      font-family: 'youandiR';
      font-size: 17px;

      &:hover,
      &:focus {
        position: relative;
        box-shadow: 15px 20px 20px -5px rgba(17, 57, 78, 0.15);
        color: $blue;

        span {
          border-bottom: 1px solid $blue;
        }
      }
    }

    &__icon {
      position: absolute;
      right: 14px;
      bottom: 14px;
      font-size: 0;
    }

    .hcoin {
      background-color: #162645;
      color: #fff;

      .o-icon:before,
      .o-icon:after {
        background-color: #fff;
      }

      &__item {
        position: absolute;
        right: 20px;
        bottom: 20px;
      }

      &__anchor {
        color: #fff;

        &:hover {
          border-bottom: 1px solid #fff;
        }
      }

      &__button {
        @extend .mypage-head__button;
        min-width: 40px;
      }

      &__point {
        font-family: 'youandiB';
        font-size: 22px;
        color: $white;
      }
    }
  }

  .product-card {
    margin: 20px 0;

    &:hover {
      box-shadow: 24px 15px 35px -5px rgba(0, 0, 0, 0.1);
      border-color: $blue;

      .product-card__title {
        color: $blue;
      }
    }
  }

  .step-guide {
    border-radius: 10px;
    background-color: $light;

    &__item {
      padding: 27px 0;
      background-color: transparent;
      font-family: 'youandiR';
      font-size: 17px;
      color: $black;

      &::before {
        content: none;
        background: none;
        margin-top: 0;
      }

      & + li:before {
        content: '';
        position: absolute;
        display: block;
        width: 50%;
        height: 1px;
        background-color: #d2d2d2;
        left: 0;
        top: 50%;
        transform: translateX(-50%);
      }
    }

    &__number {
      display: inline-block;
      margin-right: 15px;
      padding: 0 13px;
      border-radius: 60rem;
      background-color: $blue;
      line-height: 26px;
      font-family: 'youandiB';
      font-size: 15px;
      color: $white;
    }
  }
}

.mypage-menu {
  padding-top: 40px;
  $root: '.mypage-menu';

  &__row {
    position: relative;
    margin-top: 30px;
    padding-left: 360px;
    border-radius: 10px;
    border: 1px solid #e8e8e8;
    overflow: hidden;
  }

  &__head {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0 40px;
    position: absolute;
    left: 0;
    top: 0;
    width: 360px;
    height: 100%;
    background-color: #f8f8f8;
    border-radius: 10px 0 0 10px;

    .subtext {
      margin-top: 12px;
      line-height: 1.4;
      font-size: 13px;
      color: #666;
    }

    #{$root}__title {
      font-family: 'youandiR';
      font-size: 19px;
    }

    #{$root}__anchor {
      display: inline-block;
      padding-right: 22px;
      background: url('../img/com/bg_menu_anchor_arr.png') no-repeat 100% 50%;
    }
  }

  &__panel {
    min-height: 80px;
    padding: 7px 60px;

    .panel-text-wrap {
      display: table;
      padding: 13px 0;
      width: 100%;
      min-height: 66px;

      .o-text {
        display: table-cell;
        text-align: center;
        vertical-align: middle;
      }

      .o-button.md {
        height: 40px;
        line-height: 37px;
      }
    }

    .o-button.sm {
      height: 30px;
      line-height: 28px;
      font-size: 14px;
    }
  }

  .inside-list {
    @include clearfix;
    position: relative;

    &:before {
      content: '';
      position: absolute;
      bottom: 0;
      display: block;
      width: 100%;
      height: 1px;
      background-color: $white;
      z-index: 1;
    }

    &--horizontal .inside-list__item {
      width: calc(50% - 30px);
      float: left;

      &:nth-child(2n) {
        margin-left: 60px;
      }
    }

    .inside-list__item {
      position: relative;
      display: table;
      table-layout: fixed;
      height: 62px;
      border-bottom: 1px solid #eee;
      font-family: 'youandiR';
    }

    .inside-list__title,
    .inside-list__text {
      display: table-cell;
      vertical-align: middle;
      height: 100%;
    }

    .inside-list__title {
      width: 45%;
    }

    .inside-list__text {
      width: 55%;
      text-align: right;
    }
  }

  // 퇴직연금
  &__row.t-pension {
    overflow: visible;

    .list-wrap {
      width: 50%;
      padding: 0 30px;
    }
  }

  #pension-slider {
    padding-left: 0;
    padding-right: 0;
    position: relative;

    .swiper-container {
      margin: 0 30px;
      padding-bottom: 43px;
    }

    .swiper-custom-pagination {
      position: absolute;
    }

    .swiper-custom-button {
      position: absolute;
      top: 50%;
      margin-top: -14px;
      background-color: $white;
      z-index: 5;

      &--prev {
        left: 0;
        margin-left: -14px;
      }

      &--next {
        right: 0;
        margin-right: -14px;
      }

      &:not(.swiper-button-disabled) {
        border-color: $blue;

        &:after {
          border-color: inherit;
        }
      }
    }

    .swiper-button-disabled {
      // opacity: 1;
      // cursor: default;
    }

    .swiper-custom-pagination {
      left: 50%;
      bottom: 10px;
      transform: translateX(-50%);
    }

    .swiper-pagination-bullet:not(.swiper-pagination-bullet-active) {
      background-color: transparent;
      opacity: 1;
    }
  }

  .list-wrap {
    float: left;

    .list-title {
      padding: 18px 0;
      border-bottom: 1px solid #666;
      font-family: 'youandiR';
      font-weight: 600;
      font-size: 17px;
    }

    .inside-list:before {
      content: none;
    }

    .inside-list__item {
      border-top: 1px solid #eee;
      border-bottom: none;
      width: 100%;
      float: none;
    }
  }
}

.ovf-container {
  .ovf {
    transition: transform .5s ease-out;
  }
}

// 회사소개
.about-fb {
  background-color: $white;

  .ovf-fixed {
    position: fixed;
    top: 127px;
    left: 0;
    width: 100%;
    z-index: -1;
  }

  .ovf {
    position: relative;
    top: 100vh;
    background-color: $white;
  }

  .section-indicator {
    position: fixed;
    left: 40px;
    top: 50%;
    width: 334px;
    text-align: center;
    line-height: 20px;
    font-family: 'youandiR';
    font-size: 14px;
    transform: rotate(-90deg) translateX(-50%);
    transform-origin: 0 0;
    z-index: 20;

    .pagination {
      display: inline-block;
      margin-left: 20px;
    }

    &__button {
      width: 20px;
      height: 20px;
      background: url('../img/content/bg_fb_indicator.png') no-repeat 50% 50%;

      &.prev {
        float: left;
      }

      &.next {
        float: right;
        transform: rotate(180deg);
      }

      &:hover {
        background-image: url('../img/content/bg_fb_indicator_on.png');
      }
    }
  }

  &__title {
    line-height: 1.1;
    font-family: 'youandiB';
    font-size: 72px;
    color: white;
  }

  &__text {
    margin-top: 30px;
    font-size: 32px;
    color: white;
  }

  .page-section {
    background-color: $white;

    .inner {
      width: 1120px;
      padding: 150px 0;
    }

    &:not(.about-fb-intro) {
      // width: 1180px;
      // margin: 0 auto;
      // padding: 175px 30px;
      // padding: 175px calc((100% - 1120px)/2);

      // width: 100%;
      position: relative;
      // z-index: 5;
    }

    &__title {
      font-size: 19px;
      color: #666;

      .em {
        display: block;
        margin-bottom: 20px;
        line-height: 1.3;
        font-family: 'youandiB';
        font-size: 36px;
        color: $black;
      }

      &.type2 {
        margin-bottom: 32px;
        line-height: 1.1;
        font-family: 'youandiB';
        font-size: 48px;
        color: $blue;
      }
    }

    &.bg-full:before {
      background-color: inherit;
    }

    .o-button {
      height: 40px;
      padding: 0 20px;
      line-height: 38px;
    }
  }

  &--en {
    .page-section.about-fb-product .summary-card__title {
      font-size: 17px;
    }

    .page-section.about-fb-pension .pension-cont__em {
      font-size: 20px;
    }

    .page-section.about-fb-pension .pension-cont__item {
      padding: 0 80px;
    }
  }
}

 // 텍스트 등장효과
.title-animation2 {
  opacity: 0;
  transition: opacity 1s .2s;

  .active & {
    opacity: 1;
  }
}

.title-animation1 {
  display: block;
  overflow: hidden;

  i {
    font-style: normal;
    display: block;
    line-height: inherit;
    transform: translateY(100%);
    transition: transform 1s ease;
  }

  .active & i {
    transform: translateY(0);
  }
}

// 회사소개 인트로
.about-fb-intro.fixed {
  position: fixed;

  &+ .page-section {
    margin-top: calc(100vh - 130px) !important;
  }
}

.about-fb-intro {
  // position: relative;
  // height: calc(100vh - 130px);
  position: absolute;
  height: 100vh;
  text-align: center;

  left: 0;
  right: 0;
  top: 0;
  width: 100%;
  min-width: 1280px;

  .intro {
    height: 100%;
    background: url('../img/content/bg_about_fb_intro.jpg') no-repeat 50% 0/cover;
    transition: transform .2s ease-out;

    &__message {
      display: flex;
      flex-direction: column;
      justify-content: center;
      height: 100%;
    }
  }

  .scroll-indicator {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    height: 82px;
    text-align: center;
    font-family: 'youandiR';
    color: $white;

    .pin {
      position: absolute;
      left: 50%;
      top: 26px;
      margin-left: -1px;
      width: 2px;
      background-color: $white;
      animation: pin-ani .8s ease-in-out infinite;
    }

    @keyframes pin-ani {
      from {
        height: 0;
      }
      to {
        height: 56px;
      }
    }
  }

  .dim-intro {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    background-color: $black;
    opacity: 0;
  }
}

// 회사소개 메인 section 1 (보험상품)
.page-section.about-fb-product {
  background-color: #f9fafc;
  overflow: hidden;

  margin-top: calc(100vh - 130px); // 130 = 헤더 H + 브레드크럼 H

  .summary-card {
    $root: '.summary-card';
    position: relative;
    display: block;
    float: left;
    width: 340px;
    min-height: 500px;
    padding: 40px 20px;
    border-radius: 45px;
    text-align: center;
    overflow: hidden;
    box-shadow: 10px 25px 35px 0 rgba(142, 150, 166, 0.2);
    color: $white;
    transition: transform .5s ease;

    &-wrap {
      @include clearfix;
      position: relative;
      z-index: 3;
      margin: 0 -20px;
      text-align: center;
      font-size: 0;
    }

    &.bg-white {
      margin: 0 70px;
      border: 1px solid #eaedf3;
      box-shadow: none;
      color: $black;
    }

    &__img {
      display: block;
      width: 80px;
      margin: 0 auto;
      font-size: 0;
    }

    &__label {
      display: inline-block;
      margin-top: 22px;
      padding: 0 16px;
      line-height: 26px;
      border: 1px solid $white;
      border-radius: 60rem;
      font-size: $font-size-base;
    }

    &__title {
      display: block;
      margin-top: 15px;
      font-family: 'youandiR';
      font-size: 20px;
    }

    &__body {
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      height: 100%;
      
      .o-text--1 {
        padding: 0 50px;
      }
    }

    &__head {
      position: relative;
      z-index: 1;
    }

    &__panel {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      display: flex;
      align-items: center;
      height: 260px;
      margin-top: 30px;
      background-color: rgba(0, 0, 0, .1);
      font-size: $font-size-base;
      z-index: 1;

      .inside-list {
        width: 100%;

        li {
          padding: 7px 0;
          text-align: center;
        }
      }
    }
  }

  .summary-card {

    &:before {
      content: '';
      display: block;
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
    }

    &.bg-green:before {
      background: url('../img/content/bg_fb_product_gradient_1.png');
    }

    &.bg-blue:before {
      background: url('../img/content/bg_fb_product_gradient_2.png');
    }
  }
}

.floating-ring {
  width: 225px;
  height: 555px;
  position: absolute;
  left: 0;
  // border: 1px solid red;
  transition: transform .5s ease;

  &.half-start {
    bottom: 0;
    // transform: translateY(50%);
    margin-bottom: -277.5px;
    background-image: url('../img/content/img_shape_ring_1.png');
  }

  &.half-end {
    top: 0;
    // transform: translateY(-50%);
    margin-top: -277.5px;
    background-image: url('../img/content/img_shape_ring_2.png');
  }
}

.floating-circle {
  position: absolute;
  right: 0;
  transition: transform .5s ease;

  &.big {
    top: -152px;
    width: 134px;
    height: 392px;
    background-image: url('../img/content/img_shape_semicircle_big.png');
  }

  &.small {
    top: -263px;
    width: 60px;
    height: 195px;
    background-image: url('../img/content/img_shape_semicircle_small.png');
  }
}

// 회사소개 메인 section 2 (퇴직연금)
.page-section.about-fb-pension {
  overflow: hidden;

  .pension-wrap {
    position: relative;
    z-index: 3;
    margin: 10px 0;
    padding-top: 15px;
    padding-right: 790px;
    min-height: 400px;
    @include clearfix;
  }

  .pension-cont {
    $root: '.pension-cont';
    position: absolute;
    top: 0;
    right: 0;
    width: 790px;
    height: 400px;
    margin-right: -30px;

    &__item {
      position: absolute;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 400px;
      height: 400px;
      border-radius: 50%;
      background-color: $white;
      border: 1px solid #eaedf3;
      text-align: center;
      overflow: hidden;
      box-shadow: 15px 25px 35px 0 rgba(167, 181, 184, 0.1);

      &:nth-child(1) {
        left: 195px;
        transition: left .6s .2s;
      }

      &:nth-child(2) {
        right: 195px;
        background-color: #f9fafc;
        box-shadow: none;
        transition: right .6s .2s;
      }
    }

    &__img {
      display: inline-block;
      font-size: 0;
    }

    &__em {
      display: inline-block;
      line-height: 1.3;
      font-size: 26px;
    }
  }

  &.active .pension-cont__item {

    &:nth-child(1) {
      left: 0;
    }

    &:nth-child(2) {
      right: 0;
    }
  }

  .ref-article {
    margin-top: 30px;
    font-size: 14px;

    &__anchor {
      display: inline-block;
      margin-top: 10px;
      padding: 10px 20px 0 0;
      border-top: 1px solid #eee;
      font-size: 15px;
    }

    .date {
      display: block;
      margin-top: 2px;
      color: #666;
      font-size: 14px;
    }
  }
}

// 회사소개 메인 section 3 (DIGITAL SERVICE)
.page-section.about-fb-service {
  background: #f9fafc url('../img/content/bg_hatched_gray.png');

  .inside-buttons {
    width: 265px;
    margin-top: 20px;

    li {
      position: relative;
      padding: 24px 0 24px 38px;


      &:before,
      &:after {
        content: '';
        position: absolute;
        display: block;
      }

      &:before {
        left: 0;
        width: 2px;
        top: 24px;
        bottom: 24px;
        background-color: #d2d2d2;
      }

      & + li:after {
        left: 38px;
        right: 0;
        top: 0;
        height: 1px;
        background-color: #e8e8e8;
      }

      &.active {

        button {
          color: $blue;
        }

        &::before {
          background-color: $blue;
        }
      }
    }

    .inside-button {
      position: relative;
      display: block;
      width: 100%;
      text-align: left;
      font-family: 'youandiR';
      font-size: 16px;
      color: #666;

      &> span {
        position: relative;
      }

      .em {
        display: inline-block;
        line-height: 1;
        margin-bottom: 10px;
        font-family: 'youandiB';
        font-style: normal;
        font-size: 15px;
      }
    }
  }

  #svc-tab-panel {
    position: relative;
    margin-right: 33px;
    width: 280px;
    height: 514px;

    .svc-image {
      position: absolute;
      top: 0;
      right: 0;
      font-size: 0;

      &:not(.active)::before {
        content: '';
        display: block;
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        border-radius: 20px;
        background-color: rgba(255,255,255,.9);
        border: 1px solid #eaedf3;
      }
    }

    .svc-image.active {
      position: absolute;
      animation: slide-side .8s forwards;
      transition: opacity 1s;
      opacity: 1;

      &:after {
        content: '';
        position: absolute;
        top: 18px;
        display: block;
        width: 344px;
        height: 578px;
        background: url('../img/content/img_fb_service_shadow.png');
        z-index: -1;
      }

      &.shown {
        opacity: 0;
      }
    }

    @keyframes slide-side {
      to {
        transform: translateX(-388px);
      }
    }
  }
}

// 회사소개 메인 section 4 (기업소개)
.page-section.about-fb-profile {

  .text-wrap {
    position: relative;
    margin-top: 70px;
    padding: 70px 30px;
    border-radius: 10px;
    border-color: #f9fafc;

    &:first-of-type {
      margin-top: 95px;
    }

    &__label {
      position: absolute;
      top: -29px;
      left: 50%;
      transform: translateX(-50%);
      display: inline-block;
      min-width: 300px;
      height: 48px;
      padding: 0 47px;
      border-radius: 60rem;
      background-color: #00a19c;
      line-height: 48px;
      font-family: 'youandiR';
      font-size: 19px;
      color: $white;
    }
  }

  .fb-partners {
    display: flex;
    align-items: center;
    justify-content: center;

    &__item {
      position: relative;
      width: 31%;
      text-align: center;
      color: #666;

      &:not(:first-child):before {
        content: '';
        position: absolute;
        left: 0;
        top: 16px;
        margin-left: -15px;
        display: block;
        width: 29px;
        height: 7px;
        background: url('../img/content/bg_dotted_line.png');
      }
    }

    &__img {
      display: block;
      margin-bottom: 25px;
      font-size: 0;
    }
  }

  .latest-history {
    padding-bottom: 63px;

    &__item {
      width: 230px;
      padding-top: 50px;
      background: url('../img/content/ico_latest_history.png') no-repeat 50% 0;
      text-align: center;
      color: #666;

      &:not(:first-child):before {
        content: '';
        position: absolute;
        top: 7px;
        left: 0;
        display: block;
        width: 35px;
        height: 3px;
        margin-left: -17px;
        background: url('../img/content/bg_latest_history.png') no-repeat 0 0;
      }
    }

    &__date {
      display: block;
      margin-bottom: 12px;
      line-height: 1;
      font-family: 'youandiR';
      font-weight: bolder;
      color: $blue;
    }

    &__text,
    .inside-list li {
      line-height: 1.7;
      font-size: 14px;
    }

    .inside-list li {
      margin-top: 4px;
      line-height: 1.5;
    }
  }
}

// 회사소개 메인 section 5 (직원소개)
.page-section.about-fb-member {
  position: relative;

  &:before {
    content: '';
    position: absolute;
    left: 50%;
    top: 0;
    bottom: 0;
    margin-left: -50%;
    display: block;
    width: 100vw;
    background-color: #f9fafc !important;
  }

  .inner {
    padding: 144px 0 150px;
  }

  .page-section__title {
    position: relative;
  }

  .members-slider {
    margin-top: 80px;
    padding: 0 40px 68px;

    .swiper-wrapper {
      position: static;
    }

    .swiper-slide {
      width: 230px;
      height: 280px;
      // margin-right: 40px;

      &:focus .photo-wrap,
      &:hover .photo-wrap {
        box-shadow: 15px 30px 35px 0 rgba(0, 144, 191, 0.25);

        .slide-text {
          opacity: 1;
        }
      }
    }

    .photo-wrap {
      position: relative;
      width: 100%;
      height: 100%;
      border-radius: 8px;
      border-bottom-right-radius: 48px;
      cursor: pointer;
    }

    .slide-photo {
      border: 1px solid #e8e8e8;
      border-radius: inherit;
      overflow: hidden;
      font-size: 0;

      img {
        width: 100%;
      }
    }

    .slide-text {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      padding: 26px 30px;
      border-radius: inherit;
      background: linear-gradient(145deg, #0077c5 0, #00c4b0 100%);
      font-family: 'youandiR';
      word-break: keep-all;
      color: $white;
      opacity: 0;
      transition: opacity .2s;

      & > p {
        margin-bottom: 20px;
      }

      small {
        display: block;
        margin-top: 5px;
      }
    }

    .swiper-custom-controller {
      margin-top: 40px;
      display: none;
    }
  }
}

// 회사소개 메인 section 6 (ceo인사말)
.page-section.about-fb-ceo {
  .inner {
    position: relative;
    padding-right: 470px;
  }

  .co-gray {
    margin: 36px 0;
  }

  img {
    position: absolute;
    top: 150px;
    right: 60px;
  }
}

// 회사소개 메인 section 7 (SNS)
.about-fb-social {
  position: relative;
  margin-bottom: -100px !important;
  background-image: url('../img/content/bg_hatched.png'),
  linear-gradient(160deg, #00c0b0 30%, #0076c0 70%);
  background-repeat: repeat,
  no-repeat;
  background-position: left top,
  left top;

  .inner {
    padding: 113px 0;
    z-index: 2;
  }

  &:after {
    content: '';
    display: block;
    position: absolute;
    width: 100%;
    left: 0;
    top: 0;
    height: 100%;
    background-image: url('../img/content/bg_fb_sns_1.png'),
    url('../img/content/bg_fb_sns_2.png');
    background-repeat: no-repeat;
    background-position: 0 100%, 100% 0;
  }

  .sns-list {
    position: absolute;
    top: 50%;
    right: 0;
    margin-top: -50px;
    @include clearfix;

    li {
      float: left;
      margin-left: 53px;
    }

    &__anchor {
      display: block;
      font-size: 0;

      &:focus {
        outline: 1px dashed #ccc;
      }
    }
  }
}

.contract-invitation__item {
  margin-top: 60px;

  .c-radio-box {
    margin-top: 0;
  }

  &:first-of-type {
    margin-top: 50px;
  }
}

// 공통 (에러 화면)
.error {
  padding-top: 100px;
  text-align: center;
  background: url('../img/com/img_error_mark.gif') no-repeat 50% 0;

  &-msg {
    font-family: 'youandiR';

    .em {
      display: block;
      margin-bottom: 10px;
      font-family: 'youandiB';
      font-size: 21px;
    }
  }

  &-desc {
    width: 500px;
    margin: 35px auto 0;
    padding: 37px 0;
    border-radius: 6px;
    background-color: #f8f8f8;
    text-align: center;
    color: #666;
  }

  &.t-page {
    width: 500px;
    margin: 100px auto 0;

    .c-table {
      margin: 36px auto 0;
    }
  }

  .c-button-wrap .o-button:only-child {
    min-width: 160px;
  }

  &-container {
    display: flex;
    height: calc(100vh - 189px);
    align-items: center;
    justify-content: center;
  }
}

body.error-page .l-foot,
body.error-page .breadcrumb {
  display: none !important;
}
