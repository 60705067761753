@charset "euc-kr";

@font-face {
  font-family: "youandiB";
  src: url('../font/Youandi_Modern_HeadExtraBold.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "youandiR";
  src: url('../font/Youandi_Modern_TextRegular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "youandiTB";
  src: url('../font/YouandiModernTextBold.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "youandiHB";
  src: url('../font/YouandiModernHeadBold.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "youandiHR";
  src: url('../font/YouandiModernHeadRegular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Noto Sans';
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src: local('Noto Sans KR Black'), local('NotoSansKR-Black'), url('../font/NotoSansKR-Bold-Hestia.woff') format('woff2');
}

@font-face {
  font-family: 'Noto Sans';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: local('Noto Sans KR Medium'), local('NotoSansKR-Medium'), url('../font/NotoSansKR-Medium-Hestia.woff') format('woff');
}

@font-face {
  font-family: 'Noto Sans';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local('Noto Sans KR Regular'), local('NotoSansKR-Regular'), url('../font/NotoSansKR-Regular-Hestia.woff') format('woff');
}

@font-face {
  font-family: 'Noto Sans';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: local('Noto Sans KR Regular'), local('NotoSansKR-Regular'), url('../font/NotoSansKR-Light-Hestia.otf') format('otf');
}
