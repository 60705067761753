
.ta_c {
  text-align: center !important;
}

.ta_r {
  text-align: right !important;
}

.ta_l {
  text-align: left !important;
}

h4.tit-default {
  padding-left: 10px;
  font-weight: bold;
  background: url("https://www.fubonhyundai.com/images//common/bar_default.gif") no-repeat left center;
}

/* 170706 parking */
.mitem {
  display: none !important;
}

.osym {
  color: #0076c0 !important;
}

.pkpage_z {
  min-width: 730px;
  color: #333333;
}

.pk_body {
  font-family: "youandiHR";
  font-size: 14px;
  overflow-x: auto;
}

.pkpage_w {
  width: 728px;
  height: 368px;
  position: absolute;
  top: 50%;
  left: 50%;
  margin: -184px 0 0 -364px;
}

.pklogo {
  font-size: 0;
  line-height: 0;
  height: 48px;
  text-align: center;
}

.pkcont {
  width: 626px;
  height: 198px;
  border: 1px solid #dcdcdc;
  padding: 60px 50px;
  position: absolute;
  top: 48px;
  left: 0;
}

.pkment_01 {
  font-family: "youandiHB";
  font-size: 30px;
  margin-bottom: 20px;
}

.pkm_pcobj {
  position: absolute;
  top: 60px;
  right: 50px;
}

.pkment_02 {
  line-height: 20px;
}

.pkf_list {
  padding-top: 17px;
}

.pkf_list > li {
  margin-top: 23px;
}

.pkf_td {
  display: inline;
}

.pkf_td.lab {
  font-size: 16px;
  position: relative;
  padding-left: 10px;
  font-family: "youandiB";
}

.pkf_td.lab .bul {
  position: absolute;
  top: 50%;
  left: 0;
  margin-top: -6px;
  background: #959595;
  width: 2px;
  height: 12px;
}

*:first-child + html .pkf_td.lab .bul {
  margin-top: -7px;
}

.pkf_td.spec {
  padding-left: 20px;
}

.pk2 br {
  display: none;
}

.erpage_z {
  min-width: 685px;
}

.er_body {
  font-family: "youandiHR";
  font-size: 14px;
  overflow-x: auto;
}

.erpage_w {
  width: 686px;
  height: 320px;
  position: absolute;
  top: 50%;
  left: 50%;
  margin: -160px 0 0 -343px;
}

.erlogo {
  font-size: 0;
  line-height: 0;
  text-align: center;
  margin-bottom: 35px;
}

.erc_w {
  padding: 40px 0;
  border: 1px solid #e4e4e4;
  border-width: 1px 0;
  text-align: left;
  color: #393939;
}

.erc01 {
  font-size: 16px;
  font-family: "youandiHB";
  margin-bottom: 10px;
}

.erc03 {
  background: #eaeaea;
  padding: 10px 20px;
  margin-top: 30px;
}

.erc_btn_w {
  text-align: center;
  padding-top: 20px;
}

.erc_btn_w .btn_erc {
  margin: 0 5px;
}

.btn_erc {
  display: inline-block;
  color: #fff;
  background: #606060;
  font-size: 14px;
  font-family: "youandiHB";
  padding: 7px 15px 5px;
}

.btn_erc:hover {
  color: #fff !important;
}

.btn_erc.etype2 {
  background: #0076c0;
}

*:first-child + html .btn_erc {
  display: inline;
}