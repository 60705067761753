// Variables
//
// Variables should follow the `$component-state-property-size` formula for
// consistent naming. Ex: $nav-link-disabled-color and $modal-content-box-shadow-xs.

// Color system

$white:    #fff !default;
$gray-100: #f8f8f8 !default;
$gray-200: #f5f5f5 !default;
$gray-300: #eee !default;
$gray-400: #d8d8d8 !default;
$gray-500: #b8b8b8 !default;
$gray-600: #909090 !default;
$gray-700: #666 !default;
$gray-800: #333 !default;
$gray-900: #212529 !default;
$black:    #000 !default;

// fusv-disable
$grays: (
  "100": $gray-100,
  "200": $gray-200,
  "300": $gray-300,
  "400": $gray-400,
  "500": $gray-500,
  "600": $gray-600,
  "700": $gray-700,
  "800": $gray-800,
  "900": $gray-900
) !default;
// fusv-enable

$blue:    #0076c0 !default;
$indigo:  #6610f2 !default;
$purple:  #6f42c1 !default;
$pink:    #d63384 !default;
$red:     #dc3545 !default;
$orange:  #fd7e14 !default;
$yellow:  #ffc107 !default;
$green:   #00a19c !default;
$teal:    #20c997 !default;
$cyan:    #17a2b8 !default;

$colors: (
  "blue":       $blue,
  "indigo":     $indigo,
  "purple":     $purple,
  "pink":       $pink,
  "red":        $red,
  "orange":     $orange,
  "yellow":     $yellow,
  "green":      $green,
  "teal":       $teal,
  "cyan":       $cyan,
  "white":      $white,
  "gray":       $gray-700,
  "gray-dark":  $gray-800
) !default;

$primary:       $blue !default;
$secondary:     $gray-600 !default;
$success:       $green !default;
$info:          $cyan !default;
$warning:       $yellow !default;
$danger:        $red !default;
$light:         $gray-100 !default;
$dark:          $gray-800 !default;
$green:         $green !default;

$theme-colors: (
  "primary":    $primary,
  "secondary":  $secondary,
  "success":    $success,
  "info":       $info,
  "warning":    $warning,
  "danger":     $danger,
  "light":      $light,
  "dark":       $dark,
  "gray":       $gray-100,
  "blue":       $blue,
  "green":      $green
) !default;

$theme-color-interval:      8% !default;


$co-666: #666;
$co-000: #000;
$co-909: #909090;
$co-398: #398ff0;
$co-008: #0076c0;
$co-777: #777;
$co-00a: #00a19c;

$bg-color-f8f: #f8f8f8;
$bg-color-398: #398ff0;
$bg-color-008: #0076c0;
$bg-color-f5f: #f5f5f5;

$background-color: (
  1: $bg-color-f8f,
  2: $bg-color-398,
  3: $bg-color-008,
) !default;

$border-color-f5f: #f5f5f5;
$border-color-eee: #eee;
$border-color-d8d: #d8d8d8;
$border-color-b8b: #b8b8b8;
$border-color-333: #333;

// Options
//
// Quickly modify global styling by enabling or disabling optional features.
$enable-shadows:                              true !default;
$enable-rounded:                              true !default;
$enable-grid-classes:                         true !default;
$enable-important-utilities:                  true !default;
$enable-transitions:                          true !default;
$enable-prefers-reduced-motion-media-query:   true !default;
// Spacing
//
// Control the default styling of most Bootstrap elements by modifying these
// variables. Mostly focused on spacing.
// You can add more entries to the $spacers map, should you need more variation.

$spacer: 20px !default;
$spacers: (
  0: 0,
  1: $spacer * .25,
  2: $spacer * .5,
  3: $spacer * .75,
  4: $spacer,
  5: $spacer * 1.25,
  6: $spacer * 1.5,
  7: $spacer * 1.75,
  8: $spacer * 2,
  9: $spacer * 2.25,
  10: $spacer * 2.5,
) !default;

$negative-spacers: negativify-map($spacers) !default;

// Body
//
// Settings for the `<body>` element.

$body-bg:                   $white !default;
$body-color:                $black !default;
$body-text-align:           null !default;


// Grid breakpoints
//
// Define the minimum dimensions at which your layout will change,
// adapting to different screen sizes, for use in media queries.

$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px
) !default;

@include _assert-ascending($grid-breakpoints, "$grid-breakpoints");
@include _assert-starts-at-zero($grid-breakpoints, "$grid-breakpoints");


// Grid containers
//
// Define the maximum width of `.container` for different screen sizes.

$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1140px
) !default;

@include _assert-ascending($container-max-widths, "$container-max-widths");


// Grid columns
//
// Set the number of columns and specify the width of the gutters.

$grid-columns:                12 !default;
$grid-gutter-width:           30px !default;
$grid-row-columns:            6 !default;

// Links

$emphasized-link-hover-darken-percentage: 15% !default;

$stretched-link-pseudo-element:           after !default;
$stretched-link-z-index:                  1 !default;

// Container padding

$container-padding-x: $grid-gutter-width / 2 !default;

// Typography
//
// Font, line-height, and color for body text, headings, and more.

// stylelint-disable value-keyword-case
$font-family-sans-serif:      -apple-system, "Noto Sans", "맑은 고딕", sans-serif !default;
$font-family-monospace:       SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !default;
$font-family-base:            $font-family-sans-serif !default;

$font-size-sm:                13px !default;
$font-size-lg:                17px !default;
$font-size-base:              15px !default;

$font-weight-lighter:         lighter !default;
$font-weight-light:           300 !default;
$font-weight-normal:          400 !default;
$font-weight-bold:            700 !default;
$font-weight-bolder:          bolder !default;
$font-weight-base:            $font-weight-normal;

$line-height-base:            1.5 !default;
$line-height-lg:              2 !default;
$line-height-sm:              1.25 !default;

$text-muted:                  $gray-600 !default;

$link-color:                              $primary !default;
$link-decoration:                         none !default;
$link-hover-color:                        darken($link-color, 15%) !default;
$link-hover-decoration:                   underline !default;

// utilities

$box-shadow-sm:               0 .125rem .25rem rgba($black, .075) !default;
$box-shadow:                  0 .5rem 1rem rgba($black, .15) !default;
$box-shadow-lg:               0 1rem 3rem rgba($black, .175) !default;
$box-shadow-inset:            inset 0 1px 2px rgba($black, .075) !default;

$border-width:                1px !default;
$border-color:                $gray-300 !default;

$border-radius:               .25rem !default;
$border-radius-lg:            .3rem !default;
$border-radius-sm:            .2rem !default;

$rounded-pill:                50rem !default;

$embed-responsive-aspect-ratios: (
  "21by9": (
    x: 21,
    y: 9
  ),
  "16by9": (
    x: 16,
    y: 9
  ),
  "4by3": (
    x: 4,
    y: 3
  ),
  "1by1": (
    x: 1,
    y: 1
  )
) !default;

// Z-index master list

$zindex-sticky:                     1020 !default;
$zindex-fixed:                      1030 !default;
$zindex-modal-backdrop:             1040 !default;
$zindex-modal:                      1050 !default;
