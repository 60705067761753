// 텍스트 등장효과
// .ovf-fixed {
//   position: fixed;
//   left: 0;
//   top: 127px;
//   width: 100%;
// }

// .ovf {
//   position: relative;
//   margin-top: 430px;
//   margin-left: -50vw;
//   margin-right: -50vw;
//   background-color: $white;
//   z-index: 1;
// }

// .ovf-fixed {
//   position: fixed !important;
//   top: 0;
//   left: 0;
//   right: 0;
//   width: 100%;
//   height: 430px;
//   margin-top: 130px;
//   z-index: 5;
// }

// .ovf {
//   position: relative;
//   margin-top: 430px;
//   height: 100%;
//   z-index: 5;
// }

$darkgreen: #007688;

.co-darkgreen {
  color: $darkgreen;
}

.product-submain-bottom {
  position: relative;
  width: 1180px;
  margin: 0 auto;
  background-color: $white;
  padding-top: 86px;
  z-index: 5;

  &:before {
    content: '';
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    top: 0;
    height: 16px;
    // background-color: #f8f8f8;
    
    border-top: 16px solid #f8f8f8;
    display: block;
    width: 100vw;
  }

  &>.info {
    margin-top: 100px;
  }
}

.product-submain {
  $root: '.product-submain';
  // padding-top: 430px;
  // background-color: $white;

 .page-head {
    position: relative;
    min-height: 328px;
    margin-bottom: 0;
    padding: 84px 0 115px;

    .bg-wrapper {
      position: absolute;
      top: 0;
      left: 50%;
      transform: translateX(-50%);
      min-width: 1280px;
      width: 100vw;
      height: 100%;
      background-color: #00a19c;
      background-image: url('../img/com/bg_product_submain_head_1.png'),
        url('../img/com/bg_product_submain_head_2.png');
      background-position: -50px -320px, 100% 50%;
      background-repeat: no-repeat, no-repeat;
      z-index: -1;
    }

    &__text {
      margin-top: 0;
      font-size: 25px;
      color: $white;
    }

    &__title {
      margin: 30px 0 77px;
      line-height: 1;
      font-family: 'youandiB';
      font-size: 44px;
      color: $white;

      small {
        display: block;
        margin-top: 24px;
        font-family: 'youandiR';
        font-size: 19px;
      }
    }

    // 기업복지 상품용
    &--type2 {
      padding-bottom: 0;

      .page-head__title {
        margin-bottom: 0;
      }
    }
  }

  .inner {
    padding: 100px 0;

    &>.o-heading:first-child {
      margin-top: 0;
    }
  }

  .page-section {
    background-color: $white;

    &.gradient {
      background: linear-gradient(90deg, $blue 30%, #00a19c 100%);
      color: $white;

      .point:after {
        background-color: $blue;
        background-image: url('../img/insurance/bg_product_submain_dot_white.png');
      }
    }

    &.bg-lightgreen {
      background-color: #ebf8f7;
    }

    .inner {
      padding-left: 60px;
      position: relative;
      background-color: inherit;
      @include clearfix;

      &>div {
        background-color: inherit;
      }

      &>.fl-left {
        width: 530px;
      }

      &>.fl-right {
        width: 590px;
      }
    }

    &:not(:last-child) .inner:before {
      content: '';
      position: absolute;
      display: block;
      top: 0;
      left: 0;
      width: 1px;
      height: calc(100% + 100px);
      background-color: #e8e8e8;
      z-index: 1;
    }

    &.bg-dark {
      color: $white;

      .source {
        color: $white;
      }
    }

    .point {
      position: relative;
      display: block;
      background-color: inherit;
      font-family: 'youandiB';
      font-size: 15px;

      &:after {
        content: '';
        display: block;
        position: absolute;
        width: 10px;
        height: 67px;
        left: -65px;
        top: 50%;
        transform: translateY(-50%);
        background-color: inherit;
        background-image: url('../img/insurance/bg_product_submain_dot.png');
        background-repeat: no-repeat;
        background-position: 50% 50%;
        z-index: 5;
      }
    }

    &__title {
      margin: 40px 0 30px;
      line-height: 1.4;
      font-family: 'youandiR';
      font-size: 32px;

      .em,
      &.em {
        font-family: 'youandiB';
      }
    }

    &__subtitle {
      display: block;
      margin-bottom: 20px;
      font-family: 'youandiR';
      font-size: 21px;
    }

    &__label {
      display: inline-block;
      margin-bottom: 20px;
      padding: 5px 25px;
      border: 1px solid $blue;
      border-radius: 60rem;
      font-family: 'youandiR';
      font-size: 19px;
      color: $blue;
    }

    .source {
      font-style: normal;
      color: #666;
    }
  }

  .video-wrap {
    position: relative;
    height: 442px;

    video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    // &[poster] {
    //   width: 100%;
    //   height: 100%;
    // }
    }
  }

  .content-row {
    margin: 60px 0 0;
    width: 1080px;
    font-size: 0;

    &--half .content-col {
      width: 50%;

      &:nth-of-type(1) {
        padding-right: 10px;
      }

      &:nth-of-type(2) {
        padding-left: 10px;
      }

      &>div {
        width: 100%;
      }
    }

    .content-col {
      display: inline-block;
      font-size: 15px;
    }
  }
}

 // 텍스트 등장효과
.product-submain__animation1 {
  opacity: 0;
  transition: opacity 1s;

  .active & {
    opacity: 1;
  }
}

.product-submain__animation2 {
  display: block;
  overflow: hidden;

  i {
   font-style: normal;
   display: block;
   line-height: inherit;
   transform: translateY(100%);
   transition: transform 1s ease;
  }

  .active & i {
    transform: translateY(0);
  }
}

// 카드 등장효과
.product-submain-card,
.slide-up {
  transform: translateY(70px);
  transition: transform .4s;
}

.active.product-submain-card,
.active.slide-up {
  transform: translateY(0);
}

// 보험료 계산하기 플로팅 배너
.consulting-banner {
  display: none;
  position: fixed;
  bottom: 40px;
  left: 50%;
  margin-left: -610px;
  transform: translateY(100%);
  width: 1220px;
  padding: 28px 60px;
  border-radius: 12px;
  background-color: $white;
  box-shadow: 0 0 45px 10px rgba(0, 0, 0, 0.1);
  font-size: 23px;
  z-index: 20;
  opacity: 0;
  transition-property: transform opacity;
  transition-duration: .5s;

  .o-button {
    position: absolute;
    right: 60px;
    top: 18px;
  }

  &.is-active {
    transform: translateY(0);
    opacity: 1;
  }
}

.product-submain .c-text-list {
  margin-top: 40px;

  .c-text-list__item {
    margin-top: 5px;
    line-height: 1.5;
    color: #666;
  }

  &--half .c-text-list__item {
    float: left;
    min-width: 45%;
  }
}

// 보험상품 상세페이지 내 보험상품 특장점
.product-submain .product-spec-wrap {
  position: relative;
  margin: 0 auto;
  top: -100px;
  width: 1180px;
  padding: 15px 50px;
  background-color: $white;
  border-radius: 15px;
  border-bottom-right-radius: 40px;
  box-shadow: 0 35px 45px 0px rgba(0, 0, 0, 0.1);
  transform: translateY(50px);
  opacity: 0.9;
  animation: up-ani .5s forwards;
  @include clearfix;

  .product-spec {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -30px;

    &:only-child {
      margin-bottom: 25px;
    }
  }

  .product-spec__item {
    margin: 25px 0 0;
    padding: 0;
    padding-left: 80px;
    width: calc(50% - 60px);
    min-height: 50px;
    margin-left: 30px;
    border-top: none;

    .c-text-list {
      margin-top: 5px;

      .c-text-list__item {
        margin-top: 2px;
      }
    }

    .o-text--star,
    .c-text-list__item {
      font-size: 13px;
    }
  }

  .product-spec__text {
    word-break: keep-all;
  }

  &:not(.product-spec-wrap--col3) .product-spec__item:nth-child(odd) {
    margin-right: 30px;
  }

  &--col3 .product-spec__item {
    width: 340px;
    padding-left: 70px;
  }

  .product-spec__image {
    top: 0;
    left: 0;
  }

  .c-button-wrap {
    clear: left;
    margin: 25px 0 -15px;
    padding: 25px 0;
    border-top: 1px solid #e8e8e8;

    .o-button~.o-button {
    margin-left: 10px;
    }
  }

  &+.inner {
    padding-top: 0 !important;
  }

  @keyframes up-ani {
    100% {
      transform: translateY(0);
      opacity: 1;
    }
  }
}

// 보험상품 상세페이지 내 카드 스타일
.product-submain-card {
  $root: '.product-submain';
  display: inline-block;
  overflow: hidden;
  box-shadow: 20px 25px 40px rgba(0, 0, 0, 0.14);
  border-radius: 20px;
  border: 1px solid #d8d8d8;
  text-align: center;
  font-size: 15px;

  &:only-child {
    width: 454px;
  }

  #{$root}-card__head {
    padding: 34px 20px;
    background-color: #018bcf;
  }

  #{$root}-card__panel {
    padding: 22px;
    background-color: $white;
  }

  #{$root}-card__label {
    display: inline-block;
    position: absolute;
    top: 0;
    left: 50%;
    transform: translate(-50%, -50%);
    min-width: 100px;
    padding: 0 15px;
    background-color: #666;
    border-radius: 60rem;
    line-height: 32px;
    font-family: 'youandiR';
    color: $white;
  }

  #{$root}-card__highlight {
    display: block;
    line-height: 1.3;
    font-family: youandiB;
    font-size: 40px;

    &+#{$root}-card__text {
      margin-top: 8px;
    }
  }

  #{$root}-card__text {
    font-size: 20px;
    color: #666;
  }

  #{$root}-card__foot {
    position: relative;
    padding: 22px;
    background-color: #16a5a3;
    color: $white;
  }

  &--type1 {

    #{$root}-card__highlight {
      color: #018bcf;
    }

    .inside-list {
      margin: -6px;

      li {
        display: inline-block;
        line-height: 38px;
        margin: 6px 2px;
        padding: 0 20px;
        border-radius: 60rem;
        border: 1px solid $white;
        font-family: youandiR;
        font-size: 20px;
        color: $white;
      }
    }
  }

  &--type2 {
    position: relative;
    width: 286px;
    margin: 0 3px;
    overflow: visible;
    border-radius: 20px;
    border: none;

    .d-flex & {
      flex: 1;
    }

    #{$root}-card__highlight {
      font-size: 19px;
      color: $white;
    }

    #{$root}-card__panel {
      padding: 40px 20px;
      border-radius: 20px 20px 0 0;
      overflow: hidden;
      border: 1px solid #d8d8d8;
      border-bottom: none;
    }

    #{$root}-card__title {
      line-height: 1;
      font-size: 21px;

      .highlight-underline {
        position: relative;
        display: inline-block;
        padding: 0 5px 5px;
        z-index: 1;

        &:after {
          content: '';
          position: absolute;
          left: 0;
          bottom: 0;
          display: block;
          width: 100%;
          height: 12px;
          background-color: #fff558;
          z-index: -1;
        }
      }
    }

  #{$root}-card__img {
    display: block;
    margin: 0 auto 20px;
    line-height: 0;
  }

  #{$root}-card__foot {
    border-radius: 0 0 20px 20px;
    border: 1px solid #d8d8d8;
    border-top: none;

    &::before {
      content: '';
      display: block;
      position: absolute;
      bottom: 100%;
      left: 50%;
      margin-left: -20px;
      margin-bottom: -1px;
      border-bottom: 8px solid #16a5a3;
      border-left: 20px solid transparent;
      border-right: 20px solid transparent;
    }
  }

  &.blue #{$root}-card__foot {
    background-color: $blue;

    &::before {
      border-bottom-color: $blue;
      }
    }
  }

  &--type3 {
    padding: 37px 48px;
    background-color: $blue;
    color: $white;

    #{$root}-card__head {
      background-color: inherit;
    }

    #{$root}-card__text {
      font-size: 17px;
    }

    #{$root}-card__highlight {
      margin-top: 5px;
      font-size: 23px;
    }

    #{$root}-card__panel {
      margin: 25px -48px -37px;
      padding: 30px;
      background-color: #0064a3;
    }

    #{$root}-card__text {
      color: white;
    }
  }

  &--type2 .guarantee {
    display: flex;
    margin: 20px -22px 0;

    &:only-child {
      margin-top: 0;
    }

    &__col {
      position: relative;
      flex: 1;
      text-align: center;
      font-family: youandiR;
    }

    &__amount {
      display: block;
      font-family: 'youandiB';
      font-size: 17px;

      &:nth-child(2) {
        margin-bottom: 5px;
      }

      &:last-child {
        margin-top: 5px;
      }
    }
  }

  .guarantee--type1 .guarantee__col:not(:first-child) {
    border-left: 1px solid #d8d8d8;
  }

  .guarantee--type2 .guarantee__col:not(:first-child):before {
    content: '';
    display: block;
    position: absolute;
    top: 50%;
    left: 0;
    transform: translate(-50%, -50%);
    width: 18px;
    height: 18px;
    background: url('../img/insurance/ico_cross.png') no-repeat 0 0/cover;
  }
}

.product-submain-card .ins-plan {
  position: relative;
  display: flex;
  margin: 45px -5px 0;

  li {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-width: 88px;
    min-height: 100px;
    margin: 0 6px;
    padding: 15px 5px;
    border-radius: 15px;
    background-color: $white;
    font-family: 'youandiR';
    font-size: 19px;
    color: $black;

    &.bg-black {
      background-color: $black;
      color: $white;
    }

    &:last-child {
      margin-left: auto;
    }

    &:not(:first-child):before {
      content: '';
      position: absolute;
      display: block;
      top: -36px;
      left: 50%;
      margin-left: -27px;
      width: 55px;
      height: 55px;
      background: url('../img/insurance/ico_coin.png');
    }
  }

  &:before {
    content: '';
    display: block;
    position: absolute;
    left: 8px;
    right: 8px;
    top: 50%;
    height: 2px;
    background: url('../img/insurance/bg_dashed.png') repeat-x 0 0;
    // border: 1px dashed $white;
    // z-index: -1;
  }

  &__em {
    display: block;
    font-family: youandiB;
  }

  // MAX 연금보험
  &--type2 {
    margin: 0;
    position: relative;

    &:before {
      content: none;
    }

    &:after {
      content: '';
      display: block;
      position: absolute;
      width: 253px;
      height: 51px;
      top: 50%;
      left: 50%;
      margin-left: -127px;
      margin-top: -25px;
      background: url('../img/insurance/bg_max_pension_plan.png') no-repeat 0 50%;
    }

    li {
      min-width: 70px;
      min-height: 70px;
      padding: 10px;
      background-color: #0064a3;
      border: 2px solid white;
      color: white;
      flex-grow: 0;
      font-family: 'youandiB';
      font-size: 17px;
      z-index: 1;

      &:before {
        content: none !important;
      }
    }
  }
}


// ZERO 치아보험
.dental-fee {
  padding-left: 80px;

  .card {
    width: 440px;
    border-radius: 15px;
    font-family: 'youandiR';
    overflow: hidden;
    box-shadow: 20px 25px 40px rgba(0, 0, 0, 0.14);

    &__panel {
      min-height: 178px;
      padding: 35px 60px;
      background: $blue url('../img/insurance/bg_dental_card.png') no-repeat 330px 50px;
      line-height: 1.4;
      font-size: 31px;
      color: $white;

      .em {
        font-family: 'youandiB';
        font-size: 39px;
      }
    }

    &__foot {
      padding: 25px 60px;
      background-color: $white;
      font-size: 21px;

      .em {
        font-size: 23px;
      }
    }
  }
}

.dental-graph {
  padding-left: 80px;

  .graph-container {
    width: 440px;
  }
}

.dental-case {
  display: flex;
  margin-left: -25px;

  .card {
    width: 360px;
    min-height: 164px;
    margin: 0 5px;
    padding: 25px 35px;
    border-radius: 10px;
    background-color: $white;
    background: $white url('../img/insurance/bg_dental_card_2.png') no-repeat 268px 44px;
    box-shadow: 20px 0 40px rgba(0, 0, 0, 0.1);
  }

  .card__title {
    font-size: 17px;
    color: $black;

    &+.o-text {
      margin-top: 10px;
    }
  }

  .c-text-list {
    margin-top: 10px;

    .c-text-list__item {
      font-size: 14px;
      color: #666;
    }
  }
}

// ZERO 성인병
.adult-disease-fee {
  margin-bottom: -100px;
}

// ZERO 성인병보험
.medical-receipt {
  position: relative;
  width: 500px;
  margin: 0 auto;
  padding: 13px 20px 0;
  background: url('../img/insurance/bg_receipt_head.png') no-repeat 0 0/100% auto;

  &-wrap {
    padding: 44px 50px 34px;
    border: 1px solid #eee;
    border-bottom: none;
    background-color: #fafafa;
    background-image: url('../img/insurance/bg_receipt_inner_shadow.gif'),
    url('../img/insurance/bg_receipt_foot.gif');
    background-repeat: repeat-x, no-repeat;
    background-position: 0 0, 0 100%;
    box-shadow: 20px 15px 24px 1px #e7e7e7;
  }

  &__title {
    margin-bottom: 5px;
    font-family: 'youandiB';
    font-size: 23px;
  }

  .inside-list {

    li {
      display: flex;
      align-items: center;
      padding: 25px 0;
      font-family: 'youandiR';
      font-size: 17px;

      .amount {
        display: inline-block;
        margin-left: auto;
        font-family: 'youandiB';
        font-size: 19px;
      }

      & + li {
        border-top: 1px solid #d8d8d8;
      }
    }

    .highlight-item {
      color: $blue;
    }
  }
}

// ZERO 메디컬보험
.medical-case {
  display: flex;
  align-items: stretch;
  font-size: 18px;

  &__item {
    flex: 1;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    padding: 25px 35px;
    border-radius: 20px;
    line-height: 1.3;
    color: $white;

    &:first-of-type {
      background-color: $green;
    }

    &:nth-of-type(2) {
      background-color: $blue;
    }

    &:nth-of-type(3) {
      flex: 0 1 auto;
      width: 260px;
      justify-content: center;
      background-color: #004b75;
      color: #fdff78;
    }
  }

  &__em {
    display: inline-block;
    font-family: 'youandiB';
    font-size: 25px;
  }

  .op {
    display: block;
    width: 48px;
    text-align: center;

    &.multiple {
      background: url('../img/insurance/img_operator_multiple.png') no-repeat 50% 50%;
    }

    &.equal {
      background: url('../img/insurance/img_operator_equal.png') no-repeat 50% 50%;
    }
  }
}

// ZERO 정기보험
.card-compare {
  $itemH: 250px;
  display: flex;
  width: 438px;
  margin: 0 auto;
  border-radius: 15px;
  overflow: hidden;
  border: none;
  background-color: $white;
  box-shadow: 20px 25px 40px rgba(0, 0, 0, 0.14);
  text-align: center;

  &__item {
    flex: 1;
    height: #{$itemH};
    padding: 50px 20px;
    font-family: youandiR;
    font-size: 21px;
  }

  &__item.bigger {
    position: relative;
    background-color: #00a39f;
    color: $white;

    &:before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    z-index: 2;
    border-left: 21px solid #00a39f;
    border-top: #{$itemH/2} solid $white;
    border-bottom: #{$itemH/2} solid $white;
    }
  }

  &__image {
    display: block;
    margin: 0 auto 20px;
    font-size: 0;
  }

  &__em {
    font-size: 25px;
  }
}

.product-compare {
  $itemH: 269px;
  position: relative;
  min-height: 269px;
  padding: 40px 60px;
  border-radius: 20px;
  background: linear-gradient(0deg, $blue 0%, #00a19c 100%);
  font-size: 17px;
  color: $white;
  overflow: hidden;

  &:before {
    content: '';
    display: block;
    position: absolute;
    width: 459px;
    height: 100%;
    background-color: #333;
    right: 0;
    top: 0;
  }

  .triangle {
    position: absolute;
    top: 0;
    left: 50%;
    width: 0;
    height: 0;
    transform: translateX(-50%);
    border-bottom: #{$itemH} solid #333;
    border-left: 162px solid transparent;
  }

  &__item {
    width: 50%;
    position: relative;
  }

  .vs {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: skewX(-11deg) translate(-50%, -50%);
    margin-left: -10px;
    font-family: 'youandiB';
    letter-spacing: -8px;
    font-size: 86px;
    color: $dark;
    z-index: 10;
  }

  &__title {
    display: block;
    line-height: 1.4;
    font-family: 'youandiR';
  }

  &__highlight {
    display: block;
    margin: 25px 0 20px;
    font-family: 'youandiB';
    font-size: 19px;

    .em {
      display: block;
      font-size: 28px;
      font-style: normal;
    }

    .fee .em::first-letter {
      font-family: 'youandiR';
    }
  }
}

// MAX 연금보험
.ins-tip {
  position: relative;
  background-color: $white;
  margin-top: 30px;
  padding: 31px 15px;
  border-radius: 20px;
  text-align: center;

  &__label {
    position: absolute;
    display: inline-block;
    margin: 0 auto;
    padding: 0 14px;
    border-radius: 60rem;
    line-height: 34px;
    left: 50%;
    top: -14px;
    transform: translateX(-50%);
    background-color: #f65726;
    font-family: 'youandiB';
    font-size: 17px;
    color: white;
  }
}

// MAX 연금보험 하이파이브
.highfive-scenario {
  display: inline-block;
  width: 520px;
  margin-right: 30px;
  border-radius: 15px;
  background-color: $white;
  border: 1px solid #e8e8e8;
  box-shadow: 20px 25px 40px rgba(0, 0, 0, 0.14);
  overflow: hidden;

  &__title {
    display: block;
    border-radius: 5px;
    background-color: #00a19c;
    padding: 18px 0px;
    text-align: center;
    font-family: 'youandiR';
    font-size: 18px;
    color: $white;
  }

  &-list {

    li {
      position: relative;
      display: flex;
      align-items: center;
      height: 110px;
      flex-direction: row-reverse;
      text-align: center;
      line-height: 1.6;

      &+li {
        border-top: 1px solid #e8e8e8;

        .pay:after {
          content: '';
          position: absolute;
          left: 50%;
          top: -8px;
          display: block;
          width: 1px;
          height: 17px;
          background-color: #171717;
        }
      }

      &:nth-child(2n) {
        background-color: #f8f8f8;
      }

      &>* {
        display: inline-block;
        flex: 1;
      }

      .amount {
        font-family: 'youandiB';
        font-size: 21px;
        color: #00a19c;
      }

      .pay {
        flex-basis: auto;
        flex-grow: 0;
      }
    }
  }

  &__img {
    font-size: 0;
  }
}

// MAX 종신보험
.life-vs {
  position: relative;
  width: 475px;
  margin: 0 auto;
  text-align: center;
  $wrapperH: 338px;

  .life-vs-wrap {
    position: relative;
    @include clearfix;
  }

  .text-vs {
    position: absolute;
    display: inline-block;
    width: 100%;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    font-family: 'youandiB';
    font-size: 23px;
    color: #006aac;
  }

  &.active .text-vs {
    animation: em-ani 1s .4s forwards;
  }

  @keyframes em-ani {
    50% {
      transform: translateY(-50%) scale(1.5);
    }

    100% {
      transform: translateY(-50%) scale(1);
    }
  }

  &__head {
    @extend .life-vs__item;
    background-color: $white;
    border-top: 2px solid #333;
    font-size: 16px;
    color: #333;

    &.co-blue {
      border-top-color: $blue;
    }
  }

  &__col {
    display: flex;
    flex-direction: column;
    width: 196px;
    height: #{$wrapperH};
    margin: -2px 0;
    line-height: 1.3;
    font-family: 'youandiR';
    font-size: 19px;
    color: $white;
    transition: transform .6s .2s;

    .life-vs__item {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 2px 0;
      padding: 20px 0;

      sup {
        display: block;
        position: absolute;
        top: 4px;
        right: 4px;
        width: 20px;
        height: 20px;
        border-radius: 50%;
        background-color: $white;
        line-height: 20px;
        font-family: $font-family-base;
        color: #666;
      }
    }

    .bg-gradient {
      background-image: linear-gradient(90deg, $blue 0%, #00a19c 100%);
      color: $white !important;
    }

    .bg-gray {
      background-color: #666 !important;
    }

    .bg-darkblue {
      background-color: #00558a;
    }

    .co-dark {
      background-color: #eceff8;
    }

    .bg-point {
      background-color: #e5f3fa;
      border: 2px dashed $blue;
      font-family: 'youandiB';
      color: #006aac;
    }
  }

  &__col:nth-of-type(1) {
    float: left;
    transform: translateX(-30%);
  }

  &__col:nth-of-type(2) {
    float: right;
    transform: translateX(30%);
  }

  &.active .life-vs__col {
    transform: translateX(0);
  }
}

.life-vs--type2 {

  .life-vs__title .em {
    display: block;
    margin-bottom: 25px;
    font-size: 19px;
  }

  .life-vs__col {
    height: auto;
  }

  .life-vs__item {
    margin: 4px 0;
  }
}

.life-guarantee {
  display: inline-block;
  width: 520px;
  vertical-align: top;

  dl {

    dt, dd {
      display: inline;
      font-family: 'youandiR';
      font-size: 17px;
    }

    dt {
      margin-right: 20px;
      font-family: 'youandiB';
      font-size: 19px;
    }
  }

  .inside-card-wrap {
    @include clearfix;
    position: relative;
    clear: left;
    font-size: 0;

    &:first-of-type:before {
      content: '';
      position: absolute;
      display: block;
      bottom: 30px;
      left: 50%;
      width: 214px;
      height: 15px;
      transform: translateX(-50%);
      background: url('../img/insurance/bg_life_guarantee_arr.png') repeat-x 0 0;
    }
  }

  .inside-card {
    display: inline-block;
    width: 100%;
    overflow: hidden;
    margin-top: 25px;
    border-radius: 20px;
    background-color: #00a19c;
    text-align: center;
    font-family: 'youandiR';
    font-size: 10px;
    color: $white;

    &.bg-gray {
      background-color: #666 !important;
    }

    &.w-50 {

      &:before {
        background-image: url('../img/insurance/bg_life_guarantee_dot_2.png');
      }
    }

    &__head {
      padding: 25px 10px 15px;
      font-size: 18px;

      .em {
        display: block;
        font-family: 'youandiB';
        font-size: 25px;
      }
    }

    &__panel {
      position: relative;
      display: flex;
      align-items: center;
      height: 75px;
      padding: 20px 74px;
      font-size: 17px;
      color: $white;

      &:before {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        display: block;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, .15);
      }

      .panel-text {
        position: relative;
        display: inline-block;
        min-width: 60px;
        text-align: left;
      }
    }
  }

  .inside-tooltip {
    position: absolute;
    left: 50%;
    top: 100%;
    margin-top: -16px;
    transform: translateX(-50%);
    min-width: 175px;
    padding: 11px 10px;
    border-radius: 60rem;
    background-color: #ffde00;
    text-align: center;
    font-size: 15px;
    color: $black;
    box-shadow: 0px 6px 19px 0px rgba(0, 0, 0, 0.15);
    z-index: 1;

    &::before,
    &::after {
      content: '';
      display: block;
      position: absolute;
    }

    &::before {
      bottom: calc(100% - 1px);
      left: 50%;
      margin-left: -21px;
      width: 41px;
      height: 32px;
      background: url('../img/insurance/bg_life_guarantee_tooltip.png') no-repeat 0 0/cover;
    }
  }
}

// MAX 종신보험 선지급형
.life-advance-guarantee {
  display: flex;
  flex-wrap: wrap;

  &__item {
    position: relative;
    width: 360px;
    min-height: 145px;
    margin: 9px 0;
    padding: 35px 30px;
    padding-right: 105px;
    border-radius: 10px;
    background-color: $white;
    border: 1px solid #e8e8e8;
    font-size: 14px;

    &:nth-child(3n-1) {
      margin-left: 10px;
      margin-right: 10px;
    }
  }

  &__title {
    display: block;
    margin-bottom: 10px;
    font-family: 'youandiR';
    font-size: 15px;
  }

  .inside-list .inside-list__item {
    display: inline-block;
    margin-top: 2px;
    line-height: 1.5;
    color: #666;

    &:not(:last-child):after {
      content: ', ';
    }
  }

  .panel--aside {
    position: absolute;
    width: 75px;
    height: 100%;
    top: 0;
    right: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-left: 10px;
    padding-right: 10px;
    border-radius: 0 10px 10px 0;
    background: $darkgreen url('../img/insurance/bg_dotted_line.png') repeat-y 0 6px;
    text-align: center;
    line-height: 1.4;
    font-family: 'youandiB';
    font-size: 16px;
    color: $white;

    .aside-label {
      display: inline-block;
      margin-bottom: 10px;
      padding: 0 8px;
      border-radius: 60rem;
      line-height: 26px;
      background-color: rgba(0, 0, 0, 0.5);
      font-size: 15px;
    }

    .aside-text {
      display: block;
    }

    &:before,
    &:after {
      content: '';
      display: block;
      position: absolute;
      left: 0;
      margin-left: -7px;
      width: 14px;
      height: 7px;
      border-radius: 0 0 7px 7px;
      background-color: #ebf8f7;
      border: 1px solid #e8e8e8;
      border-top: none;
      z-index: 1;
    }

    &:before {
      top: -1px;
    }

    &:after {
      top: initial;
      bottom: -1px;
      transform: rotate(180deg);
    }
  }
}

// 기업복지
.ins-summary {

  .o-heading--2:not(:first-child) {
    margin-top: 40px;
    padding-top: 40px;
    border-top: 1px solid #e8e8e8;
  }
}

// 심의필
.info__passage-review {
  margin: 38px auto 18px;
  text-align: center;
  color: #666;
}

// 질병순위 룰렛
.ranking-roulette {
  position: relative;
  width: 500px;
  height: 300px;
  margin: 0 auto;
  overflow: hidden;

  .roulette-container {
    position: relative;
    height: 100%;
    margin: 0 20px;
    background: $white;
    border-radius: 20px;
    text-align: center;

    &::before {
    content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border-radius: inherit;
      background: url('../img/insurance/bg_roulette.png') no-repeat 0 0/100% 100%;
      z-index: 10;
    }
  }

  .roulette-item {
    transition: none;
    overflow: hidden;
    line-height: 74px;
    white-space: nowrap;
    font-family: 'youandiR';
    font-size: 25px;
    color: #666;

    &.finish {
      transition: font-size .4s;
      font-family: 'youandiB';
    }

    &.finish.last_motion {
      margin: 0 -20px;
      border-radius: 10px;
      background: linear-gradient(90deg, $blue 0%, #00a19c 100%);
      // box-shadow: 20px 25px 40px rgba(0, 0, 0, 0.14);
      font-family: 'youandiB';
      font-size: 35px;
      color: $white;
    }

    &-wrapper {
      transition: 1s ease-out;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
    }
  }
}

// 경제적 부담 콘텐츠 (저금통에서 돈 떨어지는 애니메이션)
.obj-pig-container {
  position: absolute;
  text-align: center;
  // margin: -60px 0 -100px;
  bottom: 0;
  right: 130px;
  width: 340px;
  padding: 0 20px 150px;
  overflow: hidden;
  font-size: 0;

  &:before {
    content: '';
    position: absolute;
    top: -40px;
    left: 50%;
    display: block;
    background: url('../img/insurance/bg_pig_gradient.png') no-repeat 0 0/ 100% 100%;
    width: 340px;
    height: 340px;
    margin-left: -170px;
  }

  .obj-pig {
    position: relative;
    display: inline-block;
    width: 228px;
    height: 174px;
    margin: 0 auto;
    background: url('../img/insurance/img_pig.png') no-repeat 0 0/cover;
    z-index: 5;
  }

  .coins-wrap {
    position: absolute;
    top: 120px;
    left: 50%;
    height: 244px;
    width: 100px;
    margin-left: -50px;
    z-index: 3;

  .coin {
    position: absolute;
    display: inline-block;
    width: 46px;
    height: 44px;
    background: url('../img/insurance/img_pig_coin.png') no-repeat 0 0/100%;

      &--1 {
        left: 35px;
      }

      &--2 {
        left: 10px;
      }

      &--3 {
        left: 55px;
      }

      &--4 {
        left: 25px;
      }
    }
  }

  &.active .coin {
    animation-iteration-count: infinite;

    &--1 {
      animation-name: falling-type1;
      animation-duration: 1.3s;
    }

    &--2 {
      animation-name: falling-type2;
      animation-duration: 1s;
      animation-delay: 1.5s;
    }

    &--3 {
      animation-name: falling-type1;
      animation-duration: 1.6s;
      animation-delay: 2s;
    }

    &--4 {
      animation-name: falling-type2;
      animation-duration: 1.8s;
      animation-delay: 2.5s;
    }
  }

  @keyframes falling-type1 {
    from {
      transform: scale(1);
      bottom: 100%;
      opacity: 1;
    }

    to {
      transform: scale(0.8);
      bottom: -50px;
      opacity: 0.5;
    }
  }

  @keyframes falling-type2 {
    from {
      transform: scale(0.8);
      bottom: 100%;
      opacity: 0.8;
    }

    to {
      transform: scale(0.6);
      bottom: -50px;
      opacity: 0.4;
    }
  }
}

// 돈 저축되는 콘텐츠 (저금통으로 돈 떨어지는 애니메이션)
.obj-pig-container.type2 {
  padding: 150px 20px 0;
  top: 0;
  bottom: initial;

  &:before {
    top: 30px;
  }

  &:after {
    content: '';
    position: absolute;
    display: block;
    left: 50%;
    margin-left: -114px;
    width: 228px;
    height: 174px;
    top: 150px;
    z-index: 10;
    background: url('../img/insurance/img_pig_over.png') no-repeat 0 0;
  }

  .coins-wrap {
    top: -50px;
    margin-left: -63px;
    z-index: 5;
  }
}

// 보험상품 상세 막대 그래프
.graph-container {
  $root: '.graph';

  .active#{$root}__bar .graph__value,
  .active#{$root}__bar .graph__label {
    opacity: 1;
  }
}

.graph-container--vertical {
  width: 480px;
  $root: '.graph';

  #{$root}__bar.bg-gradient {
    background: linear-gradient(0deg, $blue 30%, #00a19c 100%);
  }

  #{$root}-grid {
    height: 260px;
    background: url('../img/insurance/bg_graph_line.gif') repeat-x 0 100%;
    box-sizing: border-box;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
  }

  #{$root}-item-wrap {
    font-size: 0;
    text-align: center;
    border-bottom: 1px solid #666;

    &.outset {

      #{$root}__value {
        bottom: 100%;
        padding-bottom: 10px;
      }

      .bg-gradient #{$root}__value {
       color: $blue;
      }
    }

    &.inset  {

      #{$root}__value {
        top: 25px;
        color: #666;
      }

      .bg-green #{$root}__value,
      .bg-blue #{$root}__value,
      .bg-gradient #{$root}__value {
        color: $white;
      }
    }

    #{$root}__item {
      position: relative;
      display: inline-block;
      width: 80px;
      height: 260px;
      margin: 0 6px;
    }
  }

  #{$root}-data-wrap {
    height: 260px;
    box-sizing: border-box;
    position: relative;
  }

  #{$root}__bar {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 0;
    box-sizing: border-box;
    background-color: #666;
    border-radius: 10px 10px 0 0;
    transition: 1s;
  }

  #{$root}__value {
    position: absolute;
    left: 0;
    width: 100%;
    text-align: center;
    font-family: 'youandiB';
    font-size: 19px;
    color: $black;
    opacity: 0;
    transition: opacity 2s .5s;
  }

  #{$root}-label-wrap {
    display: flex;
    text-align: center;
    margin-top: 22px;
    font-size: 0;

    #{$root}__label {
      display: inline-block;
      flex: 1;
      letter-spacing: -0.5px;
      font-size: 14px;
      color: #666;

      &.fb-auto {
        flex: 0 1 auto;
      }
    }
  }

  // ZERO 암보험 그래프
  &.t-cancel {
    width: 560px;

    #{$root}__item {
      width: 110px;
      height: 260px;
      margin: 0 30px;

      &.hatched {
        background: url('../img/insurance/bg_graph_hatched.png') 0 0;
      }
    }

    #{$root}__bar {
      background: #eceff8;
      border-radius: 0;
    }

    #{$root}__bar--1.active {
      height: 54%;
    }

    #{$root}__bar--2.active {
      height: 65%;
    }

    #{$root}__bar--3.active {
      height: 100%;
    }

    #{$root}__value {
      font-size: 23px;
    }

    #{$root}__label {
      // min-width: 120px;
      // margin: 0 25px;
      font-size: 17px;
    }

    #{$root}__bar.increase {
      background: url('../img/insurance/bg_graph_increase.png') no-repeat 50% 100%;

      .increase-value {
        opacity: 0;
        transition: opacity .3s 1s;
      }

      &.active .increase-value {
        position: absolute;
        right: 100%;
        width: 60px;
        height: 100%;
        padding-top: 25px;
        background: url('../img/insurance/ico_graph_increase.png') no-repeat 50% 0;
        font-family: 'youandiB';
        font-size: 17px;
        color: $blue;
        opacity: 1;
      }
    }
  }

  // MAX 연금보험 그래프
  &.t-pension {

    #{$root}__bar--1.active {
      height: 45%;
    }

    #{$root}__bar--2.active {
      height: 80%;
    }

    #{$root}__bar--3.active {
      height: 70%;
    }

    #{$root}__bar--4.active {
      height: 15%;
    }

    #{$root}__bar--5.active {
      height: 5%;
    }
  }

    // MAX 저축보험 그래프
  &.t-savings {
    width: 490px;

    // .graph-legend {
    //   padding-left: 30px;
    //   vertical-align: middle;

    //   .legend {
    //     vertical-align: middle;

    //     &+.legend {
    //       margin-left: 10px;
    //     }
    //   }

    //   .bullet {
    //     display: inline-block;
    //     width: 10px;
    //     height: 10px;
    //     margin-right: 5px;
    //     vertical-align: middle;
    //   }

    //   .legend-1 .bullet {
    //     background-color: $blue;
    //   }

    //   .legend-2 .bullet {
    //     background-color: #eceff8;
    //     border: 1px solid #ccc;
    //   }
    // }


    .graph__bar--1 {
      .graph__value {
        position: relative;

        .legend {
          position: absolute;
          left: -48px;
        }
      }
    }

    #{$root}__item {
      width: 130px;
      height: 260px;
      margin: 0 10px;
    }

    #{$root}__bar {
      background: #eceff8;
      border-radius: 0;
      display: flex;
      flex-direction: column;
    }

    #{$root}__bar--1.active {
      height: 54%;
    }

    #{$root}__bar--2.active {
      height: 65%;
    }

    #{$root}__bar--3.active {
      height: 90%;
    }

    #{$root}__value {
      position: static;
      font-family: 'youandiR';
      font-size: 17px;

      &.outset {
        position: absolute;
        bottom: 100%;
        padding-bottom: 10px;
        font-size: 19px;
      }

      &--base {
        display: flex;
        align-items: center;
        justify-content: center;
        flex: 1;
      }

      .o-icon--up {
        margin-right: 10px;
        border-left: 5px solid transparent;
        border-bottom: 5px solid $white;
        border-right: 5px solid transparent;
      }
    }
  }
}

.graph-container--horizontal {
  $root: '.graph';
  overflow: hidden;
  width: auto;

  #{$root}__item {
    display: flex;
    align-items: center;
    position: relative;
    width: 100%;
    min-height: 45px;
    padding: 0 10px 0 15px;
    border-radius: 0 5px 5px 0;
    background-color: #666;
    text-align: left;
  }

  #{$root}__bar {
    border-radius: 0;
    margin-left: auto;
    font-family: 'youandiB';

    &.bg-gradient {
      background: linear-gradient(90deg, $blue 0%, #00a19c 100%);
    }
  }

  #{$root}__item {
    display: block;
    height: 70px;
    padding: 0;
    border-radius: 0 12px 12px 0;
    background-color: #f8f8f8;
    font-size: 17px;
    color: #000;

    &+ #{$root}__item {
      margin-top: 10px;
    }

    &--highlight {
      margin-bottom: 24px;

      &> #{$root}__value,
      &> #{$root}__label {
        color: $blue !important;
      }

      :after {
        border-top: 35px solid transparent !important;
        border-left: 12px solid $green;
        border-bottom: 35px solid transparent;
      }
    }
  }

  #{$root}__bar {
    position: relative;
    display: flex;
    align-items: center;
    height: 100%;
    margin: 0;
    padding-left: 40px;
    background-color: #666;
    text-align: left;
    color: $white;
    width: 20%;
    transition: width 1s;

    &:after {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      left: 100%;
      padding: 0;
      border-top: 70px solid #666;
      border-right: 18px solid transparent;
    }
  }

  #{$root}__bar #{$root}__label,
  #{$root}__bar #{$root}__value {
    color: white;
    opacity: 0;
    transition: opacity .5s .5s;
  }


  #{$root}__item {

    & > #{$root}__label,
    & > #{$root}__value {
     position: absolute;
     right: 40px;
     top: 0;
     display: flex;
     align-items: center;
     width: auto;
     height: 100%;
     font-family: 'youandiB';
     color: #666;
     z-index: 1;
    }
  }

  // MAX 종신보험 라이트 선지급형 그래프
  &.t-life-advance {
      width: 270px;

    #{$root}__item {
      height: 64px;

      &--highlight :after {
        border-top-width: 32px !important;
        border-bottom-width: 32px !important;
      }
    }

    #{$root}__bar:after {
      border-top-width: 64px;
    }

    #{$root}__bar--1.active {
      width: 59%;
    }

    #{$root}__bar--2.active {
      width: 50%;
    }

    #{$root}__bar--3.active {
      width: 42%;
    }

    #{$root}__bar--4.active {
      width: 33%;
    }

    .graph__label {
      right: 15px;
    }
  }

  &.t-dental {

    #{$root}__bar--1.active {
      width: 70%;
    }

    #{$root}__bar--2.active {
      width: 65%;
    }

    #{$root}__bar--3.active {
      width: 60%;
    }
  }
}

// ZERO 메디컬보험 그래프
.graph-container.t-medical {
  position: relative;
  width: 500px;

  .graph-data-wrap {
    height: 264px;
  }

  .graph-grid {
    height: inherit;
    background: url('../img/insurance/bg_graph_line_2.png') repeat-x 0 100%;
  }

  .graph__item {
    width: 60px;
    height: 264px;
    margin: 0 8px;

    .graph__value {
      font-size: 19px;
      color: $black;
    }
  }

  .graph__bar {
    border-radius: 0;
    background-color: #e0e9f2;
  }

  .graph__bar--1.active {
    height: 85%;
  }

  .graph__bar--2.active {
    height: 63%;
  }

  .graph__bar--3.active {
    height: 37%;
  }

  .graph__bar--4.active {
    height: 28%;
  }

  .graph__bar--5.active {
    height: 26%;
  }

  .graph__bar--6.active {
    height: 22%;
  }

  .average-value {
    position: absolute;
    width: 100%;
    height: 30px;
    top: 165px;
    text-align: right;
    background: url('../img/insurance/bg_graph_standard_line_1.png'),
    url('../img/insurance/bg_graph_standard_line_2.png');
    background-repeat: no-repeat, no-repeat;
    background-position: 0 50%, 100% 50%;

    span {
      position: relative;
      right: 30px;
      padding: 0 1px;
      line-height: 30px;
      display: inline-block;
      font-family: 'youandiB';
      background-color: #f8f8f8;
      font-size: 19px;
    }

    &:before {
      content: '';
      display: block;
      position: absolute;
      left: 0;
      top: 15px;
      width: 100%;
      height: 1px;
      background-color: $blue;
    }
  }
}
