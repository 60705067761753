// $modal-full: 100% !default;
$modal-full: 1300px !default;
$modal-lg: 900px !default;
$modal-md: 540px !default;
$modal-sm: 400px !default;

// $body-drop-shadow: 0 18 100 0.3 #000

.has-modal {
  overflow: hidden !important;

  .c-modal {
    overflow-x: hidden;
    overflow-y: hidden;
  }
}

.c-modal {
  $root : ".c-modal";

  position: fixed;
  top: 0;
  left: 0;
  z-index: $zindex-modal;
  display: none;
  width: 100%;
  height: 100%;
  overflow: hidden;
  padding-right: 0 !important;

    &:focus .c-modal__content {
      // outline: 1px dotted #fff;
      outline: 5px auto -webkit-focus-ring-color;
    }

  &__dialog {
    position: relative;
    pointer-events: none;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
  }

  &__content {
    overflow: hidden;
    position: relative;
    display: flex;
    flex-direction: column;
    width: 540px;
    pointer-events: auto;
    background-clip: border-box;
    background-color: $white;
    @include border-radius(30px);
    @include box-shadow(0 .125rem .25rem rgba($black, .075));
  }

  &__close {
    position: absolute;
    top: 38px;
    right: 24px;
    width: 30px;
    height: 30px;
    background: url('../img/com/btn_modal_close.png') no-repeat 50% 50%/17px auto;
    z-index: 101;
  }

  &__title {
    padding: 55px 50px 0;
    font-family: youandiR;
    font-size: 23px;
    background-color: $white;

    &--center {
      text-align: center;
    }
  }

  &__body {
    position: relative;
    flex: 1 1 auto;
    overflow-y: auto;
    max-height: 600px;
    margin: 15px 20px 20px;
    padding: 15px 30px 20px;
    background-color: $white;

    // foot 없는 경우
    &:last-of-type {
      margin-bottom: 60px;
      padding-bottom: 0;
    }
  }

  &__foot {
    position: relative;
    display: flex;
    justify-content: center;
    min-height: 60px;

    &.bt {
      border-top: 1px solid #e8e8e8;
    }

    #{$root}__button {
      height: 60px;
      margin: 0;
      border-radius: 0;

      &:first-child {
        border-bottom-left-radius: 15px;
      }

      &:last-child {
        border-bottom-right-radius: 15px;
      }
    }
  }

  &__button {
    flex: 1;
    margin: 0 6px;
    height: 52px;
    background-color: $white;
    font-size: 18px;

    // 취소, 확인 임시 스타일
    &--logout,
    &--cancel {
      border: 1px solid #b8b8b8;
      border-radius: 6px;
    }

    &--confirm {
      border-radius: 6px;
      background-color: #0078ca;
      color: $white;

      &:focus {
        outline: 2px solid yellow;
      }
    }
  }

  .o-button.gradient {
    &:focus {
      outline: 2px solid yellow;
    }
  }

  // 오늘 하루 보지 않기
  &__today {
    position: relative;
    height: 24px;
    margin-top: 20px;
    pointer-events: auto;

    .today-close {
      border: 1px solid $white;
      color: $white;
    }
  }

  // 활성화 효과
  &.fade {
    #{$root}__dialog {
      @include transition(transform .3s ease-out);
      transform: translate(0, -50px);
    }
  }

  &.is-active {
    #{$root}__title,
    #{$root}__body,
    #{$root}__foot {
      opacity: 1;
    }

    #{$root}__dialog {
      transform: none;
    }

    #{$root}--xl {
      #{$root}__content {
        width: $modal-full;
      }

      .c-button-wrap {
        display: block;
      }
    }

    #{$root}--lg {
      #{$root}__content {
        width: $modal-lg;
      }

      .c-button-wrap {
        display: block;
      }
    }
  }

  // size : 400
  &--sm {
    #{$root}__content {
      width: $modal-sm;
      overflow: visible;
      border-top-left-radius: 15px;
      border-top-right-radius: 15px;
      // background-color: transparent;
      // border: 1px solid #d8d8d8;

      .content {
        letter-spacing: -0.1px;
      }
    }

    #{$root}__title {
      padding-top: 44px;
      font-size: 20px;
      font-family: youandiB;
      border-top-left-radius: 15px;
      border-top-right-radius: 15px;
    }

    #{$root}__body {
      font-size: 17px;

      &:last-child {
        padding-bottom: 20px;
        margin-bottom: 15px;
      }
    }

    #{$root}__foot {
      min-height: initial;

      .o-button.full {
        margin: 0;
        border-radius: 0;
      }

      .o-button.full,
      .c-modal__button--confirm:only-child {
        border-bottom-left-radius: 15px;
        border-bottom-right-radius: 15px;
      }
    }
  }

  // size : 540
  &--md {
    #{$root}__content {
      width: $modal-md;
    }

    // 선택한 계약목록 팝업 (md 사이즈면서, c-modal__title 없이 c-modal__body만 존재)
    #{$root}__title[hidden] ~ #{$root}__body {
      margin: 80px 20px 30px;
      padding-top: 0;

      &:only-of-type {
        margin-bottom: 60px;
      }
    }

    // 헤딩, 텍스트 md 사이즈에서만 다름
    .o-heading--3 {
      margin-top: 23px;
      margin-bottom: 24px;
      font-family: inherit;

      &.webfont-b {
        font-family: 'youandiB';
      }
    }

    .c-text-list--dot .c-text-list__item {
      font-size: 15px;
      line-height: 24px;

      &:nth-child(n+2) {
        margin-top: 5px;
      }

      &::before {
        top: 10px;
      }
    }
  }

  // size : 900, 1280
  &--lg,
  &--xl {
    #{$root}__content {
      width: 100%;
      height: 100%;
      margin: 0 auto;
      border-radius: 0;
      background-color: $white;
      // transition: width .5s;
    }

    #{$root}__close {
      background-size: 21px auto;
      top: 46px;
      right: 56px;
    }

    #{$root}__title {
      padding: 45px 60px 0;
      opacity: 0;
      // transition: opacity .1s .4s;
    }

    #{$root}__body {
      overflow-y: hidden;
      padding: 15px 40px 45px;
      margin-bottom: 0;
      max-height: 100%;
      opacity: 0;
      // transition: opacity .1s .4s;

      &:last-child {
        margin-bottom: 60px;
      }

      // ie11에서 스크롤 영역 문제로 제거
      // &:after {
      //   content: '';
      //   position: absolute;
      //   bottom: 0;
      //   left: 0;
      //   display: block;
      //   width: 100%;
      //   height: 50px;
      //   background: url('../img/com/bg_gradient_popup.png') repeat-x 0 bottom;
      // }
    }

    #{$root}__foot {
      padding: 40px 0;
      min-height: auto;

      .o-button.xl {
        &:not(:only-child) {
          width: 220px;
        }

        &:nth-child(n+2) {
          margin-left: 10px;
        }
      }
    }
  }

  // 필터
  .c-form--filter {
    padding-left: 25px;
    padding-right: 205px;
    background-color: #f8f8f8;

    &:before {
      display: none;
    }

    .c-form__button {
      right: 25px;
    }
  }

  // 안내
  .info {
    padding-left: 25px;
    padding-right: 25px;
    background-color: #f8f8f8;

    &:before {
      display: none;
    }
  }

  // 버튼
  .c-button-wrap {
    margin-top: 40px;
  }

  // 간격 초기화
  .scroll-wrapper > *:first-child,
  .c-modal__body > *:first-child,
  .c-modal__body .info-wrap--type3:first-child {
    margin-top: 0;
  }
}

// Measure scrollbar width for padding body during modal show/hide
.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 0;
  overflow: scroll;
}

// @include media-breakpoint-up(sm) {
//   .c-modal__content {
//     max-width: $modal-lg;

//     .c-modal--sm & {
//       max-width: $modal-sm;
//     }

//     .c-modal--md & {
//       max-width: $modal-md;
//     }

//     .c-modal--lg & {
//       max-width: $modal-lg;
//     }

//     .c-modal--xl & {
//       max-width: $modal-xl;
//     }
//   }
// }

// 주소검색
.modal-address {
  $root : ".modal-address";

  height: 465px;

  &__list {
    margin-top: 30px;
    border-top: 1px solid #666;
    border-bottom: 1px solid #d2d2d2;
    overflow-y: hidden;

    &--type1 {
      height: 327px;
    }

    &--type2 {
      height: 385px;
    }
  }

  &__button {
    width: 100%;
    padding: 18px 18px 16px;
    text-align: left;

    &:hover,
    &:focus {
      background-color: #f8f8f8;
    }
  }

  &__code {
    display: block;
    font-size: 13px;
    color: #666;
  }

  &__title {
    display: block;
    margin-top: 6px;
    font-size: 15px;
    line-height: 21px;

    #{$root}__button:hover &,
    #{$root}__button:focus & {
      text-decoration: underline;
      color: #0076c0;
    }

    #{$root}__item.is-active & {
      color:#0076c0;
    }
  }

  &__detail {
    position: relative;
    padding: 0 20px;
    background-color: #f8f8f8;
  }

  &__form {
    padding: 10px 0;
    padding-right: 87px;

    &--change {
      position: relative;
      padding-right: 0;

      #{$root}__input {
        padding-right: 138px;
      }
    }
  }

  &__change {
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
    display: block;
    height: 30px;
    padding: 0 10px;
    border: 1px solid #d8d8d8;
    border-radius: 4px;
    background-color: #fff;
    font-size: 14px;
    color: #333;
  }

  &__result {
    position: relative;
    margin: 0 -20px;
    padding: 17px 105px 17px 20px;
    background-color: #0076c0;
  }

  &__text {
    font-size: 15px;
    line-height: 21px;
    color: #fff;
  }

  &__register {
    position: absolute;
    top: 50%;
    right: 20px;
    transform: translateY(-50%);
    display: block;
    width: 67px;
    height: 40px;
    border: 1px solid #0076c0;
    border-radius: 4px;
    background-color: #fff;
    color: #0076c0;
    font-size: 15px;
    font-family: youandiR;
    line-height: 38px;
  }

  .search-form {
    margin-top: -40px;

    &__input {
      margin-left: 0 !important;
    }
  }
}

// COM020201010000 - 카카오페이 로그인
.c-modal--md {
  .require {
    margin-right: 8px;
  }

  .agree-check__item--all {
    margin-top: -51px;
    margin-bottom: 27px;

    .o-input--check + label {
      padding-left: 32px !important;
    }
  }

  .c-accordion {
    &--type2 {
      .c-accordion__head {
        padding: 0;
      }

      .o-input--check.o-input--small + label {
        margin: 0;
        padding: 10px 0 12px 60px;
        font-size: 15px;

        &::before {
          left: 20px;
        }

        &::after {
          left: 25px;
        }
      }
    }

    &__button {
      &::after,
      &::before {
        right: 20px;
      }
    }
  }

  .o-button.lg {
    height: 60px;
  }
}

/* 토스트형 알럿 */
.c-modal__dialog--toast {
  .c-modal__content {
    width: 320px;
    border: 0;
    border-radius: 14px;
    background-color: transparent;
    overflow: hidden;
  }

  .c-modal__body {
    margin: 0 !important;
    padding: 30px 40px !important;
    background: rgba(0,0,0,.75);
    text-align: center;
    font-size: 15px;
    color: #fff;
  }
}